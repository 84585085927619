import React from 'react';
import { Wrapper } from './grid.css';

export interface IContainer {
    width?: string;
}

export const Container: React.FC<IContainer> = props => {
    const { width, children } = props;
    return <Wrapper width={width}>{children}</Wrapper>;
};
