import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import FormLabel from '@components/_common/_form/FormLabel';
import TextField from '@components/_common/_form/TextField/TextField';
import ErrorMsg from '@components/_common/_form/ErrorMsg/ErrorMsg';
import CheckboxGroup from '@components/_common/_form/CheckboxGroup/CheckboxGroup';
import { Form, Formik } from 'formik';
import FileUpload from '@components/_common/_form/FileUpload';
import Selectbox from '@components/_common/_form/Selectbox/Selectbox';
import RadioGroup from '@components/_common/_form/RadioGroup/RadioGroup';
import Button from '@components/_common/Button';
import { useMSTranslation } from '@utils/useMSTranslation';
import * as Yup from 'yup';
import { APIEvents } from '@api/eventApi';
import { PageJoinActions } from '@actions/pages/join_actions';
import {
    SIGNUP_STATUS_ALREADYREGISTERED,
    SIGNUP_STATUS_ALREADYREGISTEREDREFUSED,
    SIGNUP_STATUS_ALREADYREGISTEREDVALIDATE,
    SIGNUP_STATUS_FULL,
    SIGNUP_STATUS_VALID
} from '@components/Join/Signup/SignupStatus';
import TextArea from '@components/_common/_form/TextArea';
import { AuthActions } from '@actions/auth_actions';
import { displayError } from '@components/_common/Toast';
import { toast } from 'react-toastify';
import { getLocalStorage } from '@utils/localStorage';

interface IFieldsItem {
    field: string;
    labelfield: string;
    mandatory: boolean;
    listItems: string[];
}
interface ICheckBox {
    value: string;
    label: string;
}
enum EnumPaternName {
    LASTNAMEFIRSTNAME = 'LASTNAMEFIRSTNAME',
    FIRSTNAMELASTNAME = 'FIRSTNAMELASTNAME',
    FIRSTNAMEONLY = 'FIRSTNAMEONLY',
    LASTNAMEONLY = 'LASTNAMEONLY',
    USERNAME = 'USERNAME'
}

const SignupForm = () => {
    // Infos de l'evenement
    const infosEvent = useSelector((state: IStore) => state.Data.event);

    const { __ } = useMSTranslation(['struct', 'join']);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const dispatch = useDispatch();

    const tabFieldsHtml: JSX.Element[] = [];

    const initialValues: any = { nom: '', prenom: '' };
    const validation: any = {};

    const [loading, setLoading] = useState(false);

    if (!infosEvent) return <></>;

    // On construit le formulaire
    // Choix des champs principaux
    switch (infosEvent.paternName) {
        case EnumPaternName.FIRSTNAMELASTNAME:
            initialValues['prenom'] = '';
            initialValues['nom'] = '';
            validation['prenom'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            validation['nom'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            tabFieldsHtml.push(
                <div key={'firstnamelastname'}>
                    <div className='mt-2' key={'prenom'}>
                        <div className='w-full'>
                            <FormLabel htmlFor='prenom'>
                                {__('Prénom', 'struct')}
                            </FormLabel>
                            <TextField name='prenom' id='prenom' />
                            <ErrorMsg name='prenom' />
                        </div>
                    </div>
                    <div className='mt-2' key={'nom'}>
                        <div className='w-full'>
                            <FormLabel htmlFor='nom'>
                                {__('Nom', 'struct')}
                            </FormLabel>
                            <TextField name='nom' id='nom' />
                            <ErrorMsg name='nom' />
                        </div>
                    </div>
                </div>
            );
            break;
        case EnumPaternName.FIRSTNAMEONLY:
            initialValues['prenom'] = '';
            validation['prenom'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            tabFieldsHtml.push(
                <div className='mt-2' key={'prenom'}>
                    <div className='w-full'>
                        <FormLabel htmlFor='prenom'>
                            {__('Prénom', 'struct')}
                        </FormLabel>
                        <TextField name='prenom' id='prenom' />
                        <ErrorMsg name='prenom' />
                    </div>
                </div>
            );
            break;
        case EnumPaternName.LASTNAMEFIRSTNAME:
            initialValues['prenom'] = '';
            initialValues['nom'] = '';
            validation['prenom'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            validation['nom'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            tabFieldsHtml.push(
                <div key={'lastnamefirstname'}>
                    <div className='mt-2' key={'nom'}>
                        <div className='w-full'>
                            <FormLabel htmlFor='nom'>
                                {__('Nom', 'struct')}
                            </FormLabel>
                            <TextField name='nom' id='nom' />
                            <ErrorMsg name='nom' />
                        </div>
                    </div>
                    <div className='mt-2' key={'prenom'}>
                        <div className='w-full'>
                            <FormLabel htmlFor='prenom'>
                                {__('Prénom', 'struct')}
                            </FormLabel>
                            <TextField name='prenom' id='prenom' />
                            <ErrorMsg name='prenom' />
                        </div>
                    </div>
                </div>
            );
            break;
        case EnumPaternName.LASTNAMEONLY:
            initialValues['nom'] = '';
            validation['nom'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            tabFieldsHtml.push(
                <div className='mt-2' key={'nom'}>
                    <div className='w-full'>
                        <FormLabel htmlFor='nom'>
                            {__('Nom', 'struct')}
                        </FormLabel>
                        <TextField name='nom' id='nom' />
                        <ErrorMsg name='nom' />
                    </div>
                </div>
            );
            break;
        case EnumPaternName.USERNAME:
            initialValues['username'] = '';
            validation['username'] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));
            tabFieldsHtml.push(
                <div className='mt-2' key={'username'}>
                    <div className='w-full'>
                        <FormLabel htmlFor='username'>
                            {__('Pseudonyme', 'struct')}
                        </FormLabel>
                        <TextField name='username' id='username' />
                        <ErrorMsg name='username' />
                    </div>
                </div>
            );
            break;
    }

    // Ensuite les champs dynamiques
    let emailPassed = false;
    infosEvent.fields.forEach((item: IFieldsItem, index: number) => {
        let nom_item = 'item' + index;
        if (item.field == 'file') nom_item += '_file';
        initialValues[nom_item] = '';

        // Gestion du champ obligatoire
        if (item.mandatory)
            validation[nom_item] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));

        switch (item.field) {
            case 'email':
                initialValues['email'] = '';
                validation['email'] = Yup.string()
                    .trim()
                    .required(__('Obligatoire', 'struct'));
                if (!emailPassed) nom_item = 'email';
                emailPassed = true;
                tabFieldsHtml.push(
                    <div className='mt-2' key={nom_item}>
                        <div className='w-full'>
                            <FormLabel htmlFor={nom_item}>
                                {item.labelfield}
                            </FormLabel>
                            <TextField name={nom_item} id={nom_item} />
                            <ErrorMsg name={nom_item} />
                        </div>
                    </div>
                );
                break;
            case 'checkbox':
                const checkboxItems: ICheckBox[] = [];
                item.listItems.forEach((value: any) => {
                    checkboxItems.push({ value: value, label: value });
                });
                if (checkboxItems.length > 0)
                    tabFieldsHtml.push(
                        <div className='mt-2' key={nom_item}>
                            <div className='w-full'>
                                <FormLabel htmlFor={nom_item}>
                                    {item.labelfield}
                                </FormLabel>
                                <CheckboxGroup
                                    name={nom_item}
                                    items={checkboxItems}
                                    direction='horizontal'
                                />
                                <ErrorMsg name={nom_item} />
                            </div>
                        </div>
                    );
                break;
            case 'textarea':
                tabFieldsHtml.push(
                    <div className='mt-2' key={nom_item}>
                        <div className='w-full'>
                            <FormLabel htmlFor={nom_item}>
                                {item.labelfield}
                            </FormLabel>
                            <TextArea name={nom_item} id={nom_item} />
                            <ErrorMsg name={nom_item} />
                        </div>
                    </div>
                );
                break;
            case 'textfield':
                tabFieldsHtml.push(
                    <div className='mt-2' key={nom_item}>
                        <div className='w-full'>
                            <FormLabel htmlFor={nom_item}>
                                {item.labelfield}
                            </FormLabel>
                            <TextField name={nom_item} id={nom_item} />
                            <ErrorMsg name={nom_item} />
                        </div>
                    </div>
                );
                break;
            case 'file':
                tabFieldsHtml.push(
                    <div className='mt-2' key={nom_item}>
                        <div className='w-full'>
                            <FormLabel htmlFor={nom_item}>
                                {item.labelfield} - (
                                {__('PDF uniquement', 'join')})
                            </FormLabel>
                            <FileUpload
                                name={nom_item}
                                accept='application/pdf'
                            />
                            <ErrorMsg name={nom_item} />
                        </div>
                    </div>
                );
                break;
            case 'selectbox':
                const selectboxItems: any[] = [];
                item.listItems.forEach((value: any) => {
                    selectboxItems.push({ value: value, label: value });
                });
                if (selectboxItems.length > 0)
                    tabFieldsHtml.push(
                        <div className='mt-2' key={nom_item}>
                            <div className='w-full'>
                                <FormLabel htmlFor={nom_item}>
                                    {item.labelfield}
                                </FormLabel>
                                <Selectbox
                                    items={selectboxItems}
                                    name={nom_item}
                                    placeholder=''
                                />
                                <ErrorMsg name={nom_item} />
                            </div>
                        </div>
                    );
                break;
            case 'selectboxMultiple':
                initialValues[nom_item] = [];
                const selectboxmItems: any[] = [];
                item.listItems.forEach((value: any) => {
                    selectboxmItems.push({ value: value, label: value });
                });
                if (selectboxmItems.length > 0)
                    tabFieldsHtml.push(
                        <div className='mt-2' key={nom_item}>
                            <div className='w-full'>
                                <FormLabel htmlFor={nom_item}>
                                    {item.labelfield}
                                </FormLabel>
                                <Selectbox
                                    items={selectboxmItems}
                                    name={nom_item}
                                    placeholder=''
                                    multiple={true}
                                />
                                <ErrorMsg name={nom_item} />
                            </div>
                        </div>
                    );
                break;
            case 'radiosBtn':
                const checkboxrItems: ICheckBox[] = [];
                item.listItems.forEach((value: any) => {
                    checkboxrItems.push({ value: value, label: value });
                });
                if (checkboxrItems.length > 0)
                    tabFieldsHtml.push(
                        <div className='mt-2' key={nom_item}>
                            <div className='w-full'>
                                <FormLabel htmlFor={nom_item}>
                                    {item.labelfield}
                                </FormLabel>
                                <RadioGroup
                                    name={nom_item}
                                    items={checkboxrItems}
                                    direction='horizontal'
                                    variant='button'
                                />
                                <ErrorMsg name={nom_item} />
                            </div>
                        </div>
                    );
                break;
        }
    });

    const validationSchema = Yup.object().shape(validation);

    let verbe = __('Rejoindre', 'join');
    if (infosEvent.type == 'INSCRIPTION') verbe = __("S'inscrire", 'join');

    const onSubmitForm = (values: any) => {
        dispatch(PageJoinActions.setEmailSignup(values.email));
        setLoading(true);
        if (ctoken && infosEvent.roomId && infosEvent.type == 'INSCRIPTION')
            APIEvents.inscription(ctoken, infosEvent.roomId, values).then(r => {
                const isBanned = getLocalStorage('isBanned');
                if (isBanned || (r.data && r.data.error == 'banned')) {
                    toast.dismiss();
                    setLoading(false);
                    return displayError(
                        __('Vous avez été banni de cet événement !', 'join'),
                        {
                            toastId: 'banned'
                        }
                    );
                } else if (r.data && r.data.error == 'already_registered') {
                    if (r.data.participation == 'WAITING')
                        dispatch(
                            PageJoinActions.setStatusSignup(
                                SIGNUP_STATUS_ALREADYREGISTERED
                            )
                        );
                    else if (r.data.participation == 'SELECTED')
                        dispatch(
                            PageJoinActions.setStatusSignup(
                                SIGNUP_STATUS_ALREADYREGISTEREDVALIDATE
                            )
                        );
                    else if (r.data.participation == 'REFUSED')
                        dispatch(
                            PageJoinActions.setStatusSignup(
                                SIGNUP_STATUS_ALREADYREGISTEREDREFUSED
                            )
                        );
                } else if (r.data && r.data.error == 'full') {
                    dispatch(
                        PageJoinActions.setStatusSignup(SIGNUP_STATUS_FULL)
                    );
                } else if (r.data.status) {
                    dispatch(
                        PageJoinActions.setStatusSignup(SIGNUP_STATUS_VALID)
                    );
                }
            });
        // Si c'est un event sans inscription
        else {
            if (ctoken)
                dispatch(
                    AuthActions.inscriptionFree(values, infosEvent, ctoken)
                );
        }
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmitForm}
                validationSchema={validationSchema}
            >
                <Form>
                    {tabFieldsHtml.length > 0 && tabFieldsHtml}
                    <div className='mt-3'>
                        <Button
                            variant='action'
                            type='submit'
                            isLoading={loading}
                            loadingText={__('Chargement', 'struct') + '...'}
                            disabled={loading}
                        >
                            {verbe}
                        </Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default SignupForm;
