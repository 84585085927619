import React from 'react';
import styled from 'styled-components';
import { useMSTranslation } from '@utils/useMSTranslation';
import { Container } from '@components/_struct/_layouts/Grid';
import { bp } from '@components/_struct/variables';
import BigTabs from '@components/_common/BigTabs';

const SectionMasked = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 1;
`;

const SectionBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const SectionContainer = styled.div`
    position: relative;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 100px;
    padding-bottom: 180px;
    @media (${bp.md}) {
        padding-top: 50px;
        padding-bottom: 85px;
    }
`;

const SectionBackgroundStart = styled.div`
    position: absolute;
    top: 108px;
    left: -65px;
    filter: drop-shadow(13px 10px 60px rgba(0, 0, 0, 0.1));
    opacity: 0.5;
    svg {
        fill: white;
        width: 300px;
        height: 342px;
        transform: rotate(20.3deg);
    }
    @media (${bp.md}) {
        display: none;
    }
`;

const SectionBackgroundEnd = styled.div`
    position: absolute;
    bottom: 0px;
    right: -220px;
    filter: drop-shadow(13px 10px 144px rgba(0, 0, 0, 0.1));
    opacity: 0.5;
    svg {
        fill: white;
        width: 491px;
        height: 430px;
        transform: rotate(88.24deg);
    }
    @media (${bp.md}) {
        display: none;
    }
`;

const TitleSection = styled.div`
    position: relative;
    font-size: 2.5rem;
    color: #1c287f;
    font-weight: bold;
    display: inline-flex;
    &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: -6px;
        width: 19px;
        height: 21px;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='21' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 14.012C24 14.0887 24 14.1655 24 14.2353V14.4236C24 14.4236 24 14.4655 24 14.4864C23.9361 14.888 23.7879 15.2677 23.5672 15.5957C23.4958 15.6958 23.4193 15.7913 23.338 15.8818C18.3577 21.8896 11.8367 26.1067 4.63076 27.9797H4.55438C4.2026 28.0068 3.84953 28.0068 3.49776 27.9797L3.08402 27.8402L2.90579 27.7495C2.85508 27.7258 2.80614 27.6978 2.75939 27.6658C2.64026 27.5847 2.5255 27.4962 2.41568 27.4007C2.14406 27.0787 1.90503 26.7255 1.70277 26.3471C1.70186 26.3262 1.70186 26.3053 1.70277 26.2844V26.2844C1.70277 26.2495 1.70278 26.2216 1.67095 26.1936C1.63913 26.1657 1.60094 25.8936 1.67095 26.082C-0.556984 18.2182 -0.556984 9.79877 1.67095 1.93499V1.93499C1.67095 1.90011 1.67095 1.86522 1.67095 1.83732C1.8088 1.35318 2.07141 0.923048 2.42715 0.598732C2.78288 0.274416 3.21657 0.0697457 3.67598 0.0093701H3.746C3.98004 -0.0115222 4.21559 0.00256154 4.44617 0.0512324H4.49072L4.63076 0.0861163C11.7838 1.95794 18.2603 6.13808 23.2235 12.0864L23.2871 12.1631C23.3508 12.2329 23.4144 12.3096 23.4717 12.3864C23.7004 12.7029 23.8595 13.0729 23.9364 13.4678C23.9534 13.5601 23.9661 13.6532 23.9745 13.7469C23.9745 13.7469 23.9745 13.8097 23.9745 13.8445L24 14.012Z' fill='%2303CE9C'/%3E%3C/svg%3E%0A");
    }
    @media (${bp.md}) {
        font-size: 2rem;
    }
`;

const MaxW869 = styled.div`
    max-width: 869px;
    width: 100%;
`;

const HowItWorksSection = () => {
    const { __ } = useMSTranslation(['home']);
    return (
        <SectionMasked>
            <SectionBackground>
                <SectionBackgroundStart>
                    <svg
                        width='300'
                        height='342'
                        viewBox='0 0 350 399'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path d='M350 199.671C350 200.765 350 201.858 350 202.852V205.537C350 205.537 350 206.133 350 206.432C349.068 212.153 346.907 217.565 343.688 222.239C342.647 223.665 341.532 225.026 340.346 226.316C267.716 311.926 172.618 372.02 67.5319 398.711H66.418C61.288 399.096 56.139 399.096 51.0089 398.711L44.9753 396.723L42.3761 395.43C41.6366 395.093 40.9228 394.694 40.2411 394.237C38.5038 393.082 36.8302 391.821 35.2286 390.459C31.2676 385.872 27.7817 380.838 24.8321 375.447C24.8187 375.149 24.8187 374.85 24.8321 374.552V374.552C24.8321 374.055 24.8322 373.657 24.3681 373.259C23.9039 372.862 23.347 368.984 24.3681 371.669C-8.12268 259.61 -8.12268 139.633 24.3681 27.5736V27.5736C24.3681 27.0765 24.3681 26.5794 24.3681 26.1817C26.3784 19.2828 30.2081 13.1534 35.3959 8.53193C40.5837 3.91043 46.9083 0.993876 53.608 0.133524L54.6291 0.133524C58.0423 -0.164191 61.4774 0.0365019 64.84 0.730062L65.4897 0.730062L67.5319 1.22716C171.847 27.9007 266.297 87.4677 338.675 172.231L339.604 173.324C340.532 174.319 341.46 175.412 342.296 176.506C345.631 181.016 347.95 186.289 349.072 191.916C349.32 193.231 349.506 194.558 349.629 195.893C349.629 195.893 349.629 196.788 349.629 197.285L350 199.671Z' />
                    </svg>
                </SectionBackgroundStart>
                <SectionBackgroundEnd>
                    <svg
                        width='350'
                        height='399'
                        viewBox='0 0 350 399'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path d='M350 199.671C350 200.765 350 201.858 350 202.852V205.537C350 205.537 350 206.133 350 206.432C349.068 212.153 346.907 217.565 343.688 222.239C342.647 223.665 341.532 225.026 340.346 226.316C267.716 311.926 172.618 372.02 67.5319 398.711H66.418C61.288 399.096 56.139 399.096 51.0089 398.711L44.9753 396.723L42.3761 395.43C41.6366 395.093 40.9228 394.694 40.2411 394.237C38.5038 393.082 36.8302 391.821 35.2286 390.459C31.2676 385.872 27.7817 380.838 24.8321 375.447C24.8187 375.149 24.8187 374.85 24.8321 374.552V374.552C24.8321 374.055 24.8322 373.657 24.3681 373.259C23.9039 372.862 23.347 368.984 24.3681 371.669C-8.12268 259.61 -8.12268 139.633 24.3681 27.5736V27.5736C24.3681 27.0765 24.3681 26.5794 24.3681 26.1817C26.3784 19.2828 30.2081 13.1534 35.3959 8.53193C40.5837 3.91043 46.9083 0.993876 53.608 0.133524L54.6291 0.133524C58.0423 -0.164191 61.4774 0.0365019 64.84 0.730062L65.4897 0.730062L67.5319 1.22716C171.847 27.9007 266.297 87.4677 338.675 172.231L339.604 173.324C340.532 174.319 341.46 175.412 342.296 176.506C345.631 181.016 347.95 186.289 349.072 191.916C349.32 193.231 349.506 194.558 349.629 195.893C349.629 195.893 349.629 196.788 349.629 197.285L350 199.671Z' />
                    </svg>
                </SectionBackgroundEnd>
            </SectionBackground>
            <SectionContainer>
                <Container>
                    <div className='text-center mb-2'>
                        <TitleSection>
                            {__('Comment ça fonctionne ?', 'home')}
                        </TitleSection>
                    </div>
                    <MaxW869 className='text-2xl lg:text-3xl leading-normal mb-4 text-center mx-auto'>
                        {__('Vous utilisez la visioconférence,', 'home')}{' '}
                        <strong>
                            {__(
                                'Viseet la façonne pour vous offrir une expérience d’évènement virtuel sans pareil.',
                                'home'
                            )}
                        </strong>
                    </MaxW869>
                    <BigTabs>
                        <BigTabs.Panel
                            tab={__('Création d’événement', 'home')}
                            key='1'
                            icon={['fad', 'calendar-star']}
                        >
                            <div className='flex -mx-1 flex-col md:flex-row'>
                                <img
                                    className='px-1 w-full md:w-7/12'
                                    src='/images/gif-crea-event.gif'
                                    alt='Gif création event'
                                />
                                <div className='px-1 w-full md:w-5/12 mt-2 md:mt-0'>
                                    <div className='text-xl md:text-2xl font-bold leading-normal'>
                                        {__(
                                            'Création de votre événement en 1 minute.',
                                            'home'
                                        )}
                                    </div>
                                    <div className='mt-2 leading-normal text-base md:text-xl text-gray80'>
                                        {__(
                                            "Créez votre évènement à votre image, optimisez vos inscriptions, offrez un accueil digital à vos participants, et bien d'autres fonctionnalités pour gérer votre évènement de A à Z.",
                                            'home'
                                        )}
                                    </div>
                                </div>
                            </div>
                        </BigTabs.Panel>
                        <BigTabs.Panel
                            tab={__('Gestion des participants', 'home')}
                            key='2'
                            icon={['fad', 'users']}
                        >
                            <div className='flex -mx-1 flex-col md:flex-row'>
                                <img
                                    className='px-1 w-full md:w-7/12'
                                    src='/images/gif-gestion-participant.gif'
                                    alt='Gif gestion participant'
                                />
                                <div className='px-1 w-full md:w-5/12 mt-2 md:mt-0'>
                                    <div className='text-xl md:text-2xl font-bold leading-normal'>
                                        {__('Une gestion simplifiée', 'home')}
                                    </div>
                                    <div className='mt-2 leading-normal text-base md:text-xl text-gray80'>
                                        <p>
                                            {__(
                                                'Gérer un événement n’a jamais été aussi simple.',
                                                'home'
                                            )}
                                        </p>
                                        <p>
                                            {__(
                                                'Vous pouvez consulter et sélectionner les inscrits en un clic. Et vous avez même la possibilité de faire du mailing personnalisé directement sur la plateforme',
                                                'home'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </BigTabs.Panel>
                        <BigTabs.Panel
                            tab={__('Evènement interactif', 'home')}
                            key='3'
                            icon={['fad', 'mouse-pointer']}
                        >
                            <div className='flex -mx-1 flex-col md:flex-row'>
                                <img
                                    className='px-1 w-full md:w-7/12'
                                    src='/images/gif-event-interactif.gif'
                                    alt='gif-event-interactif'
                                />
                                <div className='px-1 w-full md:w-5/12 mt-2 md:mt-0'>
                                    <div className='text-xl md:text-2xl font-bold leading-normal'>
                                        {__('Un évènement modulable', 'home')}
                                    </div>
                                    <div className='mt-2 leading-normal text-base md:text-xl text-gray80'>
                                        <p>
                                            {__(
                                                'Créez un évènement personnalisé avec plusieurs salons et un contenu unique.',
                                                'home'
                                            )}
                                        </p>
                                        <p>
                                            {__(
                                                'Chacun peut aller d’une salle à l’autre et interagir comme en physique. Vous pouvez même inviter chacun des participants à échanger en privé',
                                                'home'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </BigTabs.Panel>
                        <BigTabs.Panel
                            tab={__('L’après évènement', 'home')}
                            key='4'
                            icon={['fad', 'history']}
                        >
                            <div className='flex -mx-1 flex-col md:flex-row'>
                                <img
                                    className='px-1 w-full md:w-7/12'
                                    src='/images/gif-apres-event.gif'
                                    alt='gif-apres-event'
                                />
                                <div className='px-1 w-full md:w-5/12 mt-2 md:mt-0'>
                                    <div className='text-xl md:text-2xl font-bold leading-normal'>
                                        {__(
                                            'Gardez une trace de votre évènement',
                                            'home'
                                        )}
                                    </div>
                                    <div className='mt-2 leading-normal text-base md:text-xl text-gray80'>
                                        <p>
                                            {__(
                                                'Retrouvez l’intégralité de votre évènement sur la plateforme et gardez contact avec vos participants.',
                                                'home'
                                            )}
                                        </p>
                                        <p>
                                            {__(
                                                'Vous pouvez récupérer l’intégralité des données comme les profils, les notes et réponses au sondage.',
                                                'home'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </BigTabs.Panel>
                    </BigTabs>
                </Container>
            </SectionContainer>
        </SectionMasked>
    );
};

export default HowItWorksSection;
