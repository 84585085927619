import React, {
    useRef,
    SyntheticEvent,
    InputHTMLAttributes,
    useState
} from 'react';
import Button from '@components/_common/Button';
import { HiddenInputFile } from './FileUpload.css';
import { useField, FormikHandlers } from 'formik';
import FileUploadMultiTag from './FileUploadMultiTag';

interface IFileUpload extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    /** Action à effectuer après la sélection */
    onChange?: FormikHandlers['handleChange'];
}

// Last update : 25/09/2020

const FileUpload = (props: IFileUpload) => {
    const { name, multiple, onChange } = props;
    const [, , helpers] = useField(props);

    const inputFile = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState('');

    const handleClickAdd = () => {
        inputFile.current && inputFile.current.click();
    };

    const handleChange = (e: SyntheticEvent) => {
        const elm = e.target as HTMLInputElement;
        if (!elm.files) return;
        if (multiple) {
            // Cas du multiple en base64 à traiter plus tard
            helpers.setValue(elm.files);
            onChange && onChange(elm.files);
        } else {
            // Conversion en base64
            const reader = new FileReader();
            reader.readAsDataURL(elm.files[0]);
            reader.addEventListener('load', () => {
                helpers.setValue(reader.result as string);
                if (elm.files) setFileName(elm.files[0].name);
            });
            onChange && onChange(elm.files[0]);
        }
    };

    return (
        <div>
            <Button
                variant='normal'
                leftIcon='cloud-upload'
                onClick={handleClickAdd}
                type='button'
            >
                Parcourir
            </Button>
            <HiddenInputFile
                type='file'
                {...props}
                id={name}
                ref={inputFile}
                onChange={handleChange}
            />
            <FileUploadMultiTag
                name={name}
                onChange={onChange}
                multiple={multiple}
                fileName={fileName}
                setFileName={setFileName}
                input={inputFile}
            />
        </div>
    );
};

export default FileUpload;
