import styled, { keyframes } from 'styled-components';
import { form } from '@components/_struct/variables';

// Last Update 22/05/2020

export const slideDownOut = keyframes`
    0% {
        transform: scaleY(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }
    100% {
        transform: scaleY(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
`;

export const slideUpOut = keyframes`
    0% {
        transform: scaleY(1);
        transform-origin: 0% 0%;
        opacity: 1;
    }
    100% {
        transform: scaleY(0.8);
        transform-origin: 0% 0%;
        opacity: 0;
    }
`;

export const SelectboxCont = styled.div`
    position: relative;
    &.selectbox-inline {
        height: 32px;
    }
`;

export const SelectboxControl = styled.div`
    display: flex;
    cursor: default;
    border: 2px solid ${form.borderDefault};
    font-size: ${form.fontSizeInput};
    background-color: #fff;
    color: #000;
    height: 45px;
    border-radius: 6px;
    width: 100%;
    box-shadow: none;
    transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
        border-color 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    ${SelectboxCont}.selectbox-isfocus & {
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
        border-color: ${form.borderFocus};
    }
    ${SelectboxCont}.selectbox-haserror & {
        border-color: ${form.borderError};
    }
    ${SelectboxCont}.selectbox-haserror.selectbox-isfocus & {
        box-shadow: 0 0 0 2px ${form.boxShadowError};
    }
    ${SelectboxCont}.selectbox-inline & {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid ${form.borderDefault};
        background-color: transparent;
        border-radius: 0;
        height: 32px;
    }
    ${SelectboxCont}.selectbox-inline.selectbox-haserror & {
        border-color: ${form.borderError};
    }
    ${SelectboxCont}.selectbox-inline.selectbox-isfocus & {
        border-color: ${form.borderFocus};
        box-shadow: 0 2px 0 0 ${form.boxShadowFocus};
    }
    ${SelectboxCont}.selectbox-inline.selectbox-isfocus.selectbox-haserror & {
        border-color: ${form.borderError};
    }
`;

export const SelectboxValueCont = styled.div`
    display: flex;
    flex: 1;
    padding: 0 5px 0 10px;
    align-items: center;
    position: relative;
    ${SelectboxCont}.selectbox-inline & {
        padding: 0 5px 0 5px;
    }
`;

export const SelectboxValueSingle = styled.div`
    position: absolute;
    top: 0px;
    left: 13px;
    line-height: 42px;
    font-size: ${form.fontSizeInput};
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${SelectboxCont}.selectbox-inline & {
        line-height: 32px;
        left: 6px;
        top: 0px;
    }
`;

export const SelectboxDummy = styled.input`
    position: relative;
    border: 0px none;
    outline: currentcolor none 0px;
    background: transparent;
    width: 100%;
    z-index: 10;
    font-size: ${form.fontSizeInput};
    height: 41px;
    ${SelectboxCont}.selectbox-inline & {
        height: 32px;
    }
    &.selectbox-readonly {
        background: rgba(0, 0, 0, 0) none repeat scroll 0px center;
        border: 0px none;
        font-size: inherit;
        outline: currentcolor none 0px;
        padding: 0px;
        width: 1px;
        color: transparent;
        left: -100px;
        opacity: 0;
        position: relative;
        transform: scale(0);
    }
`;

export const SelectboxPlaceholder = styled.div`
    user-select: none;
    color: ${form.placeholder};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const SelectboxIndicators = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    svg {
        transition: all 0.3s ease;
        color: #0a0e27;
        ${SelectboxCont}.selectbox-haserror & {
            color: ${form.borderError};
        }
        ${SelectboxCont}.selectbox-isopen & {
            color: ${form.borderFocus};
        }
        ${SelectboxCont}.selectbox-isopen.selectbox-haserror & {
            color: ${form.borderError};
        }
    }
    ${SelectboxCont}.selectbox-isopen & svg {
        transform: rotate(180deg);
    }
    ${SelectboxCont}.selectbox-inline & {
        width: 30px;
    }
`;

export const SelectJoinDropdown = styled.span`
    position: absolute;
    top: -5px;
    width: calc(100% + 4px);
    height: 5px;
    background: #fff;
    border-left: 2px solid ${form.borderFocus};
    border-right: 2px solid ${form.borderFocus};
    margin: 0 -2px;
    &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${form.borderDefault};
    }
    ${SelectboxCont}.selectbox-inline & {
        display: none;
    }
`;

export const SelectboxListCont = styled.div`
    position: absolute;
    border: 2px solid ${form.borderFocus};
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1050;
    background-color: #fff;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    animation: ${slideDownOut} 0.25s ease;
    max-height: 240px;
    .scrollable-area {
        max-height: 240px;
    }
    &.selectbox-isexiting {
        animation: ${slideUpOut} 0.25s ease;
    }
    ${SelectboxCont}.selectbox-haserror & {
        border-color: ${form.borderError};
    }
    ${SelectboxCont}.selectbox-inline & {
        margin-top: 2px;
        &::before {
            display: none;
        }
    }
    .simplebar-content-wrapper {
        height: 100% !important;
    }
`;

export const SelectboxList = styled.ul`
    list-style: none;
    margin: 0;
    max-height: 240px;
    padding: 0;
    ${SelectboxCont}.selectbox-inline & {
        padding: 0;
    }
`;

export const SelectboxOption = styled.li`
    cursor: default;
    position: relative;
    display: block;
    padding: 6px 10px;
    overflow: hidden;
    color: ${form.textInactive};
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: ${form.fontSizeSelectDropdown};
    &.selectbox-empty {
        text-align: center;
    }
    &::before {
        content: '';
        top: calc(100% - 1px);
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        height: 1px;
        width: calc(100% - 20px);
        background: ${form.borderDefault};
    }
    &:last-child::before {
        display: none;
    }
    &:last-child::after {
        border-bottom-left-radius: 3px;
    }
    &::after {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 4px;
        background: ${form.borderFocus};
        display: none;
        ${SelectboxCont}.selectbox-haserror & {
            background: ${form.borderError};
        }
    }
    &.selectbox-isselected {
        color: #0a0e27;
        font-weight: 700;
    }
    &.selectbox-active {
        color: #0a0e27;
        font-weight: 700;
        &::after {
            display: block;
        }
    }
`;

export const SelectboxHighlightMatch = styled.span`
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: -3px;
        bottom: -3px;
        background: ${form.highlightMatch};
        z-index: -1;
    }
`;

export const SelectboxMarker = styled.span`
    position: absolute;
    left: -3px;
    top: 50%;
    margin-top: -3px;
    border-radius: 24px;
    width: 6px;
    height: 6px;
    background: ${form.borderFocus};
`;

export const SelectboxSizer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    overflow: scroll;
    white-space: pre;
    font-size: ${form.fontSizeInput};
`;
