import React from 'react';
import { MinimalFormLayout } from '@components/_struct/_layouts/MinimalFormLayout/MinimalFormLayout';
import { colors, bp } from '@components/_struct/variables';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import animationData from '../../../public/lotties/tail_movin_cat.json';

const PictoCont = styled.div`
    margin-top: 100px;
    max-width: 1100px;
    margin: 50px auto 0;
`;

const MainTitle = styled.h1`
    font-size: 50px;
    font-weight: bold;
    color: ${colors.main};
    margin-bottom: 20px;
    @media (${bp.sm}) {
        font-size: 24px;
    }
`;

interface IWaitingScreenProps {
    title: string;
    txt: string;
}

const WaitingScreen = ({ title, txt }: IWaitingScreenProps) => {
    return (
        <MinimalFormLayout>
            <div className='w-full text-center mb-2'>
                <MainTitle>{title}</MainTitle>
                <div className='text-lg lg:text-xl opacity-50'>{txt}</div>
                <PictoCont>
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        play={true}
                        style={{ width: '100%' }}
                    />
                </PictoCont>
            </div>
        </MinimalFormLayout>
    );
};

export default WaitingScreen;
