import React, { useState, useEffect, useCallback, useRef } from 'react';
import Cam from '@components/Event/Room/Cam';
import { RoomMain, RoomCamGrid } from '@components/Event/EventLayout.css';
import { bp } from '@components/_struct/variables';
import styled from 'styled-components';
import { ICamProps } from '@components/Event/Room/Cam/Cam';
import useScreenOrientation from '@utils/useScreenOrientation';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import getConfig from 'next/config';
import { CallActions } from '@logic/actions/call_actions';
import { useMercureEventSource } from '@hooks/useMercureEventSource';
import { APIMercure } from '@api/mercureApi';
import { useMSTranslation } from '@utils/useMSTranslation';

const { publicRuntimeConfig } = getConfig();

const MainTitle = styled.h1`
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 50px;
    text-align: center;
    @media (${bp.sm}) {
        font-size: 24px;
        margin-bottom: 20px;
    }
`;

const WaitingReception = () => {
    const { __ } = useMSTranslation(['event']);

    const userSite = useSelector((state: IStore) => state.Auth.user);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const devices = useSelector((state: IStore) => state.Config.devices);

    const dispatch = useDispatch();

    const mediaStreamRef = useRef<MediaStream | null>(null);

    const lastInvitation = useRef(0);

    const JWTtokenMercureLocal = useRef('');

    const handleMessageWaiting = useCallback(
        (data: IMessageMercure) => {
            // Si c'est une invitation à une room d'accueil
            // Si la dernière invitation date d'il y a plus de 15 secondes
            if (
                (lastInvitation.current === 0 ||
                    lastInvitation.current < new Date().getTime() - 15000) &&
                data.type == 'invitation_room' &&
                data.roomId
            ) {
                // On note la date de cette invitation en state
                lastInvitation.current = new Date().getTime();

                // On rejoint la salle
                meetingToken &&
                    dispatch(
                        CallActions.joinRoom(
                            meetingToken,
                            data.roomId,
                            callObject,
                            true
                        )
                    );
            }
            // Si l'utilisateur est déjà pris en charge
            else if (
                lastInvitation.current > new Date().getTime() - 15000 &&
                data.type == 'invitation_room' &&
                data.roomId &&
                data.topicRetour
            ) {
                APIMercure.sendNotifUsers(
                    JWTtokenMercureLocal.current,
                    data.topicRetour,
                    {
                        type: 'invitation_room_already_initiated',
                        nomInvitation: userSite?.userName
                    }
                );
            }
        },
        [callObject, dispatch, meetingToken, lastInvitation, userSite]
    );

    // On s'abonne à mercure
    let topics: string[] = [];
    let subscribes: string[] = [];
    let payload: IPayloadUserMercure | null = null;

    if (userSite && infosEvent) {
        // Le topic personnel de l'utilisateur
        const topicUser =
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId;

        topics = [
            publicRuntimeConfig.LIEN_SITE + infosEvent.roomId + '-reception',
            topicUser
        ];

        subscribes = [topicUser];

        payload = {
            userId: userSite.userId,
            userName: userSite.userName,
            intervenant: userSite.intervenant,
            topic: topicUser,
            shortToken: userSite.shortToken
        };
    }

    const { token: tokenMercureTmp } = useMercureEventSource({
        topics,
        subscribes,
        payload,
        handleMessage: handleMessageWaiting,
        initialStatus: 'idle'
    });

    JWTtokenMercureLocal.current = tokenMercureTmp;

    const [camProps, setCamProps] = useState<ICamProps>({
        videoTrack: null,
        isLoading: true,
        userName: userSite ? userSite.userName : '',
        audioTrack: null,
        local: true,
        userId: userSite ? userSite.userId : '',
        pinned: false
    });

    useEffect(() => {
        if (!devices || !navigator.mediaDevices) return;

        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: {
                    width: 1280,
                    height: 720,
                    deviceId: JSON.parse(devices).cam
                }
            })
            .then(mediaStream => {
                mediaStreamRef.current = mediaStream;
                setCamProps({
                    videoTrack: mediaStream.getVideoTracks()[0],
                    isLoading: false,
                    userName: userSite ? userSite.userName : '',
                    audioTrack: mediaStream.getAudioTracks()[0],
                    local: true,
                    userId: userSite ? userSite.userId : '',
                    pinned: false
                });
            })
            .catch(() => {
                setCamProps({
                    videoTrack: null,
                    isLoading: false,
                    userName: userSite ? userSite.userName : '',
                    audioTrack: null,
                    local: true,
                    userId: userSite ? userSite.userId : '',
                    pinned: false
                });
            });
    }, [userSite, devices]);

    useEffect(() => {
        return function cleanup() {
            if (mediaStreamRef.current) {
                mediaStreamRef.current.getVideoTracks()[0].stop();
                mediaStreamRef.current.getAudioTracks()[0].stop();
            }
        };
    }, []);

    const screenOrientation = useScreenOrientation();

    const screenOrientationClassName =
        screenOrientation == 'landscape-primary' ||
        screenOrientation == 'landscape-secondary'
            ? 'landscape'
            : 'portrait';

    return (
        <RoomMain>
            <MainTitle className='flex mt-2 md:mt-0 mb-3'>
                {__(
                    'Un hôte va vous accueillir dans quelques instants',
                    'event'
                )}
                ...
            </MainTitle>
            <RoomCamGrid className={`${screenOrientationClassName} grid-1`}>
                <div className='h-full w-full md:w-2/3'>
                    <Cam {...camProps} />
                </div>
            </RoomCamGrid>
        </RoomMain>
    );
};

export default WaitingReception;
