import React from 'react';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import Scene from './Scene';
import { isMobile } from 'react-device-detect';
import Hall from '@components/Event/SelectRoom/Hall/Hall';

export const SelectRoomWrapper = styled.div`
    align-items: center;
    flex: 1;
    width: 0;
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    > * {
        margin: auto;
    }
    .scrollable-area {
        height: 100%;
        width: 100%;
        margin: 0;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.5);
        }
        .simplebar-content-wrapper {
            display: flex;
            > * {
                margin: auto;
                width: 100%;
            }
        }
    }
    .simplebar-placeholder {
        display: none;
    }
`;

const SelectRoom = () => {
    return (
        <SelectRoomWrapper>
            {!isMobile && <Scene />}
            <SimpleBar className='scrollable-area'>
                <Hall />
            </SimpleBar>
        </SelectRoomWrapper>
    );
};

export default SelectRoom;
