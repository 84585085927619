import React, { useEffect, useState } from 'react';
import { useMSTranslation } from '@utils/useMSTranslation';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { APIEvents } from '@api/eventApi';
import { IStore } from '@interfaces/logic/redux';
import WaitingScreen from '@components/Join/WaitingScreen';
import { APIUsers } from '@api/usersApi';
import { PageJoinActions } from '@actions/pages/join_actions';
import { getLocalStorage } from '@utils/localStorage';
import { APIDaily } from '@api/dailyApi';
import getConfig from 'next/config';
import SignupLayout from '@components/Join/SignupLayout';
import { DataActions } from '@actions/data_actions';
import { fromUnixTime, getTime } from 'date-fns';
import { AuthActions } from '@actions/auth_actions';
const { publicRuntimeConfig } = getConfig();
import jwt from 'jwt-simple';
import { displayError } from '@components/_common/Toast';
import { setTokensStorage } from '@utils/auth';
import { getNameByPattern } from '@logic/func';
import { ConfigActions } from '@logic/actions/config_actions';

const STATUT_VERIFEVENT_WAIT = 'STATUT_VERIFEVENT_WAIT';
const STATUT_VERIFEVENT_ERROR = 'STATUT_VERIFEVENT_ERROR';
const STATUT_VERIFEVENT_OK = 'STATUT_VERIFEVENT_OK';

const JoinController = () => {
    const { __ } = useMSTranslation(['join']);
    const dispatch = useDispatch();
    const [statutEvent, setStatutEvent] = useState(STATUT_VERIFEVENT_WAIT);
    const [fakeWaitLoading, setFakeWaitLoading] = useState(true);
    const [banned, setBanned] = useState(false);

    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const waitingShortToken = useSelector(
        (state: IStore) => state.PageJoin.waitingShortToken
    );
    const waitingMeetingToken = useSelector(
        (state: IStore) => state.PageJoin.waitingMeetingToken
    );
    const usedShortToken = useSelector(
        (state: IStore) => state.UI.usedShortToken
    );

    // On récupère l'id de la room
    const router = useRouter();
    let id = '';

    if (router.query.id === undefined) id = 'hello';
    else id = router.query.id.toString();

    const sondage_query = router.query.sondage;

    // On vérifie qu'on est pas banni
    useEffect(() => {
        if (banned || waitingShortToken) return;
        const isBanned = getLocalStorage(id + '_isBanned');
        if (isBanned != undefined && isBanned.eventId === id) {
            setBanned(true);
            displayError(__('Vous avez été banni de cet événement !', 'join'));
        }
    }, [__, banned, id, waitingShortToken]);

    // On vérifie que l'event existe
    useEffect(() => {
        if (ctoken) {
            if (banned) return;
            APIEvents.getEventInfo(ctoken, id).then((r: any) => {
                // Si la room est dispo c'est qu'elle n'existe pas
                if (r.data.error) {
                    setStatutEvent(STATUT_VERIFEVENT_ERROR);
                    dispatch(PageJoinActions.setWaitingMeetingToken(false));
                } else {
                    r.data.dateStart = fromUnixTime(r.data.dateStart);
                    r.data.dateEnd = fromUnixTime(r.data.dateEnd);

                    if (r.data.companyAvatar == '') r.data.companyAvatar = null;

                    if (r.data.cover == '' || r.data.cover == null)
                        r.data.cover =
                            'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80';

                    dispatch(DataActions.setDataEvent(r.data));
                    dispatch(
                        ConfigActions.setNeedReception(r.data.receptionRoom)
                    );
                    setStatutEvent(STATUT_VERIFEVENT_OK);
                }
            });
        }
    }, [dispatch, id, ctoken, banned]);

    // On lance le timeout pour laisser le loading au moins 2 secondes
    useEffect(() => {
        const timeoutFakeWaitLoading = setTimeout(() => {
            setFakeWaitLoading(false);
        }, 2000);

        return function cleanup() {
            clearTimeout(timeoutFakeWaitLoading);
        };
    }, []);

    let meetingTokenObject = getLocalStorage('meetingTokenEvent');
    if (meetingTokenObject) {
        try {
            meetingTokenObject = jwt.decode(
                meetingTokenObject.token,
                publicRuntimeConfig.SECRET_JWT
            );
        } catch (error) {
            console.error(error);
        }
    }

    const verifMeetingToken = !!(
        meetingTokenObject && meetingTokenObject.eventRoomId == id
    );
    const meetingToken = meetingTokenObject && meetingTokenObject.meetingToken;

    // Verification meeting token
    useEffect(() => {
        // On ne le fait que quand on a les infos de l'event et qu'il est en cours
        if (statutEvent != STATUT_VERIFEVENT_OK) return;

        if (
            (infosEvent && getTime(infosEvent.dateEnd) < getTime(new Date())) ||
            (infosEvent && getTime(infosEvent.dateStart) > getTime(new Date()))
        ) {
            dispatch(PageJoinActions.setWaitingMeetingToken(false));
            return;
        }

        // Si on est pas en mode description et qu'on ne vérifie pas un token dans l'url
        if (
            verifMeetingToken &&
            !waitingShortToken &&
            typeof router.query.desc == 'undefined'
        ) {
            // On vérifie si le meeting token existe
            APIDaily.getMeetingTokenStatus(meetingToken).then((r: any) => {
                if (r && r.data && !r.data.error) {
                    // Si l'évènement est en cours on le rejoint
                    dispatch(
                        AuthActions.setUser({
                            userName: r.data.user_name,
                            intervenant: r.data.is_owner,
                            userId: meetingTokenObject.userId,
                            didAnswerSurvey: false
                        })
                    );
                    dispatch(AuthActions.setMeetingToken(meetingToken));
                }
                dispatch(PageJoinActions.setWaitingMeetingToken(false));
            });
        } else dispatch(PageJoinActions.setWaitingMeetingToken(false));
    }, [
        id,
        dispatch,
        verifMeetingToken,
        meetingToken,
        meetingTokenObject,
        router.query.desc,
        statutEvent,
        infosEvent,
        waitingShortToken
    ]);

    // On vérifie si un shorttoken est présent dans l'url et on tente d'authentifier l'utilisateur
    useEffect(() => {
        // Si l'event n'est pas accessible on execute pas ce useeffect
        if (
            ((infosEvent &&
                infosEvent.maxParticipants -
                    infosEvent.nbParticipantsAccepted <=
                    0) ||
                (infosEvent &&
                    getTime(infosEvent.dateEnd) < getTime(new Date())) ||
                (infosEvent &&
                    getTime(infosEvent.dateStart) > getTime(new Date()))) &&
            sondage_query == undefined
        ) {
            dispatch(PageJoinActions.setWaitingShortToken(false));
            return;
        }

        let token = '';

        if (typeof router.query.token == 'string')
            token = router.query.token.toString();

        if (!token) dispatch(PageJoinActions.setWaitingShortToken(false));

        let id = '';
        if (router.query.id === undefined) id = 'hello';
        else id = router.query.id.toString();

        if (infosEvent && token && !banned && ctoken && !usedShortToken) {
            APIUsers.getByShortToken(ctoken, token, id).then(res => {
                const isBanned = getLocalStorage(
                    infosEvent?.roomId + '_isBanned'
                );
                if (
                    (res.data && res.data.error == 'banned') ||
                    (isBanned != undefined &&
                        isBanned.eventId == infosEvent?.roomId)
                ) {
                    setBanned(true);
                    return displayError(
                        __('Vous avez été banni de cet événement !', 'join')
                    );
                }
                if (
                    !res.data.error &&
                    res.data.firstname !== '' &&
                    res.data.lastname !== '' &&
                    res.data.username !== ''
                ) {
                    if (
                        res.data.passwordCredentialsToken &&
                        res.data.refreshCredentialsToken
                    ) {
                        setTokensStorage(
                            res.data.passwordCredentialsToken,
                            res.data.refreshCredentialsToken
                        );
                    }
                    if (res.data.intervenant) {
                        dispatch(ConfigActions.setNeedReception(false));
                        dispatch(
                            AuthActions.joinEvent(
                                id,
                                res.data.firstname + ' ' + res.data.lastname,
                                res.data.intervenant,
                                token,
                                res.data.id,
                                undefined,
                                undefined,
                                res.data.permissions
                            )
                        );
                    } else {
                        const userName = getNameByPattern(
                            infosEvent.paternName,
                            res.data.firstname,
                            res.data.lastname,
                            res.data.username
                        );

                        //Si relance reponse sondage && sondage et participant a pas repondu au sondage
                        if (
                            res.data.didAnswerSurvey == false &&
                            sondage_query != undefined &&
                            infosEvent?.sondage
                        ) {
                            dispatch(ConfigActions.setConfigDevicesStep(true));
                        } else if (sondage_query != undefined) {
                            //On retourne a l'accueil L'évènement est terminé.
                            router.push('/' + infosEvent?.roomId);
                            return;
                        }

                        if (userName != undefined)
                            dispatch(
                                AuthActions.joinEvent(
                                    id,
                                    userName,
                                    res.data.intervenant,
                                    token,
                                    res.data.id,
                                    ctoken,
                                    res.data.didAnswerSurvey
                                )
                            );
                    }
                } else {
                    dispatch(PageJoinActions.setWaitingShortToken(false));
                }
            });
        }
    }, [
        ctoken,
        dispatch,
        router.query.id,
        router.query.token,
        infosEvent,
        banned,
        usedShortToken,
        __,
        sondage_query,
        router
    ]);

    let toReturn;

    if (banned) {
        toReturn = (
            <WaitingScreen
                title={__('Evènement indisponible !', 'join')}
                txt={__('Vous êtes banni de cet évènement.', 'join')}
            />
        );
    } else if (usedShortToken) {
        toReturn = (
            <WaitingScreen
                title={__('Evènement indisponible !', 'join')}
                txt={__("Quelqu'un est déjà connecté avec cet accès.", 'join')}
            />
        );
    } else if (
        statutEvent == STATUT_VERIFEVENT_WAIT ||
        waitingShortToken ||
        waitingMeetingToken ||
        fakeWaitLoading
    )
        toReturn = (
            <WaitingScreen
                title={__("Connexion à l'évènement...", 'join')}
                txt={__(
                    "Veuillez patienter pendant le chargement des informations de l'évènement.",
                    'join'
                )}
            />
        );
    else if (statutEvent == STATUT_VERIFEVENT_ERROR)
        toReturn = (
            <WaitingScreen
                title={__('Evènement indisponible !', 'join')}
                txt={__("Cet évènement n'existe pas.", 'join')}
            />
        );
    else toReturn = <SignupLayout />;

    return toReturn;
};

export default JoinController;
