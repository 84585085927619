import getConfig from 'next/config';
import jwt from 'jwt-simple';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

const { publicRuntimeConfig } = getConfig();

interface IMercureEventSourceProps {
    topics: string[];
    subscribes: string[];
    payload: IPayloadUserMercure | null;
    handleMessage?: (e: any) => void;
    handleOpen?: () => void;
    handleError?: () => void;
    debug?: boolean;
    initialStatus?: 'snooze' | 'idle';
}

export const useMercureEventSource = ({
    topics = [],
    subscribes = [],
    payload = null,
    handleMessage,
    handleOpen,
    handleError,
    debug,
    initialStatus = 'snooze'
}: IMercureEventSourceProps) => {
    // Le lastEventId / eventSource est une ref car il ne doit pas rerender le composant
    const lastEventId = useRef('');
    const eventSource = useRef<EventSource | null>(null);

    // Statut de l'eventsource géré par ce hook
    const [status, setStatus] = useState<string>(initialStatus);

    // URL de base de Mercure
    const url = new URL(
        publicRuntimeConfig.LIEN_MERCURE + '.well-known/mercure'
    );
    url.searchParams.append('Last-Event-ID', lastEventId.current);

    // On l'abonne aux topics
    topics.forEach(topic => {
        url.searchParams.append('topic', topic);
    });

    // Construction du JWT
    const payloadMercure = {
        mercure: {
            publish: ['*'],
            subscribe: subscribes,
            payload: payload
        }
    };

    // On utilise la lazy initialisation pour une question de perf
    const JWTtokenMercure = jwt.encode(
        payloadMercure,
        publicRuntimeConfig.SECRET_JWT_MERCURE
    );

    // Initialisation de l'event source
    useEffect(() => {
        if ((status === 'idle' || status === 'error') && topics.length > 0) {
            if (debug) {
                console.log(
                    '%c Initialisation ES',
                    'background: #222; color: green'
                );
            }
            setStatus('pending');
            eventSource.current = new EventSourcePolyfill(url.href, {
                headers: {
                    Authorization: 'Bearer ' + JWTtokenMercure
                }
            });

            if (!eventSource.current) return;

            eventSource.current.onmessage = (e: any) => {
                const data = JSON.parse(e.data);
                if (debug) console.log('onmessage', data);
                lastEventId.current = e.lastEventId;
                if (handleMessage) handleMessage(data);
            };

            eventSource.current.onopen = () => {
                if (debug) console.log('%c onopen', 'color: green');
                if (lastEventId.current == '') {
                    setStatus('open:first');
                    if (handleOpen) handleOpen();
                } else setStatus('open');
            };

            eventSource.current.onerror = () => {
                if (debug)
                    console.log('%c onerror', 'background: #222; color: red');
                if (eventSource.current) {
                    if (eventSource.current?.readyState == 1) {
                        eventSource.current.close();
                        setStatus('error');
                    }
                }
                if (handleError) handleError();
            };
        }
    }, [
        JWTtokenMercure,
        url.href,
        status,
        handleMessage,
        handleError,
        handleOpen,
        debug,
        topics.length
    ]);

    // On désabonne lorsqu'on démonte le composant
    useEffect(() => {
        return function cleanup() {
            if (debug)
                console.log('%c cleanup', 'background: grey; color:blue');
            if (eventSource.current) {
                eventSource.current.close();
                setStatus(initialStatus);
            }
        };
    }, [debug, initialStatus]);

    // On désabonne à la demande de l'utilisateur
    const closeEvent = useCallback(() => {
        if (eventSource.current) {
            eventSource.current.close();
            setStatus(initialStatus);
        }
    }, [initialStatus]);

    // On abonne à la demande de l'utilisateur
    const openEvent = useCallback(() => {
        if (status === initialStatus) {
            setStatus('idle');
        }
    }, [status, initialStatus]);

    return {
        token: JWTtokenMercure,
        status: status,
        closeEvent: closeEvent,
        openEvent: openEvent
    };
};
