import styled from 'styled-components';
import { UserAvatarCont } from '@components/_common/Avatar/Avatar.css';
import { bp } from '@components/_struct/variables';
import { IRoomUserItem } from './RoomUserItem';

export const ListUsersWrapper = styled.div`
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    @media (${bp.md}) {
        background: rgba(0, 0, 0, 1);
    }
    display: flex;
    flex-direction: column;
    .scrollable-area {
        flex: 1;
        min-height: 0;
        height: 100%;
        position: relative;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.25);
        }
    }
`;

export const ListUsersTitle = styled.div`
    font-size: 13px;
    color: #3a90e1;
    text-transform: uppercase;
    padding: 16px 20px 10px;
    display: flex;
    align-items: center;
    user-select: none;
`;

export const ListUsers = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const ListUserItem = styled.li`
    display: flex;
    align-items: center;
    cursor: default;
    padding: 6px 20px;
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const ListWaitingItem = styled.li`
    display: flex;
    align-items: center;
    padding: 6px 20px;
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const ListAddUserItem = styled.li`
    display: flex;
    align-items: center;
    padding: 6px 20px;
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const ListVisitorItem = styled.li`
    display: flex;
    align-items: center;
    padding: 6px 20px;
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const ListUserItemState = styled.div`
    opacity: 0;
    color: #fff;
    font-size: 16px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    & + & {
        margin-left: 10px;
    }
    ${ListUserItem}:hover & {
        opacity: 0.6;
    }
`;

export const ListUserAvatarCont = styled.div<Pick<IRoomUserItem, 'noPin'>>`
    margin-right: 10px;
    position: relative;
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 30px;
        box-shadow: 0 0 0 2px #ffe665;
        opacity: 0;
    }
    ${ListUserItem}:hover & ${UserAvatarCont} {
        opacity: ${p => (p.noPin ? '1' : '0')};
        cursor: ${p => (p.noPin ? 'default' : 'pointer')};
        .is-pinned & {
            opacity: 0;
        }
    }
    button,
    a {
        ${ListUserItem}:hover & {
            opacity: 1;
        }
        position: absolute;
        top: 1px;
        left: 1px;
        opacity: 0;
        .is-pinned & {
            opacity: 1;
        }
    }
    .is-speaking & {
        ::before {
            opacity: 1;
        }
    }
`;

export const ListUserItemNameWrapper = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ListUserItemName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1;
    padding-right: 1rem;
    user-select: none;
    align-self: stretch;
    display: flex;
    align-items: center;
    &.is-clickable {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    .is-pinned & {
        font-weight: bold;
    }
`;

export const ListCloseButton = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: 0 none;
    color: #3a90e1;
    font-size: 18px;
    padding: 7px;
    width: 32px;
    height: 32px;
    margin: 0;
    outline: 0;
    border-radius: 24px;
    :hover {
        box-shadow: 0 0 2px 0 #3a90e1;
    }
`;

export const ListWaitingActions = styled.div`
    display: none;
    > * + * {
        margin-left: 10px;
    }
    ${ListWaitingItem}:hover & {
        display: flex;
    }
`;

export const ListWaitingPlacement = styled.div`
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    width: 32px;
    user-select: none;
    ${ListWaitingItem}:hover & {
        display: none;
    }
`;

export const ListVisitorsActions = styled.div`
    display: flex;
    > :first-child {
        display: none;
    }
    > * + * {
        margin-left: 10px;
    }
    ${ListVisitorItem}:hover & > :first-child {
        display: flex;
    }
`;

export const ListAddUserActions = styled.div`
    display: flex;
    > * + * {
        margin-left: 10px;
    }
`;

export const ListVisitorPlacement = styled.div`
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    width: 40px;
    user-select: none;
    svg {
        margin-right: 5px;
    }
`;

export const BottomSectionVisitors = styled.div`
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    padding: 15px;
`;
