import React, { InputHTMLAttributes, useState } from 'react';
import { FormikHandlers, useField, useFormikContext } from 'formik';
import { RadiosCont, Label, Radiobox, RadioIndicator } from './RadioGroup.css';

// Last update : 28/05/2020

export interface IRadioGroup {
    /** Liste des choix */
    items: {
        label: string;
        value: string;
    }[];
    name: string;
    /** Style des radios */
    variant?: 'button';
    /** Sens de positionnement des radios */
    direction?: 'horizontal' | 'vertical';
}

const RadioGroup = (props: IRadioGroup) => {
    const { items } = props;
    const [field, meta, helpers] = useField(props);
    const { setFieldTouched } = useFormikContext();

    const { direction, variant } = props;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedRadio = e.target.value;
        field.value != selectedRadio && helpers.setValue(selectedRadio);
        setFieldTouched(field.name, true, false);
    };

    const directionClass =
        direction == 'horizontal' ? 'radio-group-horizontal' : '';

    const errorClass = meta.error && meta.touched ? 'haserror' : '';

    return (
        <>
            <RadiosCont
                variant={variant}
                className={`${directionClass} ${errorClass}`}
            >
                {items.map((item, i) => (
                    <Radio
                        key={i}
                        label={item.label}
                        value={item.value}
                        checked={field.value == item.value}
                        onChange={onChange}
                        {...props}
                    />
                ))}
            </RadiosCont>
        </>
    );
};

export interface IRadio extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    name: string;
    onChange: FormikHandlers['handleChange'];
    /** Style des radios */
    variant?: 'button';
}

const Radio = React.memo((props: IRadio) => {
    const [field] = useField(props);
    const { label, value, checked, onChange, name, variant } = props;
    const [hasFocus, setHasFocus] = useState(false);
    Radio.displayName = 'Radio';

    const variantClass = variant == 'button' ? 'radio-button' : '';
    const checkedClass = field.value == value ? 'radio-checked' : '';
    const focusClass = hasFocus ? 'radio-focus' : '';
    return (
        <>
            <Label className={`${variantClass} ${checkedClass} ${focusClass}`}>
                <Radiobox
                    type='radio'
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    name={name}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                />
                <RadioIndicator />
                <span>{label}</span>
            </Label>
        </>
    );
});

export default React.memo(RadioGroup);
