import {
    toast,
    ToastOptions,
    ToastContent,
    cssTransition
} from 'react-toastify';
import ToastError from './ToastError';
import ToastSuccess from './ToastSuccess';
import ToastEventRoom from './ToastEventRoom';

const CustomAnim = cssTransition({
    enter: 'SlideRight',
    exit: 'SlideUpFadeOut',
    duration: [500, 600],
    appendPosition: false
});

export const displayError = (content: ToastContent, options?: ToastOptions) => {
    return toast.error(<ToastError>{content}</ToastError>, {
        autoClose: false,
        ...options
    });
};

export const displaySuccess = (
    content: ToastContent,
    options?: ToastOptions
) => {
    return toast.success(<ToastSuccess>{content}</ToastSuccess>, {
        ...options
    });
};

export const displayEvent = (
    content: ToastContent,
    name?: string,
    options?: ToastOptions
) => {
    return toast.info(<ToastEventRoom name={name}>{content}</ToastEventRoom>, {
        className: 'toast-event',
        hideProgressBar: true,
        position: 'bottom-right',
        autoClose: 2500,
        transition: CustomAnim,
        closeButton: false,
        ...options
    });
};
