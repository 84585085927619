import React from 'react';
import {
    ListUserAvatarCont,
    ListUserItemName,
    ListVisitorItem,
    ListVisitorsActions,
    ListVisitorPlacement
} from './RoomListUsers.css';
import UBCheckbox from '@components/_common/_form/Checkbox/UBCheckbox';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import Button from '@components/_common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IVisitorItem {
    userName: string;
    userId: string;
    onCheck?: (id: string) => void;
    checked?: boolean;
    placement?: number;
}

const RoomVisitorItem = (props: IVisitorItem) => {
    const { userName, userId, onCheck, checked, placement } = props;

    return (
        <ListVisitorItem>
            <UBCheckbox
                variant='white'
                onChange={() => onCheck && onCheck(userId)}
                checked={checked}
            />
            <ListUserAvatarCont>
                <UserAvatar name={`${userName}`} size='30' />
            </ListUserAvatarCont>
            <ListUserItemName>{userName}</ListUserItemName>
            {placement ? (
                <ListVisitorPlacement>
                    <FontAwesomeIcon icon={['far', 'hourglass-half']} />{' '}
                    {placement}
                </ListVisitorPlacement>
            ) : (
                <ListVisitorsActions className='ml-1'>
                    <Button
                        variant='outline-white'
                        size='small'
                        onlyIcon
                        leftIcon='times'
                    />
                    <Button
                        variant='action'
                        size='small'
                        onlyIcon
                        leftIcon='check'
                    />
                </ListVisitorsActions>
            )}
        </ListVisitorItem>
    );
};

export default RoomVisitorItem;
