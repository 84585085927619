import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import styled from 'styled-components';
import { format } from 'date-fns';
import { UIActions } from '@actions/ui_actions';
import OptionsOnUser from '@components/Event/Room/RoomListUsers/RoomUserItem/OptionsOnUser';
import Linkify from 'react-linkify';
import { useMSTranslation } from '@utils/useMSTranslation';

const MessageWrapper = styled.div`
    position: relative;
    padding: 10px 20px;
    .context-menu {
        visibility: hidden;
    }
    &:hover .context-menu {
        visibility: visible;
    }
`;

const UserNameRow = styled.div`
    display: flex;
    font-size: 12px;
    color: #fff;
    opacity: 0.7;
    margin-bottom: 5px;
    .self & {
        div {
            margin-left: auto;
        }
    }
`;

const UserNameWrapper = styled.strong`
    &.cursor-pointer {
        &:hover {
            text-decoration: underline;
        }
    }
`;

const ChatBubble = styled.div`
    position: relative;
    padding: 10px 10px;
    background: rgba(23, 33, 106, 0.5);
    border-radius: 0px 6px 6px 6px;
    color: #fff;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    a {
        color: #fff;
        text-decoration: underline;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -7px;
        background-image: url('/images/arrow-bubble.svg');
        background-repeat: no-repeat;
        height: 13px;
        width: 7px;
        opacity: 0.5;
    }
    .self & {
        margin-left: auto;
        border-radius: 6px 0px 6px 6px;
        background: rgba(58, 144, 225, 0.5);
        &::before {
            background-image: url('/images/arrow-bubble-right.svg');
            left: 100%;
        }
    }
`;

interface IChatMessage {
    msgId: string;
    message: string;
    user: string;
    userId: string;
    date: number;
    hide?: boolean;
    sessionId: string;
}

const ChatMessage = ({
    user,
    message,
    date,
    msgId,
    userId,
    hide
}: IChatMessage) => {
    const authUser = useSelector((state: IStore) => state.Auth.user);
    const dispatch = useDispatch();
    const { __ } = useMSTranslation(['event']);

    const isMySelf = userId == authUser?.userId?.toString();
    const variantClassName = isMySelf ? 'self' : '';

    const infosEvent = useSelector((state: IStore) => state.Data.event);

    const userMsg = infosEvent?.intervenants?.filter(
        user => user.id.toString() == userId
    );

    let isIntervenant = false;
    let societeLogo = '';
    if (userMsg && userMsg.length > 0) {
        isIntervenant = true;
        societeLogo = userMsg[0].societeLogo;
    }

    const canSeeProfil =
        userId && authUser && authUser.intervenant && !isIntervenant;
    const isClickableClassName = canSeeProfil
        ? 'cursor-pointer'
        : 'cursor-default';

    const linkDecorator = (
        decoratedHref: string,
        decoratedText: string,
        key: number
    ) => {
        return (
            <a
                href={decoratedHref}
                target='_blank'
                rel='noreferrer'
                key={key}
                className='text-white'
            >
                {decoratedText}
            </a>
        );
    };
    return (
        <MessageWrapper className={`${variantClassName}`}>
            <UserNameRow>
                <div>
                    <UserNameWrapper
                        onClick={() =>
                            canSeeProfil &&
                            dispatch(UIActions.openDrawerCandidat(userId))
                        }
                        className={`${isClickableClassName}`}
                    >
                        {user}
                    </UserNameWrapper>{' '}
                    {format(date, "HH'h'mm")}
                </div>
            </UserNameRow>
            <div className='flex'>
                {!isMySelf && (
                    <div
                        onClick={() =>
                            canSeeProfil &&
                            dispatch(UIActions.openDrawerCandidat(userId))
                        }
                        className={`${isClickableClassName}`}
                    >
                        <UserAvatar src={societeLogo} name={user} size='32' />
                    </div>
                )}
                <div className='flex-1 flex mx-1'>
                    <ChatBubble>
                        {hide ? (
                            <em className='opacity-50'>
                                {__('Ce message a été modéré', 'event')}
                            </em>
                        ) : (
                            <Linkify componentDecorator={linkDecorator}>
                                {message}
                            </Linkify>
                        )}
                    </ChatBubble>
                </div>
                {isMySelf && (
                    <div>
                        <UserAvatar src={societeLogo} name={user} size='32' />
                    </div>
                )}
                {!isMySelf && authUser != null && authUser.intervenant && (
                    <>
                        <div className='context-menu'>
                            <OptionsOnUser
                                withChat={true}
                                hiddenMsg={hide}
                                userId={userId}
                                msgId={msgId}
                            />
                        </div>
                    </>
                )}
            </div>
        </MessageWrapper>
    );
};

export default ChatMessage;
