import resolve from '@utils/resolve';
import getConfig from 'next/config';
import { cfetch } from '@utils/auth';
const { publicRuntimeConfig } = getConfig();

// Verifie si une room existe
const roomDispo = async (roomId: string) => {
    return await resolve(
        fetch(publicRuntimeConfig.API_BASE_URL + '/events/roomDispo', {
            method: 'post',
            body: JSON.stringify({ roomId: roomId }),
            headers: { 'Content-Type': 'application/json' }
        }).then(r => r.json())
    );
};

// Ajoute une room temporaire
const addTmpRoom = async (token: string, eventRoomId: string) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/rooms/addTmp', {
            method: 'post',
            body: JSON.stringify({ eventRoomId: eventRoomId }),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

export const APIRoom = {
    roomDispo: roomDispo,
    addTmpRoom: addTmpRoom
};
