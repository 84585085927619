import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { UIActions } from '@actions/ui_actions';
import {
    HeadCenter,
    HeaderCont,
    RoomActions,
    RoomName,
    RoomNameTitle,
    ToggleBtn,
    ToggleRooms,
    HeadReceptionRoomCont
} from '@components/Event/EventLayout/EventHeader/EventHeader.css';
import Badge from '@components/_common/Badge';
import Button from '@components/_common/Button';
import PopOver from '@components/_common/PopOver';
import WaitingListDispatch from './WaitingListDispatch';
import { useMSTranslation } from '@utils/useMSTranslation';
import { MEETING_STATE_IDLE, MEETING_STATE_JOINING } from '@constants';

const EventHeader: React.FC = () => {
    const participants = useSelector(
        (state: IStore) => state.call.participants
    );
    const { __, __s } = useMSTranslation(['event']);

    const userSite = useSelector((state: IStore) => state.Auth.user);

    const roomId = useSelector((state: IStore) => state.call.roomId);

    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );
    const meetingState = useSelector(
        (state: IStore) => state.call.meetingState
    );

    const participantsReception = useSelector(
        (state: IStore) => state.Data.participantsReception
    );
    const event = useSelector((state: IStore) => state.Data.event);
    const showListUsers = useSelector(
        (state: IStore) => state.UI.showListUsers
    );
    const showAddUser = useSelector((state: IStore) => state.UI.showAddUser);
    const showChat = useSelector((state: IStore) => state.UI.showChat);
    const showEventInfos = useSelector(
        (state: IStore) => state.UI.showEventInfos
    );
    const showRoom = useSelector((state: IStore) => state.UI.showRoom);
    /* const listWaitingState = useSelector(
        (state: IStore) => state.UI.showWaitingList
    );*/

    const needReception = useSelector(
        (state: IStore) => state.Config.needReception
    );
    const loading = useSelector((state: IStore) => state.Loading.actionLoading);

    const dispatch = useDispatch();

    const waitingParticipantsConnectedEvent = participantsReception.filter(
        item =>
            !item.roomInitiated ||
            item.roomInitiated + 15000 < new Date().getTime()
    );

    const listUserIsOpen = showListUsers ? 'active' : '';
    // const listWaitingIsOpen = listWaitingState ? 'active' : '';
    const listAddUser = showAddUser ? 'active' : '';
    const eventInfosIsOpen = showEventInfos ? 'active' : '';
    const listRoomIsOpen = showRoom ? 'active' : '';
    const chatIsOpen = showChat ? 'active' : '';
    const nbrUnreaded = useSelector((state: IStore) => state.UI.nbrUnreaded);
    const receptionRoom = useSelector(
        (state: IStore) => state.call.receptionRoom
    );
    const nbParticipantsRooms = useSelector(
        (state: IStore) => state.Data.nbParticipantsConnectedRooms
    );

    const tmpRoomOwner = useSelector((state: IStore) => state.UI.tmpRoomOwner);

    let infosRoom = null;
    let isTmpRoom = false;
    if (event && roomId) {
        infosRoom = event.rooms.filter(
            (value: IRoom) => value.roomId == roomId
        );
        if (infosRoom.length == 0) {
            infosRoom = [{ nom: __('Room temporaire', 'event') }];
            isTmpRoom = true;
        }
    }

    const canInvit = isTmpRoom ? tmpRoomOwner : userSite?.intervenant;

    // Nombre de participants
    let nbParticipants = participantsConnectedEvent.length;
    if (roomId) nbParticipants = participants.length;

    // Nombre de participants en attente
    const [totalWaitingParticipants, setTotalWaitingParticipants] = useState(0);
    const [
        totalWaitingParticipantsThisRoom,
        setTotalWaitingParticipantsThisRoom
    ] = useState(0);
    useEffect(() => {
        if (!userSite.intervenant) return;

        let totalWaitingTmp = 0;
        for (const room of nbParticipantsRooms) {
            totalWaitingTmp += room.nbWaiting;
            if (roomId && room.roomId == roomId)
                setTotalWaitingParticipantsThisRoom(room.nbWaiting);
        }
        setTotalWaitingParticipants(totalWaitingTmp);
    }, [nbParticipantsRooms, userSite, roomId]);

    return (
        <HeaderCont>
            <RoomName
                onClick={() => dispatch(UIActions.toggleShowEventInfos())}
                className={`${eventInfosIsOpen}`}
            >
                <FontAwesomeIcon
                    icon={['far', 'bars']}
                    className='block xl:hidden'
                />

                <RoomNameTitle>
                    {!showEventInfos && (
                        <>
                            {infosRoom ? infosRoom[0].nom + ' - ' : ''}
                            {event ? event.title : ''}
                        </>
                    )}
                </RoomNameTitle>
                {showEventInfos && (
                    <FontAwesomeIcon
                        icon={['far', 'chevron-left']}
                        className='chevron ml-auto'
                    />
                )}
            </RoomName>
            <HeadCenter>
                {roomId !== null && !needReception && (
                    <ToggleRooms
                        className={`${listRoomIsOpen}`}
                        onClick={() => dispatch(UIActions.toggleShowRoom())}
                    >
                        <div className='centering'>
                            <Badge
                                count={
                                    totalWaitingParticipants
                                        ? totalWaitingParticipants
                                        : 0
                                }
                                maxCount={99}
                            >
                                <span className='hidden md:flex'>
                                    {__('Changer de room', 'event')}
                                </span>
                                <span className='flex md:hidden text-xl'>
                                    #
                                </span>
                            </Badge>
                            <FontAwesomeIcon
                                icon={[
                                    'far',
                                    showRoom ? 'chevron-up' : 'chevron-down'
                                ]}
                                className='ml-1'
                            />
                        </div>
                    </ToggleRooms>
                )}
            </HeadCenter>
            {userSite?.intervenant &&
                event?.receptionRoom &&
                !receptionRoom &&
                userSite.permissions.accueil &&
                loading.indexOf('addTmpRoom') == -1 &&
                meetingState != MEETING_STATE_JOINING &&
                waitingParticipantsConnectedEvent.length > 0 && (
                    <HeadReceptionRoomCont>
                        <PopOver
                            trigger={
                                <Button variant='orange'>
                                    {__s(
                                        waitingParticipantsConnectedEvent.length,
                                        "1 personne en room d'accueil",
                                        "{{count}} personnes en room d'accueil",
                                        'event'
                                    )}
                                </Button>
                            }
                            offset={10}
                            width={400}
                        >
                            {closePopOver => (
                                <WaitingListDispatch
                                    closePopOver={closePopOver}
                                />
                            )}
                        </PopOver>
                    </HeadReceptionRoomCont>
                )}
            <RoomActions>
                {roomId !== null && !needReception && (
                    <>
                        {
                            // Pour l'instant on desactive cet onglet, je ne sais pas à quoi il sert
                            /* (
                            <ToggleBtn
                                onClick={() =>
                                    dispatch(UIActions.toggleShowWaitingList())
                                }
                                className={`${listWaitingIsOpen}`}
                            >
                                <FontAwesomeIcon icon={['far', 'user-clock']} />
                            </ToggleBtn>
                        )*/
                        }

                        {infosRoom && !infosRoom[0].type && canInvit && (
                            <ToggleBtn
                                onClick={() =>
                                    dispatch(UIActions.toggleAddUser())
                                }
                                className={`${listAddUser}`}
                            >
                                <FontAwesomeIcon icon={['far', 'user-plus']} />
                            </ToggleBtn>
                        )}
                    </>
                )}
                {meetingState != MEETING_STATE_IDLE &&
                !needReception &&
                meetingState != MEETING_STATE_JOINING ? (
                    <ToggleBtn
                        onClick={() => dispatch(UIActions.toggleChat())}
                        className={`${chatIsOpen}`}
                    >
                        <Badge count={nbrUnreaded} maxCount={99}>
                            <FontAwesomeIcon icon={['far', 'comments']} />
                        </Badge>
                    </ToggleBtn>
                ) : (
                    ''
                )}
                {!needReception && (
                    <ToggleBtn
                        onClick={() =>
                            dispatch(UIActions.toggleShowListUsers())
                        }
                        className={`${listUserIsOpen}`}
                    >
                        <FontAwesomeIcon icon={['far', 'users']} />
                        <Badge
                            count={
                                totalWaitingParticipantsThisRoom
                                    ? totalWaitingParticipantsThisRoom
                                    : 0
                            }
                            maxCount={99}
                        >
                            <span>{nbParticipants}</span>
                        </Badge>
                    </ToggleBtn>
                )}
            </RoomActions>
        </HeaderCont>
    );
};

export default EventHeader;
