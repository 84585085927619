import React, {
    Children,
    isValidElement,
    useState,
    useEffect,
    useCallback,
    useRef,
    cloneElement
} from 'react';
import {
    DropdownPopoverCont,
    DropdownPopoverWrap
} from './DropdownPopOver.css';

interface IDropdownPopOverCompouned {
    Trigger: React.FC<IDropdownPopOverTrigger>;
    Popover: React.FC;
}

interface IDropdownPopOver {
    placement?:
        | 'bottom-left'
        | 'bottom-right'
        | 'top-right'
        | 'top-left'
        | 'top-center'
        | 'bottom-center';
}

interface IDropdownPopOverTrigger {
    onClick?: () => void;
    isOpen?: boolean;
}

const Popover: React.FC = props => {
    const { children } = props;

    return <>{children}</>;
};

const Trigger: React.FC<IDropdownPopOverTrigger> = props => {
    const { onClick, children, isOpen } = props;

    const isOpenClassName = isOpen ? 'isopen' : '';
    return (
        <div className={`${isOpenClassName}`}>
            {isValidElement(children) &&
                cloneElement(children, { onClick: onClick })}
        </div>
    );
};

const DropdownPopOver: React.FC<IDropdownPopOver> &
    IDropdownPopOverCompouned = props => {
    const [render, setRender] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dpwnRef = useRef<HTMLDivElement>(null);
    const { placement } = props;

    const handleClickOutside = useCallback((e: MouseEvent) => {
        if (!dpwnRef.current) return;
        if (!dpwnRef.current.contains(e.target as Node)) {
            document.removeEventListener('click', handleClickOutside);
            setIsOpen(false);
        }
    }, []);

    const handleClick = () => {
        setIsOpen(c => !c);
        if (isOpen == false)
            document.addEventListener('click', handleClickOutside);
    };

    useEffect(() => {
        isOpen == false &&
            document.removeEventListener('click', handleClickOutside);
    }, [isOpen, handleClickOutside]);

    useEffect(() => {
        if (isOpen) setRender(true);
    }, [isOpen]);

    const onAnimationEnd = () => {
        if (!isOpen) setRender(false);
    };

    const isExiting = !isOpen ? 'isexiting' : '';
    const isOpenClassName = isOpen ? 'isopen' : '';
    const placementClassName =
        !placement || placement == 'top-center'
            ? 'topCenter'
            : placement == 'bottom-center'
            ? 'bottomCenter'
            : '';

    return (
        <DropdownPopoverWrap
            className={`${isOpenClassName} ${placementClassName}`}
        >
            {Children.map(props.children, child => {
                if (isValidElement(child)) {
                    if (child.type == Trigger) {
                        return cloneElement(child, {
                            onClick: handleClick,
                            isOpen: isOpen
                        });
                    }
                }
            })}
            {render && (
                <DropdownPopoverCont
                    onAnimationEnd={onAnimationEnd}
                    ref={dpwnRef}
                    className={`${isExiting}`}
                >
                    {Children.map(props.children, child => {
                        if (isValidElement(child)) {
                            if (child.type == Popover) {
                                return child;
                            }
                        }
                    })}
                </DropdownPopoverCont>
            )}
        </DropdownPopoverWrap>
    );
};

DropdownPopOver.Trigger = Trigger;
DropdownPopOver.Popover = Popover;

export default DropdownPopOver;
