import React, { useState, SyntheticEvent, useRef, useCallback } from 'react';
import Button from '@components/_common/Button';
import styled from 'styled-components';
import { APIMercure } from '@api/mercureApi';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';
import { useMSTranslation } from '@utils/useMSTranslation';

const { publicRuntimeConfig } = getConfig();

const ChatInputWrapper = styled.div`
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
`;

const ChatTextarea = styled.textarea`
    background: none;
    border: none;
    resize: none;
    color: #fff;
    overflow-x: hidden;
    appearance: none;
    padding: 20px 70px 20px 25px;
    width: 100%;
    outline: none;
`;

const ChatInput = () => {
    const [value, setValue] = useState('');
    const { __ } = useMSTranslation(['event']);

    const TextareaRef = useRef<HTMLTextAreaElement>(null);
    const roomId = useSelector((state: IStore) => state.call.roomId);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const authUser = useSelector((state: IStore) => state.Auth.user);
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const tchatMuted = useSelector((state: IStore) => state.call.tchatMuted);

    const handleChange = useCallback((event: SyntheticEvent) => {
        const elm = event.target as HTMLTextAreaElement;
        setValue(elm.value);

        // algo auto-grow
        const textareaLineHeight = 17.25;
        elm.rows = 1;
        const currentRows = Math.round(
            (elm.scrollHeight - 40) / textareaLineHeight
        );
        if (currentRows >= 3) {
            elm.rows = 3;
        } else {
            elm.rows = currentRows;
        }
    }, []);
    const uid = uuidv4();

    const sendValue = useCallback(() => {
        if (tchatMuted || value.trim() == '') return;
        // Envoyer un msg sur le tchat
        const data = {
            msgId: uid,
            roomId: roomId,
            type: 'tchat_msg',
            msg: value,
            username: authUser?.userName,
            userId: authUser?.userId,
            sessionId: callObject
                ? callObject.participants().local.session_id
                : '',
            date: Date.now()
        };
        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            publicRuntimeConfig.LIEN_SITE + roomId,
            data
        ).then(() => {
            setValue('');
            if (TextareaRef.current) {
                TextareaRef.current.rows = 1;
            }
        });
    }, [
        authUser,
        callObject,
        mercureTokenEvent,
        roomId,
        uid,
        value,
        tchatMuted
    ]);

    const onEnter = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key == 'Enter') {
                event.preventDefault();
                sendValue();
            }
        },
        [sendValue]
    );

    if (tchatMuted) {
        return (
            <ChatInputWrapper>
                <div className='p-2 text-center leading-normal opacity-50'>
                    <em>
                        {__(
                            "Vous n'avez plus l'autorisation de parler par écrit",
                            'event'
                        )}
                    </em>
                </div>
            </ChatInputWrapper>
        );
    }
    return (
        <ChatInputWrapper>
            <ChatTextarea
                placeholder={__('Votre message', 'event') + '...'}
                rows={1}
                onChange={e => handleChange(e)}
                onKeyPress={e => onEnter(e)}
                value={value}
                ref={TextareaRef}
            />
            <Button leftIcon='paper-plane' onlyIcon onClick={sendValue} />
        </ChatInputWrapper>
    );
};

export default ChatInput;
