import styled from 'styled-components';
import { bp } from '@components/_struct/variables';

export const MinimalFormLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    padding: 20px;
    @media (${bp.sm}) {
        justify-content: start;
        height: 100%;
        padding: 40px 20px;
    }
`;
