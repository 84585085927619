import styled from 'styled-components';
import { bp } from '@components/_struct/variables';

export const TitleEvent = styled.div`
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    @media (${bp.lg}) {
        font-size: 20px;
    }
`;

export const Picto = styled.div`
    font-size: 30px;
    width: 32px;
    color: #3a90e1;
    @media (${bp.lg}) {
        width: auto;
        font-size: 16px;
    }
`;

export const InfosEventItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const HeadSection = styled.div`
    position: relative;
    overflow: hidden;
    min-height: 400px;
    margin-top: -69px;
    @media (${bp.lg}) {
        min-height: 250px;
    }
    @media (${bp.md}) {
        margin-top: -49px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
    }
    > * {
        position: relative;
        z-index: 2;
        color: #fff;
    }
    .avatar-size {
        width: 120px;
        height: 120px;
        > * {
            width: 120px;
            height: 120px;
        }
        @media (${bp.lg}) {
            width: 56px;
            height: 56px;
            > * {
                width: 56px;
                height: 56px;
            }
        }
    }
`;

export const Cover = styled.div`
    background-size: cover;
    background-position: center center;
    position: absolute !important;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: 0 !important;
`;

export const Header = styled.div`
    position: relative;
    z-index: 20;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    background: rgba(10, 14, 39, 0.2);
    @media (${bp.lg}) {
        background: rgba(10, 14, 39, 0.2);
        padding: 10px 10px;
    }
`;

export const LogoCont = styled.div`
    width: 103px;
`;

export const TitleForm = styled.h2`
    font-size: 25px;
    font-weight: bold;
    @media (${bp.lg}) {
        font-size: 20px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
`;

export const WrapperDesc = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    p {
        margin: 0;
    }
    blockquote {
        border-left: 4px solid #ccc;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-left: 16px;
        margin-left: 0;
        margin-right: 0;
    }
`;

export const SectionForm = styled.div`
    background: #f0f1f5;
`;

export const InfosWrapper = styled.div`
    background: #0a0e27;
    padding: 20px;
    padding-left: 40px;
    color: #fff;
    border-radius: 100px;
    @media (${bp.lg}) {
        border-radius: 0;
        padding: 15px;
    }
`;

export const InfosSection = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -45px;
    z-index: 10;
    @media (${bp.lg}) {
        margin-top: 0;
        background: #0a0e27;
    }
`;

export const MobileJoin = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 15%,
        rgba(255, 255, 255, 1) 100%
    );
`;

export const Footer = styled.div`
    background: #0a0e27;
    color: #fff;
    flex: 1;
`;

export const FooterMenu = styled.ul`
    li + li::before {
        content: '|';
        margin-left: 10px;
        margin-right: 10px;
        opacity: 0.5;
        @media (${bp.lg}) {
            display: none;
        }
    }
    a {
        color: #fff;
    }
`;
