import styled from 'styled-components';
import { form } from '@components/_struct/variables';

// Last update : 22/05/2020

export const MultiValItem = styled.div`
    display: flex;
    margin: 0;
    background: ${form.tagBackgroundDefault};
    color: ${form.tagTextDefault};
    padding: 4px 6px;
    border-radius: 3px;
    .multival-label {
        font-size: ${form.fontSizeInput};
        font-weight: bold;
    }
    .multival-delete {
        padding: 0;
        border: 0;
        background: transparent;
        margin: 0 5px 0 0;
        transition: all 0.15s ease;
        color: ${form.tagTextDefault};
        cursor: pointer;
        svg {
            width: 16px;
            display: block;
            opacity: 0.7;
            transition: all 0.15s ease;
        }
        &:hover,
        &:focus {
            svg {
                opacity: 1;
            }
        }
        &:focus {
            outline: 0;
        }
    }
`;

export const MultiValWrap = styled.div`
    display: flex;
    margin: 8px -3px 0;
    flex-wrap: wrap;
`;
