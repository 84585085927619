import React from 'react';
import {
    ListUsersWrapper,
    ListCloseButton,
    ListUsersTitle,
    ListUsers,
    BottomSectionVisitors
} from './RoomListUsers.css';
import { UIActions } from '@actions/ui_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import UBCheckbox from '@components/_common/_form/Checkbox/UBCheckbox';
import DropdownContext from '@components/_common/DropdownContext';
import { IStore } from '@interfaces/logic/redux';
import { IApp } from '@interfaces/pages/_app';
import RoomVisitorItem from './RoomVisitorItem';
import Button from '@components/_common/Button';

const RoomListVisitors = () => {
    const dispatch = useDispatch();

    const participants = useSelector(
        (state: IStore) => state.call.participants
    );

    return (
        <ListUsersWrapper>
            <div className='p-2 pb-1 select-none'>
                <div>8 Visiteurs à traiter</div>
            </div>
            <ListCloseButton
                onClick={() => dispatch(UIActions.toggleShowWaitingList())}
            >
                <FontAwesomeIcon icon={['far', 'times']} />
            </ListCloseButton>
            <SimpleBar className='scrollable-area'>
                <ListUsersTitle>
                    <UBCheckbox variant='white' />
                    <div className='flex-1'>Visiteurs</div>
                    <div className='ml-1'>
                        <DropdownContext placement='bottom-end' sizeBtn='small'>
                            <DropdownContext.Item>Coucou</DropdownContext.Item>
                        </DropdownContext>
                    </div>
                </ListUsersTitle>
                <ListUsers>
                    {participants.map((item: IApp.IParticipant) => {
                        return (
                            <RoomVisitorItem
                                key={item.userId}
                                userName={item.userName}
                                userId={item.userId}
                            />
                        );
                    })}
                </ListUsers>
            </SimpleBar>
            <BottomSectionVisitors>
                <div className='flex-1'>
                    <Button variant='outline-white' fullWidth>
                        Tout refuser
                    </Button>
                </div>
                <div className='ml-2 flex-1'>
                    <Button variant='action' fullWidth>
                        Tout accepter
                    </Button>
                </div>
            </BottomSectionVisitors>
        </ListUsersWrapper>
    );
};

export default RoomListVisitors;
