import React, { useCallback } from 'react';
import SimpleBar from 'simplebar-react';
import CandidatNotesInput from './CandidatNotesInput/CandidatNotesInput';
import styled from 'styled-components';
import DropdownContext from '@components/_common/DropdownContext';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { format } from 'date-fns';
import Confirm from '@components/_common/Modal/Confirm';
import { useModal } from '@components/_common/Modal/Modal';
import { DataActions } from '@logic/actions/data_actions';
import { useMSTranslation } from '@utils/useMSTranslation';

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .scrollable-area {
        flex: 1;
        min-height: 0;
        position: relative;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.25);
        }
    }
`;
interface IProps {
    notes: INoteApi[];
    participant: any;
}

const CandidatNotes = (props: IProps) => {
    const { notes, participant } = props;
    const { __ } = useMSTranslation(['struct', 'event']);

    const loading = useSelector((state: IStore) => state.Loading.actionLoading);

    let contentNotes: JSX.Element | JSX.Element[] = (
        <div className='py-3 text-center'>
            <span className='opacity-50'>{__('Chargement', 'struct')}...</span>
        </div>
    );

    if (loading.indexOf('getNotes') == -1) {
        if (notes && notes.length > 0) {
            contentNotes = notes.map((item: INoteApi) => {
                return (
                    <Note
                        key={item.id}
                        user={item.author.name}
                        participantId={participant.id}
                        ownerId={item.author.id}
                        id={item.id}
                        date={item.date}
                    >
                        {item.text}
                    </Note>
                );
            });
        } else
            contentNotes = (
                <div className='py-3 text-center'>
                    <span className='opacity-50'>
                        {__('Aucune note pour le moment', 'event')}
                    </span>
                </div>
            );
    }

    return (
        <Wrapper>
            <SimpleBar className='scrollable-area'>
                <div className='px-2'>{contentNotes}</div>
            </SimpleBar>
            <CandidatNotesInput participant={participant} />
        </Wrapper>
    );
};

const NoteWrapper = styled.div`
    position: relative;
    padding: 20px 0;
    display: flex;
    & + & {
        border-top: 1px solid #e3e4e8;
    }
`;

const UserNameRow = styled.div`
    display: flex;
    font-size: 15px;
    color: #6c6e7e;
    margin-bottom: 5px;
`;

const Note: React.FC<INote> = props => {
    const { user, date, children, ownerId, id } = props;
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const [visibleConfirm, handleCloseConfirm, openConfirm] = useModal();
    const dispatch = useDispatch();
    const notes = useSelector((state: IStore) => state.Data.notes);
    const { __ } = useMSTranslation(['struct', 'event']);

    const delNote = useCallback(() => {
        if (ctoken) dispatch(DataActions.delNote(id, ctoken, notes));
    }, [ctoken, id, dispatch, notes]);

    return (
        <NoteWrapper>
            <div className='flex-1'>
                <UserNameRow>
                    <div>
                        {format(date * 1000, 'dd/MM/yyyy HH:mm')}{' '}
                        <strong>{user}</strong> {__('note', 'event')}
                    </div>
                </UserNameRow>
                <div className='text-base leading-normal'>{children}</div>
            </div>
            {userSite && ownerId == userSite.userId && (
                <div>
                    <Confirm
                        visible={visibleConfirm}
                        onClose={handleCloseConfirm}
                        onSubmit={delNote}
                        title={__('Supprimer cette note ?', 'event')}
                        description={__(
                            'Voulez-vous supprimer cette note ?',
                            'event'
                        )}
                    />
                    <DropdownContext>
                        <DropdownContext.Item onClick={openConfirm}>
                            {__('Supprimer', 'struct')}
                        </DropdownContext.Item>
                    </DropdownContext>
                </div>
            )}
        </NoteWrapper>
    );
};

export default CandidatNotes;
