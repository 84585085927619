import React from 'react';
import { MultiValWrap } from '../MultiVal/MultiVal.css';
import MultiVal from '../MultiVal';
import { FormikHandlers, useField } from 'formik';

interface IFileUploadMultiTag {
    name: string;
    onChange?: FormikHandlers['handleChange'];
    multiple?: boolean;
    fileName?: string;
    setFileName: React.Dispatch<React.SetStateAction<string>>;
    input?: React.RefObject<HTMLInputElement>;
}

// Last update : 25/09/2020

const FileUploadMultiTag = (props: IFileUploadMultiTag) => {
    const { onChange, multiple, fileName, setFileName, input } = props;
    const [field, , helpers] = useField(props);

    const deleteVal = (index?: number) => {
        if (!multiple) {
            if (input && input.current) {
                input.current.value = '';
            }
            helpers.setValue({});
            setFileName('');
            onChange && onChange({});
        } else if (index) {
            const newFileList = Array.from(field.value);
            const listItems = newFileList.splice(index, 1);
            helpers.setValue(listItems);
            onChange && onChange(listItems);
        }
    };

    if (!multiple) {
        return (
            <>
                {fileName != undefined && fileName != '' && (
                    <MultiValWrap>
                        <MultiVal
                            label={fileName}
                            onDelete={() => deleteVal()}
                        />
                    </MultiValWrap>
                )}
            </>
        );
    } else {
        return (
            <>
                {field.value.length > 0 && (
                    <MultiValWrap>
                        {[...field.value].map((item: File, i: number) => (
                            <MultiVal
                                label={item.name}
                                key={i}
                                onDelete={() => deleteVal(i)}
                            />
                        ))}
                    </MultiValWrap>
                )}
            </>
        );
    }
};

export default FileUploadMultiTag;
