import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';

interface IAudioProps {
    audioTrack: MediaStreamTrack | null;
    local: boolean;
}
const AudioCam = (props: IAudioProps) => {
    const { audioTrack, local } = props;
    const audioEl = useRef<HTMLAudioElement>(null);

    // Technique tentative pour résoudre le son qui se perd parfois chez certains (fatinne?)
    // On tente de forcer un rerender lorsque le state du meeting change. Arrive peut etre en cas de déco/reco (4g etc)
    const meetingState = useSelector(
        (state: IStore) => state.call.meetingState
    );

    useEffect(() => {
        if (audioEl && audioEl.current && audioTrack) {
            audioEl.current.srcObject = new MediaStream([audioTrack]);
        }
    }, [audioTrack, meetingState]);

    let audioLayout = null;
    if (audioTrack && !local)
        audioLayout = <audio autoPlay playsInline ref={audioEl} />;

    return audioLayout;
};

export default AudioCam;
