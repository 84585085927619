import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { useMSTranslation } from '@utils/useMSTranslation';
import styled from 'styled-components';
import { background, bp } from '@components/_struct/variables';
import { Container } from '@components/_struct/_layouts/Grid';
import Scene from '@components/Event/SelectRoom/Scene';
import { Block } from '@components/_common/Block/index.css';
import Button from '@components/_common/Button';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormLabel from '@components/_common/_form/FormLabel';
import TextField from '@components/_common/_form/TextField/TextField';
import PhoneField from '@components/_common/_form/PhoneField/PhoneField';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import useResizeObserver from 'use-resize-observer';
import { APIUsers } from '@api/usersApi';
import ErrorMsg from '@components/_common/_form/ErrorMsg/ErrorMsg';

const SectionMasked = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 1;
`;

const SectionBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${background.darkblue};
    canvas {
        height: 100% !important;
        width: 100%;
    }
`;

const SectionContainer = styled.div`
    position: relative;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 100px;
    padding-bottom: 180px;
    @media (${bp.md}) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
`;

const MW600 = styled.div`
    max-width: 600px;
`;

const PictoCont = styled.div`
    font-size: 96px;
`;

const AnimatedCont = styled.div`
    transition: opacity 500ms, transform 500ms;
    &.enter {
        opacity: 0;
    }
    &.enter-active {
        opacity: 1;
    }
    &.enter-done {
        opacity: 1;
    }
    &.exit {
        opacity: 1;
    }
    &.exit-active {
        opacity: 0;
    }
    &.exit-done {
        opacity: 0;
    }
`;

const AnimatedHeight = styled.div`
    transition: height 0.25s ease;
`;

export interface InscriptionForm {
    prenom: string;
    nom: string;
    email: string;
    phone: string;
    societe: string;
    fonction: string;
}

const FormSection = () => {
    const { __ } = useMSTranslation(['home']);

    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { height } = useResizeObserver({ ref });

    const Schema = Yup.object().shape({
        prenom: Yup.string().trim().required(__('Obligatoire')),
        nom: Yup.string().trim().required(__('Obligatoire')),
        email: Yup.string()
            .trim()
            .email(__("L'e-mail ci-dessus n'est pas valide"))
            .required(__('Obligatoire')),
        phone: Yup.string().trim().required(__('Obligatoire')),
        societe: Yup.string().trim().required(__('Obligatoire')),
        fonction: Yup.string().trim().required(__('Obligatoire'))
    });

    const initialValues: InscriptionForm = {
        prenom: '',
        nom: '',
        email: '',
        phone: '',
        societe: '',
        fonction: ''
    };

    const onSubmit = (values: InscriptionForm) => {
        if (ctoken) {
            setLoading(true);
            APIUsers.signUp(ctoken, values).then(res => {
                if (res.data?.status === true) setSubmitted(true);
                setLoading(false);
            });
        }
    };

    const checkEmail = async (value: string) => {
        if (ctoken)
            return APIUsers.emailDispo(ctoken, value).then(r => {
                if (r?.data?.status) {
                    return r.data.status;
                } else {
                    return false;
                }
            });
    };

    return (
        <SectionMasked id='form'>
            <SectionBackground>
                <Scene from='home' />
            </SectionBackground>
            <SectionContainer>
                <Container>
                    <div className='text-center mb-4 text-3xl font-bold text-white'>
                        {__(
                            'Créez votre prochain évènement en quelques minutes !',
                            'home'
                        )}
                    </div>
                    <MW600 className='mx-auto'>
                        <Block className='md:p-4 p-2 mb-2'>
                            <AnimatedHeight style={{ height: height }}>
                                <div ref={ref}>
                                    <SwitchTransition>
                                        <CSSTransition
                                            key={submitted ? '1' : '2'}
                                            timeout={500}
                                        >
                                            {!submitted ? (
                                                <AnimatedCont>
                                                    <h2 className='text-xl text-main text-center mb-2'>
                                                        {__(
                                                            'Inscrivez-vous',
                                                            'home'
                                                        )}
                                                    </h2>
                                                    <Formik
                                                        initialValues={
                                                            initialValues
                                                        }
                                                        validationSchema={
                                                            Schema
                                                        }
                                                        onSubmit={onSubmit}
                                                    >
                                                        {() => (
                                                            <Form>
                                                                <div>
                                                                    <FormLabel htmlFor='prenom'>
                                                                        {__(
                                                                            'Prénom',
                                                                            'struct'
                                                                        )}
                                                                    </FormLabel>
                                                                    <TextField
                                                                        name='prenom'
                                                                        placeholder=''
                                                                    />
                                                                    <ErrorMsg name='prenom' />
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <FormLabel htmlFor='nom'>
                                                                        {__(
                                                                            'Nom',
                                                                            'struct'
                                                                        )}
                                                                    </FormLabel>
                                                                    <TextField
                                                                        name='nom'
                                                                        placeholder=''
                                                                    />
                                                                    <ErrorMsg name='nom' />
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <FormLabel htmlFor='email'>
                                                                        {__(
                                                                            'Adresse e-mail',
                                                                            'struct'
                                                                        )}
                                                                    </FormLabel>
                                                                    <TextField
                                                                        name='email'
                                                                        placeholder={__(
                                                                            'Votre adresse e-mail',
                                                                            'struct'
                                                                        )}
                                                                        asyncValidation={
                                                                            checkEmail
                                                                        }
                                                                        asyncErrorMsg={__(
                                                                            'Cet email est déjà enregistré'
                                                                        )}
                                                                    />
                                                                    <ErrorMsg name='email' />
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <FormLabel htmlFor='phone'>
                                                                        {__(
                                                                            'Téléphone',
                                                                            'struct'
                                                                        )}
                                                                    </FormLabel>
                                                                    <PhoneField name='phone' />
                                                                    <ErrorMsg name='phone' />
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <FormLabel htmlFor='societe'>
                                                                        {__(
                                                                            'Entreprise',
                                                                            'struct'
                                                                        )}
                                                                    </FormLabel>
                                                                    <TextField
                                                                        name='societe'
                                                                        placeholder=''
                                                                    />
                                                                    <ErrorMsg name='societe' />
                                                                </div>
                                                                <div className='mt-2'>
                                                                    <FormLabel htmlFor='fonction'>
                                                                        {__(
                                                                            'Fonction',
                                                                            'struct'
                                                                        )}
                                                                    </FormLabel>
                                                                    <TextField
                                                                        name='fonction'
                                                                        placeholder=''
                                                                    />
                                                                    <ErrorMsg name='fonction' />
                                                                </div>
                                                                <div className='mt-4 text-center'>
                                                                    <Button
                                                                        variant='action'
                                                                        type='submit'
                                                                        isLoading={
                                                                            loading
                                                                        }
                                                                        loadingText={
                                                                            __(
                                                                                'Inscription en cours...',
                                                                                'home'
                                                                            ) +
                                                                            '...'
                                                                        }
                                                                    >
                                                                        {__(
                                                                            'Valider',
                                                                            'home'
                                                                        )}
                                                                    </Button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </AnimatedCont>
                                            ) : (
                                                <AnimatedCont>
                                                    <PictoCont className='text-action mb-4'>
                                                        <FontAwesomeIcon
                                                            className='block m-auto'
                                                            icon={[
                                                                'far',
                                                                'paper-plane'
                                                            ]}
                                                        />
                                                    </PictoCont>
                                                    <h2 className='text-xl text-main text-center mb-2'>
                                                        {__(
                                                            'Votre inscription a été prise en compte',
                                                            'home'
                                                        )}
                                                    </h2>
                                                    <p className='text-center opacity-50 leading-normal'>
                                                        {__(
                                                            'Vous allez recevoir votre mot de passe par mail',
                                                            'home'
                                                        )}
                                                    </p>
                                                </AnimatedCont>
                                            )}
                                        </CSSTransition>
                                    </SwitchTransition>
                                </div>
                            </AnimatedHeight>
                        </Block>
                    </MW600>
                </Container>
            </SectionContainer>
        </SectionMasked>
    );
};

export default FormSection;
