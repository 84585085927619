import React from 'react';
import { Chk, Label, ChkIndicator } from './Checkbox.css';
import { FormikHandlers, useField } from 'formik';

// Last update 08/07/2020

interface Checkbox {
    label?: string;
    value?: string;
    id?: string;
    checked?: boolean;
    onChange?: FormikHandlers['handleChange'];
    name: string;
    disabled?: boolean;
    variant?: 'white';
    widthChildrens?: string;
}

const Checkbox = (props: Checkbox) => {
    const {
        id,
        label,
        checked,
        onChange,
        widthChildrens,
        disabled,
        variant,
        ...rest
    } = props;

    const [field] = useField(props);

    const isChecked: boolean = checked != undefined ? checked : field.value;

    const onChanged = onChange != undefined ? onChange : field.onChange;

    const disabledClassName = disabled ? 'disabled' : '';

    const variantClassName = variant && variant == 'white' ? 'white' : '';

    return (
        <>
            <Label
                style={{ width: widthChildrens }}
                className={`${disabledClassName} ${variantClassName}`}
            >
                <Chk
                    {...field}
                    {...rest}
                    disabled={disabled}
                    checked={isChecked}
                    onChange={onChanged}
                    type='checkbox'
                    id={id}
                />
                <ChkIndicator />
                {label && label}
            </Label>
        </>
    );
};

export default React.memo(Checkbox);
