import React from 'react';
import { UserAvatarCont, UserAvatarInner } from './Avatar.css';
import { CSSProperties } from 'styled-components';

export interface IUserAvatar {
    name: string;
    src?: string;
    size?: string;
}

interface IUserAvatarStyle extends CSSProperties {
    background?: string;
}

const UserAvatar: React.FC<IUserAvatar> = props => {
    const { src, size, name } = props;

    const stringToHslColor = (
        name: string,
        saturation: number,
        lightness: number
    ) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        const h = hash % 360;
        return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
    };

    const getInitials = (name: string) => {
        const parts = name.split(' ');
        let initials = '';
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && parts[i] !== '') {
                initials += parts[i][0];
            }
        }
        initials = initials.toUpperCase();
        return initials;
    };

    const style: IUserAvatarStyle = {};

    if (!src) {
        style.background = stringToHslColor(name, 60, 60);
    }

    const inner = src ? <img src={src} alt={name} /> : getInitials(name);

    return (
        <UserAvatarCont size={size}>
            <UserAvatarInner size={size} style={style}>
                {inner}
            </UserAvatarInner>
        </UserAvatarCont>
    );
};

export default UserAvatar;
