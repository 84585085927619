import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { CallActions } from '@actions/call_actions';
import { MAX_PARTICIPANTS_SHOW, MEETING_STATE_JOINING } from '@constants';
import Cam from '@components/Event/Room/Cam';
import AudioCam from '@components/Event/Room/AudioCam';
import { IApp } from '@interfaces/pages/_app';
import { APIDaily } from '@api/dailyApi';
import Controls from '@components/Event/Room/Controls';
import { RoomCamGrid, RoomMain } from '@components/Event/EventLayout.css';
import useScreenOrientation from '@utils/useScreenOrientation';
import getConfig from 'next/config';
import { displayError, displayEvent } from '@components/_common/Toast';
import Portal from '@components/_common/Portal';
import { DataActions } from '@actions/data_actions';
import { UIActions } from '@actions/ui_actions';
import styled from 'styled-components';
import { bp } from '@components/_struct/variables';
import { useMSTranslation } from '@utils/useMSTranslation';
import Button from '@components/_common/Button';
import RoomDispatcher from './RoomDispatcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIMercure } from '@api/mercureApi';
import { useMercureEventSource } from '@hooks/useMercureEventSource';
import { useMercureUpdateParticipants } from '@hooks/useMercureUpdateParticipants';
import { ConfigActions } from '@actions/config_actions';
import { LoadingActions } from '@actions/loading_actions';
import { isMobile } from 'react-device-detect';

const { publicRuntimeConfig } = getConfig();

const MainTitle = styled.h1`
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    @media (${bp.sm}) {
        font-size: 24px;
        margin-bottom: 20px;
    }
`;

const RoomDispatcherCont = styled.div`
    width: 100%;
    max-width: 470px;
`;

const Room = () => {
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const { __ } = useMSTranslation(['event']);

    const participants = useSelector(
        (state: IStore) => state.call.participants
    );
    const meetingState = useSelector(
        (state: IStore) => state.call.meetingState
    );
    const activeSpeakersIdsList = useSelector(
        (state: IStore) => state.call.activeSpeakersIdsList
    );
    const activeSpeakerId = useSelector(
        (state: IStore) => state.call.activeSpeakerId
    );
    const pinnedUsersIds = useSelector(
        (state: IStore) => state.call.pinnedUsersIds
    );
    const screenShareUsersIds = useSelector(
        (state: IStore) => state.call.screenShareUsersIds
    );
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const infosEvent = useSelector((state: IStore) => state.Data.event);

    const roomId = useSelector((state: IStore) => state.call.roomId);

    let infosRoom = null;
    if (infosEvent && roomId) {
        infosRoom = infosEvent.rooms.filter(
            (value: IRoom) => value.roomId == roomId
        );
    }

    const isTmpRoom = infosRoom ? infosRoom.length == 0 : false;

    const userSite = useSelector((state: IStore) => state.Auth.user);

    const isIntervenant = useSelector(
        (state: IStore) => state.Auth.user?.intervenant
    );
    const receptionRoom = useSelector(
        (state: IStore) => state.call.receptionRoom
    );
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const needReception = useSelector(
        (state: IStore) => state.Config.needReception
    );
    const tmpRoomOwner = useSelector((state: IStore) => state.UI.tmpRoomOwner);

    const { __s } = useMSTranslation();

    const dispatch = useDispatch();

    const joinRef = useRef<HTMLAudioElement>(null);
    const leaveRef = useRef<HTMLAudioElement>(null);
    const [redirecting, setRedirecting] = useState(false);

    const [waitingParticipantsLoaded, setWaitingParticipantsLoaded] = useState(
        false
    );

    const timeoutToLeaveIfNoOwner = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        return () => {
            if (isTmpRoom && tmpRoomOwner) {
                dispatch(UIActions.setTmpRoomOwner(false));
            }
        };
    }, [isTmpRoom, tmpRoomOwner, dispatch]);

    useEffect(() => {
        if (receptionRoom && !userSite?.intervenant) {
            if (
                participants.find(participant => participant.owner) ===
                undefined
            ) {
                if (timeoutToLeaveIfNoOwner.current)
                    clearTimeout(timeoutToLeaveIfNoOwner.current);
                timeoutToLeaveIfNoOwner.current = setTimeout(() => {
                    callObject.leave();
                }, 30000);
            } else clearTimeout(timeoutToLeaveIfNoOwner.current);
        }
    }, [
        mercureTokenEvent,
        participants,
        receptionRoom,
        callObject,
        timeoutToLeaveIfNoOwner,
        userSite
    ]);

    // On va chercher la config daily de la room
    useEffect(() => {
        if (!roomId) return;
        APIDaily.getRoomStatus(roomId).then(r => {
            if (r && r.data) {
                if (r.data.config && r.data.config.start_audio_off) {
                    dispatch(
                        CallActions.setDefaultMuted(
                            r.data.config.start_audio_off
                        )
                    );
                }
                if (r.data.config && r.data.config.start_video_off)
                    dispatch(
                        CallActions.setDefaultNoCam(
                            r.data.config.start_video_off
                        )
                    );
            }
        });
    }, [roomId, dispatch]);

    // On s'abonne à mercure
    let topics: string[] = [];
    let subscribes: string[] = [];
    let payload: IPayloadUserMercure | null = null;

    if (userSite) {
        // Le topic personnel de l'utilisateur
        const topicUser =
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId;

        topics = [
            publicRuntimeConfig.LIEN_SITE + roomId,
            '/.well-known/mercure/subscriptions/' +
                encodeURIComponent(publicRuntimeConfig.LIEN_SITE + roomId) +
                '{/subscriber}',
            topicUser
        ];

        subscribes = [
            publicRuntimeConfig.LIEN_SITE + roomId,
            '/.well-known/mercure/subscriptions/' +
                encodeURIComponent(publicRuntimeConfig.LIEN_SITE + roomId) +
                '{/subscriber}',
            topicUser
        ];

        if (userSite.intervenant) {
            subscribes = [
                ...subscribes,
                '/.well-known/mercure/subscriptions/' +
                    encodeURIComponent(
                        publicRuntimeConfig.LIEN_SITE + roomId + 'waitinglist'
                    ) +
                    '{/subscriber}'
            ];
            topics = [
                ...topics,
                '/.well-known/mercure/subscriptions/' +
                    encodeURIComponent(
                        publicRuntimeConfig.LIEN_SITE + roomId + 'waitinglist'
                    ) +
                    '{/subscriber}'
            ];
        }

        // Construction du JWT
        payload = {
            userId: userSite.userId,
            userName: userSite.userName,
            intervenant: userSite.intervenant,
            topic: publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId
        };
    }

    const handleRemoveParticipantWaiting = useCallback(
        (userId: string) => {
            dispatch(CallActions.removeWaitingParticipantRoom(userId));
        },
        [dispatch]
    );

    const handleAddParticipantWaiting = useCallback(
        (newParticipant: IApp.IParticipantEvent) => {
            dispatch(CallActions.addWaitingParticipantRoom(newParticipant));

            displayEvent(
                __('souhaite rejoindre cette room', 'event'),
                newParticipant.userName,
                {
                    closeOnClick: false,
                    onClick: () =>
                        dispatch(
                            UIActions.openDrawerCandidat(newParticipant.userId)
                        ),
                    autoClose: 5000
                }
            );
        },
        [__, dispatch]
    );

    const updateListParticipantsWaiting = useMercureUpdateParticipants(
        handleRemoveParticipantWaiting,
        handleAddParticipantWaiting
    );

    const handleParticipantJoined = useCallback(
        (newParticipant: IApp.IParticipantEvent) => {
            if (!isMobile)
                displayEvent(
                    __('a rejoint la salle', 'event'),
                    newParticipant.userName
                );
        },
        [__]
    );
    const showParticipantJoined = useMercureUpdateParticipants(
        null,
        handleParticipantJoined
    );

    const handleMessageMercure = useCallback(
        (data: IMessageMercure & IMessageMercureMsgChat) => {
            if (!userSite) return;

            if (
                userSite.intervenant &&
                data.topic ==
                    publicRuntimeConfig.LIEN_SITE + roomId + 'waitinglist'
            ) {
                updateListParticipantsWaiting(data);
            }
            if (data.type == 'tchat_msg') {
                dispatch(
                    DataActions.setMessage({
                        msgId: data.msgId,
                        msg: data.msg,
                        username: data.username,
                        userId: data.userId,
                        date: data.date
                    })
                );
                dispatch(UIActions.incrementUnreadedMsgCount());
            }
            if (data.type == 'tchat_event')
                switch (data.event) {
                    case 'DELETE_MSG':
                        dispatch(DataActions.deleteMessage(data.msgId));
                        break;
                    case 'HIDE_MSG':
                        dispatch(DataActions.hideMessage(data.msgId));
                        break;
                    case 'UNHIDE_MSG':
                        dispatch(DataActions.unHideMessage(data.msgId));
                        break;
                    case 'MUTE_USER':
                        if (`${userSite.userId}` == `${data.userId}`)
                            dispatch(CallActions.tchatMute(true));
                        dispatch(
                            CallActions.setMutedParticipant(data.userId, true)
                        );
                        break;
                    case 'UNMUTE_USER':
                        if (`${userSite.userId}` == `${data.userId}`)
                            dispatch(CallActions.tchatMute(false));
                        dispatch(
                            CallActions.setMutedParticipant(data.userId, false)
                        );
                        break;
                    case 'KICK_USER':
                        if (userSite.userId == data.userId) {
                            callObject.leave();
                            displayError(
                                __(
                                    'Un intervenant vous a exclu de la salle !',
                                    'event'
                                )
                            );
                        }

                        break;
                    default:
                        break;
                }

            if (data.topic == publicRuntimeConfig.LIEN_SITE + roomId) {
                showParticipantJoined(data);
            }

            // Si on se fait rediriger par un intervenant
            if (data.type == 'redirection_room') {
                if (meetingToken && data.roomId) {
                    dispatch(
                        CallActions.joinRoom(
                            meetingToken,
                            data.roomId,
                            callObject
                        )
                    );
                    dispatch(ConfigActions.setNeedReception(false));
                    dispatch(
                        LoadingActions.setText(
                            <>
                                {__('Redirection vers la room', 'event')}{' '}
                                <div className='text-paleYellow ml-1'>
                                    {
                                        infosEvent?.rooms.filter(
                                            item => item.roomId === data.roomId
                                        )[0].nom
                                    }
                                </div>
                            </>
                        )
                    );
                }
            }

            // Si on se fait rediriger sur le hall
            if (data.type == 'redirection_hall') {
                dispatch(ConfigActions.setNeedReception(false));
                if (callObject) callObject.leave();
            }

            // Si on se fait déconnecter de l'évènement par un intervenant
            if (data.type == 'disconnect_event') {
                window.location.href =
                    publicRuntimeConfig.LIEN_SITE + infosEvent?.roomId;
            }
        },
        [
            userSite,
            roomId,
            updateListParticipantsWaiting,
            dispatch,
            callObject,
            __,
            showParticipantJoined,
            meetingToken,
            infosEvent
        ]
    );

    const {
        token: JWTTokenMercure,
        status: statusMercureES
    } = useMercureEventSource({
        topics,
        subscribes,
        payload,
        handleMessage: handleMessageMercure,
        initialStatus: 'idle'
    });

    // Lorsqu'on se connecte pour la première fois on update notre liste de participants en attente si on est intervenant
    useEffect(() => {
        if (!userSite || !JWTTokenMercure) return;

        if (statusMercureES === 'open:first') {
            // Si on est intervenant
            if (userSite.intervenant && !waitingParticipantsLoaded) {
                dispatch(
                    CallActions.updateWaitingParticipantsRoom(
                        JWTTokenMercure,
                        publicRuntimeConfig.LIEN_SITE + roomId + 'waitinglist'
                    )
                );
                setWaitingParticipantsLoaded(true);
            }
        }
    }, [
        userSite,
        dispatch,
        roomId,
        JWTTokenMercure,
        waitingParticipantsLoaded,
        statusMercureES
    ]);

    // On clean les messages au démontage
    useEffect(() => {
        dispatch(DataActions.resetMessages());
    }, [dispatch]);

    // Détection des changements sur les participants
    useEffect(() => {
        if (!callObject) return;

        const events = [
            'participant-joined',
            'participant-updated',
            'participant-left'
        ];

        function handleNewParticipantsState() {
            dispatch(CallActions.setParticipants(callObject.participants()));
        }

        handleNewParticipantsState();

        for (const event of events) {
            callObject.on(event, handleNewParticipantsState);
        }

        return function cleanup() {
            for (const event of events) {
                callObject.off(event, handleNewParticipantsState);
            }
        };
    }, [callObject, dispatch]);

    // Détection des changements sur la personne qui parle actuellement
    useEffect(() => {
        if (!callObject) return;

        const handleNewActiveSpeaker = (e?: any) => {
            // On va chercher son userId dans les participants du call
            let speakerUserId = null;
            Object.entries(callObject.participants()).forEach((item: any) => {
                if (item[1].session_id === e.activeSpeaker.peerId)
                    speakerUserId = item[1].user_id;
            });

            dispatch(CallActions.setActiveSpeaker(speakerUserId));
        };

        callObject.on('active-speaker-change', handleNewActiveSpeaker);
        return function cleanup() {
            callObject.off('active-speaker-change', handleNewActiveSpeaker);
        };
    }, [callObject, dispatch]);

    // Quand la quality du reseau change , on met à jour l'info networkQuality
    useEffect(() => {
        if (!callObject) return;

        const handleScreenShareEvent = (e?: any) => {
            dispatch(CallActions.setNetworkQuality(e.threshold));
        };
        callObject.on('network-quality-change', handleScreenShareEvent);

        return function cleanup() {
            callObject.off('network-quality-change', handleScreenShareEvent);
        };
    }, [callObject, dispatch]);

    // Quand les participants changent, on met à jour les infos sur le partage d'écran
    useEffect(() => {
        if (!callObject) return;

        const handleScreenShareEvent = () => {
            dispatch(CallActions.setScreenShare(callObject.participants()));
            // Si j'étais en train de share mon ecran, et que quelqu'un d'autre le fait, je desactive mon share
            if (
                callObject.participants().local.screen &&
                screenShareUsersIds !== null &&
                screenShareUsersIds.indexOf(
                    callObject.participants().local.user_id
                ) != -1
            ) {
                let otherShare = false;
                Object.entries(callObject.participants()).forEach(
                    (item: any) => {
                        if (item[1].screen && !item[1].local) otherShare = true;
                    }
                );
                if (otherShare) callObject.stopScreenShare();
            }
        };
        callObject.on('participant-updated', handleScreenShareEvent);

        return function cleanup() {
            callObject.off('participant-updated', handleScreenShareEvent);
        };
    }, [callObject, dispatch, screenShareUsersIds]);

    // Gestion des sons join/left
    useEffect(() => {
        if (!callObject || isMobile) return;

        const handleJoinLeaveSound = (e?: any) => {
            if (
                e.action == 'participant-joined' &&
                meetingState == 'MEETING_STATE_JOINED'
            ) {
                const sourceJoin1 = document.createElement('source');
                const sourceJoin2 = document.createElement('source');
                sourceJoin1.src = '/sounds/connect.wav';
                sourceJoin2.src = '/sounds/connect.ogg';
                const audioJoin = document.createElement('audio');
                audioJoin.appendChild(sourceJoin1);
                audioJoin.appendChild(sourceJoin2);
                audioJoin.play();
                audioJoin.remove();
            } else if (e.action == 'participant-left') {
                const sourceLeave1 = document.createElement('source');
                const sourceLeave2 = document.createElement('source');
                sourceLeave1.src = '/sounds/disconnect.mp3';
                sourceLeave2.src = '/sounds/disconnect.ogg';
                const audioLeave = document.createElement('audio');
                audioLeave.appendChild(sourceLeave1);
                audioLeave.appendChild(sourceLeave2);
                audioLeave.play();
                audioLeave.remove();
            }
        };

        callObject.on('participant-joined', handleJoinLeaveSound);
        callObject.on('participant-left', handleJoinLeaveSound);

        return function cleanup() {
            callObject.off('participant-joined', handleJoinLeaveSound);
            callObject.off('participant-left', handleJoinLeaveSound);
        };
    }, [callObject, joinRef, leaveRef, meetingState]);

    // Reset du texte de loading
    useEffect(() => {
        dispatch(LoadingActions.setText(null));
    }, [dispatch]);

    const getCam = useCallback(
        (participant: IApp.IParticipant, callItem: any) => {
            const user = infosEvent?.intervenants?.filter(
                user => user.id.toString() == participant.userId
            );

            return (
                <Cam
                    key={participant.userId}
                    videoTrack={
                        callItem.screen
                            ? callItem.screenVideoTrack
                            : callItem.videoTrack
                    }
                    audioTrack={
                        callItem.screen
                            ? callItem.screenAudioTrack
                            : callItem.audioTrack
                    }
                    isLoading={
                        meetingState === MEETING_STATE_JOINING ||
                        callItem.videoTrack === false ||
                        callItem.audioTrack === false
                    }
                    userName={participant.userName}
                    local={participant.local}
                    pinned={
                        pinnedUsersIds
                            ? pinnedUsersIds.includes(participant.userId)
                            : false
                    }
                    userId={participant.userId}
                    sharingScreen={participant.local && participant.screen}
                    activeSpeaker={
                        activeSpeakerId !== null &&
                        activeSpeakerId == participant.userId &&
                        participants.length <= MAX_PARTICIPANTS_SHOW
                    }
                    isAdmin={participant.owner}
                    interview={
                        isIntervenant &&
                        participant.userId != userSite?.userId &&
                        !participant.owner &&
                        receptionRoom
                    }
                    fonction={
                        user && user.length > 0 && user[0].fonction
                            ? user[0].fonction
                            : undefined
                    }
                    societeLogo={
                        user && user.length > 0 && user[0].societeLogo
                            ? user[0].societeLogo
                            : undefined
                    }
                />
            );
        },
        [
            meetingState,
            activeSpeakerId,
            receptionRoom,
            isIntervenant,
            userSite,
            infosEvent,
            participants,
            pinnedUsersIds
        ]
    );

    const getInfosParticipant = useCallback(
        (userId: string) => {
            const tabReturn: any[] = [];
            participants.forEach((item: IApp.IParticipant) => {
                if (item.userId == userId) {
                    tabReturn.push(item);
                }
            });

            Object.entries(callObject.participants()).forEach((item: any) => {
                if (item[1].user_id == userId) tabReturn.push(item[1]);
            });

            return tabReturn;
        },
        [callObject, participants]
    );

    // Affichage des cams en fonction de la page
    const cams: JSX.Element[] = [];
    const camOthers: JSX.Element[] = [];
    const audioAll: JSX.Element[] = [];

    let layout = 'normal';
    let mode = 'static';
    if (participants.length > MAX_PARTICIPANTS_SHOW) {
        layout = 'presentation';
        mode = 'speakers';
    }
    if (pinnedUsersIds !== null && pinnedUsersIds.length > 0)
        layout = 'presentation';

    // Si on est en layout de présentation, on détermine qui on doit pin, et qui on doit mettre en dessous
    if (layout == 'presentation') {
        // Si on est en mode speakers
        if (mode == 'speakers') {
            // On s'arrange pour avoir autant de cam qu'il faut pour les others, en fonction d'un pin manuel ou non
            let nbCamNeeded = MAX_PARTICIPANTS_SHOW;
            if (pinnedUsersIds !== null && pinnedUsersIds.length > 0) {
                nbCamNeeded--;
            }
            let nbCamNeededWithoutPinned = nbCamNeeded;

            for (let i = 0; i < nbCamNeededWithoutPinned; i++) {
                if (activeSpeakersIdsList === null || !activeSpeakersIdsList[i])
                    break;
                // Si cette personne n'est pas pin
                if (
                    pinnedUsersIds === null ||
                    pinnedUsersIds[0] != activeSpeakersIdsList[i]
                ) {
                    const [participant, callItem] = getInfosParticipant(
                        activeSpeakersIdsList[i]
                    );
                    camOthers.push(getCam(participant, callItem));
                } else if (
                    pinnedUsersIds &&
                    pinnedUsersIds[0] == activeSpeakersIdsList[i]
                ) {
                    nbCamNeededWithoutPinned++;
                }
            }

            // Si on a pas assez de speakers pour compléter camOthers, on complète avec d'autres participants si il y en a
            const participantsToComplete = participants.filter(
                x =>
                    (activeSpeakersIdsList === null ||
                        !activeSpeakersIdsList.includes(x.userId)) &&
                    (pinnedUsersIds === null || pinnedUsersIds[0] != x.userId)
            );

            const nbCamToComplete = nbCamNeeded - camOthers.length;
            for (let i = 0; i < nbCamToComplete; i++) {
                if (!participantsToComplete[i]) break;
                const [participant, callItem] = getInfosParticipant(
                    participantsToComplete[i].userId
                );
                camOthers.push(getCam(participant, callItem));
            }

            // Ensuite, si il y a quelqu'un de pin, on l'ajoute en cam pin, sinon on ajoute le premier camOthers
            if (pinnedUsersIds !== null && pinnedUsersIds.length > 0) {
                const [participant, callItem] = getInfosParticipant(
                    pinnedUsersIds[0]
                );
                cams.push(getCam(participant, callItem));
            } else {
                cams.push(camOthers[0]);
                camOthers.shift();
            }
        }
        // Sinon, en mode static + layout presentation on a forcément quelqu'un de pin
        else {
            if (pinnedUsersIds !== null) {
                const [participant, callItem] = getInfosParticipant(
                    pinnedUsersIds[0]
                );
                cams.push(getCam(participant, callItem));

                // Les others sont les MAX_PARTICIPANTS_SHOW-1 participants dans l'ordre
                const participantsTmp = participants.filter(
                    x => x.userId != pinnedUsersIds[0]
                );
                participantsTmp.forEach((item, index) => {
                    if (index < MAX_PARTICIPANTS_SHOW - 1) {
                        const [participant, callItem] = getInfosParticipant(
                            item.userId
                        );
                        camOthers.push(getCam(participant, callItem));
                    }
                });
            }
        }
    }
    // Sinon, si on est en mode normal
    else {
        for (let i = 0; i < MAX_PARTICIPANTS_SHOW; i++) {
            if (!participants[i]) break;

            const [participant, callItem] = getInfosParticipant(
                participants[i].userId
            );
            const camTmp = getCam(participant, callItem);
            cams.push(camTmp);
        }
    }

    const tabDeja: string[] = [];
    Object.entries(callObject.participants()).forEach((callItem: any) => {
        if (tabDeja.indexOf(callItem[1].user_id) === -1) {
            tabDeja.push(callItem[1].user_id);
            const audioTmp = (
                <AudioCam
                    key={callItem[1].user_id}
                    audioTrack={callItem[1].audioTrack}
                    local={callItem[1].local}
                />
            );
            audioAll.push(audioTmp);

            if (callItem[1].screen) {
                const audioTmp = (
                    <AudioCam
                        key={callItem[1].user_id + '_screen'}
                        audioTrack={callItem[1].screenAudioTrack}
                        local={callItem[1].local}
                    />
                );
                audioAll.push(audioTmp);
            }
        }
    });

    let pageLayout = (
        <>
            {cams}
            <Portal>{audioAll}</Portal>
        </>
    );

    if (layout == 'presentation') {
        pageLayout = (
            <>
                <div className='pinned'>{cams}</div>
                <div className='others'>{camOthers}</div>
                {audioAll}
            </>
        );
    }

    const screenOrientation = useScreenOrientation();

    const getGridClass = useCallback(
        (nbParticipants: number) => {
            if (layout == 'presentation') return 'grid-split';
            else if (nbParticipants < 5) return 'grid-' + nbParticipants;
            else return 'grid-5';
        },
        [layout]
    );

    const gridClassName = getGridClass(participants.length);
    const screenOrientationClassName =
        screenOrientation == 'landscape-primary' ||
        screenOrientation == 'landscape-secondary'
            ? 'landscape'
            : 'portrait';

    // Construction d'un tableau d'id des participants pour le <RoomDispatcher />
    const listUserId = participants
        .filter(item => {
            if (item.userId != userSite?.userId) return item;
        })
        .map(item => {
            return item.userId;
        });

    let participantsAfterDispatch = participants.slice();
    const disconnectUsers = (listUserId: string[]) => {
        const data = {
            type: 'disconnect_event'
        };

        listUserId.forEach(userId => {
            APIMercure.sendNotifUsers(
                mercureTokenEvent,
                publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
                data
            );
            participantsAfterDispatch = participantsAfterDispatch.filter(
                item => item.userId != userId
            );
        });
        if (participantsAfterDispatch.length == 1) {
            callObject.leave();
            participantsAfterDispatch = [];
        }
    };

    return (
        <RoomMain>
            {receptionRoom && !needReception && participants.length - 1 > 0 && (
                <MainTitle className='mt-2 md:mt-0'>
                    {__s(
                        participants.length - 1,
                        '{{count}} nouveau visiteur souhaite rejoindre l’évènement',
                        '{{count}} nouveaux visiteurs souhaitent rejoindre l’évènement',
                        'event'
                    )}
                </MainTitle>
            )}
            <RoomCamGrid
                className={`${gridClassName} ${screenOrientationClassName}`}
            >
                {pageLayout}
            </RoomCamGrid>
            {receptionRoom &&
                !redirecting &&
                participants.length - 1 > 1 &&
                userSite?.intervenant && (
                    <div className='mt-4'>
                        <div className='select-none'>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                className='mr-05'
                            />{' '}
                            {__(
                                'En acceptant tous les participants vous les redirigerez tous vers la même room',
                                'event'
                            )}{' '}
                        </div>
                        <div className='flex justify-center mt-2'>
                            <div className='mx-1'>
                                <Button
                                    variant='action'
                                    onClick={() => setRedirecting(true)}
                                >
                                    {__(
                                        'Accepter tous les participants',
                                        'event'
                                    )}
                                </Button>
                            </div>
                            <div className='mx-1'>
                                <Button
                                    variant='red'
                                    onClick={() => disconnectUsers(listUserId)}
                                >
                                    {__(
                                        'Refuser tous les participants',
                                        'event'
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            {receptionRoom && redirecting && (
                <RoomDispatcherCont className='mt-4'>
                    <RoomDispatcher
                        onBack={() => setRedirecting(false)}
                        usersToDispatch={listUserId}
                        labelBtnRedirect={__(
                            'Rediriger les participants',
                            'event'
                        )}
                    />
                </RoomDispatcherCont>
            )}
            <Controls />
        </RoomMain>
    );
};

export default Room;
