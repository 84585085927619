import { ACTION_TYPES } from '@actions/actions_types';
import DailyIframe from '@daily-co/daily-js';
import getConfig from 'next/config';
import { DailyCall } from '@interfaces/lib/daily-co';
import { APIMercure } from '@api/mercureApi';
import { IApp } from '@interfaces/pages/_app';
import { APIDaily } from '@api/dailyApi';
const { publicRuntimeConfig } = getConfig();

const setCall = (call: {}) => ({
    payload: { callObject: call },
    type: ACTION_TYPES.Call.setCallObject
});

const resetState = () => ({
    type: ACTION_TYPES.Call.resetState
});

const setMeetingState = (state: string) => ({
    payload: { meetingState: state },
    type: ACTION_TYPES.Call.setMeetingState
});

const setParticipants = (participants: any) => ({
    payload: { participants: participants },
    type: ACTION_TYPES.Call.setParticipants
});

const setRoomId = (id: string | null) => ({
    payload: { roomId: id },
    type: ACTION_TYPES.Call.setRoomId
});

const setNetworkQuality = (networkQuality: string) => ({
    payload: { networkQuality: networkQuality },
    type: ACTION_TYPES.Call.setNetworkQuality
});

const pinParticipant = (pinnedUserId: string | null) => ({
    payload: { pinnedUserId: pinnedUserId },
    type: ACTION_TYPES.Call.pinParticipant
});

const setScreenShare = (participants: any) => ({
    payload: { participants: participants },
    type: ACTION_TYPES.Call.setScreenShare
});

const setActiveSpeaker = (speakerId: null | string) => ({
    payload: { activeSpeakerId: speakerId },
    type: ACTION_TYPES.Call.setActiveSpeaker
});

const toggleDefaultMuted = () => ({
    type: ACTION_TYPES.Call.toggleDefaultMuted
});

const setDefaultMuted = (muted: boolean) => ({
    payload: { defaultMuted: muted },
    type: ACTION_TYPES.Call.setDefaultMuted
});

const toggleDefaultNoCam = () => ({
    type: ACTION_TYPES.Call.toggleDefaultNoCam
});

const setDefaultNoCam = (nocam: boolean) => ({
    payload: { defaultNoCam: nocam },
    type: ACTION_TYPES.Call.setDefaultNoCam
});

const setReceptionRoom = (status: boolean) => ({
    payload: { receptionRoom: status },
    type: ACTION_TYPES.Call.setReceptionRoom
});

const initiateCallObject = (
    token: string,
    roomId: string,
    dispatch: any,
    receptionRoom?: boolean
) => {
    const newCallObject = DailyIframe.createCallObject();
    newCallObject
        .join({
            url: publicRuntimeConfig.DAILY_LINK + roomId,
            token: token
        })
        .then(() => {
            dispatch(setRoomId(roomId));
            if (receptionRoom) dispatch(setReceptionRoom(true));
        });
    dispatch(setCall(newCallObject));
};
const joinRoom = (
    token: string,
    roomId: string,
    callObject: DailyCall | null,
    receptionRoom?: boolean
) => (dispatch: any) => {
    if (token) {
        // Si un callObject est déjà en cours, on le quitte
        if (
            callObject &&
            (callObject.meetingState() == 'joining-meeting' ||
                callObject.meetingState() == 'joined-meeting')
        ) {
            callObject.leave().then(() => {
                initiateCallObject(token, roomId, dispatch, receptionRoom);
            });
        } else {
            initiateCallObject(token, roomId, dispatch, receptionRoom);
        }
    }
};

// Mute le user sur le tchat
const tchatMute = (mute: boolean) => ({
    payload: { mute: mute },
    type: ACTION_TYPES.Call.tchatMute
});

const setWaitingParticipantsRoom = (
    waitingParticipantsRoom: IApp.IParticipantEvent[]
) => ({
    payload: { waitingParticipantsRoom: waitingParticipantsRoom },
    type: ACTION_TYPES.Call.setWaitingParticipantsRoom
});

const addWaitingParticipantRoom = (
    waitingParticipantRoom: IApp.IParticipantEvent
) => ({
    payload: { waitingParticipantRoom: waitingParticipantRoom },
    type: ACTION_TYPES.Call.addWaitingParticipantsRoom
});

const removeWaitingParticipantRoom = (waitingParticipantRoomId: string) => ({
    payload: { waitingParticipantRoomId: waitingParticipantRoomId },
    type: ACTION_TYPES.Call.removeWaitingParticipantsRoom
});

// Mettre à jour les participants en liste d'attente de la room
const updateWaitingParticipantsRoom = (token: string, topic: string) => (
    dispatch: any
) => {
    APIMercure.getParticipantsEvent(token, topic).then(r => {
        // On construit notre tableau de participants
        const data = r.data;
        const participants = data.subscriptions.map((item: any) => {
            return {
                userId: item.payload.userId,
                topic: item.payload.topic,
                userName: item.payload.userName,
                intervenant: item.payload.intervenant
            };
        });
        dispatch(setWaitingParticipantsRoom(participants));
    });
};

const joinOrCreate = (
    meetingToken: string,
    roomId: string,
    callObject: any
) => (dispatch: any) => {
    // On vérifie si la room existe déjà
    APIDaily.getRoomStatus(roomId).then(r => {
        if (r && r.data) {
            // Si elle n'existe pas, on la créé
            if (r.data && r.data.error && r.data.error === 'not-found') {
                APIDaily.createRoom(roomId).then(r => {
                    if (r && r.data)
                        dispatch(joinRoom(meetingToken, roomId, callObject));
                });
            } else dispatch(joinRoom(meetingToken, roomId, callObject));
        }
    });
};

const setMutedParticipant = (userId: string, muted: boolean) => ({
    payload: { userId: userId, muted: muted },
    type: ACTION_TYPES.Call.setMutedParticipant
});

const micToggle = (
    userId: string,
    mercureTokenEvent: string,
    value: boolean
) => () => {
    const data = {
        userId: userId,
        event: 'MIC_TOGGLE',
        type: 'call_event',
        value: value
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
        data
    );
};

const camToggle = (
    userId: string,
    mercureTokenEvent: string,
    value: boolean
) => () => {
    const data = {
        userId: userId,
        event: 'CAM_TOGGLE',
        type: 'call_event',
        value: value
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
        data
    );
};

export const CallActions = {
    setCall: setCall,
    setMeetingState: setMeetingState,
    setParticipants: setParticipants,
    setRoomId: setRoomId,
    setReceptionRoom: setReceptionRoom,
    pinParticipant: pinParticipant,
    setScreenShare: setScreenShare,
    setActiveSpeaker: setActiveSpeaker,
    setNetworkQuality: setNetworkQuality,
    resetState: resetState,
    toggleDefaultMuted: toggleDefaultMuted,
    setDefaultMuted: setDefaultMuted,
    toggleDefaultNoCam: toggleDefaultNoCam,
    setDefaultNoCam: setDefaultNoCam,
    joinRoom: joinRoom,
    updateWaitingParticipantsRoom: updateWaitingParticipantsRoom,
    tchatMute: tchatMute,
    joinOrCreate: joinOrCreate,
    setMutedParticipant: setMutedParticipant,
    setWaitingParticipantsRoom: setWaitingParticipantsRoom,
    removeWaitingParticipantRoom: removeWaitingParticipantRoom,
    addWaitingParticipantRoom: addWaitingParticipantRoom,
    micToggle: micToggle,
    camToggle: camToggle
};
