import { ACTION_TYPES } from '@actions/actions_types';
import { APIMercure } from '@api/mercureApi';
import { IApp } from '@interfaces/pages/_app';
import { APIRoom } from '@api/roomApi';
import { DailyCall } from '@interfaces/lib/daily-co';
import { CallActions } from '@actions/call_actions';
import { APIEvents } from '@api/eventApi';
import getConfig from 'next/config';
import { APINote } from '@api/noteApi';
import { LoadingActions } from '@actions/loading_actions';
import { UIActions } from './ui_actions';
const { publicRuntimeConfig } = getConfig();

// Mettre à jour les infos de l'event
const setDataEvent = (event: IEvent) => ({
    payload: { event: event },
    type: ACTION_TYPES.Data.setEvent
});

// Mettre à jour le token mercure
const setMercureTokenEvent = (token: string) => ({
    payload: { mercureTokenEvent: token },
    type: ACTION_TYPES.Data.setMercureTokenEvent
});

// Mettre à jour le lastEventIdReception mercure
const setLastEventIdReception = (id: string) => ({
    payload: { lastEventId: id },
    type: ACTION_TYPES.Data.setLastEventIdReception
});

// Mettre à jour le lastEventId mercure
const setLastEventID = (id: string) => ({
    payload: { lastEventId: id },
    type: ACTION_TYPES.Data.setLastEventId
});

const setParticipantsConnectedEvent = (
    participants: IApp.IParticipantEvent[]
) => ({
    payload: { participantsConnectedEvent: participants },
    type: ACTION_TYPES.Data.setParticipantsConnected
});

const removeParticipantConnectedEvent = (participantId: string) => ({
    payload: { participantId: participantId },
    type: ACTION_TYPES.Data.removeParticipantsConnected
});

const addParticipantConnectedEvent = (participant: IApp.IParticipantEvent) => ({
    payload: { participant: participant },
    type: ACTION_TYPES.Data.addParticipantsConnected
});

const removeParticipantReception = (participantId: string) => ({
    payload: { participantId: participantId },
    type: ACTION_TYPES.Data.removeParticipantReception
});

const addParticipantReception = (participant: IApp.IParticipantEvent) => ({
    payload: { participant: participant },
    type: ACTION_TYPES.Data.addParticipantReception
});

const initiateParticipantReception = (userId: string) => ({
    payload: { userId: userId },
    type: ACTION_TYPES.Data.initiateParticipantReception
});

const removeInitiateParticipantReception = (userId: string) => ({
    payload: { userId: userId },
    type: ACTION_TYPES.Data.removeInitiateParticipantReception
});

const setParticipantsReception = (participants: IApp.IParticipantEvent[]) => ({
    payload: { participantsReception: participants },
    type: ACTION_TYPES.Data.setParticipantsReception
});

const setMessage = (messageRoom: IApp.IMessageRoom) => ({
    payload: { messageRoom: messageRoom },
    type: ACTION_TYPES.Data.setMessage
});

const resetMessages = () => ({
    type: ACTION_TYPES.Data.resetMessages
});

const deleteMessage = (msgId: string) => ({
    payload: { msgId: msgId },
    type: ACTION_TYPES.Data.deleteMessage
});

const hideMessage = (msgId: string) => ({
    payload: { msgId: msgId },
    type: ACTION_TYPES.Data.hideMessage
});

const unHideMessage = (msgId: string) => ({
    payload: { msgId: msgId },
    type: ACTION_TYPES.Data.unHideMessage
});

const setNotes = (notes: INoteApi[]) => ({
    payload: { notes: notes },
    type: ACTION_TYPES.Data.setNotes
});

const delNote = (id: number, ctoken: string, notes: INoteApi[]) => (
    dispatch: any
) => {
    APINote.delNote(id, ctoken).then(() => {
        dispatch(setNotes(notes.filter(item => item.id != id)));
    });
};

const getNotes = (participantId: string, ctoken: string) => (dispatch: any) => {
    dispatch(LoadingActions.startLoading('getNotes'));
    APINote.getNotes(ctoken, participantId).then((r: any) => {
        dispatch(setNotes(r.data.data));
        dispatch(LoadingActions.stopLoading('getNotes'));
    });
};

// Mettre à jour les participants de l'event
const updateParticipantsEvent = (token: string, topic: string) => (
    dispatch: any
) => {
    APIMercure.getParticipantsEvent(token, topic).then(r => {
        // On construit notre tableau de participants
        const data = r.data;
        const tabDeja: string[] = [];
        const participants =
            data != null && data.subscriptions != null
                ? data.subscriptions.flatMap((item: any) => {
                      if (tabDeja.indexOf(item.payload.userId) === -1) {
                          tabDeja.push(item.payload.userId);
                          return [
                              {
                                  topic: item.payload.topic,
                                  userName: item.payload.userName,
                                  intervenant: item.payload.intervenant,
                                  userId: item.payload.userId
                              }
                          ];
                      } else return [];
                  })
                : [];

        if (participants.length != 0)
            dispatch(setParticipantsConnectedEvent(participants));
    });
};

// Mettre à jour les participants en attente de réception
const updateParticipantsReception = (token: string, topic: string) => (
    dispatch: any
) => {
    APIMercure.getParticipantsEvent(token, topic).then(r => {
        // On construit notre tableau de participants
        const data = r.data;
        const participants = data.subscriptions.map((item: any) => {
            return {
                topic: item.payload.topic,
                userName: item.payload.userName,
                intervenant: item.payload.intervenant,
                userId: item.payload.userId
            };
        });
        dispatch(setParticipantsReception(participants));
    });
};

// Ajoute une room temporaire, initie le call et invite les participants
const addTmpRoom = (
    token: string,
    eventRoomId: string,
    meetingToken: string,
    callObject: DailyCall | null,
    topics: string[],
    mercureTokenEvent: string,
    data: any
) => (dispatch: any) => {
    dispatch(LoadingActions.startLoading('addTmpRoom'));
    APIRoom.addTmpRoom(token, eventRoomId).then(r => {
        if (r.data && r.data.status) {
            if (data.receptionRoom)
                dispatch(CallActions.setReceptionRoom(true));
            dispatch(
                CallActions.joinRoom(
                    meetingToken,
                    r.data.roomId,
                    callObject,
                    data.receptionRoom
                )
            );
            dispatch(UIActions.setTmpRoomOwner(true));

            // Envoyer une invitation au user
            data.roomId = r.data.roomId;
            topics.forEach(topic => {
                APIMercure.sendNotifUsers(mercureTokenEvent, topic, data);
            });
        }
        dispatch(LoadingActions.stopLoading('addTmpRoom'));
    });
};

const closeEvent = (
    token: string,
    mercureTokenEvent: string,
    eventId: string
) => {
    APIEvents.closeEvent(token, eventId);
    const data = { type: 'close_event' };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + eventId,
        data
    );
};

const setNbParticipantsRoom = (roomId: string, nbParticipants: number) => {
    return {
        payload: {
            roomId: roomId,
            nbConnected: nbParticipants
        },
        type: ACTION_TYPES.Data.setNbParticipantsRoom
    };
};

const setNbParticipantsWaitingRoom = (
    roomId: string,
    nbParticipants: number
) => {
    return {
        payload: { roomId: roomId, nbWaiting: nbParticipants },
        type: ACTION_TYPES.Data.setNbParticipantsWaitingRoom
    };
};

const askForFiche = (
    notifiedUserId: string,
    mercureTokenEvent: string,
    currentUserId: string
) => () => {
    const data = {
        userId: currentUserId,
        type: 'ask_for_fiche'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_MERCURE + 'users/' + notifiedUserId,
        data
    );
};

const sendFiche = (
    userId: string | undefined,
    mercureTokenEvent: string,
    fiche: any
) => () => {
    const data = {
        userId: userId,
        type: 'send_fiche',
        fiche: fiche
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
        data
    );
};

export const DataActions = {
    setDataEvent: setDataEvent,
    updateParticipantsEvent: updateParticipantsEvent,
    addTmpRoom: addTmpRoom,
    setMercureTokenEvent: setMercureTokenEvent,
    closeEvent: closeEvent,
    setLastEventID: setLastEventID,
    setLastEventIdReception: setLastEventIdReception,
    setMessage: setMessage,
    deleteMessage: deleteMessage,
    hideMessage: hideMessage,
    unHideMessage: unHideMessage,
    setParticipantsConnectedEvent: setParticipantsConnectedEvent,
    setNotes: setNotes,
    delNote: delNote,
    getNotes: getNotes,
    removeParticipantConnectedEvent: removeParticipantConnectedEvent,
    addParticipantConnectedEvent: addParticipantConnectedEvent,
    resetMessages: resetMessages,
    removeParticipantReception: removeParticipantReception,
    addParticipantReception: addParticipantReception,
    setParticipantsReception: setParticipantsReception,
    updateParticipantsReception: updateParticipantsReception,
    setNbParticipantsRoom: setNbParticipantsRoom,
    askForFiche: askForFiche,
    sendFiche: sendFiche,
    setNbParticipantsWaitingRoom: setNbParticipantsWaitingRoom,
    initiateParticipantReception: initiateParticipantReception,
    removeInitiateParticipantReception: removeInitiateParticipantReception
};
