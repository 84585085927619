import React from 'react';
import { bp } from '@components/_struct/variables';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/_common/Button';
import UBSwitch from '@components/_common/_form/Switch/UBSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { CallActions } from '@logic/actions/call_actions';
import getConfig from 'next/config';
import { APIDaily } from '@api/dailyApi';
import { useMSTranslation } from '@utils/useMSTranslation';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Confirm from '@components/_common/Modal/Confirm';
import { useModal } from '@components/_common/Modal/Modal';
import { DataActions } from '@actions/data_actions';
import { UIActions } from '@logic/actions/ui_actions';

const { publicRuntimeConfig } = getConfig();

const EventInfosWrapper = styled.div`
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    @media (${bp.md}) {
        background: rgba(0, 0, 0, 1);
    }
    display: flex;
    flex-direction: column;
    .scrollable-area {
        flex: 1;
        min-height: 0;
        height: 100%;
        position: relative;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.25);
        }
    }
`;

const TitleEvent = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-top: -47px;
    padding: 12px 40px 12px 20px;
    pointer-events: none;
    @media (${bp.xl}) {
        margin-top: 0;
    }
`;

const TitleSection = styled.div`
    font-size: 13px;
    color: #3a90e1;
    text-transform: uppercase;
    padding: 16px 20px 10px;
    display: flex;
    align-items: center;
    user-select: none;
`;

const CoverPreview = styled.div`
    position: relative;
    div {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        background: rgba(10, 14, 39, 0.7);
        line-height: 1.5;
        font-size: 12px;
        padding: 4px 10px;
    }
`;

const BottomSection = styled.div`
    background: rgba(10, 14, 39, 0.9);
`;

const EventInfos = () => {
    const dispatch = useDispatch();
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const roomId = useSelector((state: IStore) => state.call.roomId);
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const { __ } = useMSTranslation(['event', 'struct']);
    const [visibleConfirm, closeConfirm, openConfirm] = useModal();

    const defaultMuted = useSelector(
        (state: IStore) => state.call.defaultMuted
    );

    const defaultNoCam = useSelector(
        (state: IStore) => state.call.defaultNoCam
    );

    const toggleDefaultMuted = () => {
        if (roomId !== null) {
            APIDaily.toggleDefaultMuted(roomId, !defaultMuted);
            dispatch(CallActions.toggleDefaultMuted());
        }
    };

    const toggleDefaultNoCam = () => {
        if (roomId !== null) {
            APIDaily.toggleDefaultNoCam(roomId, !defaultNoCam);
            dispatch(CallActions.toggleDefaultNoCam());
        }
    };

    const endEvent = () => {
        if (ctoken && infosEvent)
            DataActions.closeEvent(
                ctoken,
                mercureTokenEvent,
                infosEvent.roomId
            );
    };

    // Configuration de la room, de l'évènement
    let configRoom = null;
    let configEvent = null;
    // Si on est intervenant
    if (infosEvent && userSite && userSite.intervenant) {
        configEvent = (
            <div className='p-2'>
                <div>
                    <Button
                        variant='outline-white'
                        fullWidth
                        leftIcon='cog'
                        href={
                            publicRuntimeConfig.LIEN_SITE_ADMIN +
                            'events/edit/' +
                            infosEvent.id
                        }
                        isExternal
                    >
                        {__('Paramètres de l’évènemement', 'event')}
                    </Button>
                </div>
                <div className='mt-1'>
                    <Button
                        variant='red'
                        onClick={openConfirm}
                        fullWidth
                        disabled
                    >
                        {__("Mettre fin à l'évènement", 'event')}
                    </Button>
                    <Confirm
                        visible={visibleConfirm}
                        onClose={closeConfirm}
                        onSubmit={() => {
                            endEvent();
                            closeConfirm();
                        }}
                        title={__("Mettre fin à l'évènement ?", 'event')}
                        description={__(
                            "Confirmez-vous vouloir mettre fin à l'évènement ?",
                            'event'
                        )}
                    />
                </div>
            </div>
        );

        // Si en + d'être intervenant, on est dans une room
        if (roomId !== null)
            configRoom = (
                <div>
                    <TitleSection>
                        {__('Arrivé sur la room', 'event')}
                    </TitleSection>
                    <div className='px-2 py-1'>
                        <div className='mb-1'>
                            <UBSwitch
                                label={__('Désactiver le micro', 'event')}
                                checked={defaultMuted}
                                onChange={toggleDefaultMuted}
                                variant='onDarkBg'
                            />
                        </div>
                        <div>
                            <UBSwitch
                                label={__('Désactiver la caméra', 'event')}
                                checked={defaultNoCam}
                                onChange={toggleDefaultNoCam}
                                variant='onDarkBg'
                            />
                        </div>
                    </div>
                </div>
            );
    }

    let infosRoom = null;
    if (infosEvent && roomId) {
        infosRoom = infosEvent.rooms.filter(
            (value: IRoom) => value.roomId == roomId
        );
        if (infosRoom.length == 0)
            infosRoom = [{ nom: __('Room temporaire', 'event') }];
    }

    if (infosEvent)
        return (
            <EventInfosWrapper>
                <TitleEvent>
                    {infosRoom ? infosRoom[0].nom + ' - ' : ''}
                    {infosEvent ? infosEvent.title : ''}
                </TitleEvent>
                <SimpleBar className='scrollable-area'>
                    <div>
                        <TitleSection>
                            {__('Informations de l’évènement', 'event')}
                        </TitleSection>
                        <div className='px-2'>
                            <div className='mb-1 leading-normal'>
                                <span className='opacity-75 mr-1'>
                                    <FontAwesomeIcon
                                        icon={['far', 'clock']}
                                        className='mr-1'
                                    />
                                    {__('Heure', 'struct')}
                                </span>
                                <strong>
                                    {infosEvent && [
                                        format(infosEvent.dateStart, 'HH:mm'),
                                        ' - ',
                                        format(infosEvent.dateEnd, 'HH:mm')
                                    ]}
                                </strong>
                            </div>
                            <div className='mb-1 leading-normal'>
                                <span className='opacity-75 mr-1'>
                                    <FontAwesomeIcon
                                        icon={['far', 'calendar-alt']}
                                        className='mr-1'
                                    />
                                    {__('Date', 'struct')}
                                </span>
                                <strong>
                                    {infosEvent &&
                                        format(
                                            infosEvent.dateStart,
                                            'dd MMMM yyyy',
                                            { locale: fr }
                                        )}
                                </strong>
                            </div>
                            <div className='mb-1 leading-normal'>
                                <span className='opacity-75 mr-1'>
                                    <FontAwesomeIcon
                                        icon={['far', 'users']}
                                        className='mr-1'
                                    />
                                    {__('Participants', 'event')}
                                </span>
                                <strong>
                                    {infosEvent.maxParticipants}{' '}
                                    {__('places', 'event')}
                                </strong>
                            </div>
                            <div className='my-2'>
                                <CoverPreview>
                                    <img
                                        src={infosEvent.cover}
                                        alt=''
                                        className='w-full block'
                                    />
                                    <div className='opacity-75'>
                                        {__('Photo de couverture', 'event')}
                                    </div>
                                </CoverPreview>
                            </div>
                        </div>
                    </div>
                    <div>
                        <TitleSection>
                            {__('Description de l’évènement', 'event')}
                        </TitleSection>
                        <div className='px-2 leading-normal'>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        infosEvent.description
                                            .replace(/(<([^>]+)>)/gi, '')
                                            .substr(0, 200) +
                                        '... <a href="/' +
                                        infosEvent.roomId +
                                        '?desc" target="_blank">' +
                                        __('Afficher la suite', 'struct') +
                                        '</a>'
                                }}
                            />
                        </div>
                    </div>
                    {configRoom}
                    {configEvent}
                </SimpleBar>
                {roomId !== null && (
                    <BottomSection className='p-2'>
                        <Button
                            variant='normal'
                            fullWidth
                            onClick={() => dispatch(UIActions.toggleShowRoom())}
                        >
                            {__('Changer de room', 'event')}
                        </Button>
                    </BottomSection>
                )}
            </EventInfosWrapper>
        );
    else return <></>;
};

export default EventInfos;
