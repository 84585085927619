import styled from 'styled-components';
import { bp } from '@components/_struct/variables';
import { BadgeWrapper } from '@components/_common/Badge/Badge';

export const HeaderCont = styled.div`
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    > * {
        user-select: none;
    }
`;

export const RoomName = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    max-width: 330px;
    cursor: pointer;
    @media (${bp.md}) {
        max-width: none;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
    &.active {
        background: rgba(0, 0, 0, 0.7);
        width: 330px;
        @media (${bp.md}) {
            background: rgba(0, 0, 0, 1);
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            height: 50px;
            z-index: 50;
            &:hover {
                &::before {
                    opacity: 0.9;
                }
            }
        }
    }
    .chevron {
        color: #3a90e1;
        @media (${bp.md}) {
            display: none;
        }
    }
    &.active .chevron {
        @media (${bp.md}) {
            display: block;
        }
    }
`;

export const RoomNameTitle = styled.div`
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (${bp.xl}) {
        display: none;
    }
    .active & {
        @media (${bp.lg}) {
            display: block;
            margin-left: 10px;
        }
    }
`;

export const ToggleRooms = styled.div`
    position: relative;
    text-align: center;
    font-size: 18px;
    height: 100%;
    cursor: pointer;
    .centering {
        position: fixed;
        padding: 10px 20px;
        border-radius: 25px;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);
        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }
        @media (${bp.md}) {
            background: rgba(255, 255, 255, 0.066);
            position: relative;
            top: 0;
            left: 0;
            transform: inherit;
            border-radius: 0;
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    &.active .centering {
        background: rgba(255, 255, 255, 0.2);
    }
`;

export const HeadCenter = styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.04);
    font-size: 18px;
    svg {
        opacity: 0.5;
    }
    @media (${bp.md}) {
        justify-content: flex-start;
    }
`;

export const HeadReceptionRoomCont = styled.div`
    height: 100%;
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.04);
    padding: 0 20px;
`;

export const RoomActions = styled.div`
    height: 100%;
    max-width: 330px;
    display: flex;
    @media (${bp.md}) {
        width: auto;
    }
`;

export const ToggleBtn = styled.button`
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    margin: 0;
    border: 0 none;
    background: inherit;
    outline: none;
    height: 100%;
    color: #fff;
    padding: 0 30px;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    svg + span,
    span + svg {
        margin-left: 8px;
    }
    svg {
        font-size: 18px;
        animation-duration: 0.8s;
        max-height: 100%;
    }
    span {
        white-space: nowrap;
        font-weight: bold;
        font-size: 17px;
    }
    ${BadgeWrapper} {
        span {
            margin-left: 8px;
        }
        sup {
            transform: translate(95%, -76%);
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.1);
        opacity: 1;
        z-index: -1;
    }
    &:hover {
        &::before {
            background: rgba(255, 255, 255, 0.2);
        }
    }
    &.active {
        &::before {
            background: #000;
            opacity: 0.7;
            @media (${bp.md}) {
                opacity: 1;
            }
        }
        @media (${bp.md}) {
            width: 100%;
            position: fixed;
            left: 0;
            top: 0;
            height: 50px;
            z-index: 50;
            &:hover {
                &::before {
                    opacity: 0.9;
                }
            }
        }
    }
`;
