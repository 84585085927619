import React from 'react';
import { Formik, Form } from 'formik';
import FormLabel from '@components/_common/_form/FormLabel';
import Selectbox, {
    SelectboxItem
} from '@components/_common/_form/Selectbox/Selectbox';
import Button from '@components/_common/Button';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import buildFormItem from '@utils/buildFormItem';
import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
import { CallActions } from '@actions/call_actions';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

interface IRoomDispatcher {
    label?: string;
    labelBtnRedirect?: string;
    onBack: () => void;
    usersToDispatch: string | string[];
}

const RoomDispatcher = (props: IRoomDispatcher) => {
    const { onBack, label, labelBtnRedirect, usersToDispatch } = props;
    const rooms = useSelector((state: IStore) => state.Data.event?.rooms);
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const { __ } = useMSTranslation(['event', 'struct']);

    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const participantsReceptionRoom = useSelector(
        (state: IStore) => state.call.participants
    );
    const initialValues = {
        room: ''
    };

    const listRooms: SelectboxItem[] | undefined =
        rooms && rooms.map(room => buildFormItem(room.nom, room.roomId));

    // Ajout du hall en premier dans la liste des rooms
    listRooms && listRooms.unshift(buildFormItem('Hall', '__HALL__'));

    let participantsAfterDispatch = participantsReceptionRoom.slice();
    const dispatchUsers = (roomId: string) => {
        const data = {
            roomId: roomId,
            type: roomId == '__HALL__' ? 'redirection_hall' : 'redirection_room'
        };
        if (Array.isArray(usersToDispatch)) {
            usersToDispatch.forEach(userId => {
                APIMercure.sendNotifUsers(
                    mercureTokenEvent,
                    publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
                    data
                );
                participantsAfterDispatch = participantsAfterDispatch.filter(
                    item => item.userId != userId
                );
            });
            CallActions.setParticipants(participantsAfterDispatch);
        } else {
            APIMercure.sendNotifUsers(
                mercureTokenEvent,
                publicRuntimeConfig.LIEN_MERCURE + 'users/' + usersToDispatch,
                data
            );
            participantsAfterDispatch = participantsReceptionRoom.filter(
                item => item.userId != usersToDispatch
            );
            CallActions.setParticipants(participantsAfterDispatch);
        }
        if (participantsAfterDispatch.length == 1) {
            callObject.leave();
            participantsAfterDispatch = [];
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={values => dispatchUsers(values.room)}
        >
            {({ values }) => (
                <Form>
                    <div>
                        <FormLabel>
                            {label
                                ? label
                                : __('Sélectionnez une room', 'event')}
                        </FormLabel>
                        {listRooms && (
                            <Selectbox
                                name='room'
                                placeholder={__(
                                    'Sélectionnez une room',
                                    'event'
                                )}
                                items={listRooms}
                                noSearch
                            />
                        )}
                    </div>
                    <div className='flex justify-center mt-2'>
                        <div className='flex-1'>
                            <Button
                                variant='action'
                                type='submit'
                                fullWidth
                                disabled={values.room == ''}
                            >
                                {labelBtnRedirect
                                    ? labelBtnRedirect
                                    : __('Rediriger', 'event')}
                            </Button>
                        </div>
                        <div className='ml-1 flex-1'>
                            <Button
                                variant='outline-white'
                                onClick={onBack}
                                fullWidth
                            >
                                {__('Retour', 'struct')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RoomDispatcher;
