import React, {
    isValidElement,
    Children,
    useEffect,
    useState,
    cloneElement
} from 'react';
import BigTabPanel, { IBigTabPanel } from './BigTabPanel';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, bp } from '@components/_struct/variables';

// Style

const TabsWrapper = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const TabNav = styled.div`
    cursor: pointer;
    user-select: none;
    position: relative;
    color: ${colors.main};
    border-radius: 12px;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    border: 2px solid #e9eaf2;
    flex: 1;
    transition: all 0.15s ease-in-out;
    svg {
        font-size: 28px;
    }
    &:hover {
        border: 2px solid ${colors.main};
    }
    &.active {
        background: ${colors.main};
        border: 2px solid ${colors.main};
        color: #fff;
        &::after {
            background: #fff;
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            height: 5px;
            width: 100%;
        }
    }
    @media (${bp.md}) {
        font-size: 15px;
        display: flex;
        align-items: center;
        svg {
            font-size: 20px;
        }
    }
    & + & {
        margin-left: 20px;
        @media (${bp.md}) {
            margin: 0;
        }
    }
`;

const TabsPanelWrapper = styled.div`
    position: relative;
    flex: 1;
    min-height: 0;
    margin-top: 40px;
    @media (${bp.md}) {
        margin-top: 20px;
    }
`;

// Composant

interface ITabs {
    defaultActiveKey?: string;
    onChange?: (activeTab: string | undefined) => void;
}

interface ITabsPanel {
    Panel: React.FC<IBigTabPanel>;
}

const BigTabs: React.FC<ITabs> & ITabsPanel = props => {
    const { children, defaultActiveKey, onChange } = props;
    const [activeTab, setActiveTab] = useState(
        (defaultActiveKey && defaultActiveKey) || undefined
    );

    // Gestion du onChange
    useEffect(() => {
        onChange && onChange(activeTab);
    }, [activeTab, onChange]);

    useEffect(() => {
        !defaultActiveKey &&
            Children.map(children, (child, index) => {
                if (isValidElement(child)) {
                    if (child.type == BigTabPanel) {
                        if (index == 0) {
                            const key = child.key?.toString();
                            setActiveTab(key);
                        }
                    }
                }
            });
    }, [defaultActiveKey, children]);

    const parseTabList = () => {
        const listTab: JSX.Element[] = [];
        Children.map(children, child => {
            if (isValidElement(child)) {
                if (child.type == BigTabPanel) {
                    const childProps: IBigTabPanel = child.props;
                    const nameTab = childProps.tab;
                    const icon = childProps.icon;
                    const keyTab = child.key?.toString();
                    listTab.push(
                        <TabNav
                            key={keyTab}
                            onClick={() => setActiveTab(keyTab)}
                            className={keyTab == activeTab ? 'active' : ''}
                        >
                            <div className='text-center'>
                                <FontAwesomeIcon icon={icon} />
                            </div>
                            <div className='ml-2 md:mt-1 md:ml-0 md:text-center'>
                                {nameTab}
                            </div>
                        </TabNav>
                    );
                }
            }
        });
        return listTab;
    };

    const parseTabPanel = () => {
        const listTabPanel: JSX.Element[] = [];
        Children.map(children, child => {
            if (isValidElement(child)) {
                if (child.type == BigTabPanel) {
                    const key = child.key?.toString();
                    listTabPanel.push(
                        cloneElement(child, {
                            active: key == activeTab ? true : false
                        })
                    );
                }
            }
        });
        return listTabPanel;
    };

    return (
        <TabsWrapper>
            <div className='relative grid grid-cols-2 md:flex gap-1 md:gap-0'>
                {parseTabList()}
            </div>
            <TabsPanelWrapper>{parseTabPanel()}</TabsPanelWrapper>
        </TabsWrapper>
    );
};

BigTabs.Panel = BigTabPanel;

export default BigTabs;
