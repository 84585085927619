import React from 'react';
import Footer from '../Footer';
import styled from 'styled-components';
import Logo from '@components/_common/Logo';
import DropdownLangue from '@components/_common/DropdownLangue';
import Button from '@components/_common/Button';
import { useMSTranslation } from '@utils/useMSTranslation';
import getConfig from 'next/config';
import { Container } from '../Grid';
import { bp } from '@components/_struct/variables';
import { Default, Tablet } from '@components/_common/MediaQuery/MediaQuery';
const { publicRuntimeConfig } = getConfig();

const LogoCont = styled.div`
    width: 173px;
    @media (${bp.md}) {
        width: 70px;
    }
`;

const FooterCont = styled.div`
    background: #0a0e27;
    color: #fff;
    @media (${bp.md}) {
        margin-bottom: 102px;
    }
`;

const HomeLayout: React.FC = props => {
    const { children } = props;
    const { __ } = useMSTranslation(['home', 'struct']);

    const redirectToAdmin = () => {
        window.location.href = publicRuntimeConfig.LIEN_SITE_ADMIN;
    };

    return (
        <div>
            <div className='bg-darkblue px-1'>
                <Container>
                    <div className='flex items-center py-2'>
                        <LogoCont>
                            <Logo variant='white' />
                        </LogoCont>
                        <div className='ml-auto flex'>
                            <div>
                                <DropdownLangue variant='white' />
                            </div>
                            <div className='ml-1'>
                                <Default>
                                    <Button
                                        onClick={() => redirectToAdmin()}
                                        variant='outline-white'
                                    >
                                        {__('Connexion', 'struct')}
                                    </Button>
                                </Default>
                                <Tablet>
                                    <Button
                                        onClick={() => redirectToAdmin()}
                                        variant='outline-white'
                                        size='small'
                                    >
                                        {__('Connexion', 'struct')}
                                    </Button>
                                </Tablet>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div>{children}</div>
            <FooterCont>
                <Footer />
            </FooterCont>
        </div>
    );
};

export default HomeLayout;
