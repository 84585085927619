import styled from 'styled-components';
import { bp } from '@components/_struct/variables';

export const EventWrapperLayout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
`;

export const EventWrapperContentLayout = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    min-height: 0;
    color: #fff;
`;

export const RoomMain = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
    padding-bottom: 70px;
    flex-direction: column;
    justify-content: center;
    @media (${bp.md}) {
        padding-bottom: 53px;
    }
`;

export const EventDarkOverlay = styled.div`
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    flex-direction: column;
    > * {
        margin: auto;
    }
    .scrollable-area {
        height: 100%;
        margin: 0;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.5);
        }
        .simplebar-content-wrapper {
            display: flex;
            > * {
                margin: auto;
                width: 100%;
            }
        }
    }
`;

export const EventDarkOverlayCloseBtn = styled.button`
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    border: 0 none;
    color: #fff;
    font-size: 18px;
    padding: 7px;
    width: 32px;
    height: 32px;
    margin: 0;
    outline: 0;
    border-radius: 24px;
    cursor: pointer;
    :hover {
        box-shadow: 0 0 2px 0 #fff;
    }
`;

export const EventSideBar = styled.div`
    position: relative;
    width: 330px;
    z-index: 101;
    @media (${bp.md}) {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 101;
    }
`;

export const EventOverlay = styled.div`
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: linear-gradient(180deg, #0c1a8a 0%, #263acd 100%);
    z-index: -1;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #0c0c0c;
        opacity: 0.6;
    }
`;

export const RoomCamGrid = styled.div`
    position: relative;
    padding: 10px;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    z-index: 20;
    &.grid-1 {
        width: 60%;
        height: 65%;
        > * {
            margin: 0 auto;
        }
        @media (${bp.lg}) {
            width: 100%;
            height: 100%;
            > * {
                width: auto;
            }
        }
    }
    &.grid-2 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        height: 65%;
        @media (${bp.lg}) {
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            height: 100%;
        }
    }
    &.grid-3 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        height: 100%;
        @media (${bp.md}) {
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
        }
        > * {
            grid-column: span 2;
            &:last-child:nth-child(4n - 1) {
                grid-column-end: 4;
                @media (${bp.md}) {
                    grid-column-end: inherit;
                }
            }
        }
    }
    &.grid-4 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        height: 100%;
        @media (${bp.sm}) {
            width: 100%;
        }
        > * {
            grid-column: span 2;
        }
    }
    &.grid-5 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(6, 1fr);
        width: 100%;
        height: 100%;
        @media (${bp.md}) {
            grid-template-columns: repeat(4, 1fr);
        }
        > * {
            grid-column: span 2;
            &:last-child:nth-child(3n - 1) {
                grid-column-end: -2;
                @media (${bp.md}) {
                    grid-column-end: inherit;
                }
            }
            &:nth-last-child(2):nth-child(3n + 1) {
                grid-column-end: 4;
                @media (${bp.md}) {
                    grid-column-end: inherit;
                }
            }
            &:last-child:nth-child(3n - 2) {
                grid-column-end: 5;
                @media (${bp.md}) {
                    grid-column-end: inherit;
                }
            }
        }
    }
    &.grid-split {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 83% 15%;
        height: 100%;
        width: 100%;
        @media (${bp.sm}) {
            grid-template-rows: 80% 18%;
            width: 100%;
        }
        &.landscape {
            @media (max-height: 640px) and (${bp.lg}) {
                grid-template-columns: 2.5fr 0.5fr;
                grid-template-rows: 100%;
            }
            @media (${bp.md}) {
                grid-template-columns: 2fr 1fr;
                grid-template-rows: 100%;
            }
            .others {
                @media (${bp.md}) {
                    flex-direction: column;
                }
                @media (max-height: 640px) and (${bp.lg}) {
                    flex-direction: column;
                }
            }
        }
        > .pinned {
            display: grid;
            grid-gap: 10px;
            align-items: center;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
            grid-template-rows: 1fr;
            justify-content: center;
            height: 100%;
        }
        > .others {
            display: flex;
            grid-gap: 10px;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
`;
