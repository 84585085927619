import React, { useState, useEffect } from 'react';
import {
    ListUsersWrapper,
    ListUsers,
    ListUsersTitle,
    ListCloseButton
} from './RoomListUsers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { IApp } from '@interfaces/pages/_app';
import SimpleBar from 'simplebar-react';
import { useDispatch } from 'react-redux';
import { UIActions } from '@actions/ui_actions';
import DropdownContext from '@components/_common/DropdownContext';
import { DropdownContextItem } from '@components/_common/DropdownContext/DropdownContext.css';
import RoomUserItem from './RoomUserItem';
import RoomWaitingUser from './RoomWaitingUser';
import UBCheckbox from '@components/_common/_form/Checkbox/UBCheckbox';
import { useMSTranslation } from '@utils/useMSTranslation';
import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
import UBTextField from '@components/_common/_form/TextField/UBTextField';
const { publicRuntimeConfig } = getConfig();

const RoomListUsers = () => {
    const dispatch = useDispatch();
    const { __ } = useMSTranslation(['event']);

    const participants = useSelector(
        (state: IStore) => state.call.participants
    );

    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );
    const waitingParticipantsRoom = useSelector(
        (state: IStore) => state.call.waitingParticipantsRoom
    );
    const waitingParticipantsRoomIds = waitingParticipantsRoom.map(
        participant => {
            return participant['userId'];
        }
    );
    const selectedParticipants = useSelector(
        (state: IStore) => state.UI.selectedParticipants
    );
    const selectedWaitingParticipants = useSelector(
        (state: IStore) => state.UI.selectedWaitingParticipants
    );
    const selectedIntervenants = useSelector(
        (state: IStore) => state.UI.selectedIntervenants
    );
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const roomId = useSelector((state: IStore) => state.call.roomId);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const [search, setSearch] = useState('');
    const [filteredParticipantsEvent, setFilteredParticipantsEvent] = useState<
        IApp.IParticipantEvent[]
    >([]);
    const [filteredParticipants, setFilteredParticipants] = useState<
        IApp.IParticipant[]
    >([]);

    const activeSpeakerId = useSelector(
        (state: IStore) => state.call.activeSpeakerId
    );

    const { __s } = useMSTranslation([]);
    const [allWaitingSelected, setAllWaitingSelected] = useState(false);

    let nbParticipants = participantsConnectedEvent.length;
    if (roomId) nbParticipants = participants.length;

    const onCheckParticipants = (id: string) => {
        if (!selectedParticipants.includes(id)) {
            dispatch(
                UIActions.selectedParticipants([...selectedParticipants, id])
            );
        } else {
            const newSelectedParticipant = selectedParticipants.filter(
                item => id != item
            );
            dispatch(UIActions.selectedParticipants(newSelectedParticipant));
        }
    };

    const onCheckIntervenants = (id: string) => {
        if (!selectedIntervenants.includes(id)) {
            dispatch(
                UIActions.selectedIntervenants([...selectedIntervenants, id])
            );
        } else {
            const newSelectedParticipant = selectedIntervenants.filter(
                item => id != item
            );
            dispatch(UIActions.selectedIntervenants(newSelectedParticipant));
        }
    };

    const onCheckWaitingParticipants = (id: string) => {
        if (!selectedWaitingParticipants.includes(id)) {
            dispatch(
                UIActions.selectedWaitingParticipants([
                    ...selectedWaitingParticipants,
                    id
                ])
            );
        } else {
            const newSelectedParticipant = selectedWaitingParticipants.filter(
                item => id != item
            );
            dispatch(
                UIActions.selectedWaitingParticipants(newSelectedParticipant)
            );
        }
    };

    let infosRoom = null;
    if (infosEvent && roomId) {
        infosRoom = infosEvent.rooms.filter(
            (value: IRoom) => value.roomId == roomId
        );
    }

    const isTmpRoom = infosRoom && roomId ? infosRoom.length == 0 : false;

    // ------------------------------
    // Filtrage des suggestions
    // ------------------------------
    useEffect(() => {
        setFilteredParticipantsEvent(
            participantsConnectedEvent.filter(item => {
                if (
                    item.userName.toLowerCase().includes(search.toLowerCase())
                ) {
                    return true;
                }
            })
        );
    }, [participantsConnectedEvent, search]);

    useEffect(() => {
        setFilteredParticipants(
            participants.filter(item => {
                if (
                    item.userName.toLowerCase().includes(search.toLowerCase())
                ) {
                    return true;
                }
            })
        );
    }, [participants, search]);

    // Si on est dans une room on en affiche les participants
    const intervenantsList: JSX.Element[] = [];
    const participantsList: JSX.Element[] = [];
    const eventIntervenants = infosEvent?.intervenants;
    if (roomId) {
        filteredParticipants.forEach((item: IApp.IParticipant) => {
            if (item.owner) {
                const user = eventIntervenants?.filter(
                    user => user.id.toString() == item.userId
                );

                const tmpPart = (
                    <RoomUserItem
                        key={item.userId}
                        userName={item.userName}
                        microphone={item.audio}
                        webcam={item.video}
                        pinned={item.pinned}
                        userId={item.userId}
                        intervenant={
                            item.intervenant ? item.intervenant : false
                        }
                        topic={item.topic}
                        activeSpeaker={
                            activeSpeakerId !== null &&
                            activeSpeakerId === item.userId
                        }
                        societeNom={
                            user && user.length > 0
                                ? user[0].societeNom
                                : undefined
                        }
                        societeLogo={
                            user && user.length > 0
                                ? user[0].societeLogo
                                : undefined
                        }
                        fonction={
                            user && user.length > 0
                                ? user[0].fonction
                                : undefined
                        }
                    />
                );
                intervenantsList.push(tmpPart);
            } else {
                const tmpPart = (
                    <RoomUserItem
                        key={item.userId}
                        userName={item.userName}
                        microphone={item.audio}
                        webcam={item.video}
                        pinned={item.pinned}
                        userId={item.userId}
                        intervenant={
                            item.intervenant ? item.intervenant : false
                        }
                        topic={item.topic}
                        activeSpeaker={
                            activeSpeakerId !== null &&
                            activeSpeakerId === item.userId
                        }
                    />
                );
                participantsList.push(tmpPart);
            }
        });
    }
    // Sinon si on est pas dans une room, on affiche les participants de l'event
    else {
        filteredParticipantsEvent.forEach((item: IApp.IParticipantEvent) => {
            let checked;
            let onCheck;
            if (item.intervenant) {
                checked = selectedIntervenants?.includes(item.userId);
                onCheck = onCheckIntervenants;
                const user = eventIntervenants?.filter(
                    user => user.id.toString() == item.userId
                );
                const tmpPart = (
                    <RoomUserItem
                        key={item.userId}
                        userName={item.userName}
                        userId={item.userId}
                        topic={item.topic}
                        intervenant={item.intervenant}
                        onCheck={onCheck}
                        checked={checked}
                        checkable={true}
                        noPin
                        societeNom={
                            user && user.length > 0
                                ? user[0].societeNom
                                : undefined
                        }
                        societeLogo={
                            user && user.length > 0
                                ? user[0].societeLogo
                                : undefined
                        }
                        fonction={
                            user && user.length > 0
                                ? user[0].fonction
                                : undefined
                        }
                    />
                );
                intervenantsList.push(tmpPart);
            } else {
                checked = selectedParticipants?.includes(item.userId);
                onCheck = onCheckParticipants;
                const tmpPart = (
                    <RoomUserItem
                        key={item.userId}
                        userName={item.userName}
                        userId={item.userId}
                        topic={item.topic}
                        intervenant={item.intervenant}
                        onCheck={onCheck}
                        checked={checked}
                        checkable={true}
                        noPin
                    />
                );
                participantsList.push(tmpPart);
            }
        });
    }

    if (!infosEvent || !userSite) return <></>;

    const roomActu = infosEvent.rooms.filter(room => room.roomId == roomId)[0];
    const selectAllWaiting = () => {
        if (allWaitingSelected) {
            setAllWaitingSelected(false);
            dispatch(UIActions.selectedWaitingParticipants([]));
        } else {
            dispatch(
                UIActions.selectedWaitingParticipants(
                    waitingParticipantsRoomIds
                )
            );
            setAllWaitingSelected(true);
        }
    };

    const refuseJoinRoomSelected = () => {
        // Envoyer une notification au user pour le kick
        const data = {
            roomId: roomId,
            type: 'refuse_join_room',
            adminName: userSite ? userSite.userName : ''
        };
        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            selectedWaitingParticipants.map(id => {
                return publicRuntimeConfig.LIEN_MERCURE + 'users/' + id;
            }),
            data
        );
    };

    const acceptJoinRoom = () => {
        // Envoyer une notification au user pour rejoindre la room
        const data = {
            roomId: roomId,
            type: 'accept_join_room',
            adminName: userSite ? userSite.userName : ''
        };
        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            selectedWaitingParticipants.map(id => {
                return publicRuntimeConfig.LIEN_MERCURE + 'users/' + id;
            }),
            data
        );
    };

    return (
        <ListUsersWrapper>
            <div className='p-2 pb-1 select-none'>
                <div>
                    {__s(
                        nbParticipants,
                        '1 Participant',
                        '{{count}} Participants',
                        'event'
                    )}
                </div>
                {roomId && roomActu && (
                    <div className='uppercase opacity-50 text-sm leading-normal'>
                        {roomActu.sizeRoom - nbParticipants}{' '}
                        {__('PLACES RESTANTES', 'event')}
                        {userSite &&
                            userSite.intervenant &&
                            roomActu.type == 'PRIVATE' &&
                            ' - ' +
                                waitingParticipantsRoom.length +
                                ' ' +
                                __('en liste d’attente', 'event')}
                    </div>
                )}
            </div>
            <ListCloseButton
                onClick={() => dispatch(UIActions.toggleShowListUsers())}
            >
                <FontAwesomeIcon icon={['far', 'times']} />
            </ListCloseButton>
            <div className='my-1 px-2'>
                <UBTextField
                    variant='rounded-outline-white'
                    suffixElm={<FontAwesomeIcon icon={['far', 'search']} />}
                    placeholder={__('Rechercher un utilisateur', 'event')}
                    onChange={val => setSearch(val)}
                />
            </div>
            <SimpleBar className='scrollable-area'>
                {intervenantsList.length > 0 && !isTmpRoom && (
                    <ListUsersTitle>
                        <div className='flex-1'>
                            {__('Intervenants', 'event')}
                        </div>
                        {selectedIntervenants.length > 1 && (
                            <div className='ml-1'>
                                <DropdownContext
                                    placement='bottom-end'
                                    sizeBtn='small'
                                >
                                    <DropdownContext.Item>
                                        {__(
                                            'Inviter dans une salle privée',
                                            'event'
                                        )}
                                    </DropdownContext.Item>
                                </DropdownContext>
                            </div>
                        )}
                    </ListUsersTitle>
                )}

                <ListUsers>{intervenantsList}</ListUsers>
                {participantsList.length > 0 && (
                    <ListUsersTitle>
                        <div className='flex-1'>
                            {__('Participants', 'event')}
                        </div>
                    </ListUsersTitle>
                )}

                <ListUsers>{participantsList}</ListUsers>

                {roomId &&
                    userSite.intervenant &&
                    waitingParticipantsRoom.length > 0 && (
                        <ListUsersTitle>
                            <UBCheckbox
                                variant='white'
                                checked={allWaitingSelected}
                                onChange={() => selectAllWaiting()}
                            />{' '}
                            <div className='flex-1'>
                                {__("Liste d'attente", 'event')}
                            </div>
                            <div
                                className='ml-1'
                                style={
                                    selectedWaitingParticipants.length > 0
                                        ? { visibility: 'visible' }
                                        : { visibility: 'hidden' }
                                }
                            >
                                <DropdownContext
                                    placement='bottom-end'
                                    sizeBtn='small'
                                >
                                    <DropdownContextItem
                                        onClick={() => refuseJoinRoomSelected()}
                                    >
                                        {__(
                                            "Retirer de la liste d'attente",
                                            'event'
                                        )}
                                    </DropdownContextItem>
                                    <DropdownContextItem
                                        onClick={() => acceptJoinRoom()}
                                    >
                                        {__('Accepter dans la room', 'event')}
                                    </DropdownContextItem>
                                </DropdownContext>
                            </div>
                        </ListUsersTitle>
                    )}

                <ListUsers>
                    {
                        // Les participants en liste d'attente

                        waitingParticipantsRoom.length > 0 ? (
                            waitingParticipantsRoom.map((item: any) => {
                                const checked = selectedWaitingParticipants?.includes(
                                    item.userId
                                );
                                return (
                                    <RoomWaitingUser
                                        key={item.userId}
                                        userName={item.userName}
                                        userId={item.userId}
                                        onCheck={onCheckWaitingParticipants}
                                        checked={checked}
                                    />
                                );
                            })
                        ) : (
                            <></>
                        )
                    }
                </ListUsers>
            </SimpleBar>
        </ListUsersWrapper>
    );
};

export default RoomListUsers;
