import React from 'react';
import {
    WaitingListDispatchItemWrapper,
    DispatchItemAvatarCont,
    DispatchItemName
} from './WaitingListDispatch.css';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import UBCheckbox from '@components/_common/_form/Checkbox/UBCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { UIActions } from '@actions/ui_actions';
import Button from '@components/_common/Button';
import { DataActions } from '@actions/data_actions';
import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

interface IWaitingListDispatchItem {
    userName: string;
    userId: string;
    placement?: number;
    topic: string;
    closePopOver?: () => void;
}

const WaitingListDispatchItem = (props: IWaitingListDispatchItem) => {
    const { userName, userId, topic, closePopOver } = props;
    const dispatch = useDispatch();
    const selectedWaitingDispatch = useSelector(
        (state: IStore) => state.UI.selectedWaitingDispatch
    );
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const { __ } = useMSTranslation(['event']);

    const onCheckParticipants = (id: string) => {
        if (!selectedWaitingDispatch.includes(id)) {
            dispatch(
                UIActions.selectedWaitingDispatch([
                    ...selectedWaitingDispatch,
                    id
                ])
            );
        } else {
            const newSelectedParticipant = selectedWaitingDispatch.filter(
                item => id != item
            );
            dispatch(UIActions.selectedWaitingDispatch(newSelectedParticipant));
        }
    };

    const checked = selectedWaitingDispatch?.includes(userId);

    const inviteToRoom = () => {
        if (!infosEvent || !ctoken || !meetingToken) return;

        // On prévient les intervenants connectés que cet utilisateur va etre pris en charge
        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            publicRuntimeConfig.LIEN_SITE + infosEvent.roomId + '-inter',
            { type: 'invitation_room_initiated', userId: userId }
        );

        // On initie la room temporaire pr l'accueil
        dispatch(
            DataActions.addTmpRoom(
                ctoken,
                infosEvent.roomId,
                meetingToken,
                callObject,
                [topic],
                mercureTokenEvent,
                {
                    type: 'invitation_room',
                    nomInvitation: userSite?.userName,
                    topicRetour:
                        publicRuntimeConfig.LIEN_MERCURE +
                        'users/' +
                        userSite.userId,
                    receptionRoom: true
                }
            )
        );
        closePopOver && closePopOver();
    };

    return (
        <WaitingListDispatchItemWrapper>
            <UBCheckbox
                variant='white'
                checked={checked}
                onChange={() => onCheckParticipants(userId)}
            />
            <DispatchItemAvatarCont onClick={() => onCheckParticipants(userId)}>
                <UserAvatar name={`${userName}`} size='30' />
            </DispatchItemAvatarCont>
            <DispatchItemName onClick={() => onCheckParticipants(userId)}>
                {userName}
            </DispatchItemName>
            {selectedWaitingDispatch.length == 0 && (
                <div>
                    <Button variant='action' onClick={inviteToRoom}>
                        {__('Accueillir', 'event')}
                    </Button>
                </div>
            )}
        </WaitingListDispatchItemWrapper>
    );
};

export default WaitingListDispatchItem;
