import React, { useEffect, useRef, useState, useCallback } from 'react';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/_common/Button';
import { CallActions } from '@actions/call_actions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import DropdownContext from '@components/_common/DropdownContext';
import {
    CamWrapper,
    CamOverlayCam,
    CamAvatarNameWrapper,
    CamConnecting,
    CamNameTag,
    CamUserState,
    CamOverlaySharingScreen,
    CamBlockSharingScreen,
    CamPictoSharingScreen,
    CamSharingScreenText,
    CamTopRightButtonCont,
    CamContextButtonCont,
    CamWrapperInterviewButtons,
    CamOverlayRedirecting,
    CamTopLeft
} from './Cam.css';
import RoomDispatcher from '../RoomDispatcher';
import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
import { IApp } from '@interfaces/pages/_app';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

export interface ICamProps {
    videoTrack: MediaStreamTrack | null;
    isLoading: boolean;
    userName: string;
    activeSpeaker?: boolean;
    audioTrack: MediaStreamTrack | null;
    local: boolean;
    userId: string;
    pinned: boolean;
    sharingScreen?: boolean;
    isAdmin?: boolean;
    interview?: boolean;
    fonction?: string;
    societeLogo?: string;
}

const Cam = (props: ICamProps) => {
    const {
        videoTrack,
        isLoading,
        userName,
        userId,
        audioTrack,
        activeSpeaker,
        sharingScreen,
        pinned,
        local,
        isAdmin,
        interview,
        fonction,
        societeLogo
    } = props;
    const { __ } = useMSTranslation(['event', 'struct']);

    const videoEl = useRef<HTMLVideoElement>(null);
    const dispatch = useDispatch();
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const networkQuality = useSelector(
        (state: IStore) => state.call.networkQuality
    );
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const participants = useSelector(
        (state: IStore) => state.call.participants
    );

    const camRef = useRef<HTMLDivElement>(null);
    const [fullscreen, setFullscreen] = useState(false);
    const [contextOpen, setContextOpen] = useState(false);
    const [redirecting, setRedirecting] = useState(false);

    const mode = participants.length - 1 > 1 ? 'Multi' : 'Solo';

    let participantsAfterDispatch: IApp.IParticipant[];
    const disconnectUser = (userId: string) => {
        // Envoyer une notification au user pour le déconnecter
        const data = {
            type: 'disconnect_event'
        };
        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
            data
        );
        participantsAfterDispatch = participants.filter(
            item => item.userId != userId
        );
        if (participantsAfterDispatch.length == 1) {
            callObject.leave();
            participantsAfterDispatch = [];
        }
    };

    const toggleContextMenu = useCallback(() => {
        setContextOpen(x => !x);
    }, []);

    const toggleFullscreen = () => {
        if (camRef.current == null) return;
        if (fullscreen) {
            document.exitFullscreen().then(() => {
                setFullscreen(false);
            });
        } else {
            camRef.current
                .requestFullscreen()
                .then(() => {
                    setFullscreen(true);
                })
                .catch(() => {
                    setFullscreen(false);
                });
        }
    };

    useEffect(() => {
        if (videoEl && videoEl.current && videoTrack) {
            videoEl.current.srcObject = new MediaStream([videoTrack]);
        }
    }, [videoTrack, isLoading]);

    useEffect(() => {
        document.onfullscreenchange = () =>
            setFullscreen(document.fullscreenElement != null);
    });

    const isSpeakingClassName = activeSpeaker ? 'is-speaking' : '';
    const variantButton = pinned ? 'normal-white' : 'outline-white';

    const fullscreenClassName = fullscreen ? 'fullscreenEnabled' : '';
    const contextOpenClassName = contextOpen ? 'contextMenuOpened' : '';

    return isLoading ? (
        <CamWrapper className={`${fullscreenClassName}`}>
            <CamOverlayCam>
                <CamAvatarNameWrapper>
                    <UserAvatar name={userName} size='64' />
                    <CamConnecting>
                        {__('Chargement', 'struct')}...
                    </CamConnecting>
                </CamAvatarNameWrapper>
            </CamOverlayCam>
            <CamNameTag isAdmin={isAdmin}>
                <CamUserState className='signin' isAdmin={isAdmin}>
                    <FontAwesomeIcon icon={['far', 'sign-in']} />
                </CamUserState>
                <span className='truncate opacity-50'>{userName}</span>
            </CamNameTag>
        </CamWrapper>
    ) : (
        <CamWrapper
            className={`${isSpeakingClassName} ${fullscreenClassName} ${contextOpenClassName}`}
            ref={camRef}
        >
            <CamOverlayCam>
                <CamAvatarNameWrapper>
                    {!videoTrack && <UserAvatar name={userName} size='64' />}
                    {networkQuality == 'very-low' && local && (
                        <CamConnecting>
                            {__('Bande passante trop faible', 'event')}
                        </CamConnecting>
                    )}
                </CamAvatarNameWrapper>
            </CamOverlayCam>
            {videoTrack && (
                <video
                    width={'100%'}
                    autoPlay
                    muted
                    playsInline
                    ref={videoEl}
                />
            )}
            {sharingScreen && (
                <CamOverlaySharingScreen>
                    <CamBlockSharingScreen>
                        <CamPictoSharingScreen>
                            <FontAwesomeIcon icon={['far', 'desktop']} />
                        </CamPictoSharingScreen>
                        <CamSharingScreenText>
                            {__(
                                'Ecran partagé avec tous les participants',
                                'event'
                            )}
                        </CamSharingScreenText>
                        <Button
                            onClick={() => callObject.stopScreenShare()}
                            variant='action'
                        >
                            {__('Arrêter le partage', 'event')}
                        </Button>
                    </CamBlockSharingScreen>
                </CamOverlaySharingScreen>
            )}
            <CamNameTag isAdmin={isAdmin}>
                {!videoTrack && (
                    <CamUserState isAdmin={isAdmin}>
                        <FontAwesomeIcon icon={['far', 'webcam-slash']} />
                    </CamUserState>
                )}
                {!audioTrack && (
                    <CamUserState isAdmin={isAdmin}>
                        <FontAwesomeIcon icon={['far', 'microphone-slash']} />
                    </CamUserState>
                )}
                <span className='truncate'>
                    {userName} {isAdmin && fonction && `- ${fonction}`}
                </span>
            </CamNameTag>
            {!fullscreen && (
                <CamTopRightButtonCont>
                    <Button
                        onClick={() =>
                            dispatch(CallActions.pinParticipant(userId))
                        }
                        variant={variantButton}
                        onlyIcon
                        leftIcon='thumbtack'
                    />
                </CamTopRightButtonCont>
            )}
            {fullscreen && (
                <CamTopRightButtonCont>
                    <Button
                        onClick={toggleFullscreen}
                        variant='clear-white'
                        onlyIcon
                        leftIcon='times'
                    />
                </CamTopRightButtonCont>
            )}
            <CamTopLeft>
                {videoTrack && isAdmin && (
                    <div className='mr-1'>
                        <UserAvatar src={societeLogo} name={userName} />
                    </div>
                )}
                {document.fullscreenEnabled && (
                    <CamContextButtonCont>
                        <DropdownContext
                            context={userName}
                            triggerVariant='clear-white'
                            onOpen={toggleContextMenu}
                            onClose={toggleContextMenu}
                            placement='bottom-start'
                        >
                            <DropdownContext.Item onClick={toggleFullscreen}>
                                {__('Mettre en plein écran', 'event')}
                            </DropdownContext.Item>
                        </DropdownContext>
                    </CamContextButtonCont>
                )}
            </CamTopLeft>
            {interview && !redirecting && (
                <CamWrapperInterviewButtons>
                    <div className='mx-1'>
                        <Button
                            variant='action'
                            leftIcon='thumbs-up'
                            onClick={() => setRedirecting(true)}
                        >
                            {mode == 'Multi' ? '' : 'Accepter'}
                        </Button>
                    </div>
                    <div className='mx-1'>
                        <Button
                            variant='red'
                            leftIcon='thumbs-down'
                            onClick={() => disconnectUser(userId)}
                        >
                            {mode == 'Multi' ? '' : 'Refuser'}
                        </Button>
                    </div>
                </CamWrapperInterviewButtons>
            )}
            {interview && redirecting && (
                <CamOverlayRedirecting>
                    <div className='w-2/3'>
                        <RoomDispatcher
                            onBack={() => setRedirecting(false)}
                            usersToDispatch={userId}
                        />
                    </div>
                </CamOverlayRedirecting>
            )}
        </CamWrapper>
    );
};

export default React.memo(Cam);
