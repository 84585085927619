import React, {
    Children,
    isValidElement,
    cloneElement,
    useState,
    useEffect
} from 'react';
import { TabPanel } from '.';
import { ITabPanel } from './TabPanel';
import {
    TabNavList,
    TabNav,
    TabNotif,
    TabsWrapper,
    TabsPanelWrapper
} from './Tabs.css';

interface ITabs {
    defaultActiveKey?: string;
    variant?: 'basic' | 'alt';
    fullHeight?: boolean;
    onChange?: (activeTab: string | undefined) => void;
    textColorInactive?: string;
}

const Tabs: React.FC<ITabs> = React.memo(props => {
    const {
        children,
        defaultActiveKey,
        variant,
        fullHeight,
        onChange,
        textColorInactive
    } = props;
    const [activeTab, setActiveTab] = useState(
        (defaultActiveKey && defaultActiveKey) || undefined
    );

    Tabs.displayName = 'Tabs';

    // Gestion du onChange
    useEffect(() => {
        onChange && onChange(activeTab);
    }, [activeTab, onChange]);

    useEffect(() => {
        !defaultActiveKey &&
            Children.map(children, (child, index) => {
                if (isValidElement(child)) {
                    if (child.type == TabPanel) {
                        if (index == 0) {
                            const key = child.key?.toString();
                            setActiveTab(key);
                        }
                    }
                }
            });
    }, [defaultActiveKey, children]);

    const parseTabList = () => {
        const listTab: JSX.Element[] = [];
        Children.map(children, child => {
            if (isValidElement(child)) {
                if (child.type == TabPanel) {
                    const childProps: ITabPanel = child.props;
                    const nameTab = childProps.tab;
                    const notif = childProps.notif;
                    const keyTab = child.key?.toString();
                    listTab.push(
                        <TabNav
                            key={keyTab}
                            onClick={() => setActiveTab(keyTab)}
                            className={keyTab == activeTab ? 'active' : ''}
                            textColorInactive={textColorInactive}
                        >
                            {nameTab}{' '}
                            {notif != undefined && notif != 0 && (
                                <TabNotif>{notif}</TabNotif>
                            )}
                        </TabNav>
                    );
                }
            }
        });
        return listTab;
    };

    const parseTabPanel = () => {
        const listTabPanel: JSX.Element[] = [];
        Children.map(children, child => {
            if (isValidElement(child)) {
                if (child.type == TabPanel) {
                    const key = child.key?.toString();
                    listTabPanel.push(
                        cloneElement(child, {
                            style: key != activeTab ? { display: 'none' } : {}
                        })
                    );
                }
            }
        });
        return listTabPanel;
    };

    const variantClassName =
        variant == 'basic'
            ? 'tabs-basic'
            : variant == 'alt'
            ? 'tabs-alt'
            : 'tabs-basic';

    const fullHeightClassName = fullHeight ? 'h-full' : '';

    return (
        <TabsWrapper className={`${variantClassName} ${fullHeightClassName}`}>
            <TabNavList>{parseTabList()}</TabNavList>
            <TabsPanelWrapper>{parseTabPanel()}</TabsPanelWrapper>
        </TabsWrapper>
    );
});

export default Tabs;
