import React, { useCallback } from 'react';
import {
    SwitchCont,
    SwitchToggleCont,
    SwitchToggleBar,
    SwitchToggleButton,
    SwitchLabel
} from './Switch.css';

// Last update 15/07/2020

interface IUBSwitch {
    checked?: boolean;
    small?: boolean;
    disabled?: boolean;
    name?: string;
    label?: string;
    onChange?: () => void;
    variant?: 'onDarkBg';
}

const UBSwitch = (props: IUBSwitch) => {
    const { checked, disabled, label, onChange, variant } = props;

    const isChecked = checked ? 'checked' : '';
    const isDisabled = disabled == true ? 'disabled' : '';

    const variantClassName = variant == 'onDarkBg' ? 'on-dark-background' : '';

    const handleClick = useCallback(() => {
        if (!disabled && onChange) onChange();
    }, [onChange, disabled]);

    return (
        <SwitchCont
            tabIndex={0}
            role='switch'
            aria-checked={checked ? true : false}
            className={`${isChecked} ${isDisabled} ${variantClassName}`}
            onClick={handleClick}
        >
            <SwitchToggleCont>
                <SwitchToggleBar />
                <SwitchToggleButton />
            </SwitchToggleCont>
            <SwitchLabel>{label}</SwitchLabel>
        </SwitchCont>
    );
};

export default React.memo(UBSwitch);
