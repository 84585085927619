import resolve from '@utils/resolve';
import { cfetch } from '@utils/auth';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const answer = async (
    token: string,
    roomId: string,
    participantId: string,
    values: any
) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/responses/add', {
            method: 'post',
            body: JSON.stringify({
                roomId: roomId,
                participantId: participantId,
                data: values
            }),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

export const APISondage = {
    answer: answer
};
