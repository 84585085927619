import React from 'react';
import { ErrorMessage } from 'formik';
import { ErrorCont } from './ErrorMsg.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ErrorMsg {
    name: string;
}

const ErrorMsg = React.memo((props: ErrorMsg) => {
    const { name } = props;

    ErrorMsg.displayName = 'ErrorMsg';

    return (
        <ErrorMessage name={name}>
            {msg => (
                <ErrorCont>
                    <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />{' '}
                    <div>{msg}</div>
                </ErrorCont>
            )}
        </ErrorMessage>
    );
});

export default ErrorMsg;
