import styled from 'styled-components';
import { colors } from '@components/_struct/variables';

interface ITag {
    variant?:
        | 'orange'
        | 'blue'
        | 'black'
        | 'gray'
        | 'green'
        | 'darkgreen'
        | 'outline-green'
        | 'red';
    color?: string;
    size?: 'small' | 'medium' | 'large';
}

export const Tag = styled.span<ITag>`
    display: inline-flex;
    font-weight: bold;
    font-size: ${p =>
        (p.size == 'small' && '12px') ||
        (p.size == 'medium' && '14px') ||
        (p.size == 'large' && '18px') ||
        '14px'};
    text-align: left;
    ${p => p.size == 'small' && `text-transform: uppercase;`}
    color: #ffffff;
    border-radius: 3px;
    padding: ${p =>
        (p.size == 'small' && '1px 4px') ||
        (p.size == 'medium' && '4px 5px') ||
        (p.size == 'large' && '7px 10px') ||
        '1px 4px'};
    background: ${p =>
        (p.color && p.color) ||
        (p.variant == 'red' && colors.error) ||
        (p.variant == 'orange' && '#FF8D27') ||
        (p.variant == 'blue' && '#3A90E1') ||
        (p.variant == 'black' && '#0A0E27') ||
        (p.variant == 'gray' && '#8589AD') ||
        (p.variant == 'outline-green' && '#ffffff') ||
        (!p.variant && colors.blue)};
`;
