import styled from 'styled-components';

export const ChkCont = styled.div`
    display: flex;
    flex-direction: column;
    &.checkboxes-horizontal {
        flex-direction: row;
        flex-wrap: wrap;
        > * {
            margin-bottom: 10px;
        }
    }
    > * + * {
        margin-top: 10px;
    }
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
`;
