import React, { useState, useEffect, useCallback } from 'react';
import {
    ListUsersWrapper,
    ListCloseButton,
    ListUsersTitle,
    ListUsers,
    ListAddUserItem
} from './RoomListUsers.css';
import { UIActions } from '@actions/ui_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { IApp } from '@interfaces/pages/_app';
import { IStore } from '@interfaces/logic/redux';
import UBTextField from '@components/_common/_form/TextField/UBTextField';
import RoomAddUserItem from './RoomAddUserItem';
import { useMSTranslation } from '@utils/useMSTranslation';

const RoomAddUserList = () => {
    const dispatch = useDispatch();
    const [filteredList, setFilteredList] = useState<IApp.IParticipantEvent[]>(
        []
    );
    const [val, setVal] = useState('');
    const { __ } = useMSTranslation();

    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );
    const participants = useSelector(
        (state: IStore) => state.call.participants
    );
    const userSite = useSelector((state: IStore) => state.Auth.user);

    // ------------------------------
    // Filtrage des suggestions
    // ------------------------------
    const filterSuggestion = useCallback(
        (item: IApp.IParticipantEvent) => {
            if (item.userName.toLowerCase().includes(val.toLowerCase())) {
                return true;
            }
        },
        [val]
    );

    useEffect(() => {
        setFilteredList(participantsConnectedEvent.filter(filterSuggestion));
    }, [filterSuggestion, participantsConnectedEvent]);

    return (
        <ListUsersWrapper>
            <div className='p-2 pb-1 select-none'>
                <div>{__('Ajouter des participants', 'event')}</div>
            </div>
            <ListCloseButton
                onClick={() => dispatch(UIActions.toggleAddUser())}
            >
                <FontAwesomeIcon icon={['far', 'times']} />
            </ListCloseButton>
            <div className='my-1 px-2'>
                <UBTextField
                    variant='rounded-outline-white'
                    suffixElm={<FontAwesomeIcon icon={['far', 'search']} />}
                    placeholder={__('Rechercher un utilisateur', 'event')}
                    onChange={val => setVal(val)}
                />
            </div>
            <SimpleBar className='scrollable-area'>
                <ListUsersTitle>
                    {__('Liste des participants', 'event')}
                </ListUsersTitle>
                <ListUsers>
                    {filteredList.length > 0 && userSite ? (
                        filteredList.map((item: IApp.IParticipantEvent) => {
                            if (item.userId != userSite.userId)
                                return (
                                    <RoomAddUserItem
                                        key={item.userId}
                                        userName={item.userName}
                                        userId={item.userId}
                                        remove={
                                            participants.filter(
                                                itemCall =>
                                                    itemCall.userId ==
                                                    item.userId
                                            ).length > 0
                                        }
                                    />
                                );
                        })
                    ) : (
                        <ListAddUserItem>
                            {__('Aucun utilisateur ne correspond', 'event')}
                        </ListAddUserItem>
                    )}
                </ListUsers>
            </SimpleBar>
        </ListUsersWrapper>
    );
};

export default RoomAddUserList;
