import { useFormikContext, useField, FormikHandlers } from 'formik';
import { SelectboxItem } from './Selectbox';
import MultiVal from '../MultiVal';
import { MultiValWrap } from '../MultiVal/MultiVal.css';

interface ISelectBoxMultiTag {
    name: string;
    items: SelectboxItem[];
    onChange?: FormikHandlers['handleChange'];
}

const SelectBoxMultiTag = (props: ISelectBoxMultiTag) => {
    const { items, onChange } = props;
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();

    const deleteVal = (itemName: string) => {
        let itemValue = '';
        for (const i of items) {
            if (itemName === i.label) {
                itemValue = i.value;
            }
        }
        const newArray = field.value.filter((value: string) => {
            return value != itemValue;
        });
        setFieldValue(field.name, newArray);
        onChange && onChange(newArray);
    };

    const listItems: string[] = [];
    field.value.forEach((val: string) => {
        for (const i of items) {
            if (val === i.value) {
                listItems.push(i.label);
            }
        }
    });

    return (
        <>
            {listItems.length > 0 && (
                <MultiValWrap>
                    {listItems.map((item: string, i: number) => (
                        <MultiVal
                            label={item}
                            key={i}
                            onDelete={() => deleteVal(item)}
                        />
                    ))}
                </MultiValWrap>
            )}
        </>
    );
};

export default SelectBoxMultiTag;
