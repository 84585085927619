import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useMSTranslation } from '@utils/useMSTranslation';

const CamWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    color: #fff;
    &::before {
        content: '';
        box-shadow: 0 0 0 3px #ffe665;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }
`;

const OverlayCam = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        opacity: 0.9;
        z-index: -1;
    }
`;

const AvatarNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Connecting = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
`;

const CamControls = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: default;
    user-select: none;
    display: flex;
    button {
        background: rgba(0, 0, 0, 0.6);
        border: 0;
        outline: 0;
        height: 60px;
        width: 60px;
        color: #fff;
        border-radius: 50px;
        font-size: 20px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background: rgba(0, 0, 0, 1);
            box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.6);
        }
    }
    button + button {
        margin-left: 10px;
    }
`;

interface IConfigCamProps {
    videoTrack: MediaStreamTrack | null;
    isLoading: boolean;
    userName: string;
    stateMic: boolean;
    stateCam: boolean;
}

const ConfigCam = (props: IConfigCamProps) => {
    const { __ } = useMSTranslation(['join', 'struct']);

    const { videoTrack, isLoading, userName, stateCam, stateMic } = props;

    const callObject = useSelector((state: IStore) => state.call.callObject);

    const icoWebcam: IconName = stateCam ? 'webcam' : 'webcam-slash';
    const icoMicro: IconName = stateMic ? 'microphone' : 'microphone-slash';
    const noCam = useSelector((state: IStore) => state.UI.noCam);
    const noMic = useSelector((state: IStore) => state.UI.noMic);

    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoEl && videoEl.current && videoTrack) {
            videoEl.current.srcObject = new MediaStream([videoTrack]);
        }
    }, [videoTrack, isLoading]);

    return isLoading ? (
        <CamWrapper>
            <OverlayCam>
                <AvatarNameWrapper>
                    <UserAvatar name={userName} size='64' />
                    <Connecting>{__('Chargement', 'struct')}...</Connecting>
                </AvatarNameWrapper>
            </OverlayCam>
        </CamWrapper>
    ) : (
        <CamWrapper>
            <OverlayCam>
                <AvatarNameWrapper>
                    {!videoTrack && <UserAvatar name={userName} size='64' />}
                    {!videoTrack && (
                        <Connecting>
                            {__('Aucune caméra détectée', 'join')}
                        </Connecting>
                    )}
                </AvatarNameWrapper>
            </OverlayCam>
            {videoTrack && (
                <video
                    width={'100%'}
                    autoPlay
                    muted
                    playsInline
                    ref={videoEl}
                />
            )}
            <CamControls>
                <button
                    onClick={() =>
                        callObject.setLocalVideo(!callObject.localVideo())
                    }
                    type='button'
                >
                    <FontAwesomeIcon
                        icon={['far', icoWebcam]}
                        className={noCam ? 'text-error' : ''}
                    />
                </button>
                <button
                    onClick={() =>
                        callObject.setLocalAudio(!callObject.localAudio())
                    }
                    type='button'
                >
                    <FontAwesomeIcon
                        icon={['far', icoMicro]}
                        className={noMic ? 'text-error' : ''}
                    />
                </button>
            </CamControls>
        </CamWrapper>
    );
};

export default React.memo(ConfigCam);
