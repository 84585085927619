import React from 'react';
import UserAvatar from '../Avatar/UserAvatar';
import styled from 'styled-components';

const IcoCont = styled.div`
    padding: 0 10px 0 12px;
`;

interface IToastEventRoom {
    name?: string;
}

const ToastEventRoom: React.FC<IToastEventRoom> = ({ name, children }) => {
    return (
        <div className='flex items-center'>
            {name && (
                <IcoCont>
                    <UserAvatar name={name} />
                </IcoCont>
            )}
            <div>
                {name && <strong>{name}, </strong>}
                {children}
            </div>
        </div>
    );
};

export default ToastEventRoom;
