import styled from 'styled-components';
import { ICamProps } from './Cam';
import { bp } from '@components/_struct/variables';
import {
    UserAvatarCont,
    UserAvatarInner
} from '@components/_common/Avatar/Avatar.css';

export const CamWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    .grid-hover & {
        opacity: 0.5;
    }
    .grid-split & {
        width: 100%;
        height: 100%;
        &::after {
            padding-bottom: 0;
        }
    }
    .grid-split .others & {
        max-width: 225px;
    }
    &::before {
        content: '';
        box-shadow: 0 0 0 3px #ffe665;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
    &.is-speaking::before {
        opacity: 1;
    }
    video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }
`;

export const CamOverlayCam = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        opacity: 0.5;
        z-index: -1;
    }
    .others & ${UserAvatarCont} {
        width: 48px;
        height: 48px;
        @media (${bp.sm}) {
            width: 32px;
            height: 32px;
        }
        @media (max-height: 640px) {
            width: 32px;
            height: 32px;
        }
        ${UserAvatarInner} {
            font-size: 16px;
            line-height: 48px;
            @media (${bp.sm}) {
                font-size: 12px;
                line-height: 32px;
            }
            @media (max-height: 640px) {
                font-size: 12px;
                line-height: 32px;
            }
        }
    }
`;

export const CamOverlaySharingScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        opacity: 0.7;
        z-index: -1;
    }
`;

export const CamOverlayRedirecting = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    flex-direction: column;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        opacity: 0.7;
        z-index: -1;
    }
`;

export const CamAvatarNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const CamNameTag = styled.div<Pick<ICamProps, 'isAdmin'>>`
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${p =>
        p.isAdmin ? 'rgba(58, 144, 225, 0.8)' : 'rgba(0, 0, 0, 0.7)'};
    border-radius: 0px 6px 0px 0px;
    padding: 0 6px;
    height: 30px;
    display: flex;
    align-items: center;
    z-index: 5;
    max-width: 100%;
    cursor: default;
    user-select: none;
    @media (${bp.sm}) {
        height: 24px;
    }
    span {
        @media (${bp.sm}) {
            font-size: 13px;
        }
    }
`;

export const CamConnecting = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
`;

export const CamUserState = styled.div<Pick<ICamProps, 'isAdmin'>>`
    opacity: 0.6;
    color: ${p => (!p.isAdmin ? '#3a90e1' : 'rgba(0, 0, 0, 1)')};
    font-size: 16px;
    min-width: 20px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    @media (${bp.sm}) {
        font-size: 13px;
        margin-right: 5px;
    }
    &.signin {
        color: #f7c887;
    }
`;

export const CamTopRightButtonCont = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0;
    visibility: hidden;
    width: 110px;
    height: 53px;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            205.98deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 56.25%
        );
        opacity: 0.6;
        z-index: -1;
    }
    ${CamWrapper}:hover & {
        opacity: 1;
        visibility: visible;
    }
    .pinned & {
        opacity: 1;
        visibility: visible;
    }
`;

export const CamTopLeft = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 110px;
    height: 53px;
    display: flex;
    padding: 10px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            155deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 56.25%
        );
        opacity: 0.6;
        z-index: -1;
        visibility: hidden;
    }
    ${CamWrapper}:hover &::before,
    ${CamWrapper}.contextMenuOpened &::before {
        visibility: visible;
    }
`;

export const CamContextButtonCont = styled.div`
    opacity: 0;
    visibility: hidden;
    .fullscreenEnabled & {
        display: none;
    }
    ${CamWrapper}:hover &,
    ${CamWrapper}.contextMenuOpened & {
        opacity: 1;
        visibility: visible;
    }
`;

export const CamBlockSharingScreen = styled.div`
    border-radius: 20px;
    background: #000;
    .pinned & {
        width: 36%;
        height: 36%;
    }
    .grid-5 &,
    .others & {
        background: transparent;
    }
    .others & {
        display: none;
    }
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    user-select: none;
`;

export const CamPictoSharingScreen = styled.div`
    .pinned & {
        width: 23%;
    }
    .grid-3 &,
    .grid-4 &,
    .grid-5 &,
    .others & {
        display: none;
    }
    width: 15%;
    > svg {
        display: block;
        width: 100% !important;
        height: inherit;
    }
`;

export const CamSharingScreenText = styled.div`
    font-size: 1.6rem;
    margin: 20px 0;
    font-weight: bold;
    text-align: center;
    .pinned & {
        font-size: 1.8rem;
    }
    .grid-3 &,
    .grid-4 & {
        font-size: 1.4rem;
        margin-top: 0;
    }
    .grid-5 & {
        display: none;
    }
    .others & {
        display: none;
    }
`;

export const CamWrapperInterviewButtons = styled.div`
    position: absolute;
    bottom: 40px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
`;
