import { ACTION_TYPES } from '@actions/actions_types';

const startLoading = (nom: string) => ({
    payload: { actionLoading: nom },
    type: ACTION_TYPES.Loading.start
});

const stopLoading = (nom: string) => ({
    payload: { actionLoading: nom },
    type: ACTION_TYPES.Loading.stop
});

const setText = (text: JSX.Element | null) => ({
    payload: { textLoading: text },
    type: ACTION_TYPES.Loading.setText
});

export const LoadingActions = {
    startLoading: startLoading,
    stopLoading: stopLoading,
    setText: setText
};
