import styled from 'styled-components';
import { IRadioGroup } from './RadioGroup';
import { colors, form } from '@components/_struct/variables';

// Last update : 28/05/2020

// RadioGroup
export const RadiosCont = styled.div<Pick<IRadioGroup, 'variant'>>`
    display: flex;
    flex-direction: ${p => (p.variant == 'button' ? 'row' : 'column')};
    margin: ${p => p.variant == 'button' && '-5px 0 -5px -5px'};
    &.radio-group-horizontal {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

// Radio
export const Radiobox = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
`;

export const Label = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    text-transform: none;
    font-size: ${form.textSizeDefault};
    padding-left: 26px;
    & ~ & {
        margin-top: 10px;
    }
    .radio-group-horizontal & {
        margin-top: 0;
        margin-right: 20px;
    }
    &.radio-button {
        padding: 0 ${form.radioBtnPaddingX};
        border: 2px solid ${form.borderDefault};
        height: ${form.radioBtnHeight};
        border-radius: ${form.radioBtnBorderRadius};
        line-height: calc(${form.radioBtnHeight} - 4px);
        margin: 5px;
        color: ${form.textInactive};
        transition: all 0.15s ease;
        &:hover {
            background: ${form.radioBtnBgHover};
            color: ${form.radioBtnTextHover};
            border-color: ${form.radioBtnTextHover};
        }
        ${RadiosCont}.haserror & {
            border-color: ${form.borderError};
            color: ${colors.error};
        }
        &.radio-checked {
            color: ${form.radioBtnTextActive};
            background: ${form.radioBtnBgActive};
            border-color: ${form.radioBtnBgActive};
        }
        &.radio-focus::before {
            content: '';
            position: absolute;
            left: -2px;
            top: -2px;
            bottom: -2px;
            right: -2px;
            border-radius: ${form.radioBtnBorderRadius};
            box-shadow: 0 0 0 2px ${form.boxShadowFocus};
        }
    }
`;

export const RadioIndicator = styled.span`
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 2px solid ${form.borderDefault};
    border-radius: 24px;
    color: #fff;
    margin-left: -26px;
    margin-right: 10px;
    margin-top: -3px;
    vertical-align: middle;
    user-select: none;
    &::before {
        display: block;
        width: 8px;
        height: 8px;
        content: '';
    }
    ${Radiobox}:focus ~ & {
        border-color: ${form.borderFocus};
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
    }
    ${Radiobox}:focus:checked ~ & {
        border-color: ${form.borderFocus};
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
    }
    ${Radiobox}:checked ~ & {
        border-color: ${form.borderDefault};
        ::before {
            border-radius: 24px;
            background: #000;
            margin-top: 50%;
            margin-left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .radio-button & {
        display: none;
    }
`;
