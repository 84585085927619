import resolve from '@utils/resolve';
import getConfig from 'next/config';
import { cfetch, mercureFetch } from '@utils/auth';
const { publicRuntimeConfig } = getConfig();

/**
 * Récupère les participants de l'event
 *
 */
const getParticipantsEvent = async (token: string, topic: string) => {
    return await resolve(
        mercureFetch(
            token,
            publicRuntimeConfig.LIEN_MERCURE +
                '.well-known/mercure/subscriptions/' +
                encodeURIComponent(topic)
        )
    );
};

/**
 * Récupère les participants d'une room
 *
 */
const getParticipantsRoom = async (token: string, topic: string) => {
    return await resolve(
        mercureFetch(
            token,
            publicRuntimeConfig.LIEN_MERCURE +
                '.well-known/mercure/subscriptions/' +
                encodeURIComponent(topic)
        )
    );
};

/**
 * Récupère les participants d'une ou plusieurs rooms
 *
 */
const getParticipantsRoomV2 = async (
    token: string,
    mercureToken: string,
    topics: string[]
) => {
    return await resolve(
        cfetch(
            token,
            publicRuntimeConfig.API_BASE_URL + '/rooms/participants',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ topics, mercureToken })
            }
        )
    );
};

/**
 * Envoie une notification à un utilisateur mercure
 *
 */
const sendNotifUsers = async (
    token: string,
    topic: string | string[],
    data: any
) => {
    const body = new URLSearchParams({
        data: JSON.stringify(data)
    });
    if (Array.isArray(topic)) {
        topic.forEach(id => {
            body.append('topic', id);
        });
    } else body.append('topic', topic);

    return await resolve(
        fetch(publicRuntimeConfig.LIEN_MERCURE + '.well-known/mercure', {
            method: 'post',
            body: body,
            headers: {
                authorization: 'Bearer ' + token
            }
        })
    );
};

export const APIMercure = {
    getParticipantsEvent: getParticipantsEvent,
    sendNotifUsers: sendNotifUsers,
    getParticipantsRoom: getParticipantsRoom,
    getParticipantsRoomV2
};
