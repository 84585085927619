import styled from 'styled-components';
import { colors } from '@components/_struct/variables';

export const TabsWrapper = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    .scrollable-area {
        flex: 1;
        min-height: 0;
        position: relative;
    }
`;

export const TabNavList = styled.div`
    display: flex;
    position: relative;
    ${TabsWrapper}.tabs-basic & {
        margin-bottom: 4px;
    }
    ${TabsWrapper}.tabs-basic &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 3px;
        background: ${colors.gray20};
    }
`;

export const TabNav = styled.div<{ textColorInactive?: string }>`
    cursor: pointer;
    user-select: none;
    ${TabsWrapper}.tabs-basic & {
        position: relative;
        color: ${colors.gray60};
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 18px;
        &::before {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 3px;
            background: ${colors.main};
            opacity: 0;
        }
        &.active {
            color: ${colors.main};
            font-weight: bold;
            &::before {
                opacity: 1;
            }
        }
    }
    ${TabsWrapper}.tabs-alt & {
        position: relative;
        color: ${p => (p.textColorInactive ? p.textColorInactive : '#fff')};
        border-radius: 6px 6px 0 0;
        font-size: 14px;
        font-weight: bold;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 13px;
        &.active {
            background: #fff;
            color: #000;
            &::after {
                background: #fff;
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                height: 5px;
                width: 100%;
            }
        }
    }
    ${TabsWrapper}.tabs-alt & + & {
        margin-left: 10px;
    }
`;

export const TabNotif = styled.span`
    ${TabsWrapper}.tabs-alt & {
        display: flex;
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        text-transform: uppercase;
        color: #ffffff;
        border-radius: 6px;
        padding: 4px 8px;
        background: rgba(255, 255, 255, 0.1);
        margin-left: 10px;
    }
    ${TabsWrapper}.tabs-alt .active & {
        color: ${colors.main};
        background: rgba(28, 40, 127, 0.1);
    }
`;

export const TabsPanelWrapper = styled.div`
    position: relative;
    flex: 1;
    min-height: 0;
`;

export const TabPanelWrapper = styled.div`
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${TabsWrapper}.tabs-alt & {
        background: #fff;
        border-radius: 6px;
    }
`;
