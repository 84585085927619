import styled from 'styled-components';

export const ErrorCont = styled.div`
    display: flex;
    color: #d80027;
    margin: 8px 0 0;
    font-size: 15px;
    div {
        margin-left: 5px;
    }
`;
