import React, { useState } from 'react';
import { useMSTranslation } from '@utils/useMSTranslation';
import { Container } from '@components/_struct/_layouts/Grid';
import CompanyAvatar from '@components/_common/Avatar/CompanyAvatar';
import styled from 'styled-components';
import { Tag } from '@components/_common/Tag';
import { Block } from '@components/_common/Block/index.css';
import { Formik, Form } from 'formik';
import TextArea from '@components/_common/_form/TextArea';
import RadioGroup from '@components/_common/_form/RadioGroup/RadioGroup';
import Button from '@components/_common/Button';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import ErrorMsg from '@components/_common/_form/ErrorMsg/ErrorMsg';
import CheckboxGroup from '@components/_common/_form/CheckboxGroup/CheckboxGroup';
import * as Yup from 'yup';
import TextField from '@components/_common/_form/TextField/TextField';
import Selectbox from '@components/_common/_form/Selectbox/Selectbox';
import { APISondage } from '@api/sondageApi';
import SurveyLayout from '@components/_struct/_layouts/SurveyLayout/SurveyLayout';

const HeadEvent = styled.div`
    background: #0a0e27;
    border-radius: 12px;
`;

const Cover = styled.div<{ $cover?: string }>`
    z-index: 0;
    background: ${p =>
        (p.$cover && p.$cover != '' && `url(${p.$cover})`) ||
        'url("/images/header_ban.jpg")'};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 12px 12px 0 0;
    padding: 40px 20px 20px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 12px 12px 0 0;
        z-index: 0;
    }
    > * {
        position: relative;
        z-index: 1;
    }
`;

const SuccessWrapper = styled.div`
    &.enter {
        opacity: 0;
        transform: translateY(80px);
    }
    &.enter-active {
        opacity: 1;
        transform: translateX(0) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
`;

const MinW600 = styled.div`
    width: 600px;
`;

const PictoCont = styled.div`
    font-size: 96px;
`;

interface IFieldsItem {
    field: string;
    labelfield: string;
    mandatory: boolean;
    listItems: string[];
    uniqueId: string;
}
interface ICheckBox {
    value: string;
    label: string;
}

const TheEndSurvey = () => {
    const { __ } = useMSTranslation(['event', 'struct']);
    const [showSuccess, setShowSuccess] = useState(false);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const participant = useSelector((state: IStore) => state.Auth.user);

    if (!infosEvent) return <></>;

    const tabFieldsHtml: JSX.Element[] = [];
    const initialValues: any = {};
    const validation: any = {};

    infosEvent.sondageFields.forEach((item: IFieldsItem, index: number) => {
        initialValues[item.uniqueId] = '';

        // Gestion du champ obligatoire
        if (item.mandatory)
            validation[item.uniqueId] = Yup.string()
                .trim()
                .required(__('Obligatoire', 'struct'));

        switch (item.field) {
            case 'checkbox':
                const checkboxItems: ICheckBox[] = [];
                item.listItems.forEach((value: any) => {
                    checkboxItems.push({ value: value, label: value });
                });
                if (checkboxItems.length > 0)
                    tabFieldsHtml.push(
                        <Block
                            key={index}
                            className='my-2 px-2 py-2 md:px-4 md:py-3'
                        >
                            <div className='font-bold text-base md:text-lg'>
                                {item.labelfield}
                            </div>
                            <div className='mt-2' key={item.uniqueId}>
                                <CheckboxGroup
                                    name={item.uniqueId}
                                    items={checkboxItems}
                                    direction='horizontal'
                                />
                                <ErrorMsg name={item.uniqueId} />
                            </div>
                        </Block>
                    );
                break;
            case 'textarea':
                tabFieldsHtml.push(
                    <Block
                        key={index}
                        className='my-2 px-2 py-2 md:px-4 md:py-3'
                    >
                        <div className='font-bold text-base md:text-lg'>
                            {item.labelfield}
                        </div>
                        <div className='mt-2' key={item.uniqueId}>
                            <TextArea name={item.uniqueId} id={item.uniqueId} />
                            <ErrorMsg name={item.uniqueId} />
                        </div>
                    </Block>
                );
                break;
            case 'textfield':
                tabFieldsHtml.push(
                    <Block
                        key={index}
                        className='my-2 px-2 py-2 md:px-4 md:py-3'
                    >
                        <div className='font-bold text-base md:text-lg'>
                            {item.labelfield}
                        </div>
                        <div className='mt-2' key={item.uniqueId}>
                            <TextField
                                name={item.uniqueId}
                                id={item.uniqueId}
                            />
                            <ErrorMsg name={item.uniqueId} />
                        </div>
                    </Block>
                );
                break;
            case 'selectbox':
                const selectboxItems: any[] = [];
                item.listItems.forEach((value: any) => {
                    selectboxItems.push({ value: value, label: value });
                });
                if (selectboxItems.length > 0)
                    tabFieldsHtml.push(
                        <Block
                            key={index}
                            className='my-2 px-2 py-2 md:px-4 md:py-3'
                        >
                            <div className='font-bold text-base md:text-lg'>
                                {item.labelfield}
                            </div>
                            <div className='mt-2' key={item.uniqueId}>
                                <Selectbox
                                    items={selectboxItems}
                                    name={item.uniqueId}
                                    placeholder=''
                                />
                                <ErrorMsg name={item.uniqueId} />
                            </div>
                        </Block>
                    );
                break;
            case 'selectboxMultiple':
                initialValues[item.uniqueId] = [];
                const selectboxmItems: any[] = [];
                item.listItems.forEach((value: any) => {
                    selectboxmItems.push({ value: value, label: value });
                });
                if (selectboxmItems.length > 0)
                    tabFieldsHtml.push(
                        <Block
                            key={index}
                            className='my-2 px-2 py-2 md:px-4 md:py-3'
                        >
                            <div className='font-bold text-base md:text-lg'>
                                {item.labelfield}
                            </div>
                            <div className='mt-2' key={item.uniqueId}>
                                <Selectbox
                                    items={selectboxmItems}
                                    name={item.uniqueId}
                                    placeholder=''
                                    multiple={true}
                                />
                                <ErrorMsg name={item.uniqueId} />
                            </div>
                        </Block>
                    );
                break;
            case 'radiosBtn':
                const checkboxrItems: ICheckBox[] = [];
                item.listItems.forEach((value: any) => {
                    checkboxrItems.push({ value: value, label: value });
                });
                if (checkboxrItems.length > 0)
                    tabFieldsHtml.push(
                        <Block
                            key={index}
                            className='my-2 px-2 py-2 md:px-4 md:py-3'
                        >
                            <div className='font-bold text-base md:text-lg'>
                                {item.labelfield}
                            </div>
                            <div className='mt-2' key={item.uniqueId}>
                                <RadioGroup
                                    name={item.uniqueId}
                                    items={checkboxrItems}
                                    direction='horizontal'
                                    variant='button'
                                />
                                <ErrorMsg name={item.uniqueId} />
                            </div>
                        </Block>
                    );
                break;
        }
    });

    const onSubmitForm = (values: any) => {
        if (ctoken && infosEvent.roomId && participant) {
            APISondage.answer(
                ctoken,
                infosEvent.roomId,
                participant.userId,
                values
            ).then(() => {
                setShowSuccess(true);
            });
        }
    };

    const validationSchema = Yup.object().shape(validation);

    return (
        <SurveyLayout showLogo={true}>
            <div className='py-3 md:pb-5 pt-0 px-1 flex-1'>
                <SwitchTransition>
                    <CSSTransition
                        key={showSuccess ? '1' : '2'}
                        timeout={250}
                        onExiting={() => setShowSuccess(true)}
                    >
                        {!showSuccess && infosEvent ? (
                            <Container width={'820'}>
                                <HeadEvent className='z-0 mb-3'>
                                    <Cover
                                        $cover={
                                            infosEvent.cover
                                                ? infosEvent.cover
                                                : 'https://w.wallhaven.cc/full/43/wallhaven-43vgyn.jpg'
                                        }
                                    >
                                        <div className='flex flex-col items-center'>
                                            <CompanyAvatar
                                                name='BNP Paribas'
                                                size='64'
                                                src={infosEvent.companyAvatar}
                                            />
                                            <div className='mt-2 text-xl font-bold'>
                                                {infosEvent.title}
                                            </div>
                                            <div className='mt-1'>
                                                <Tag
                                                    variant='orange'
                                                    size='medium'
                                                >
                                                    {__(
                                                        "L'évènement est terminé",
                                                        'event'
                                                    )}
                                                </Tag>
                                            </div>
                                        </div>
                                    </Cover>
                                    <div className='text-center py-2 text-lg md:text-2xl font-bold'>
                                        {__(
                                            'Merci de votre participation',
                                            'event'
                                        )}
                                    </div>
                                </HeadEvent>
                                <div className='text-center text-lg md:text-2xl font-bold mb-2'>
                                    {__(
                                        'Auriez-vous 2 minutes à nous accorder ?',
                                        'event'
                                    )}
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmitForm}
                                    validationSchema={validationSchema}
                                >
                                    {() => (
                                        <Form>
                                            {tabFieldsHtml.length > 0 &&
                                                tabFieldsHtml}
                                            <div className='mt-3 text-center'>
                                                <Button
                                                    variant='action'
                                                    type='submit'
                                                    size='large'
                                                >
                                                    {__(
                                                        'Envoyer mes réponses',
                                                        'event'
                                                    )}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Container>
                        ) : (
                            <SuccessWrapper className='flex h-full items-center justify-center'>
                                <MinW600>
                                    <Block className='p-4'>
                                        <PictoCont className='text-action mb-4'>
                                            <FontAwesomeIcon
                                                className='block m-auto'
                                                icon={['far', 'check-circle']}
                                            />
                                        </PictoCont>
                                        <div className='text-center text-lg md:text-xl text-main font-bold leading-normal'>
                                            {__(
                                                "Nous vous remercions d'avoir pris le temps de répondre à nos questions",
                                                'event'
                                            )}
                                        </div>
                                    </Block>
                                </MinW600>
                            </SuccessWrapper>
                        )}
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </SurveyLayout>
    );
};

export default TheEndSurvey;
