import React, { useEffect, useState } from 'react';
import RoomCard from './RoomCard/RoomCard';
import { ListingRoom, ListingRoomCols } from './Hall.css';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMSTranslation } from '@utils/useMSTranslation';

interface IRoomHall {
    name: string;
    description: string;
    nbParticipantsMax: number;
    pic?: string;
    color: string;
    type: EnumTypeRoom;
    roomId: string;
}

const Hall = () => {
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const { __ } = useMSTranslation(['event']);

    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );
    const waitingRoom = useSelector((state: IStore) => state.UI.waitingRoom);
    const roomId = useSelector((state: IStore) => state.call.roomId);

    const [roomsEvent, setRoomsEvent] = useState<IRoomHall[]>([]);

    // On construit nos rooms
    useEffect(() => {
        let rooms = [];
        if (infosEvent) {
            rooms = infosEvent.rooms.map(item => {
                return {
                    name: item.nom,
                    description: item.desc,
                    nbParticipantsMax: item.sizeRoom,
                    pic: item.pic,
                    color: item.color,
                    type: item.type,
                    roomId: item.roomId,
                    here: roomId == item.roomId
                };
            });
            setRoomsEvent(rooms);
        }
    }, [infosEvent, roomId]);

    const getRoomLayout = () => {
        if (roomsEvent.length <= 6) {
            const col1: JSX.Element[] = [];
            const col2: JSX.Element[] = [];
            roomsEvent.forEach((item, i) => {
                if (waitingRoom !== '' && waitingRoom !== item.roomId) return;
                if (i % 2 == 0) {
                    col1.push(<RoomCard {...item} key={i} />);
                } else {
                    col2.push(<RoomCard {...item} key={i} />);
                }
            });

            return (
                <ListingRoomCols>
                    <div>{col1}</div>
                    <div>{col2}</div>
                </ListingRoomCols>
            );
        } else {
            return roomsEvent.map((item, i) => <RoomCard {...item} key={i} />);
        }
    };

    const RoomLayoutClassName = roomsEvent.length <= 6 ? '' : 'single-col';

    return (
        <div className='p-3'>
            <div className='text-center text-xl opacity-50 mb-1'>
                {participantsConnectedEvent.length}
                <FontAwesomeIcon icon={['far', 'users']} className='ml-1' />
            </div>
            <div className='mb-3 md:mb-5 text-xl text-center'>
                {__('Choisissez la salle à rejoindre', 'event')}
            </div>
            <ListingRoom className={RoomLayoutClassName}>
                {getRoomLayout()}
            </ListingRoom>
        </div>
    );
};

export default Hall;
