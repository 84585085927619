import { useState, useEffect } from 'react';

const getOrientation = () => {
    if (window.screen.orientation) return window.screen.orientation.type;
    else
        return Math.abs(+window.orientation) === 90
            ? 'landscape-primary'
            : 'portrait-primary';
};

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation());

    const updateOrientation = () => {
        setOrientation(getOrientation());
    };

    useEffect(() => {
        window.addEventListener('orientationchange', updateOrientation);
        return () => {
            window.removeEventListener('orientationchange', updateOrientation);
        };
    }, []);

    return orientation;
};

export default useScreenOrientation;
