import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import DropdownContext from '@components/_common/DropdownContext';
import { UIActions } from '@actions/ui_actions';
import { DataActions } from '@actions/data_actions';
import { useModal } from '@components/_common/Modal/Modal';
import Confirm from '@components/_common/Modal/Confirm';
import React, { useCallback } from 'react';
import { CallActions } from '@actions/call_actions';
import { ChatActions } from '@actions/chat_actions';
import { DropdownContextName } from '@components/_common/DropdownContext/DropdownContext.css';
import { displaySuccess } from '@components/_common/Toast';
import getConfig from 'next/config';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

interface IOptionsOnUserProps {
    userId: string;
    withChat?: boolean;
    hiddenMsg?: boolean;
    msgId?: string;
}

const OptionsOnUser = (props: IOptionsOnUserProps) => {
    const { userId, withChat, hiddenMsg, msgId } = props;
    const { __ } = useMSTranslation(['event', 'struct']);

    const dispatch = useDispatch();

    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const participants = useSelector(
        (state: IStore) => state.call.participants
    );
    const roomId = useSelector((state: IStore) => state.call.roomId);
    let infosRoom = null;
    if (infosEvent && roomId) {
        infosRoom = infosEvent.rooms.filter(
            (value: IRoom) => value.roomId == roomId
        );
    }

    const isTmpRoom = infosRoom && roomId ? infosRoom.length == 0 : false;

    const participant = participantsConnectedEvent.filter(
        item => item.userId == userId
    )[0];

    const [
        visibleConfirmMuteTchat,
        handleCloseConfirmMuteTchat,
        openConfirmMuteTchat
    ] = useModal();
    const [
        visibleConfirmBan,
        handleCloseConfirmBan,
        openConfirmBan
    ] = useModal();
    const [
        visibleConfirmKick,
        handleCloseConfirmKick,
        openConfirmKick
    ] = useModal();
    const [
        visibleConfirmDel,
        handleCloseConfirmDel,
        openConfirmDel
    ] = useModal();
    const [
        visibleConfirmUnHide,
        handleCloseConfirmUnHide,
        openConfirmUnHide
    ] = useModal();
    const [
        visibleConfirmHide,
        handleCloseConfirmHide,
        openConfirmHide
    ] = useModal();

    const canSeeProfil =
        userId &&
        participant &&
        !participant.intervenant &&
        userSite &&
        userSite.intervenant;

    const micLocked = !participants.find(item => item.userId == userId)?.audio;

    const camLocked = !participants.find(item => item.userId == userId)?.video;

    const isMutedTchat = participants.find(item => item.userId == userId)
        ?.mutedTchat;

    let session_id = '';
    if (callObject != null)
        Object.values(callObject.participants()).map((item: any) => {
            if (item.user_id == userId) session_id = item.session_id;
        });

    const inviteRoomTemporaire = () => {
        if (!infosEvent || !ctoken || !meetingToken) return;
        dispatch(
            DataActions.addTmpRoom(
                ctoken,
                infosEvent.roomId,
                meetingToken,
                callObject,
                [participant.topic],
                mercureTokenEvent,
                {
                    type: 'invitation_room',
                    nomInvitation: userSite?.userName,
                    topicRetour:
                        publicRuntimeConfig.LIEN_MERCURE +
                        'users/' +
                        userSite.userId
                }
            )
        );
        displaySuccess(
            __(
                "Une proposition a été envoyée à {{username}}, il vous rejoindra en cas d'acceptation",
                'event',
                { username: participant.userName }
            )
        );
    };

    const noMic = useCallback(() => {
        callObject.updateParticipant(session_id, {
            setAudio: false
        });
    }, [callObject, session_id]);

    const withMic = useCallback(() => {
        dispatch(CallActions.micToggle(userId, mercureTokenEvent, true));
    }, [dispatch, userId, mercureTokenEvent]);

    const noCam = useCallback(() => {
        callObject.updateParticipant(session_id, {
            setVideo: false
        });
    }, [callObject, session_id]);

    const withCam = useCallback(() => {
        dispatch(CallActions.camToggle(userId, mercureTokenEvent, true));
    }, [dispatch, userId, mercureTokenEvent]);

    const muteUser = useCallback(() => {
        dispatch(ChatActions.muteUser(userId, mercureTokenEvent, roomId));
    }, [userId, mercureTokenEvent, roomId, dispatch]);

    const unMuteUser = useCallback(() => {
        dispatch(ChatActions.unMuteUser(userId, mercureTokenEvent, roomId));
    }, [userId, mercureTokenEvent, roomId, dispatch]);

    const kickUser = useCallback(() => {
        dispatch(ChatActions.kickUser(userId, mercureTokenEvent, roomId));
    }, [userId, mercureTokenEvent, roomId, dispatch]);

    const banUser = useCallback(() => {
        dispatch(
            ChatActions.banUser(
                mercureTokenEvent,
                infosEvent,
                ctoken,
                userId,
                session_id,
                callObject
            )
        );
    }, [
        callObject,
        session_id,
        userId,
        infosEvent,
        ctoken,
        mercureTokenEvent,
        dispatch
    ]);

    const deleteMsg = useCallback(() => {
        if (msgId)
            dispatch(ChatActions.deleteMsg(msgId, mercureTokenEvent, roomId));
    }, [msgId, mercureTokenEvent, roomId, dispatch]);

    const hideMsg = useCallback(() => {
        if (msgId)
            dispatch(ChatActions.hideMsg(msgId, mercureTokenEvent, roomId));
    }, [msgId, mercureTokenEvent, roomId, dispatch]);

    const unHideMsg = useCallback(() => {
        if (msgId)
            dispatch(ChatActions.unHideMsg(msgId, mercureTokenEvent, roomId));
    }, [msgId, mercureTokenEvent, roomId, dispatch]);

    if (!participant) return null;

    return (
        <>
            <DropdownContext placement='bottom-end' sizeBtn='small'>
                {withChat && userSite && userSite.intervenant && (
                    <>
                        <DropdownContextName>
                            <div>{__('Le message', 'event')}</div>
                        </DropdownContextName>
                        <DropdownContext.Item onClick={openConfirmDel}>
                            {__('Supprimer', 'struct')}
                        </DropdownContext.Item>
                        {hiddenMsg && (
                            <DropdownContext.Item onClick={openConfirmUnHide}>
                                {__('Afficher', 'struct')}
                            </DropdownContext.Item>
                        )}
                        {!hiddenMsg && (
                            <DropdownContext.Item onClick={openConfirmHide}>
                                {__('Masquer', 'event')}
                            </DropdownContext.Item>
                        )}
                    </>
                )}
                <DropdownContextName>
                    <div>{participant.userName}</div>
                </DropdownContextName>
                {canSeeProfil && userSite && userSite.intervenant && (
                    <DropdownContext.Item
                        onClick={() =>
                            dispatch(UIActions.openDrawerCandidat(userId))
                        }
                    >
                        {__('Afficher son profil', 'event')}
                    </DropdownContext.Item>
                )}
                {!isTmpRoom && (
                    <DropdownContext.Item onClick={inviteRoomTemporaire}>
                        {__('Inviter dans une salle privée', 'event')}
                    </DropdownContext.Item>
                )}

                {!micLocked &&
                    session_id &&
                    userSite &&
                    userSite.intervenant && (
                        <DropdownContext.Item onClick={noMic}>
                            {__('Couper le micro', 'event')}
                        </DropdownContext.Item>
                    )}
                {micLocked &&
                    session_id &&
                    userSite &&
                    userSite.intervenant && (
                        <DropdownContext.Item onClick={withMic}>
                            {__('Activer le micro', 'event')}
                        </DropdownContext.Item>
                    )}
                {!camLocked &&
                    session_id &&
                    userSite &&
                    userSite.intervenant && (
                        <DropdownContext.Item onClick={noCam}>
                            {__('Couper la webcam', 'event')}
                        </DropdownContext.Item>
                    )}
                {camLocked &&
                    session_id &&
                    userSite &&
                    userSite.intervenant && (
                        <DropdownContext.Item onClick={withCam}>
                            {__('Activer la webcam', 'event')}
                        </DropdownContext.Item>
                    )}

                {!isMutedTchat &&
                    session_id &&
                    userSite &&
                    userSite.intervenant && (
                        <DropdownContext.Item onClick={openConfirmMuteTchat}>
                            {__('Couper le chat', 'event')}
                        </DropdownContext.Item>
                    )}
                {isMutedTchat &&
                    session_id &&
                    userSite &&
                    userSite.intervenant && (
                        <DropdownContext.Item onClick={unMuteUser}>
                            {__('Autoriser le chat', 'event')}
                        </DropdownContext.Item>
                    )}
                {roomId && userSite && userSite.intervenant && (
                    <DropdownContext.Item onClick={openConfirmKick}>
                        {__('Retirer de la salle', 'event')}
                    </DropdownContext.Item>
                )}

                {userSite && userSite.intervenant && (
                    <DropdownContext.Item onClick={openConfirmBan}>
                        {__("Bannir de l'évènement", 'event')}
                    </DropdownContext.Item>
                )}
            </DropdownContext>

            <Confirm
                visible={visibleConfirmMuteTchat}
                onClose={handleCloseConfirmMuteTchat}
                onSubmit={muteUser}
                title={__('Couper le chat de ce participant ?', 'event')}
                description={__(
                    'Voulez-vous couper le chat de {{username}} ?',
                    'event',
                    { username: participant.userName }
                )}
            />
            <Confirm
                visible={visibleConfirmKick}
                onClose={handleCloseConfirmKick}
                onSubmit={kickUser}
                title={__('Retirer ce participant ?', 'event')}
                description={__(
                    'Voulez-vous retirer {{username}} de la salle ?',
                    'event',
                    { username: participant.userName }
                )}
            />

            <Confirm
                visible={visibleConfirmBan}
                onClose={handleCloseConfirmBan}
                onSubmit={banUser}
                title={__('Bannir ce participant ?', 'event')}
                description={__('Voulez-vous bannir {{username}} ?', 'event', {
                    username: participant.userName
                })}
            />
            <Confirm
                visible={visibleConfirmDel}
                onClose={handleCloseConfirmDel}
                onSubmit={deleteMsg}
                title={__('Supprimer ce message ?', 'event')}
                description={__('Voulez-vous supprimer ce message ?', 'event')}
            />
            <Confirm
                visible={visibleConfirmUnHide}
                onClose={handleCloseConfirmUnHide}
                onSubmit={unHideMsg}
                title={__('Afficher ce message ?', 'event')}
                description={__('Voulez-vous afficher ce message ?', 'event')}
            />
            <Confirm
                visible={visibleConfirmHide}
                onClose={handleCloseConfirmHide}
                onSubmit={hideMsg}
                title={__('Masquer ce message ?', 'event')}
                description={__('Voulez-vous masquer ce message ?', 'event')}
            />
        </>
    );
};

export default OptionsOnUser;
