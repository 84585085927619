import React from 'react';
import {
    WaitingListDispatchWrapper,
    WaitingListDispatchBottomSection
} from './WaitingListDispatch.css';
import WaitingListDispatchItem from './WaitingListDispatchItem';
import Button from '@components/_common/Button';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { IApp } from '@interfaces/pages/_app';
import { useMSTranslation } from '@utils/useMSTranslation';
import { DataActions } from '@actions/data_actions';
import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

interface IWaitingListDispatch {
    closePopOver?: () => void;
}

const WaitingListDispatch = (props: IWaitingListDispatch) => {
    const dispatch = useDispatch();
    const { __, __s } = useMSTranslation(['event']);

    const participantsReception = useSelector(
        (state: IStore) => state.Data.participantsReception
    );

    const selectedWaitingDispatch = useSelector(
        (state: IStore) => state.UI.selectedWaitingDispatch
    );
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );

    const { closePopOver } = props;

    // Si une room n'a pas été initialisée il y a moins de 15s pr le user
    const participants = participantsReception.filter(
        item =>
            !item.roomInitiated ||
            item.roomInitiated + 15000 < new Date().getTime()
    );

    const inviteRoomTemporaire = () => {
        if (!infosEvent || !ctoken || !meetingToken) return;
        const topics: string[] = [];
        selectedWaitingDispatch.forEach(userId => {
            const participant = participants.find(
                item => item.userId == userId
            );
            if (participant) {
                topics.push(participant.topic);

                // On prévient les intervenants connectés que cet utilisateur va etre pris en charge
                APIMercure.sendNotifUsers(
                    mercureTokenEvent,
                    publicRuntimeConfig.LIEN_SITE +
                        infosEvent.roomId +
                        '-inter',
                    {
                        type: 'invitation_room_initiated',
                        userId: participant.userId
                    }
                );
            }
        });
        dispatch(
            DataActions.addTmpRoom(
                ctoken,
                infosEvent.roomId,
                meetingToken,
                callObject,
                topics,
                mercureTokenEvent,
                {
                    type: 'invitation_room',
                    nomInvitation: userSite?.userName,
                    topicRetour:
                        publicRuntimeConfig.LIEN_MERCURE +
                        'users/' +
                        userSite.userId,
                    receptionRoom: true
                }
            )
        );
        closePopOver && closePopOver();
    };

    const nbParticipants = participants.length;
    const nbCheckedParticipants = selectedWaitingDispatch.length;

    return (
        <WaitingListDispatchWrapper>
            <div className='text-center text-lg font-bold py-2'>
                {nbParticipants == 0
                    ? __('Aucune personne en', 'event')
                    : __s(
                          nbParticipants,
                          '1 personne en attente',
                          '{{count}} personnes en attente',
                          'event'
                      )}
            </div>
            <SimpleBar className='scrollable-area'>
                {participants.map((item: IApp.IParticipantEvent) => {
                    return (
                        <WaitingListDispatchItem
                            userName={item.userName}
                            userId={item.userId}
                            topic={item.topic}
                            key={item.userId}
                            closePopOver={closePopOver}
                        />
                    );
                })}
            </SimpleBar>
            {nbCheckedParticipants != 0 && (
                <WaitingListDispatchBottomSection className='p-2'>
                    <Button
                        variant='action'
                        fullWidth
                        onClick={inviteRoomTemporaire}
                    >
                        {__s(
                            nbCheckedParticipants,
                            'Accueillir la personne sélectionnée',
                            'Accueillir les {{count}} sélectionnées',
                            'event'
                        )}
                    </Button>
                </WaitingListDispatchBottomSection>
            )}
        </WaitingListDispatchWrapper>
    );
};

export default WaitingListDispatch;
