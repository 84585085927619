import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
import { APIEvents } from '@api/eventApi';
const { publicRuntimeConfig } = getConfig();

const deleteMsg = (
    msgId: string,
    mercureTokenEvent: string,
    roomId: string | null
) => () => {
    const data = {
        msgId: msgId,
        event: 'DELETE_MSG',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + roomId,
        data
    );
};

const hideMsg = (
    msgId: string,
    mercureTokenEvent: string,
    roomId: string | null
) => () => {
    const data = {
        msgId: msgId,
        event: 'HIDE_MSG',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + roomId,
        data
    );
};

const unHideMsg = (
    msgId: string,
    mercureTokenEvent: string,
    roomId: string | null
) => () => {
    const data = {
        msgId: msgId,
        event: 'UNHIDE_MSG',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + roomId,
        data
    );
};

const muteUser = (
    userId: string,
    mercureTokenEvent: string,
    roomId: string | null
) => () => {
    const data = {
        userId: userId,
        event: 'MUTE_USER',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + roomId,
        data
    );
};

const unMuteUser = (
    userId: string,
    mercureTokenEvent: string,
    roomId: string | null
) => () => {
    const data = {
        userId: userId,
        event: 'UNMUTE_USER',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + roomId,
        data
    );
};

const kickUser = (
    userId: string,
    mercureTokenEvent: string,
    roomId: string | null
) => () => {
    const data = {
        userId: userId,
        event: 'KICK_USER',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_SITE + roomId,
        data
    );
};

const banEmail = (
    infosEvent: IEvent | null,
    ctoken: string | null,
    userId: string | null,
    sessionId: string,
    callObject: any
) => () => {
    if (ctoken && infosEvent && userId) {
        const email = infosEvent?.participants?.filter(
            item => `${item.userId}` == `${userId}`
        )[0]?.email;
        if (email)
            APIEvents.banEmail(ctoken, infosEvent?.roomId, email).then(() => {
                if (callObject) {
                    callObject.updateParticipant(sessionId, {
                        eject: true
                    });
                }
            });
    }
};

const banUser = (
    mercureTokenEvent: string,
    infosEvent: IEvent | null,
    ctoken: string | null,
    userId: string | null,
    sessionId: string,
    callObject: any
) => (dispatch: any) => {
    const data = {
        userId: userId,
        event: 'BAN_USER',
        type: 'tchat_event'
    };
    APIMercure.sendNotifUsers(
        mercureTokenEvent,
        publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
        data
    ).then(() => {
        dispatch(banEmail(infosEvent, ctoken, userId, sessionId, callObject));
    });
};

export const ChatActions = {
    deleteMsg: deleteMsg,
    hideMsg: hideMsg,
    unHideMsg: unHideMsg,
    muteUser: muteUser,
    kickUser: kickUser,
    banEmail: banEmail,
    banUser: banUser,
    unMuteUser: unMuteUser
};
