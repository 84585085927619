import styled from 'styled-components';

export const SwitchCont = styled.div`
    display: inline-flex;
    cursor: pointer;
    height: 24px;
    vertical-align: middle;
    margin-bottom: 0;
    outline: none;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
`;

export const SwitchToggleCont = styled.span`
    display: inline-block;
    position: relative;
    height: 24px;
    width: 48px;
`;

export const SwitchToggleBar = styled.span`
    position: absolute;
    height: 100%;
    width: 100%;
    background: #e3e4e8;
    border-width: 2px;
    border-style: solid;
    border-color: #c1c1c1;
    border-radius: 18px;
    transition: border-color 0.3s, background-color 0.3s;
    ${SwitchCont}.on-dark-background & {
        background: transparent;
        border-color: rgba(255, 255, 255, 0.4);
    }
    ${SwitchCont}.checked & {
        background: #03ce9c;
        border-color: #00b78a;
    }
`;

export const SwitchToggleButton = styled.span`
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 13px;
    transition: transform 0.3s, border-color 0.3s;
    width: 30px;
    height: 24px;
    z-index: 20;
    border: 2px solid #c1c1c1;
    background-color: #f0f2f5;
    .checked & {
        transform: translateX(18px);
        border-color: #00b78a;
    }
`;

export const SwitchLabel = styled.span`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;
