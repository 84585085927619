import styled from 'styled-components';
import { RoomCardWrapper } from './RoomCard/RoomCard.css';
import { bp } from '@components/_struct/variables';

export const ListingRoom = styled.div`
    max-width: 1140px;
    margin: 0 auto;
    ${RoomCardWrapper} + ${RoomCardWrapper} {
        margin-top: 20px;
    }
    &.single-col {
        max-width: 560px;
    }
`;

export const ListingRoomCols = styled.div`
    display: flex;
    @media (${bp.lg}) {
        flex-direction: column;
    }
    > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    > div + div {
        margin-left: 20px;
        @media (${bp.lg}) {
            margin-left: 0;
            margin-top: 20px;
        }
    }
`;
