import React from 'react';
import { useMediaQuery } from 'react-responsive';
import NoSSR from '../NoSSR';

export const Desktop: React.FC = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? <NoSSR>{children}</NoSSR> : null;
};

export const Tablet: React.FC = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 768 });
    return isTablet ? <NoSSR>{children}</NoSSR> : null;
};

export const Mobile: React.FC = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 640 });
    return isMobile ? <NoSSR>{children}</NoSSR> : null;
};

export const Default: React.FC = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 769 });
    return isNotMobile ? <NoSSR>{children}</NoSSR> : null;
};
