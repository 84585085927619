import React from 'react';
import styled from 'styled-components';

export const BadgeWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const BadgeCount = styled.sup<Pick<IBadge, 'variant'>>`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    line-height: 15px;
    white-space: nowrap;
    text-align: center;
    z-index: auto;
    min-width: 20px;
    height: 16px;
    border-radius: 3px;
    padding: 0 6px;
    background: ${p =>
        (p.color && p.color) ||
        (p.variant == 'orange' && '#FF8D27') ||
        (!p.variant && !p.color && '#FF8D27')};
`;

interface IBadge {
    count: number;
    color?: string;
    showZero?: boolean;
    maxCount?: number;
    variant?: 'orange';
}

const Badge: React.FC<IBadge> = props => {
    const { children, count, maxCount, showZero, variant, color } = props;

    const number = maxCount && count > maxCount ? `${maxCount}+` : count;

    return (
        <BadgeWrapper>
            {children}
            {count == 0 && !showZero ? null : (
                <BadgeCount color={color} variant={variant}>
                    {number}
                </BadgeCount>
            )}
        </BadgeWrapper>
    );
};

export default Badge;
