import styled from 'styled-components';
import { IUserAvatar } from './UserAvatar';
import { ICompanyAvatar } from './CompanyAvatar';

export const UserAvatarCont = styled.div<Pick<IUserAvatar, 'size'>>`
    width: ${p => (p.size ? p.size + 'px' : '32px')};
    height: ${p => (p.size ? p.size + 'px' : '32px')};
`;

export const UserAvatarInner = styled.div<Pick<IUserAvatar, 'size'>>`
    border-radius: 50%;
    background: #c8c8c8;
    font-size: ${p => (p.size ? `calc(${p.size}px/3)` : '15px')};
    line-height: ${p => (p.size ? p.size + 'px' : '32px')};
    text-align: center;
    color: #fff;
    font-weight: bold;
    img {
        width: 100%;
        display: block;
        border-radius: 50%;
    }
`;

export const CompanyAvatarCont = styled.div<Pick<ICompanyAvatar, 'size'>>`
    width: ${p => (p.size ? p.size + 'px' : '32px')};
    height: ${p => (p.size ? p.size + 'px' : '32px')};
`;

export const CompanyAvatarInner = styled.div<Pick<ICompanyAvatar, 'size'>>`
    border-radius: 3px;
    background: #c8c8c8;
    height: ${p => (p.size ? p.size + 'px' : '32px')};
    width: ${p => (p.size ? p.size + 'px' : '32px')};
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        display: block;
        border-radius: 3px;
    }
    svg {
        display: block;
        height: 60%;
    }
`;
