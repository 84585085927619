import { useState, useEffect, useCallback } from 'react';

export const useWindowSize = () => {
    const hasWindow = typeof window !== 'undefined';

    const getWindowDimensions = useCallback(() => {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return [height, width];
    }, [hasWindow]);

    const [size, setSize] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setSize(getWindowDimensions());
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getWindowDimensions]);
    return size;
};
