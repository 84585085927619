import { ACTION_TYPES } from '@actions/actions_types';

const setConfigDevicesStep = (statut: boolean) => ({
    payload: { configDevicesStep: statut },
    type: ACTION_TYPES.Config.setConfigDevicesStep
});

const setDevices = (devices: any) => ({
    payload: { devices: devices },
    type: ACTION_TYPES.Config.setDevices
});

const setNeedReception = (status: boolean) => ({
    payload: { needReception: status },
    type: ACTION_TYPES.Config.setNeedReception
});

export const ConfigActions = {
    setConfigDevicesStep: setConfigDevicesStep,
    setDevices: setDevices,
    setNeedReception: setNeedReception
};
