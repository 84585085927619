import React from 'react';
import {
    ModalLayoutHeader,
    ModalLayoutTitle,
    ModalLayoutHeading,
    ModalLayoutClose,
    ConfirmLayoutWrapper,
    ConfirmLayoutFooter
} from './ModalLayout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModalContext } from '@components/_common/Modal/Modal';
import { colors } from '@components/_struct/variables';
import Button from '@components/_common/Button';

interface IConfirmLayout {
    title: string;
    onSubmit: () => void;
    onCancel?: () => void;
}

const ConfirmLayout: React.FC<IConfirmLayout> = props => {
    const { title, onSubmit, onCancel } = props;
    const { handleClose } = useModalContext();

    const handleSubmit = () => {
        onSubmit();
        handleClose && handleClose();
    };

    const handleCloseAndCancel = () => {
        onCancel && onCancel();
        handleClose && handleClose();
    };

    return (
        <ConfirmLayoutWrapper>
            <ModalLayoutHeader>
                <ModalLayoutHeading>
                    <ModalLayoutTitle>
                        <FontAwesomeIcon
                            icon={['fas', 'exclamation-triangle']}
                            className='mr-1'
                            style={{ color: colors.error }}
                        />
                        {title}
                    </ModalLayoutTitle>
                </ModalLayoutHeading>
                <ModalLayoutClose onClick={handleCloseAndCancel}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                    <span className='sr-only'>Fermer</span>
                </ModalLayoutClose>
            </ModalLayoutHeader>
            <div className='px-2 md:px-3'>{props.children}</div>
            <ConfirmLayoutFooter>
                <Button variant='clear' onClick={handleCloseAndCancel}>
                    Annuler
                </Button>
                <Button variant='action' onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ConfirmLayoutFooter>
        </ConfirmLayoutWrapper>
    );
};

export default ConfirmLayout;
