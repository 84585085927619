import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors, background, bp } from '@components/_struct/variables';
import { useMSTranslation } from '@utils/useMSTranslation';
import { Container } from '@components/_struct/_layouts/Grid';
import Button from '@components/_common/Button';
import Logo from '@components/_common/Logo';

const SectionMasked = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 5;
`;

const SectionBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${background.darkblue};
    background-image: url('/images/home_01_lines_bg.png');
    background-repeat: no-repeat;
    background-position: bottom left;
`;

const SectionContainer = styled.div`
    position: relative;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 140px;
    padding-bottom: 180px;
    @media (${bp.md}) {
        padding-top: 35px;
        padding-bottom: 130px;
    }
`;

const UnderlineGreen = styled.span`
    position: relative;
    &::before {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: ${colors.success};
    }
`;

const SectionBackgroundEnd = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    svg {
        fill: #fff;
        width: calc(292% + 1.3px);
        height: 150px;
        transform: rotateY(180deg);
    }
`;

const BgDots = styled.div`
    position: relative;
    z-index: 0;
    &::before {
        display: block;
        width: 110px;
        height: 135px;
        content: '';
        background-image: url('/images/home_01_dots.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        position: absolute;
        bottom: -19px;
        left: -60px;
        z-index: -1;
    }
`;

const HeroTitle = styled.h3`
    font-size: 60px;
    @media (${bp.lg}) {
        font-size: 38px;
    }
`;

const StickyBar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.25s ease-in-out;
    &.visible {
        transform: translateY(0);
        opacity: 1;
    }
`;

const LogoCont = styled.div`
    width: 173px;
`;

const StickyButtons = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 20;
`;

const HeroSection = () => {
    const { __ } = useMSTranslation(['home', 'struct']);
    const [showTopbar, setShowTopbar] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const cachedRef = ref.current;

        const observer = new IntersectionObserver(
            ([e]) => setShowTopbar(!e.isIntersecting),
            { rootMargin: '0px', threshold: 0 }
        );

        if (cachedRef) observer.observe(cachedRef);

        return function () {
            if (cachedRef) observer.unobserve(cachedRef);
        };
    }, [ref]);

    const showTopBarClassName = showTopbar ? 'visible' : '';

    return (
        <SectionMasked>
            <SectionBackground>
                <SectionBackgroundEnd>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 1200 120'
                        preserveAspectRatio='none'
                    >
                        <path d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'></path>
                    </svg>
                </SectionBackgroundEnd>
            </SectionBackground>
            <SectionContainer className='text-white'>
                <Container>
                    <div className='flex -mx-1'>
                        <BgDots className='px-1 w-full lg:w-6/12'>
                            <HeroTitle className='leading-tight'>
                                {__(
                                    'La plateforme qui transforme votre',
                                    'home'
                                )}{' '}
                                <span className='text-success'>
                                    {__('évènement', 'home')}
                                </span>
                                <br />
                                {__('du début à la fin !', 'home')}
                            </HeroTitle>
                            <div className='text-2xl mt-2 leading-normal'>
                                {__(
                                    'Créez votre prochain évènement en quelques minutes',
                                    'home'
                                )}{' '}
                                <UnderlineGreen>
                                    {__('gratuitement', 'home')}
                                </UnderlineGreen>
                            </div>
                            <div className='mt-4 md:flex hidden' ref={ref}>
                                <div className='mr-2'>
                                    <Button
                                        variant='action'
                                        size='large'
                                        href='#form'
                                    >
                                        {__('Créer un évènement', 'home')}
                                    </Button>
                                </div>
                                <div className='mr-2'>
                                    <Button
                                        variant='normal-white'
                                        size='large'
                                        href='/p/demo'
                                    >
                                        {__('Demandez une démo', 'home')}
                                    </Button>
                                </div>
                            </div>
                        </BgDots>
                        <div className='px-1 w-full lg:w-6/12 hidden lg:block'>
                            <img src='/images/home_01.svg' />
                        </div>
                    </div>
                </Container>
                {/* Stickybar desktop */}
                <StickyBar
                    className={`bg-darkblue ${showTopBarClassName} hidden md:block px-1`}
                >
                    <Container>
                        <div className='flex items-center py-2'>
                            <LogoCont>
                                <Logo variant='white' />
                            </LogoCont>
                            <div className='ml-auto flex'>
                                <div>
                                    <Button
                                        variant='action'
                                        size='large'
                                        href='#form'
                                    >
                                        {__('Créer un évènement', 'home')}
                                    </Button>
                                </div>
                                <div className='ml-1'>
                                    <Button
                                        variant='normal-white'
                                        size='large'
                                        href='/p/demo'
                                    >
                                        {__('Demandez une démo', 'home')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </StickyBar>
                {/* Sticky button mobile */}
                <StickyButtons className='bg-white p-1 md:hidden'>
                    <div>
                        <Button variant='action' fullWidth href='#form'>
                            {__('Créer un évènement', 'home')}
                        </Button>
                    </div>
                    <div className='mt-1'>
                        <Button variant='outline' fullWidth href='/p/demo'>
                            {__('Demandez une démo', 'home')}
                        </Button>
                    </div>
                </StickyButtons>
            </SectionContainer>
        </SectionMasked>
    );
};

export default HeroSection;
