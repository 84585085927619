import React from 'react';
import { colors, bp } from '@components/_struct/variables';
import styled from 'styled-components';
import Button from '@components/_common/Button';
import { useMSTranslation } from '@utils/useMSTranslation';

const Picto = styled.img`
    max-width: 100%;
    display: block;
    margin: auto;
    max-height: 100%;
`;

const MainTitle = styled.h1`
    font-size: 50px;
    font-weight: bold;
    color: ${colors.main};
    margin-bottom: 20px;
    @media (${bp.md}) {
        text-align: center;
        font-size: 24px;
    }
`;

const LayoutWrapper = styled.div`
    display: flex;
    height: 100%;
    position: relative;
    padding: 20px;
    background: #f0f1f5;
    @media (${bp.sm}) {
        justify-content: start;
        height: 100%;
        padding: 40px 20px;
        display: block;
    }
`;

interface ITheEndProps {
    nonCompatible?: boolean;
}

const TheEnd = ({ nonCompatible = false }: ITheEndProps) => {
    const { __ } = useMSTranslation(['event', 'struct']);

    return (
        <LayoutWrapper>
            <div className='md:w-1/2 flex'>
                <div className='m-auto w-full lg:w-3/4'>
                    <MainTitle>
                        {nonCompatible
                            ? __(
                                  "Votre navigateur n'est pas compatible avec Viseet !",
                                  'event'
                              )
                            : __('Evénement terminé', 'event')}
                    </MainTitle>
                    <div className='text-lg lg:text-xl opacity-50 leading-normal text-center md:text-left'>
                        {nonCompatible
                            ? __(
                                  'Pour votre propre confort et votre sécurité, nous vous invitons à télécharger Google Chrome ou Mozilla Firefox.',
                                  'event'
                              )
                            : __(
                                  "Merci d'avoir participé à notre évènement, il est maintenant clôturé.",
                                  'event'
                              )}
                    </div>
                    {!nonCompatible && (
                        <div className='mt-2 md:mt-4 text-center md:text-left'>
                            <Button
                                variant='action'
                                onClick={() => window.location.reload()}
                            >
                                {__('Retourner à la page d’accueil', 'struct')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className='md:w-1/2 mt-3 md:mt-0 flex'>
                <Picto src='/images/dead-cat.svg' alt='' />
            </div>
        </LayoutWrapper>
    );
};

export default TheEnd;
