import { MultiValItem } from './MultiVal.css';

// Last update : 22/05/2020

interface IMultiVal {
    label: string;
    onDelete: () => void;
}

export const MultiVal = (props: IMultiVal) => {
    const { label, onDelete } = props;

    return (
        <MultiValItem>
            <button
                className='multival-delete'
                onClick={onDelete}
                type='button'
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='far'
                    data-icon='times-circle'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                >
                    <path
                        fill='currentColor'
                        d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z'
                    ></path>
                </svg>
            </button>
            <span className='multival-label'>{label}</span>
        </MultiValItem>
    );
};
