import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { ChkCont } from './CheckboxGroup.css';
import { useField, FormikHandlers } from 'formik';

interface CheckboxGroup {
    items: {
        label: string;
        value: string;
    }[];
    name: string;
    /** Sens de positionnement des checkboxes */
    direction?: 'horizontal' | 'vertical';
    /** largeur des enfants si nécessaire... */
    widthChildrens?: string;
    onChange?: FormikHandlers['handleChange'];
}

const CheckboxGroup = (props: CheckboxGroup) => {
    const [field, meta, helpers] = useField(props);
    const { items, direction, widthChildrens, onChange } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedCheckbox = e.target.value;
        let selectedCheckboxes: string[] = Array.isArray(field.value)
            ? field.value.slice()
            : [];

        if (
            e.target.checked &&
            selectedCheckboxes.indexOf(selectedCheckbox) === -1
        ) {
            selectedCheckboxes.push(selectedCheckbox);
        } else if (!e.target.checked) {
            selectedCheckboxes = selectedCheckboxes.filter(
                chk => chk != selectedCheckbox
            );
        }
        helpers.setValue(selectedCheckboxes);
        onChange && onChange(selectedCheckboxes);
    };

    const directionClass =
        direction == 'horizontal' ? 'checkboxes-horizontal' : '';

    const errorClass = meta.error && meta.touched ? 'haserror' : '';

    return (
        <ChkCont className={`${directionClass} ${errorClass}`}>
            {items.map((item, index) => (
                <Checkbox
                    key={index}
                    label={item.label}
                    value={item.value}
                    id={item.value}
                    checked={field.value.indexOf(item.value) !== -1}
                    onChange={handleChange}
                    name={field.name}
                    widthChildrens={widthChildrens}
                />
            ))}
        </ChkCont>
    );
};

export default React.memo(CheckboxGroup);
