import styled from 'styled-components';
import { colors, bp } from '@components/_struct/variables';

export const ModalLayoutWrapper = styled.div`
    border-radius: 12px;
    background: #ffffff;
    min-width: 500px;
`;

export const ModalLayoutHeader = styled.div`
    padding: 35px;
    display: flex;
    @media (${bp.md}) {
        padding: 20px;
    }
`;

export const ModalLayoutHeading = styled.div`
    flex: 1;
`;

export const ModalLayoutTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
`;

export const ModalLayoutClose = styled.button`
    background: inherit;
    border: 0 none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 24px;
    color: ${colors.main};
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    &:hover {
        background: #e3e4e8;
    }
`;

export const ConfirmLayoutWrapper = styled.div`
    border-radius: 12px;
    background: #ffffff;
    width: 700px;
    @media (${bp.md}) {
        width: 90%;
        margin: 0 auto;
    }
`;

export const ModalLayoutContent = styled.div`
    padding: 35px;
`;

export const ModalLayoutFooter = styled.div`
    padding: 35px;
    display: flex;
`;

export const ConfirmLayoutFooter = styled.div`
    padding: 30px;
    text-align: right;
    @media (${bp.md}) {
        padding: 20px;
        text-align: center;
    }
    button + button {
        margin-left: 10px;
    }
`;
