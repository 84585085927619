import JoinController from '@components/Join/JoinController';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { useRouter } from 'next/router';
import EventController from '@components/Event/EventController';
import ConfigStep from '@components/Join/ConfigStep/ConfigStep';
import Home from '@components/Home';
import TheEnd from '@components/Event/TheEnd';
import React, { useEffect, useCallback } from 'react';
import Head from 'next/head';
import { useMSTranslation } from '@utils/useMSTranslation';

const Index = () => {
    const { __ } = useMSTranslation(['struct']);
    const router = useRouter();
    // Utilisateur connecté
    const userSite = useSelector((state: IStore) => state.Auth.user);
    // Configuration déjà effectuée?
    const configDevicesStep = useSelector(
        (state: IStore) => state.Config.configDevicesStep
    );

    const resizeDoc = useCallback(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    useEffect(() => {
        if (typeof window != 'undefined') {
            resizeDoc();
            window.addEventListener('resize', resizeDoc);
        }
        return () => {
            window.removeEventListener('resize', resizeDoc);
        };
    }, [resizeDoc]);

    // @ts-ignore
    const isIE = process.browser && !!document.documentMode;

    const head = (
        <Head>
            <title>Viseet - {__('transforme ton événement', 'struct')}</title>
        </Head>
    );

    if (isIE)
        return (
            <>
                {head}
                <TheEnd nonCompatible={true} />
            </>
        );
    else if (router.query.id === undefined)
        return (
            <>
                {head}
                <Home />
            </>
        );
    else if (userSite === null)
        return (
            <>
                {head}
                <JoinController />
            </>
        );
    else if (!configDevicesStep)
        return (
            <>
                {head}
                <ConfigStep />
            </>
        );
    else
        return (
            <>
                {head}
                <EventController />
            </>
        );
};

Index.getInitialProps = async () => {
    return {
        namespacesRequired: ['struct']
    };
};

export default Index;
