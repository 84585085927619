import styled from 'styled-components';

export const WaitingListDispatchWrapper = styled.div`
    border-radius: 12px;
    background: #0a0e27;
    color: #fff;
    display: flex;
    flex-direction: column;
    max-height: 600px;
    .scrollable-area {
        flex: 1;
        min-height: 0;
        position: relative;
        margin-bottom: 15px;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.25);
        }
    }
`;

export const WaitingListDispatchBottomSection = styled.div`
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 12px 12px;
`;

// Item

export const WaitingListDispatchItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 20px;
    height: 48px;
    cursor: pointer;
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const DispatchItemAvatarCont = styled.div`
    margin-right: 10px;
    position: relative;
    user-select: none;
    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 30px;
        box-shadow: 0 0 0 2px #ffe665;
        opacity: 0;
    }
`;

export const DispatchItemName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1;
    padding-right: 1rem;
    user-select: none;
    align-self: stretch;
    display: flex;
    align-items: center;
    &.is-clickable {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`;
