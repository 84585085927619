import EventLayout from '@components/Event/EventLayout';
import SelectRoom from '@components/Event/SelectRoom/SelectRoom';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CallActions } from '@actions/call_actions';
import {
    MEETING_STATE_ERROR,
    MEETING_STATE_IDLE,
    MEETING_STATE_JOINED,
    MEETING_STATE_JOINING
} from '@constants';
import { clearLocalStorage, setLocalStorage } from '@utils/localStorage';
import { UIActions } from '@actions/ui_actions';
import { ErrorsActions } from '@actions/errors_actions';
import Room from '@components/Event/Room/Room';
import { DataActions } from '@actions/data_actions';
import { useModal } from '@components/_common/Modal/Modal';
import Confirm from '@components/_common/Modal/Confirm';
import getConfig from 'next/config';
import LoadingRoom from '@components/Event/LoadingRoom';
import TheEnd from './TheEnd';
import { IApp } from '@interfaces/pages/_app';
import WaitingReception from './WaitingReception';
import { displayError, displayEvent } from '@components/_common/Toast';
import { useRouter } from 'next/router';
import { useMercureEventSource } from '@hooks/useMercureEventSource';
import { useMercureUpdateParticipants } from '@hooks/useMercureUpdateParticipants';
import { APIMercure } from '@api/mercureApi';
import { useMSTranslation } from '@utils/useMSTranslation';
import { APIEvents } from '@api/eventApi';
import { fromUnixTime, getTime } from 'date-fns';
import TheEndSurvey from './TheEndSurvey';

const { publicRuntimeConfig } = getConfig();

const EventController = () => {
    const { __ } = useMSTranslation(['event', 'struct']);

    const callObject = useSelector((state: IStore) => state.call.callObject);
    const dispatch = useDispatch();
    const roomId = useSelector((state: IStore) => state.call.roomId);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const fields = useSelector((state: IStore) => state.Auth.fiche);
    const needReception = useSelector(
        (state: IStore) => state.Config.needReception
    );
    const meetingState = useSelector(
        (state: IStore) => state.call.meetingState
    );
    const loading = useSelector((state: IStore) => state.Loading.actionLoading);
    const waitingRoomId = useSelector((state: IStore) => state.UI.waitingRoom);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);

    const [visibleConfirm, closeConfirm, openConfirm] = useModal();
    const [
        visibleConfirmWithCam,
        handleCloseConfirmWithCam,
        openConfirmWithCam
    ] = useModal();
    const [
        visibleConfirmWithMic,
        handleCloseConfirmWithMic,
        openConfirmWithMic
    ] = useModal();

    const [nomInvitation, setNomInvitation] = useState('');
    const [roomIdInvitation, setRoomIdInvitation] = useState('');
    const [theEnd, setTheEnd] = useState(false);

    const JWTtokenMercureLocal = useRef('');
    const lastTopicInvitation = useRef('');
    const noConcurrentPresenceCall = useRef(false);
    const noConcurrentPresenceCallWaiting = useRef(false);

    const router = useRouter();

    const withCam = useCallback(() => {
        callObject.setLocalVideo(true);
    }, [callObject]);

    const withMic = useCallback(() => {
        callObject.setLocalAudio(true);
    }, [callObject]);

    const handleRemoveParticipantConnectedEvent = useCallback(
        (userId: string) => {
            dispatch(DataActions.removeParticipantConnectedEvent(userId));
        },
        [dispatch]
    );

    const handleAddParticipantConnectedEvent = useCallback(
        (newParticipant: IApp.IParticipantEvent) => {
            dispatch(DataActions.addParticipantConnectedEvent(newParticipant));
        },
        [dispatch]
    );

    const handleRemoveParticipantReception = useCallback(
        (userId: string) => {
            dispatch(DataActions.removeParticipantReception(userId));
        },
        [dispatch]
    );

    const handleAddParticipantReception = useCallback(
        (newParticipant: IApp.IParticipantEvent) => {
            dispatch(DataActions.addParticipantReception(newParticipant));
        },
        [dispatch]
    );

    const updateListParticipants = useMercureUpdateParticipants(
        handleRemoveParticipantConnectedEvent,
        handleAddParticipantConnectedEvent
    );

    const updateListParticipantsReception = useMercureUpdateParticipants(
        handleRemoveParticipantReception,
        handleAddParticipantReception
    );

    //Mettre fin a l'Event si c une reponse sondage.
    useEffect(() => {
        if (
            router.query.sondage != undefined &&
            infosEvent?.sondage &&
            userSite.didAnswerSurvey == false &&
            getTime(infosEvent.dateEnd) < getTime(new Date())
        ) {
            setTheEnd(true);
        }
    }, [router.query.sondage, infosEvent, userSite.didAnswerSurvey]);

    // Mise à jour du nombre de participants dans les rooms toutes les 60s par sécurité
    useEffect(() => {
        const interval = setInterval(() => {
            if (
                infosEvent &&
                !needReception &&
                userSite &&
                ctoken &&
                !noConcurrentPresenceCall.current
            ) {
                noConcurrentPresenceCall.current = true;
                APIMercure.getParticipantsRoomV2(
                    ctoken,
                    JWTtokenMercureLocal.current,
                    infosEvent.rooms.map(
                        room => publicRuntimeConfig.LIEN_SITE + room.roomId
                    )
                ).then(r => {
                    for (const topicSub in r.data.subscriptions) {
                        let nbSub = 0;
                        const tabDeja: string[] = [];
                        r.data.subscriptions[topicSub].forEach((item: any) => {
                            if (!tabDeja.includes(item.payload.topic)) {
                                tabDeja.push(item.payload.topic);
                                nbSub++;
                            }
                        });
                        dispatch(
                            DataActions.setNbParticipantsRoom(
                                topicSub.replace(
                                    publicRuntimeConfig.LIEN_SITE,
                                    ''
                                ),
                                nbSub
                            )
                        );
                    }
                    noConcurrentPresenceCall.current = false;
                });
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [ctoken, dispatch, infosEvent, needReception, userSite]);

    // Lorsqu'on recoit un message
    const handleMessage = useCallback(
        (data: IMessageMercure) => {
            if (!infosEvent || !userSite) return;

            // Si c'est une connexion sur l'une des rooms, on met à jour le nb de participants de cette dernière
            infosEvent.rooms.forEach(room => {
                if (
                    data.topic ===
                    publicRuntimeConfig.LIEN_SITE + room.roomId
                ) {
                    //On met à jour le nb de participants de la room
                    if (ctoken && !noConcurrentPresenceCall.current) {
                        noConcurrentPresenceCall.current = true;
                        APIMercure.getParticipantsRoomV2(
                            ctoken,
                            JWTtokenMercureLocal.current,
                            [data.topic]
                        ).then(r => {
                            for (const topicSub in r.data.subscriptions) {
                                let nbSub = 0;
                                const tabDeja: string[] = [];
                                r.data.subscriptions[topicSub].forEach(
                                    (item: any) => {
                                        if (
                                            !tabDeja.includes(
                                                item.payload.topic
                                            )
                                        ) {
                                            tabDeja.push(item.payload.topic);
                                            nbSub++;
                                        }
                                    }
                                );
                                dispatch(
                                    DataActions.setNbParticipantsRoom(
                                        room.roomId,
                                        nbSub
                                    )
                                );
                            }
                            noConcurrentPresenceCall.current = false;
                        });
                    }
                    return true;
                }
            });

            // On compte le nombre de participants en attente

            infosEvent.rooms.forEach(room => {
                if (
                    data.topic ===
                    publicRuntimeConfig.LIEN_SITE + room.roomId + 'waitinglist'
                ) {
                    //On met à jour le nb de participants en attente de la room
                    if (ctoken && !noConcurrentPresenceCallWaiting.current) {
                        noConcurrentPresenceCallWaiting.current = true;
                        APIMercure.getParticipantsRoomV2(
                            ctoken,
                            JWTtokenMercureLocal.current,
                            [data.topic]
                        ).then(r => {
                            for (const topicSub in r.data.subscriptions) {
                                let nbSub = 0;
                                const tabDeja: string[] = [];
                                r.data.subscriptions[topicSub].forEach(
                                    (item: any) => {
                                        if (
                                            !tabDeja.includes(
                                                item.payload.topic
                                            )
                                        ) {
                                            tabDeja.push(item.payload.topic);
                                            nbSub++;
                                        }
                                    }
                                );
                                dispatch(
                                    DataActions.setNbParticipantsWaitingRoom(
                                        room.roomId,
                                        nbSub
                                    )
                                );
                            }
                            noConcurrentPresenceCallWaiting.current = false;
                        });
                    }
                }
                return true;
            });

            // Si c'est un évènement sur le topic de l'event, on met à jour nos participants (sauf si c'est nous même)
            if (
                data.topic ===
                    publicRuntimeConfig.LIEN_SITE + infosEvent.roomId &&
                publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId !=
                    data.payload.topic
            ) {
                updateListParticipants(data);
            }

            // Si c'est une connexion à la salle d'attente
            else if (
                data.topic ===
                publicRuntimeConfig.LIEN_SITE + infosEvent.roomId + '-reception'
            ) {
                updateListParticipantsReception(data);
            }

            //-----

            // Initiation d'une prise en charge room d'attente
            else if (data.type === 'invitation_room_initiated') {
                if (data.userId) {
                    dispatch(
                        DataActions.initiateParticipantReception(data.userId)
                    );
                    // Si il était coché dans la waitinglist, on le décoche
                    dispatch(
                        UIActions.removeSelectedWaitingDispatch(data.userId)
                    );
                }
            }

            // Refus d'initiation d'une prise en charge room d'attente
            else if (data.type === 'invitation_room_already_initiated') {
                displayError(
                    __(
                        '{{nom}} est déjà pris en charge par un autre intervenant',
                        'event',
                        { nom: data.nomInvitation }
                    )
                );
            }

            // Refus d'invitation dans une room privée
            else if (data.type === 'invitation_room_refus') {
                displayError(
                    __(
                        '{{nom}} a refusé votre invitation dans la room privée',
                        'event',
                        { nom: data.nomInvitation }
                    )
                );
            }

            // Si c'est une invitation à une room privée
            else if (data.type === 'invitation_room') {
                if (data.nomInvitation && data.roomId) {
                    setNomInvitation(data.nomInvitation);
                    setRoomIdInvitation(data.roomId);
                    if (data.receptionRoom) {
                        dispatch(CallActions.setReceptionRoom(true));
                        // On rejoint la salle
                        meetingToken &&
                            dispatch(
                                CallActions.joinRoom(
                                    meetingToken,
                                    data.roomId,
                                    callObject,
                                    true
                                )
                            );
                    } else {
                        if (data.topicRetour)
                            lastTopicInvitation.current = data.topicRetour;
                        openConfirm();
                    }
                }
            }
            // Un intervenant demande sa fiche à un utilisateur
            else if (data.type === 'ask_for_fiche') {
                dispatch(
                    DataActions.sendFiche(
                        data.userId,
                        JWTtokenMercureLocal.current,
                        {
                            username: userSite.userName,
                            fields: fields
                        }
                    )
                );
            }
            // Un utilisateur renvoie sa fiche à un intervenant
            else if (data.type === 'send_fiche') {
                dispatch(UIActions.setDrawerCandidatData(data.fiche));
            } else if (data.type === 'call_event')
                switch (data.event) {
                    case 'CAM_TOGGLE':
                        if (data.value === true) {
                            openConfirmWithCam();
                        }
                        if (data.value === false) {
                            callObject.setLocalVideo(false);
                        }
                        break;
                    case 'MIC_TOGGLE':
                        if (data.value === true) {
                            openConfirmWithMic();
                        }
                        if (data.value === false) {
                            callObject.setLocalAudio(false);
                        }
                        break;
                    default:
                        break;
                }
            else if (data.type === 'tchat_event')
                switch (data.event) {
                    case 'BAN_USER':
                        if (userSite.userId === data.userId) {
                            clearLocalStorage();
                            setLocalStorage(infosEvent?.roomId + '_isBanned', {
                                eventId: infosEvent?.roomId,
                                value: true
                            });
                            displayError(
                                __(
                                    'Vous avez été banni de cet événement !',
                                    'event'
                                )
                            );
                            router.push('/' + infosEvent?.roomId);
                        }
                        break;
                }
            //------
            // Si c'est une fermeture de l'event
            else if (data.type == 'close_event') {
                setTheEnd(true);
            }
            // Si c'est une fermeture de l'event
            else if (data.type == 'updateEventInfos') {
                if (ctoken)
                    APIEvents.getEventInfo(ctoken, infosEvent.roomId).then(
                        (r: any) => {
                            r.data.dateStart = fromUnixTime(r.data.dateStart);
                            r.data.dateEnd = fromUnixTime(r.data.dateEnd);

                            if (r.data.companyAvatar == '')
                                r.data.companyAvatar = null;

                            if (r.data.cover == '' || r.data.cover == null)
                                r.data.cover =
                                    'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80';

                            dispatch(DataActions.setDataEvent(r.data));
                        }
                    );
            }
        },
        [
            infosEvent,
            userSite,
            dispatch,
            updateListParticipants,
            updateListParticipantsReception,
            __,
            meetingToken,
            callObject,
            openConfirm,
            fields,
            openConfirmWithCam,
            openConfirmWithMic,
            router,
            ctoken
        ]
    );

    // On s'abonne à mercure
    let topics: string[] = [];
    let subscribes: string[] = [];
    let payload: IPayloadUserMercure | null = null;

    if (userSite && infosEvent && !needReception) {
        // Le topic personnel de l'utilisateur
        const topicUser =
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId;

        // Les topics (event et souscription a l'event)
        topics = [
            publicRuntimeConfig.LIEN_SITE + infosEvent.roomId,
            '/.well-known/mercure/subscriptions/' +
                encodeURIComponent(
                    publicRuntimeConfig.LIEN_SITE + infosEvent.roomId
                ) +
                '{/subscriber}',
            topicUser
        ];

        const eventRoomTopics = infosEvent?.rooms?.map(
            room =>
                '/.well-known/mercure/subscriptions/' +
                encodeURIComponent(
                    publicRuntimeConfig.LIEN_SITE + room.roomId
                ) +
                '{/subscriber}'
        );

        let waitingListTopics: string[] = [];
        if (userSite.intervenant) {
            waitingListTopics = infosEvent?.rooms
                ?.filter(room => room.type === 'PRIVATE')
                .map(room => {
                    return (
                        '/.well-known/mercure/subscriptions/' +
                        encodeURIComponent(
                            publicRuntimeConfig.LIEN_SITE +
                                room.roomId +
                                'waitinglist'
                        ) +
                        '{/subscriber}'
                    );
                });
        }

        // On rajoute la souscription aux rooms (pour avoir le nombre de gens connecté)
        if (eventRoomTopics) {
            topics = [...topics, ...eventRoomTopics, ...waitingListTopics];
            subscribes = [...eventRoomTopics, ...waitingListTopics];
        }

        // Les droits de souscription
        subscribes = [
            ...subscribes,
            topicUser,
            publicRuntimeConfig.LIEN_SITE + infosEvent.roomId,
            '/.well-known/mercure/subscriptions/' +
                encodeURIComponent(
                    publicRuntimeConfig.LIEN_SITE + infosEvent.roomId
                ) +
                '{/subscriber}',
            '/.well-known/mercure/subscriptions'
        ];

        // Si on est intervenant et que event avec reception, on subscribe à la reception
        if (userSite.intervenant && infosEvent?.receptionRoom) {
            subscribes = [
                ...subscribes,
                publicRuntimeConfig.LIEN_SITE + infosEvent.roomId + '-inter'
            ];
            topics = [
                ...topics,
                publicRuntimeConfig.LIEN_SITE + infosEvent.roomId + '-inter'
            ];
        }

        // Si on est intervenant on subscribe au topic spécifique intervenant
        if (userSite.intervenant) {
            subscribes = [
                ...subscribes,
                '/.well-known/mercure/subscriptions/' +
                    encodeURIComponent(
                        publicRuntimeConfig.LIEN_SITE +
                            infosEvent.roomId +
                            '-reception'
                    ) +
                    '{/subscriber}'
            ];
            topics = [
                ...topics,
                '/.well-known/mercure/subscriptions/' +
                    encodeURIComponent(
                        publicRuntimeConfig.LIEN_SITE +
                            infosEvent.roomId +
                            '-reception'
                    ) +
                    '{/subscriber}'
            ];
        }

        // Le payload
        payload = {
            userId: userSite.userId,
            userName: userSite.userName,
            intervenant: userSite.intervenant,
            topic: topicUser,
            shortToken: userSite.shortToken
        };
    }

    const {
        token: JWTtokenMercure,
        status: statusMercureES
    } = useMercureEventSource({
        topics,
        subscribes,
        payload,
        handleMessage,
        initialStatus: 'idle'
    });

    // On met a jour le token local
    JWTtokenMercureLocal.current = JWTtokenMercure;

    // Lorsqu'on se connecte pour la première fois on update notre liste de participants
    useEffect(() => {
        if (
            !infosEvent ||
            !JWTtokenMercure ||
            (!userSite?.intervenant && needReception)
        )
            return;

        if (statusMercureES === 'open:first') {
            // Les participants de l'évènement
            dispatch(
                DataActions.updateParticipantsEvent(
                    JWTtokenMercure,
                    publicRuntimeConfig.LIEN_SITE + infosEvent.roomId
                )
            );
            dispatch(DataActions.setMercureTokenEvent(JWTtokenMercure));

            // Les personnes en liste d'attente
            if (userSite?.intervenant && infosEvent.receptionRoom) {
                dispatch(
                    DataActions.updateParticipantsReception(
                        JWTtokenMercure,
                        publicRuntimeConfig.LIEN_SITE +
                            infosEvent.roomId +
                            '-reception'
                    )
                );
            }

            // Le nombre de connectés aux rooms
            if (ctoken) {
                APIMercure.getParticipantsRoomV2(
                    ctoken,
                    JWTtokenMercure,
                    infosEvent.rooms.map(
                        item => publicRuntimeConfig.LIEN_SITE + item.roomId
                    )
                ).then(r => {
                    for (const topicSub in r.data.subscriptions) {
                        let nbSub = 0;
                        const tabDeja: string[] = [];
                        r.data.subscriptions[topicSub].forEach((item: any) => {
                            if (!tabDeja.includes(item.payload.topic)) {
                                tabDeja.push(item.payload.topic);
                                nbSub++;
                            }
                        });
                        dispatch(
                            DataActions.setNbParticipantsRoom(
                                topicSub.replace(
                                    publicRuntimeConfig.LIEN_SITE,
                                    ''
                                ),
                                nbSub
                            )
                        );
                    }
                });
            }

            // Si on est intervenant, on met à jour le nombre de personnes en attente dans chaque room
            if (userSite.intervenant && ctoken) {
                APIMercure.getParticipantsRoomV2(
                    ctoken,
                    JWTtokenMercureLocal.current,
                    infosEvent.rooms
                        .filter(room => room.type === 'PRIVATE')
                        .map(
                            room =>
                                publicRuntimeConfig.LIEN_SITE +
                                room.roomId +
                                'waitinglist'
                        )
                ).then(r => {
                    for (const topicSub in r.data.subscriptions) {
                        let nbSub = 0;
                        const tabDeja: string[] = [];
                        r.data.subscriptions[topicSub].forEach((item: any) => {
                            if (!tabDeja.includes(item.payload.topic)) {
                                tabDeja.push(item.payload.topic);
                                nbSub++;
                            }
                        });
                        dispatch(
                            DataActions.setNbParticipantsWaitingRoom(
                                topicSub.replace(
                                    publicRuntimeConfig.LIEN_SITE,
                                    ''
                                ),
                                nbSub
                            )
                        );
                    }
                });
            }
        }
    }, [
        dispatch,
        updateListParticipants,
        openConfirm,
        callObject,
        openConfirmWithCam,
        openConfirmWithMic,
        router,
        meetingToken,
        updateListParticipantsReception,
        fields,
        infosEvent,
        JWTtokenMercure,
        statusMercureES,
        userSite,
        needReception,
        ctoken
    ]);

    // Gestion de la file d'attente sur une room
    let topicsWaiting: string[] = [];
    let subscribesWaiting: string[] = [];
    let payloadWaiting: IPayloadUserMercure | null = null;

    if (userSite && waitingRoomId != '') {
        topicsWaiting = [
            publicRuntimeConfig.LIEN_SITE + waitingRoomId + 'waitinglist',
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId
        ];

        subscribesWaiting = [
            publicRuntimeConfig.LIEN_SITE + waitingRoomId + 'waitinglist'
        ];

        payloadWaiting = {
            userId: userSite.userId,
            userName: userSite.userName,
            intervenant: userSite.intervenant,
            topic: publicRuntimeConfig.LIEN_MERCURE + 'users/' + userSite.userId
        };
    }

    const handleMessageWaitingRoom = useCallback(
        (data: IMessageMercure) => {
            // Si c'est ok pour join la room privée
            if (data.type == 'accept_join_room' && data.roomId) {
                if (meetingToken)
                    dispatch(
                        CallActions.joinRoom(
                            meetingToken,
                            data.roomId,
                            callObject
                        )
                    );
                dispatch(UIActions.setWaitingRoom(''));
            }

            // Si c'est un refus de join la room privé
            if (data.type == 'refuse_join_room') {
                dispatch(UIActions.setWaitingRoom(''));
                displayEvent(
                    __('Vous avez été refusé de la conférence', 'event')
                );
            }
        },
        [meetingToken, dispatch, callObject, __]
    );

    const {
        closeEvent: closeEventMercureESWaiting,
        openEvent: openEventMercureESWaiting,
        status: statusEventMercureESWaiting
    } = useMercureEventSource({
        topics: topicsWaiting,
        subscribes: subscribesWaiting,
        payload: payloadWaiting,
        handleMessage: handleMessageWaitingRoom
    });

    useEffect(() => {
        // Si on est pas en attente de room et que l'event source est open on le close
        if (
            waitingRoomId === '' &&
            (statusEventMercureESWaiting === 'open' ||
                statusEventMercureESWaiting === 'open:first')
        ) {
            closeEventMercureESWaiting();
        }
        // Si on est en attente de room et que l'event source est pas open, on l'open
        else if (
            waitingRoomId != '' &&
            statusEventMercureESWaiting === 'snooze'
        )
            openEventMercureESWaiting();
    }, [
        waitingRoomId,
        openEventMercureESWaiting,
        closeEventMercureESWaiting,
        statusEventMercureESWaiting
    ]);

    // Quand le statut du meeting change, on change notre state
    useEffect(() => {
        if (!callObject) return;

        const events = [
            'joining-meeting',
            'joined-meeting',
            'left-meeting',
            'error'
        ];

        function handleNewMeetingState() {
            switch (callObject.meetingState()) {
                case 'joining-meeting':
                    dispatch(
                        CallActions.setMeetingState(MEETING_STATE_JOINING)
                    );
                    break;
                case 'joined-meeting':
                    dispatch(CallActions.setMeetingState(MEETING_STATE_JOINED));
                    break;
                case 'left-meeting':
                    callObject.destroy().then(() => {
                        dispatch(
                            CallActions.setMeetingState(MEETING_STATE_IDLE)
                        );

                        dispatch(CallActions.setRoomId(null));

                        // On reset le state redux
                        dispatch(UIActions.resetState());
                        dispatch(CallActions.resetState());
                        dispatch(ErrorsActions.reset());
                        dispatch(ErrorsActions.resetPage());
                    });
                    break;
                case 'error':
                    CallActions.setMeetingState(MEETING_STATE_ERROR);
                    console.log('erreur de meeting');
                    //displayError('erreur de meeting');
                    break;
                default:
                    break;
            }
        }

        handleNewMeetingState();

        for (const event of events) {
            callObject.on(event, handleNewMeetingState);
        }

        return function cleanup() {
            for (const event of events) {
                callObject.off(event, handleNewMeetingState);
            }
        };
    }, [callObject, dispatch]);

    const showAppState = () => {
        if (needReception && !userSite?.intervenant && !roomId)
            return <WaitingReception />;
        else if (
            loading.indexOf('addTmpRoom') != -1 ||
            (callObject &&
                (meetingState === MEETING_STATE_IDLE ||
                    meetingState === MEETING_STATE_JOINING))
        ) {
            return <LoadingRoom />;
        } else if (!callObject) return <SelectRoom />;
        else if (roomId) return <Room />;
        else return <div className='flex-1' />;
    };

    const onSubmit = useCallback(() => {
        if (meetingToken) {
            // On rejoint la salle
            dispatch(
                CallActions.joinRoom(meetingToken, roomIdInvitation, callObject)
            );
        }
        closeConfirm();
    }, [callObject, closeConfirm, dispatch, meetingToken, roomIdInvitation]);

    const onCancelInvitation = useCallback(() => {
        // On indique à la personne qu'on a refusé son invitation
        if (lastTopicInvitation.current != '') {
            APIMercure.sendNotifUsers(
                JWTtokenMercureLocal.current,
                lastTopicInvitation.current,
                {
                    type: 'invitation_room_refus',
                    nomInvitation: userSite.userName
                }
            );
        }
        closeConfirm();
    }, [closeConfirm, userSite]);

    const TheEndPage =
        infosEvent?.sondage && userSite.intervenant == false ? (
            <TheEndSurvey />
        ) : (
            <TheEnd />
        );

    return (
        <>
            {!theEnd ? <EventLayout>{showAppState()}</EventLayout> : TheEndPage}
            <Confirm
                visible={visibleConfirm}
                onClose={closeConfirm}
                onCancel={onCancelInvitation}
                onSubmit={onSubmit}
                title={__(
                    '{{nom}} vous invite dans une salle privée',
                    'event',
                    { nom: nomInvitation }
                )}
                description={__(
                    'Souhaitez-vous le rejoindre? Vous quitterez votre salle actuelle.',
                    'event'
                )}
            />
            <Confirm
                visible={visibleConfirmWithCam}
                onClose={handleCloseConfirmWithCam}
                onSubmit={withCam}
                title={__('Reactiver votre webcam', 'event')}
                description={__(
                    'Souhaitez-vous reactiver votre webcam ?',
                    'event'
                )}
            />
            <Confirm
                visible={visibleConfirmWithMic}
                onClose={handleCloseConfirmWithMic}
                onSubmit={withMic}
                title={__('Reactiver votre micro', 'event')}
                description={__(
                    'Souhaitez-vous reactiver votre micro ?',
                    'event'
                )}
            />
        </>
    );
};

export default EventController;
