import React, { CSSProperties } from 'react';
import { TabPanelWrapper } from './Tabs.css';

export interface ITabPanel {
    tab: string;
    key: string;
    style?: CSSProperties;
    notif?: string | number;
}

export const TabPanel: React.FC<ITabPanel> = props => {
    return (
        <TabPanelWrapper style={props.style} id={props.key}>
            {props.children}
        </TabPanelWrapper>
    );
};
