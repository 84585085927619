import React, { useState, cloneElement, useRef } from 'react';
import { usePopper } from 'react-popper';
import Portal from '../Portal';
import useOnClickOutside from '@utils/useOnClickOutside';

interface IPopOver {
    /** callback à l'ouverture du popover */
    onOpen?: () => void;
    /** callback à la fermeture du popover */
    onClose?: () => void;
    /** Placement du popover par rapport au boutton */
    placement?: 'bottom-end' | 'bottom' | 'bottom-start';
    /** Bouton */
    trigger: JSX.Element;
    /** Largeur du popover */
    width?: number;
    /** espace entre la trigger et le popover */
    offset?: number;
    children: (closePopOver: () => void) => React.ReactNode;
}

const PopOver = (props: IPopOver) => {
    const [isOpen, setIsOpen] = useState(false);
    const [
        referenceElement,
        setReferenceElement
    ] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(
        null
    );
    const ContPopOver = useRef<HTMLDivElement>(null);

    const {
        onOpen,
        onClose,
        placement,
        trigger,
        children,
        width,
        offset
    } = props;

    const { styles } = usePopper(referenceElement, popperElement, {
        placement: !placement ? 'bottom-end' : placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, offset ? offset : 0]
                }
            }
        ]
    });

    const handleClose = (e: MouseEvent | TouchEvent) => {
        onClose && onClose();
        referenceElement &&
            !referenceElement.contains(e.target as Node) &&
            setIsOpen(false);
    };

    useOnClickOutside(ContPopOver, handleClose);

    const handleClick = () => {
        onOpen && onOpen();
        setIsOpen(c => !c);
    };

    const closePopOver = () => {
        setIsOpen(false);
    };

    const isOpenClassName = isOpen ? 'isopen' : '';

    return (
        <div
            ref={setReferenceElement}
            className={`inline-block ${isOpenClassName}`}
        >
            {cloneElement(trigger, { onClick: handleClick })}
            {isOpen && (
                <Portal>
                    <div ref={setPopperElement} style={styles.popper}>
                        <div
                            ref={ContPopOver}
                            style={width ? { width: width + 'px' } : undefined}
                        >
                            {typeof children == 'function'
                                ? children(closePopOver as () => void)
                                : children}
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    );
};

export default PopOver;
