import styled, { keyframes } from 'styled-components';
import { bp } from '@components/_struct/variables';

export const slideDownOut = keyframes`
    0% {
        transform:scaleY(.8) translateX(-50%);
        transform-origin:100% 100%;
        opacity:0
    }
    100% {
        transform:scaleY(1) translateX(-50%);
        transform-origin:100% 100%;
        opacity:1
    }
`;

export const slideUpOut = keyframes`
    0% {
        transform: scaleY(1) translateX(-50%);
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
        transform: scaleY(0.8) translateX(-50%);
        transform-origin: 100% 100%;
        opacity: 0;
    }
`;

export const DropdownPopoverWrap = styled.div`
    position: relative;
`;

export const DropdownPopoverCont = styled.div`
    background: #fff;
    position: absolute;
    min-width: 200px;
    border-radius: 12px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    margin-top: 3px;
    z-index: 25;
    color: #000;
    animation: ${slideDownOut} 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    &.isexiting {
        animation: ${slideUpOut} 0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    .topCenter & {
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
        margin-bottom: 15px;
        ::after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            @media (${bp.xs}) {
                display: none;
            }
        }
        ::after {
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #ffffff;
            border-width: 8px;
            margin-left: -8px;
        }
    }
    .bottomCenter & {
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        margin-top: 15px;
    }
    @media (${bp.xs}) {
        position: fixed;
    }
`;
