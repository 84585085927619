import React from 'react';
import {
    TextFieldInput,
    FormGroupCont,
    PrefixWrapper,
    SuffixWrapper
} from './TextField.css';

// Last update : 17/07/2020

interface IUBTextField {
    type?: string;
    name?: string;
    id?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    /** Variante de style */
    variant?: 'rounded' | 'inline' | 'rounded-outline-white';
    /** Contenu qui se positionne avant le champ */
    prefixElm?: JSX.Element | 'string';
    /** Contenu qui se positionne après le champ */
    suffixElm?: JSX.Element | 'string';
}

const UBTextField = React.memo((props: IUBTextField) => {
    const {
        type,
        placeholder,
        variant,
        prefixElm,
        suffixElm,
        onChange,
        ...rest
    } = props;

    UBTextField.displayName = 'UBTextField';

    const asType = !type ? 'text' : type;

    const handleChange = (val: string) => {
        onChange && onChange(val);
    };

    const variantClassName =
        variant == 'rounded'
            ? 'textfield-rounded'
            : '' || variant == 'inline'
            ? 'textfield-inline'
            : '' || variant == 'rounded-outline-white'
            ? 'textfield-rounded-outline-white'
            : '';

    return (
        <FormGroupCont className={`${variantClassName}`}>
            {prefixElm && <PrefixWrapper>{prefixElm}</PrefixWrapper>}
            <TextFieldInput
                type={asType}
                onChange={e => handleChange(e.target.value)}
                placeholder={placeholder}
                {...rest}
            />
            {suffixElm && <SuffixWrapper>{suffixElm}</SuffixWrapper>}
        </FormGroupCont>
    );
});

export default UBTextField;
