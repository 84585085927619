import React, {
    useState,
    useEffect,
    SyntheticEvent,
    useRef,
    RefObject,
    useCallback
} from 'react';
import {
    SelectboxCont,
    SelectboxControl,
    SelectboxValueCont,
    SelectboxValueSingle,
    SelectboxIndicators,
    SelectboxListCont,
    SelectboxList,
    SelectboxOption,
    SelectboxPlaceholder,
    SelectboxDummy,
    SelectboxHighlightMatch,
    SelectboxMarker,
    SelectboxSizer,
    SelectJoinDropdown
} from './Selectbox.css';
import { useField, useFormikContext, FormikHandlers } from 'formik';
import SelectBoxMultiTag from './SelectboxMultiTag';
import { useWindowSize } from '@utils/useWindowSize';
import SimpleBar from 'simplebar-react';

// Last Update 22/05/2020

export interface SelectboxItem {
    label: string;
    value: string;
    flag?: string;
}

export interface ISelectbox {
    items: SelectboxItem[];
    /** Texte servant de placeholder */
    placeholder: string;
    /** name du champ formik */
    name: string;
    /** Transforme le select en select mutiple */
    multiple?: boolean;
    /** Cache la flêche */
    noArrow?: boolean;
    /** Mode inline */
    inline?: boolean;
    /** S'adapte à la longueur du texte */
    autoResize?: boolean;
    /** Permet de séparer les tags du select mutiple */
    tagLess?: boolean;
    /** Action à effectuer après la sélection */
    onChange?: FormikHandlers['handleChange'];
    /** custom HTML option */
    optionComponent?: (props: ICustomOption) => JSX.Element;
    /** Valeur sélectionné custom */
    selValueComponent?: (props: ICustomSelValue) => JSX.Element;
    /** Choix maximum */
    max?: number;
    /** Pas de recherche */
    noSearch?: boolean;
}

interface ICustomOption {
    label: JSX.Element;
    item: SelectboxItem;
}

interface ICustomSelValue {
    selectVal: string | JSX.Element | undefined;
    inputVal: string;
}

const Selectbox: React.FC<ISelectbox> = props => {
    const [isFocus, setIsFocus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [filteredSuggestion, setFilteredSuggestion] = useState<
        SelectboxItem[]
    >([]);
    const [val, setVal] = useState('');
    const [heightWindow] = useWindowSize();
    const [spaceAvailable, setSpaceAvailable] = useState(0);
    const SizerRef = useRef<HTMLDivElement>(null);
    const [inputWidth, setInputWidth] = useState<number>(0);

    const [field, meta, helpers] = useField(props);
    const { setFieldTouched } = useFormikContext();
    const {
        items,
        placeholder,
        multiple,
        name,
        inline,
        autoResize,
        tagLess,
        onChange,
        optionComponent,
        selValueComponent,
        max,
        noSearch
    } = props;

    // ------------------------------
    // Ref
    // ------------------------------
    const SelectRef = React.createRef<HTMLDivElement>();
    const DummyRef = useRef<HTMLInputElement>(null);

    // ------------------------------
    // Gestion de la suggestion active
    // ------------------------------
    const getActiveOption = (offset: number) => {
        const length = filteredSuggestion.length;
        if (activeIndex + offset < 0) setActiveIndex(length - 1);
        else if (activeIndex + offset > length - 1) setActiveIndex(0);
        else setActiveIndex(n => n + offset);
    };

    // ------------------------------
    // Event Handler
    // ------------------------------
    const onWrapperClick = useCallback(() => {
        DummyRef.current && DummyRef.current.focus();
    }, []);

    const cleanInput = () => {
        setVal('');
    };

    const getRemainingSpaceFromWindow = useCallback(() => {
        if (heightWindow != null) {
            const offSetSelect = SelectRef.current?.getBoundingClientRect().top;
            const heightSelect = SelectRef.current?.offsetHeight;
            if (offSetSelect != undefined && heightSelect != undefined) {
                setSpaceAvailable(
                    heightWindow - offSetSelect - heightSelect - 4
                );
            }
        }
    }, [heightWindow, SelectRef]);

    const onFocus = () => {
        setIsFocus(true);
        if (max && multiple) {
            if (field.value.length < max) {
                setIsOpen(true);
                getRemainingSpaceFromWindow();
            } else {
                setIsOpen(false);
            }
        } else {
            setIsOpen(true);
            getRemainingSpaceFromWindow();
        }
    };

    const onBlur = () => {
        if (filteredSuggestion.length == 0) cleanInput();
        setIsFocus(false);
        setIsOpen(false);
        setFieldTouched(field.name, true, false);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowDown':
                if (isOpen) e.preventDefault();
                let offset = 0;
                if (e.key == 'ArrowUp') offset = -1;
                else if (e.key == 'ArrowDown') offset = 1;
                getActiveOption(offset);
                break;
            case 'Enter':
                if (
                    filteredSuggestion.length >= 1 &&
                    filteredSuggestion[activeIndex]
                ) {
                    if (multiple) {
                        if (filteredSuggestion[activeIndex]) {
                            helpers.setValue([
                                ...field.value,
                                filteredSuggestion[activeIndex].value
                            ]);
                            onChange &&
                                onChange([
                                    ...field.value,
                                    filteredSuggestion[activeIndex].value
                                ]);
                        } else {
                            if (filteredSuggestion[activeIndex]) {
                                helpers.setValue(
                                    filteredSuggestion[activeIndex].value
                                );
                                onChange &&
                                    onChange(
                                        filteredSuggestion[activeIndex].value
                                    );
                            }
                        }
                    }
                    cleanInput();
                    DummyRef.current && DummyRef.current.blur();
                }
                break;
            case 'Escape':
                cleanInput();
                DummyRef.current && DummyRef.current.blur();
                break;
            default:
                return;
        }
    };

    // ------------------------------
    // Filtrage des suggestions
    // ------------------------------
    const filterSuggestion = useCallback(
        (item: SelectboxItem) => {
            if (multiple) {
                if (
                    item.label.toLowerCase().includes(val.toLowerCase()) &&
                    !field.value.includes(item.value)
                ) {
                    return true;
                }
            } else {
                if (item.label.toLowerCase().includes(val.toLowerCase())) {
                    return true;
                }
            }
        },
        [val, multiple, field.value]
    );

    useEffect(() => {
        setFilteredSuggestion(items.filter(filterSuggestion));
    }, [filterSuggestion, items]);

    useEffect(() => {
        if (filteredSuggestion && activeIndex > filteredSuggestion.length - 1)
            setActiveIndex(0);
    }, [activeIndex, filteredSuggestion, setActiveIndex]);

    useEffect(() => {
        autoResize &&
            SizerRef.current &&
            setInputWidth(SizerRef.current.scrollWidth + 36);
    }, [field.value, autoResize]);

    // ------------------------------
    // JSX
    // ------------------------------
    const isFocusClassName = isFocus ? 'selectbox-isfocus' : '';
    const isOpenClassName = isOpen ? 'selectbox-isopen' : '';
    const hasError = meta.error && meta.touched ? 'selectbox-haserror' : '';
    const isInlineClassName = inline ? 'selectbox-inline' : '';
    const noSearchClassName = noSearch ? 'selectbox-readonly' : '';

    let selectVal;
    if (multiple) {
        if (val == '') {
            selectVal = (
                <SelectboxPlaceholder>{placeholder}</SelectboxPlaceholder>
            );
        }
    } else {
        if ((field.value == '' || field.value == undefined) && val == '') {
            selectVal = (
                <SelectboxPlaceholder>{placeholder}</SelectboxPlaceholder>
            );
        } else if (field.value != '' && field.value != undefined && val == '') {
            for (const i of items) {
                if (field.value.toString() === i.value) {
                    selectVal = i.label;
                }
            }
        }
    }

    return (
        <div>
            <SelectboxCont
                className={`${isFocusClassName} ${isOpenClassName} ${hasError} ${isInlineClassName}`}
                ref={SelectRef}
                onKeyDown={e => onKeyDown(e)}
            >
                <SelectboxControl
                    onClick={onWrapperClick}
                    style={autoResize ? { width: inputWidth + 'px' } : {}}
                >
                    <SelectboxValueCont>
                        <SelectboxDummy
                            ref={DummyRef}
                            type='text'
                            value={val}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onChange={e => setVal(e.target.value)}
                            autoComplete='off'
                            readOnly={noSearch && true}
                            className={noSearchClassName}
                        />
                        {!selValueComponent ? (
                            <SelectboxValueSingle>
                                {selectVal}
                            </SelectboxValueSingle>
                        ) : (
                            React.createElement(selValueComponent, {
                                selectVal: selectVal,
                                inputVal: val
                            })
                        )}
                    </SelectboxValueCont>
                    <SelectboxIndicators>
                        <svg
                            aria-hidden='true'
                            focusable='false'
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 320 512'
                            width='14'
                        >
                            <path
                                fill='currentColor'
                                d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'
                            ></path>
                        </svg>
                    </SelectboxIndicators>
                </SelectboxControl>
                <Dropdown
                    items={filteredSuggestion}
                    isOpen={isOpen}
                    name={name}
                    userInput={val}
                    InputRef={DummyRef}
                    cleanInput={cleanInput}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    multiple={multiple}
                    onChange={onChange}
                    customOption={optionComponent}
                    maxHeightDropdown={spaceAvailable}
                    noSearch={noSearch}
                />
                {autoResize && (
                    <SelectboxSizer ref={SizerRef} aria-hidden='true'>
                        {(field.value != '' && selectVal) || placeholder}
                    </SelectboxSizer>
                )}
            </SelectboxCont>
            {multiple && !tagLess && (
                <SelectBoxMultiTag
                    name={field.name}
                    items={items}
                    onChange={onChange}
                />
            )}
        </div>
    );
};

interface IDropdown {
    items: SelectboxItem[];
    isOpen: boolean;
    userInput: string;
    InputRef: RefObject<HTMLInputElement>;
    cleanInput: () => void;
    activeIndex: number;
    setActiveIndex: React.Dispatch<number>;
    multiple?: boolean;
    name: string;
    onChange?: FormikHandlers['handleChange'];
    customOption?: (props: ICustomOption) => JSX.Element;
    maxHeightDropdown: number;
    noSearch?: boolean;
}

const Dropdown = (props: IDropdown) => {
    const [field, , helpers] = useField<any>(props);
    const DropdownRef = useRef<HTMLDivElement>(null);
    const {
        isOpen,
        userInput,
        InputRef,
        cleanInput,
        activeIndex,
        setActiveIndex,
        items,
        multiple,
        onChange,
        customOption,
        maxHeightDropdown,
        noSearch
    } = props;
    const [render, setRender] = useState(false);

    useEffect(() => {
        if (isOpen) setRender(true);
    }, [isOpen]);

    const onOptionMouseDown = (e: SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (!DropdownRef.current || !InputRef.current) return null;
        if (!DropdownRef.current.contains(e.currentTarget)) {
            InputRef.current.blur();
        }
    };

    const onOptionMouseUp = (item: SelectboxItem) => {
        if (multiple) {
            helpers.setValue([...field.value, item.value]);
            onChange && onChange([...field.value, item.value]);
        } else {
            helpers.setValue(item.value);
            onChange && onChange(item.value);
        }
        InputRef.current && InputRef.current.blur();
        cleanInput();
    };

    const onAnimationEnd = () => {
        if (!isOpen) setRender(false);
        setActiveIndex(0);
    };

    const getHighlightedText = (text: string, higlight: string) => {
        const parts = text.split(new RegExp(`(${higlight})`, 'gi'));
        return (
            <>
                {parts.map((part, i) => {
                    if (part.toLowerCase() === higlight.toLowerCase()) {
                        return (
                            <SelectboxHighlightMatch key={i}>
                                {part}
                            </SelectboxHighlightMatch>
                        );
                    } else {
                        return part;
                    }
                })}
            </>
        );
    };

    const isExiting = !isOpen ? 'selectbox-isexiting' : '';

    if (!render) return null;
    return (
        <SelectboxListCont
            onAnimationEnd={onAnimationEnd}
            className={`${isExiting}`}
            ref={DropdownRef}
        >
            <SelectJoinDropdown />
            <SimpleBar
                className='scrollable-area'
                style={
                    maxHeightDropdown < 240
                        ? { maxHeight: maxHeightDropdown + 'px' }
                        : {}
                }
            >
                <SelectboxList>
                    {items.length >= 1 ? (
                        items.map((item, i) => {
                            const isActive =
                                activeIndex == i ? 'selectbox-active' : '';
                            const isSelected =
                                field.value == item.value
                                    ? 'selectbox-isselected'
                                    : '';
                            if (!customOption) {
                                return (
                                    <SelectboxOption
                                        key={item.value}
                                        id={item.value}
                                        className={`${isActive} ${isSelected}`}
                                        onMouseDown={e => onOptionMouseDown(e)}
                                        onMouseUp={() => onOptionMouseUp(item)}
                                        onMouseMove={() => setActiveIndex(i)}
                                        tabIndex={-1}
                                        title={item.label}
                                    >
                                        {isSelected != '' && (
                                            <SelectboxMarker />
                                        )}
                                        {!noSearch
                                            ? getHighlightedText(
                                                  item.label,
                                                  userInput
                                              )
                                            : item.label}
                                    </SelectboxOption>
                                );
                            } else {
                                return (
                                    <SelectboxOption
                                        key={item.value}
                                        id={item.value}
                                        className={`${isActive} ${isSelected}`}
                                        onMouseDown={e => onOptionMouseDown(e)}
                                        onMouseUp={() => onOptionMouseUp(item)}
                                        onMouseMove={() => setActiveIndex(i)}
                                        tabIndex={-1}
                                        title={item.label}
                                    >
                                        {isSelected != '' && (
                                            <SelectboxMarker />
                                        )}
                                        {React.createElement(customOption, {
                                            label: getHighlightedText(
                                                item.label,
                                                userInput
                                            ),
                                            item: item
                                        })}
                                    </SelectboxOption>
                                );
                            }
                        })
                    ) : (
                        <SelectboxOption
                            tabIndex={0}
                            className='selectbox-empty'
                        >
                            {userInput != ''
                                ? 'Aucune option ne correspond'
                                : 'Aucune option disponible'}
                        </SelectboxOption>
                    )}
                </SelectboxList>
            </SimpleBar>
        </SelectboxListCont>
    );
};

export default React.memo(Selectbox);
