import React, { CSSProperties } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

export interface IBigTabPanel {
    tab: string;
    key: string;
    style?: CSSProperties;
    active?: boolean;
    icon: IconProp;
}

const AnimatedCont = styled.div`
    transition: opacity 500ms, transform 500ms;
    transform: translateX(30px);
    &.enter {
        opacity: 0;
    }
    &.enter-active {
        opacity: 1;
        transform: translateX(0);
    }
    &.enter-done {
        opacity: 1;
        transform: translateX(0);
    }
    &.exit {
        opacity: 1;
        transform: translateX(0);
    }
    &.exit-active {
        opacity: 0;
        display: none;
    }
    &.exit-done {
        opacity: 0;
        display: none;
    }
`;

const BigTabPanel: React.FC<IBigTabPanel> = props => {
    const { active } = props;
    return (
        <div>
            <CSSTransition in={active} timeout={500} unmountOnExit>
                <AnimatedCont id={props.key}>{props.children}</AnimatedCont>
            </CSSTransition>
        </div>
    );
};

export default BigTabPanel;
