import styled, { keyframes } from 'styled-components';
import { colors } from '@components/_struct/variables';

export const inAnimation = keyframes`
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
    }
`;

export const outAnimation = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
`;

export const DrawerWrapper = styled.div`
    position: fixed;
    right: 0;
    top: 0%;
    height: 100%;
    background: #fff;
    z-index: 102;
    box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15);
    transition: all 300ms cubic-bezier(0.7, 0.3, 0.1, 1);
    &.exit-active,
    &.appear {
        transform: translateX(100%);
        opacity: 0;
    }
    &.enter-active {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const DrawerCloseButton = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: 0 none;
    color: ${colors.main};
    font-size: 18px;
    padding: 7px;
    width: 32px;
    height: 32px;
    margin: 0;
    outline: 0;
    border-radius: 24px;
    :hover {
        box-shadow: 0 0 2px 0 ${colors.main};
    }
`;
