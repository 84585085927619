import React, { useState, useCallback } from 'react';
import Portal from '../Portal';
import {
    ModalRoot,
    ModalOverlay,
    ModalContainer,
    ModalDialog
} from './Modal.css';

interface IModal {
    visible: boolean;
    onClose?: () => void;
    noCloseOverlay?: boolean;
}

interface IModalCtx {
    handleClose?: () => void;
}

const ModalContext = React.createContext(undefined as any);
const ModalProvider = ModalContext.Provider;

export function useModalContext() {
    const context = React.useContext<IModalCtx>(ModalContext);
    return context;
}

export function useModal(): [boolean, () => void, () => void] {
    const [visible, setVisible] = useState(false);
    const handleClose = useCallback(() => setVisible(false), []);
    const handleOpen = useCallback(() => setVisible(true), []);
    return [visible, handleClose, handleOpen];
}

const Modal: React.FC<IModal> = props => {
    const { children, visible, onClose, noCloseOverlay } = props;
    const [ctx] = useState<IModalCtx>({ handleClose: onClose });

    if (!visible) return null;
    return (
        <Portal>
            <ModalRoot>
                <ModalProvider value={ctx}>
                    {!noCloseOverlay && <ModalOverlay onClick={onClose} />}
                    <ModalContainer>
                        <ModalDialog>{children}</ModalDialog>
                    </ModalContainer>
                </ModalProvider>
            </ModalRoot>
        </Portal>
    );
};

export default Modal;
