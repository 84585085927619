import { useCallback, useState } from 'react';
import { IApp } from '@interfaces/pages/_app';

export const useMercureUpdateParticipants = (
    handleRemoveParticipant: ((userId: string) => void) | null,
    handleAddParticipant: (newParticipant: IApp.IParticipantEvent) => void
) => {
    const [timeoutArr, setTimeoutArr] = useState<
        ReturnType<typeof setTimeout>[]
    >([]);

    const updateParticipants = useCallback(
        (data: IMessageMercure) => {
            if (!timeoutArr) return;
            // On check si c'est une connexion ou une déconnexion
            if (!data.active) {
                // Si c'est une déco on créer un délai pour le supprimer dans 5 secondes, au cas où il se reconnecte entre temps
                timeoutArr[data.payload.topic] = setTimeout(() => {
                    handleRemoveParticipant &&
                        handleRemoveParticipant(data.payload.userId);
                    delete timeoutArr[data.payload.topic];
                    setTimeoutArr(timeoutArr);
                }, 2000);
                setTimeoutArr(timeoutArr);
            } else {
                // Si c'est une connexion, on check d'abord si c'est une reconnexion, ce à quoi on annule la suppression prévue plus haut dans le code
                if (timeoutArr[data.payload.topic] !== undefined) {
                    clearTimeout(timeoutArr[data.payload.topic]);
                    delete timeoutArr[data.payload.topic];
                    setTimeoutArr(timeoutArr);
                } else {
                    // Si ce n'est pas une reconnexion, il s'agit forcément d'une première connexion, on rajoute donc le user à notre userlist
                    const newParticipant: IApp.IParticipantEvent = {
                        userId: data.payload.userId,
                        topic: data.payload.topic,
                        userName: data.payload.userName,
                        intervenant: data.payload.intervenant
                    };
                    handleAddParticipant(newParticipant);
                }
            }
        },
        [timeoutArr, handleRemoveParticipant, handleAddParticipant]
    );

    return updateParticipants;
};
