import React from 'react';
import HomeLayout from '@components/_struct/_layouts/HomeLayout/HomeLayout';
import HeroSection from './HomeSection/HeroSection';
import HowItWorksSection from './HomeSection/HowItWorksSection';
import ForWhoSection from './HomeSection/ForWhoSection';
import FormSection from './HomeSection/FormSection';

const Home = () => {
    return (
        <HomeLayout>
            <HeroSection />
            <HowItWorksSection />
            <ForWhoSection />
            <FormSection />
        </HomeLayout>
    );
};

export default Home;
