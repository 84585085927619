import React, { useCallback, useEffect, useRef } from 'react';
import { RoomCardWrapper, RoomCardPic, RoomCardInfos } from './RoomCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { CallActions } from '@actions/call_actions';
import { UIActions } from '@actions/ui_actions';
import Button from '@components/_common/Button';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import Confirm from '@components/_common/Modal/Confirm';
import { useModal } from '@components/_common/Modal/Modal';
import { Tag } from '@components/_common/Tag';
import { useMSTranslation } from '@utils/useMSTranslation';
import VanillaTilt from 'vanilla-tilt';

interface IRoomCard {
    color?: string;
    pic?: string;
    name: string;
    nbParticipantsMax: number;
    description: string;
    here?: boolean;
    type: EnumTypeRoom;
    roomId: string;
}

const RoomCard = (props: IRoomCard) => {
    const {
        color,
        pic,
        name,
        nbParticipantsMax,
        description,
        type,
        roomId,
        here
    } = props;
    const { __ } = useMSTranslation(['event']);

    const [visibleConfirm, closeConfirm, openConfirm] = useModal();
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const waitingRoom = useSelector((state: IStore) => state.UI.waitingRoom);
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const currentRoomId = useSelector((state: IStore) => state.call.roomId);
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const userName = useSelector((state: IStore) => state.Auth.user?.userName);

    const nbParticipantsConnectedRooms = useSelector(
        (state: IStore) => state.Data.nbParticipantsConnectedRooms
    );

    const dispatch = useDispatch();

    const { __s } = useMSTranslation([]);

    const nbParticipants = nbParticipantsConnectedRooms.find(
        room => room.roomId == roomId
    );

    const customColor = {
        background: color ? color : '#3a90e1'
    };

    const background = {
        backgroundImage: `url(${pic})`
    };

    const tiltRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tiltRef.current)
            VanillaTilt.init(tiltRef.current, {
                scale: 1.1,
                glare: true,
                'max-glare': 0.5
            });
    }, []);

    // Rejoindre une room
    const joinRoom = useCallback(() => {
        if (!here) {
            if (userSite && !userSite.intervenant && type == 'PRIVATE') {
                dispatch(UIActions.setWaitingRoom(roomId));
            } else if (
                meetingToken &&
                userSite &&
                (userSite.intervenant || type != 'PRIVATE')
            )
                dispatch(
                    CallActions.joinRoom(meetingToken, roomId, callObject)
                );
        }
    }, [userSite, meetingToken, dispatch, roomId, callObject, type, here]);

    const onClickCard = () => {
        if (
            userSite &&
            !userSite.intervenant &&
            nbParticipants &&
            nbParticipants.nbConnected >= nbParticipantsMax
        ) {
            return;
        }
        currentRoomId !== null && !here ? openConfirm() : joinRoom();
    };

    return (
        <>
            <RoomCardWrapper
                style={customColor}
                onClick={onClickCard}
                ref={tiltRef}
            >
                {pic && (
                    <div>
                        <RoomCardPic style={background} />
                    </div>
                )}
                <RoomCardInfos>
                    {userSite.intervenant &&
                        nbParticipants &&
                        nbParticipants.nbWaiting > 0 && (
                            <div className='text-right mb-05'>
                                <Tag variant='orange'>
                                    {__s(
                                        nbParticipants?.nbWaiting,
                                        '1 participant en attente',
                                        '{{count}} participants en attente',
                                        'event'
                                    )}
                                </Tag>
                            </div>
                        )}
                    <div className='flex items-center'>
                        <div className='text-lg font-bold'>{name}</div>
                        {type == 'PRIVATE' && (
                            <div className='opacity-50 ml-1'>
                                <FontAwesomeIcon icon={['fas', 'lock']} />{' '}
                                {__('Privée', 'event')}
                            </div>
                        )}
                        <div className='ml-auto pl-1 text-lg opacity-50'>
                            <span className='mr-1 text-lg'>
                                {nbParticipants
                                    ? nbParticipants.nbConnected
                                    : 0}{' '}
                                / {nbParticipantsMax}
                            </span>
                            <FontAwesomeIcon icon={['far', 'users']} />
                        </div>
                    </div>
                    <div className='leading-normal mt-1 whitespace-pre-wrap'>
                        {waitingRoom != '' ? (
                            <>
                                <div>
                                    {__("Vous êtes en file d'attente", 'event')}
                                    .
                                </div>
                                <div className='mt-1'>
                                    <Button
                                        variant='normal-white'
                                        onClick={e => {
                                            dispatch(
                                                UIActions.setWaitingRoom('')
                                            );
                                            e.stopPropagation();
                                        }}
                                    >
                                        {__(
                                            "Me retirer de la file d'attente",
                                            'event'
                                        )}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            description
                        )}
                    </div>
                    {here && userName && (
                        <div className='mt-2 flex items-center'>
                            <div>
                                <UserAvatar name={userName} />
                            </div>
                            <div className='flex-1 ml-1'>
                                <strong>{userName}</strong>,{' '}
                                {__(
                                    'Vous êtes actuellement dans cette salle',
                                    'event'
                                )}
                            </div>
                        </div>
                    )}
                </RoomCardInfos>
            </RoomCardWrapper>
            <Confirm
                visible={visibleConfirm}
                onClose={closeConfirm}
                onSubmit={joinRoom}
                title={__('Vous allez rejoindre la salle {{name}}', 'event', {
                    name: name
                })}
                description={__(
                    'Etes-vous sûr? Vous quitterez votre salle actuelle.',
                    'event'
                )}
            />
        </>
    );
};

export default RoomCard;
