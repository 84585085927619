import {
    ListUserAvatarCont,
    ListUserItemName,
    ListWaitingItem,
    ListWaitingActions,
    ListWaitingPlacement
} from './RoomListUsers.css';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import Button from '@components/_common/Button';
import UBCheckbox from '@components/_common/_form/Checkbox/UBCheckbox';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { APIMercure } from '@api/mercureApi';
import getConfig from 'next/config';
import { UIActions } from '@actions/ui_actions';
import { CallActions } from '@actions/call_actions';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

interface IWaitingUser {
    userName: string;
    userId: string;
    onCheck?: (id: string) => void;
    checked?: boolean;
}

const RoomWaitingUser: React.FC<IWaitingUser> = props => {
    const { userName, userId, onCheck, checked } = props;
    const { __ } = useMSTranslation(['struct']);

    const roomId = useSelector((state: IStore) => state.call.roomId);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );

    const userSite = useSelector((state: IStore) => state.Auth.user);
    const dispatch = useDispatch();

    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );

    const participant = participantsConnectedEvent.filter(
        item => item.userId == userId
    )[0];

    const acceptJoinRoom = (userId: string) => {
        // Envoyer une invitation au user
        const data = {
            roomId: roomId,
            type: 'accept_join_room'
        };
        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
            data
        );
    };

    const refuseJoinRoom = (userId: string) => {
        // Envoyer une notification au user pour le kick
        const data = {
            roomId: roomId,
            type: 'refuse_join_room',
            adminName: userSite ? userSite.userName : ''
        };
        dispatch(CallActions.removeWaitingParticipantRoom(userId));

        APIMercure.sendNotifUsers(
            mercureTokenEvent,
            publicRuntimeConfig.LIEN_MERCURE + 'users/' + userId,
            data
        );
    };

    const canSeeProfil = !!(
        userSite &&
        userSite.intervenant &&
        !participant.intervenant
    );
    const isClickableClassName = canSeeProfil ? 'is-clickable' : '';

    return (
        <ListWaitingItem>
            <UBCheckbox
                variant='white'
                onChange={() => onCheck && onCheck(userId)}
                checked={checked}
                name='waiting-user'
            />
            <ListUserAvatarCont>
                <UserAvatar name={`${userName}`} size='30' />
            </ListUserAvatarCont>
            <ListUserItemName
                className={`${isClickableClassName}`}
                onClick={() =>
                    canSeeProfil &&
                    dispatch(UIActions.openDrawerCandidat(userId))
                }
            >
                <span className='truncate'>{userName}</span>
            </ListUserItemName>
            <ListWaitingPlacement>1</ListWaitingPlacement>
            <ListWaitingActions className='ml-1'>
                <Button
                    variant='action'
                    size='small'
                    onClick={() => {
                        acceptJoinRoom(userId);
                    }}
                >
                    {__('Ajouter', 'struct')}
                </Button>
                <Button
                    variant='red'
                    size='small'
                    onClick={() => {
                        refuseJoinRoom(userId);
                    }}
                >
                    {__('Refuser', 'struct')}
                </Button>
            </ListWaitingActions>
        </ListWaitingItem>
    );
};

export default RoomWaitingUser;
