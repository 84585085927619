import React, { useState, useCallback } from 'react';
import { DrawerWrapper } from './Drawer.css';
import Portal from '../Portal';
import useDelayUnmount from '@utils/useDelayUnmount';
import { CSSTransition } from 'react-transition-group';

interface IDrawer {
    /** Etat du volet */
    visible: boolean;
    /** Fonction de fermeture du volet */
    onClose?: () => void;
    /**
     * Largeur du volet
     * @default 300
     */
    width?: number;
}

interface IDrawerCtx {
    handleClose?: () => void;
}

const DrawerContext = React.createContext(undefined as any);
const DrawerProvider = DrawerContext.Provider;

export function useDrawerContext() {
    return React.useContext<IDrawerCtx>(DrawerContext);
}

export function useDrawer(): [boolean, () => void, () => void] {
    const [visible, setVisible] = useState(false);
    const handleClose = useCallback(() => setVisible(false), []);
    const handleOpen = useCallback(() => setVisible(true), []);
    return [visible, handleClose, handleOpen];
}

const Drawer: React.FC<IDrawer> = React.memo(props => {
    const { children, visible, onClose, width } = props;
    const [ctx] = useState<IDrawerCtx>({ handleClose: onClose });
    const shouldRender = useDelayUnmount(visible, 300);

    if (!shouldRender) return null;
    return (
        <Portal>
            <CSSTransition in={visible} timeout={300} appear={visible}>
                <DrawerWrapper
                    style={{ width: !width ? '300px' : `${width}px` }}
                >
                    <DrawerProvider value={ctx}>{children}</DrawerProvider>
                </DrawerWrapper>
            </CSSTransition>
        </Portal>
    );
});

Drawer.displayName = 'Drawer';

export default Drawer;
