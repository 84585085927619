import { ACTION_TYPES } from '@actions/actions_types';
import { IApp } from '@interfaces/pages/_app';

const resetState = () => ({
    type: ACTION_TYPES.UI.resetState
});

const toggleShowListUsers = () => ({
    type: ACTION_TYPES.UI.toggleShowListUsers
});

const toggleShowWaitingList = () => ({
    type: ACTION_TYPES.UI.toggleShowWaitingList
});

const toggleShowEventInfos = () => ({
    type: ACTION_TYPES.UI.toggleShowEventInfos
});

const toggleShowRoom = () => ({
    type: ACTION_TYPES.UI.toggleShowRoom
});

const toggleAddUser = () => ({
    type: ACTION_TYPES.UI.toggleAddUser
});

const toggleChat = () => ({
    type: ACTION_TYPES.UI.toggleChat
});

const setNoMic = (statut: boolean) => ({
    payload: { statut: statut },
    type: ACTION_TYPES.UI.setNoMic
});

const setNoCam = (statut: boolean) => ({
    payload: { statut: statut },
    type: ACTION_TYPES.UI.setNoCam
});

const setUnreadedMsgCount = (nbr: number) => ({
    payload: { nbr: nbr },
    type: ACTION_TYPES.UI.setUnreadedMsgCount
});

const incrementUnreadedMsgCount = () => ({
    type: ACTION_TYPES.UI.incrementUnreadedMsgCount
});

const selectedParticipants = (listSelected: string[]) => ({
    payload: { selectedParticipants: listSelected },
    type: ACTION_TYPES.UI.selectedParticipants
});

const selectedWaitingParticipants = (listSelected: string[]) => ({
    payload: { selectedWaitingParticipants: listSelected },
    type: ACTION_TYPES.UI.selectedWaitingParticipants
});

const selectedWaitingDispatch = (listSelected: string[]) => ({
    payload: { selectedWaitingDispatch: listSelected },
    type: ACTION_TYPES.UI.selectedWaitingDispatch
});

const removeSelectedWaitingDispatch = (userId: string) => ({
    payload: { userId: userId },
    type: ACTION_TYPES.UI.removeSelectedWaitingDispatch
});

const selectedIntervenants = (listSelected: string[]) => ({
    payload: { selectedIntervenants: listSelected },
    type: ACTION_TYPES.UI.selectedIntervenants
});

const selectedVisiteurs = (listSelected: string[]) => ({
    payload: { selectedVisiteurs: listSelected },
    type: ACTION_TYPES.UI.selectedVisiteurs
});

const setWaitingRoom = (waitingRoom: string) => ({
    payload: { waitingRoom: waitingRoom },
    type: ACTION_TYPES.UI.setWaitingRoom
});

const setTmpRoomOwner = (owner: boolean) => ({
    payload: { owner: owner },
    type: ACTION_TYPES.UI.setTmpRoomOwner
});

const openDrawerCandidat = (id: number | string) => ({
    payload: { drawerCandidat: { isOpen: true, userId: id } },
    type: ACTION_TYPES.UI.openDrawerCandidat
});

const setDrawerCandidatData = (fiche: IApp.IFiche | null) => ({
    payload: { fiche: fiche },
    type: ACTION_TYPES.UI.setDrawerCandidatData
});

const setUsedShortToken = (usedShortToken: boolean) => ({
    payload: { usedShortToken: usedShortToken },
    type: ACTION_TYPES.UI.setUsedShortToken
});

const closeDrawerCandidat = () => ({
    type: ACTION_TYPES.UI.closeDrawerCandidat
});

export const UIActions = {
    toggleShowListUsers: toggleShowListUsers,
    toggleShowWaitingList: toggleShowWaitingList,
    toggleShowEventInfos: toggleShowEventInfos,
    toggleShowRoom: toggleShowRoom,
    toggleAddUser: toggleAddUser,
    toggleChat: toggleChat,
    setNoMic: setNoMic,
    setNoCam: setNoCam,
    resetState: resetState,
    selectedParticipants: selectedParticipants,
    selectedIntervenants: selectedIntervenants,
    selectedVisiteurs: selectedVisiteurs,
    setWaitingRoom: setWaitingRoom,
    selectedWaitingParticipants: selectedWaitingParticipants,
    selectedWaitingDispatch: selectedWaitingDispatch,
    removeSelectedWaitingDispatch: removeSelectedWaitingDispatch,
    setUnreadedMsgCount: setUnreadedMsgCount,
    incrementUnreadedMsgCount: incrementUnreadedMsgCount,
    openDrawerCandidat: openDrawerCandidat,
    closeDrawerCandidat: closeDrawerCandidat,
    setTmpRoomOwner: setTmpRoomOwner,
    setDrawerCandidatData: setDrawerCandidatData,
    setUsedShortToken: setUsedShortToken
};
