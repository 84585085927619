import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompanyAvatar from '@components/_common/Avatar/CompanyAvatar';
import Button from '@components/_common/Button';
import { Link } from '@utils/i18n';
import { format, getTime } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { fr } from 'date-fns/locale';
import SignupForm from '@components/Join/Signup/SignupForm';
import {
    Cover,
    Footer,
    FooterMenu,
    Header,
    HeadSection,
    InfosEventItem,
    InfosSection,
    InfosWrapper,
    Picto,
    SectionForm,
    TitleEvent,
    TitleForm,
    Wrapper,
    LogoCont,
    WrapperDesc
} from '@components/Join/Signup/SignupLayout.css';
import { useMSTranslation } from '@utils/useMSTranslation';
import {
    SIGNUP_STATUS_ALREADYREGISTERED,
    SIGNUP_STATUS_ALREADYREGISTEREDREFUSED,
    SIGNUP_STATUS_ALREADYREGISTEREDVALIDATE,
    SIGNUP_STATUS_FULL,
    SIGNUP_STATUS_ENDED,
    SIGNUP_STATUS_IDLE,
    SIGNUP_STATUS_VALID,
    SignupFull,
    SignupRegistered,
    SignupRegisteredRefused,
    SignupRegisteredValidate,
    SignupValidate,
    SignupEnded,
    SIGNUP_STATUS_NOT_STARTED,
    SignupNotStartedYet
} from '@components/Join/Signup/SignupStatus';
import { PageJoinActions } from '@actions/pages/join_actions';
import Logo from '@components/_common/Logo';
import { useRouter } from 'next/router';
import { getLocalStorage } from '@utils/localStorage';
import { displayError } from '@components/_common/Toast';
import DropdownLangue from '@components/_common/DropdownLangue';

const SignupLayout = () => {
    const formRef = useRef(document.createElement('div'));
    const { __, __s } = useMSTranslation(['struct', 'join']);
    const dispatch = useDispatch();
    const router = useRouter();

    // Infos de l'evenement
    const infosEvent = useSelector((state: IStore) => state.Data.event);

    // Email de souscription
    const emailSignup = useSelector(
        (state: IStore) => state.PageJoin.emailSignup
    );

    // Statut de l'inscription
    const statusSignup = useSelector(
        (state: IStore) => state.PageJoin.statusSignup
    );

    let verbe = __('Rejoindre', 'struct');

    if (infosEvent && infosEvent.type == 'INSCRIPTION')
        verbe = __("S'inscrire à", 'join');

    const scrollForm = () => {
        const nextDiv = document.querySelector('#__next');
        if (nextDiv) nextDiv.scrollTo(0, formRef.current.offsetTop);
    };

    useEffect(() => {
        // Si l'évènement est plein on le signale
        if (
            infosEvent &&
            infosEvent.maxParticipants - infosEvent.nbParticipantsAccepted == 0
        )
            dispatch(PageJoinActions.setStatusSignup(SIGNUP_STATUS_FULL));

        // Si l'évènement est terminé on le signale
        if (infosEvent && getTime(infosEvent.dateEnd) < getTime(new Date()))
            dispatch(PageJoinActions.setStatusSignup(SIGNUP_STATUS_ENDED));

        // Si l'évènement n'est pas commencé on le signale (si il est en participation libre)
        if (
            infosEvent &&
            (infosEvent.type == 'LIBRE' || router.query.token) &&
            getTime(infosEvent.dateStart) > getTime(new Date())
        )
            dispatch(
                PageJoinActions.setStatusSignup(SIGNUP_STATUS_NOT_STARTED)
            );
    }, [infosEvent, dispatch, router]);

    useEffect(() => {
        const isBanned = getLocalStorage('isBanned');
        if (isBanned && isBanned.value)
            displayError(__('Vous avez été banni de cet événement !', 'join'), {
                toastId: 'banned'
            });
    });
    return (
        <div className='flex flex-col h-full'>
            <Header>
                <LogoCont>
                    <Logo variant='white' />
                </LogoCont>
                <div className='ml-auto'>
                    <DropdownLangue variant='white' />
                </div>
            </Header>
            <HeadSection className='flex flex-col justify-center py-2'>
                <Cover
                    style={
                        (infosEvent && {
                            backgroundImage: "url('" + infosEvent.cover + "')"
                        }) || {
                            backgroundImage: "url('')"
                        }
                    }
                />
                <div className='flex flex-col items-center'>
                    <CompanyAvatar
                        name='BNP Paribas'
                        size='120'
                        className='avatar-size'
                        src={
                            (infosEvent && infosEvent.companyAvatar) ||
                            undefined
                        }
                    />
                    <TitleEvent className='mt-1 lg:mt-3'>
                        {infosEvent && infosEvent.title}
                    </TitleEvent>
                </div>
            </HeadSection>
            <InfosSection>
                <InfosWrapper className='flex'>
                    <div className='text-center lg:text-left lg:flex'>
                        <Picto className='flex mb-1 lg:mr-2 lg:mb-0 items-center justify-center'>
                            <FontAwesomeIcon icon={['far', 'calendar-alt']} />
                        </Picto>
                        <InfosEventItem>
                            <div className='text-sm opacity-50 lg:text-base'>
                                {__('Date', 'struct')}
                            </div>
                            <div className='text-sm lg:text-xl'>
                                {infosEvent &&
                                    format(
                                        infosEvent.dateStart,
                                        'dd MMMM yyyy',
                                        { locale: fr }
                                    )}
                            </div>
                        </InfosEventItem>
                    </div>
                    <div className='text-center lg:text-left lg:flex ml-5'>
                        <Picto className='flex mb-1 lg:mr-2 lg:mb-0 items-center justify-center'>
                            <FontAwesomeIcon icon={['far', 'clock']} />
                        </Picto>
                        <InfosEventItem>
                            <div className='text-sm opacity-50 lg:text-base'>
                                {__('Heure', 'struct')}
                            </div>
                            <div className='text-sm lg:text-xl'>
                                {infosEvent && [
                                    format(infosEvent.dateStart, 'HH:mm'),
                                    ' - ',
                                    format(infosEvent.dateEnd, 'HH:mm')
                                ]}
                            </div>
                        </InfosEventItem>
                    </div>
                    <div className='text-center lg:text-left lg:flex ml-5'>
                        <Picto className='flex mb-1 lg:mr-2 lg:mb-0 items-center justify-center'>
                            <FontAwesomeIcon icon={['far', 'users']} />
                        </Picto>
                        <InfosEventItem>
                            <div className='text-sm opacity-50 lg:text-base'>
                                {__('Participants', 'join')}
                            </div>
                            <div className='text-sm lg:text-xl'>
                                {infosEvent &&
                                    infosEvent.maxParticipants -
                                        infosEvent.nbParticipantsAccepted <=
                                        0 &&
                                    __('Evènement plein', 'join')}
                                {infosEvent &&
                                    infosEvent.maxParticipants -
                                        infosEvent.nbParticipantsAccepted >
                                        0 &&
                                    __s(
                                        infosEvent.maxParticipants -
                                            infosEvent.nbParticipantsAccepted,
                                        '1 place restante',
                                        '{{count}} places restantes',
                                        'join'
                                    )}
                            </div>
                        </InfosEventItem>
                    </div>
                    {typeof router.query.desc == 'undefined' &&
                        typeof router.query.token == 'undefined' &&
                        statusSignup != SIGNUP_STATUS_ENDED && (
                            <div className='ml-5 hidden md:block'>
                                <Button
                                    variant='action'
                                    size='large'
                                    onClick={scrollForm}
                                >
                                    {verbe} {__('l’évènement', 'join')}
                                </Button>
                            </div>
                        )}
                </InfosWrapper>
            </InfosSection>
            <div className='py-4 px-2'>
                <WrapperDesc className='leading-normal'>
                    {infosEvent && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: infosEvent.description
                            }}
                        />
                    )}
                </WrapperDesc>
            </div>
            {typeof router.query.desc == 'undefined' && (
                <SectionForm className='py-4 px-2' ref={formRef}>
                    <Wrapper>
                        {statusSignup == SIGNUP_STATUS_IDLE && (
                            <TitleForm className='mb-2'>
                                {verbe} {__("l'évènement", 'join')}
                            </TitleForm>
                        )}
                        {statusSignup == SIGNUP_STATUS_IDLE && (
                            <div>
                                <SignupForm />
                            </div>
                        )}
                        {statusSignup == SIGNUP_STATUS_ALREADYREGISTERED && (
                            <SignupRegistered email={emailSignup} />
                        )}
                        {statusSignup ==
                            SIGNUP_STATUS_ALREADYREGISTEREDVALIDATE &&
                            infosEvent &&
                            emailSignup && (
                                <SignupRegisteredValidate
                                    emailSignup={emailSignup}
                                />
                            )}
                        {statusSignup ==
                            SIGNUP_STATUS_ALREADYREGISTEREDREFUSED &&
                            infosEvent && (
                                <SignupRegisteredRefused
                                    roomName={infosEvent.title}
                                />
                            )}
                        {statusSignup == SIGNUP_STATUS_VALID && (
                            <SignupValidate email={emailSignup} />
                        )}
                        {statusSignup == SIGNUP_STATUS_FULL && <SignupFull />}
                        {statusSignup == SIGNUP_STATUS_ENDED && <SignupEnded />}
                        {statusSignup == SIGNUP_STATUS_NOT_STARTED && (
                            <SignupNotStartedYet />
                        )}
                    </Wrapper>
                </SectionForm>
            )}
            <Footer>
                <div className='lg:flex w-full items-center p-2'>
                    <div className='lg:w-1/3 text-center lg:text-left'>
                        © 2021 Viseet
                    </div>
                    <div className='lg:w-1/3 mt-2 flex justify-center lg:mt-0'>
                        <LogoCont>
                            <Logo variant='white' />
                        </LogoCont>
                    </div>
                    <div className='lg:w-1/3 mt-2 lg:mt-0'>
                        <FooterMenu className='list-none m-0 p-0 text-center lg:flex lg:justify-end'>
                            <li>
                                <Link href='/'>
                                    <a>{__('Mentions légales', 'struct')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link href='/'>
                                    <a>
                                        {__(
                                            'Conditions Générales d’Utilisation',
                                            'struct'
                                        )}
                                    </a>
                                </Link>
                            </li>
                        </FooterMenu>
                    </div>
                </div>
            </Footer>
        </div>
    );
};

export default SignupLayout;
