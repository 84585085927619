import styled from 'styled-components';
import { background } from '@components/_struct/variables';

export const CandDrawerWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background: ${background.blue};
    display: flex;
    flex-direction: column;
`;

export const CandidatDrawerHead = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
`;

export const CandidatDrawerCloseButton = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: 0 none;
    color: #fff;
    font-size: 18px;
    padding: 7px;
    width: 32px;
    height: 32px;
    margin: 0;
    outline: 0;
    border-radius: 24px;
    :hover {
        box-shadow: 0 0 2px 0 #fff;
    }
`;
