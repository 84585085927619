import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const IcoCont = styled.div`
    font-size: 24px;
    padding: 0 20px 0 12px;
    > svg {
        display: block;
    }
`;

const ToastSuccess: React.FC = props => {
    return (
        <div className='flex items-center'>
            <IcoCont>
                <FontAwesomeIcon icon={['fas', 'check-circle']} />
            </IcoCont>
            <div>{props.children}</div>
        </div>
    );
};

export default ToastSuccess;
