import {
    ListUserAvatarCont,
    ListUserItemName,
    ListAddUserItem,
    ListAddUserActions
} from './RoomListUsers.css';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import Button from '@components/_common/Button';
import { APIMercure } from '@api/mercureApi';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { UIActions } from '@actions/ui_actions';
import { displaySuccess } from '@components/_common/Toast';
import { useMSTranslation } from '@utils/useMSTranslation';
import { ChatActions } from '@actions/chat_actions';

interface IRoomAddUserItem {
    userName: string;
    userId: string;
    remove?: boolean;
}

const RoomAddUserItem: React.FC<IRoomAddUserItem> = props => {
    const { userName, userId, remove } = props;
    const { __ } = useMSTranslation(['event', 'struct']);

    const dispatch = useDispatch();
    const roomId = useSelector((state: IStore) => state.call.roomId);
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );

    const participant = participantsConnectedEvent.filter(
        item => item.userId == userId
    )[0];

    const inviteRoomTemporaire = () => {
        // Envoyer une invitation au user
        const data = {
            roomId: roomId,
            type: 'invitation_room',
            nomInvitation: userSite?.userName
        };
        APIMercure.sendNotifUsers(mercureTokenEvent, participant.topic, data);
        displaySuccess(
            __(
                "Une proposition a été envoyée à {{username}}, il vous rejoindra en cas d'acceptation",
                'event',
                { username: participant.userName }
            )
        );
    };

    const kickFromRoom = (userId: string) => {
        // Envoyer une notification au user pour le kick
        dispatch(ChatActions.kickUser(userId, mercureTokenEvent, roomId));
    };

    let buttonOpt = <></>;
    if (!remove)
        buttonOpt = (
            <Button
                variant='action'
                size='small'
                onClick={() => {
                    inviteRoomTemporaire();
                }}
            >
                {__('Ajouter', 'struct')}
            </Button>
        );
    else if (userSite?.intervenant)
        buttonOpt = (
            <Button
                variant='outline-white'
                size='small'
                onClick={() => {
                    kickFromRoom(userId);
                }}
            >
                {__('Retirer', 'struct')}
            </Button>
        );

    const canSeeProfil =
        userId && userSite && userSite.intervenant && !participant.intervenant;
    const isClickableClassName = canSeeProfil ? 'is-clickable' : '';

    return (
        <ListAddUserItem>
            <ListUserAvatarCont>
                <UserAvatar name={`${userName}`} size='30' />
            </ListUserAvatarCont>
            <ListUserItemName
                className={`${isClickableClassName}`}
                onClick={() =>
                    canSeeProfil &&
                    dispatch(UIActions.openDrawerCandidat(userId))
                }
            >
                <span className='truncate'>{userName}</span>
            </ListUserItemName>
            <ListAddUserActions className='ml-1'>
                {buttonOpt}
            </ListAddUserActions>
        </ListAddUserItem>
    );
};

export default RoomAddUserItem;
