import React from 'react';
import styled, { keyframes } from 'styled-components';
import { bp } from '@components/_struct/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IStore } from '@interfaces/logic/redux';
import { useSelector } from 'react-redux';
import { useMSTranslation } from '@utils/useMSTranslation';

const FadeInBottom = keyframes`
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const BlinkingDot = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const MainTitle = styled.h1`
    display: flex;
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
    animation: ${FadeInBottom} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    span {
        display: flex;
        animation: ${BlinkingDot} 1.4s infinite linear;
    }
    span:nth-child(2) {
        animation-delay: 0.2s;
    }
    span:nth-child(3) {
        animation-delay: 0.4s;
    }
    @media (${bp.sm}) {
        font-size: 24px;
    }
`;

const SubTitle = styled.div`
    animation: ${FadeInBottom} 0.6s 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
    opacity: 0;
    color: rgba(255, 255, 255, 0.5);
`;

const LoadingRoom: React.FC = () => {
    const { __ } = useMSTranslation(['event']);

    const loadingText = useSelector(
        (state: IStore) => state.Loading.textLoading
    );

    return (
        <div className='flex w-full'>
            <div className='m-auto'>
                <div className='text-center mb-3'>
                    <FontAwesomeIcon icon='spinner-third' size='6x' spin />
                </div>
                <MainTitle>
                    {loadingText
                        ? loadingText
                        : __('Connexion à la room', 'event')}
                    <div className='flex ml-1'>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </MainTitle>
                <SubTitle className='text-lg lg:text-xl text-center'>
                    {__('Cela peut prendre quelques secondes', 'event')}
                </SubTitle>
            </div>
        </div>
    );
};

export default LoadingRoom;
