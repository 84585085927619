import styled from 'styled-components';
import { bp } from '@components/_struct/variables';

export const ChatRoomWrapper = styled.div`
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    @media (${bp.md}) {
        background: rgba(0, 0, 0, 1);
    }
    display: flex;
    flex-direction: column;
    .scrollable-area {
        flex: 1;
        min-height: 0;
        height: 100%;
        position: relative;
        .simplebar-track .simplebar-scrollbar::before {
            box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.25);
        }
    }
`;
