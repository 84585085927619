import resolve from '@utils/resolve';
import getConfig from 'next/config';
import { cfetch } from '@utils/auth';
import { getLocalStorage } from '@utils/localStorage';
const { publicRuntimeConfig } = getConfig();

/**
 * Renvoie les notes d'un participant
 *
 */
const getNotes = async (token: string, id: string | number) => {
    const meetingToken = getLocalStorage('meetingTokenEvent');
    if (!meetingToken) return;
    return await resolve(
        cfetch(
            token,
            publicRuntimeConfig.API_BASE_URL +
                '/notes/' +
                id +
                '?meetingToken=' +
                meetingToken.token,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            }
        )
    );
};

/**
 * Ajoute une note
 *
 */
const sendNote = async (
    value: string,
    userId: number | string,
    participantId: number | string,
    token: string
) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/notes/add', {
            method: 'post',
            body: JSON.stringify({
                user_id: userId,
                participant_id: participantId,
                text: value
            }),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

/**
 * delete une note
 *
 */
const delNote = async (id: number | string, token: string) => {
    return await resolve(
        cfetch(token, publicRuntimeConfig.API_BASE_URL + '/notes/del', {
            method: 'post',
            body: JSON.stringify({
                id: id
            }),
            headers: { 'Content-Type': 'application/json' }
        })
    );
};

export const APINote = {
    getNotes: getNotes,
    sendNote: sendNote,
    delNote: delNote
};
