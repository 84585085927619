import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import RoomListUsers from '@components/Event/Room/RoomListUsers/RoomListUsers';
import {
    EventOverlay,
    EventSideBar,
    EventWrapperContentLayout,
    EventWrapperLayout,
    EventDarkOverlay,
    EventDarkOverlayCloseBtn
} from '@components/Event/EventLayout.css';
import EventHeader from '@components/Event/EventLayout/EventHeader/EventHeader';
import EventInfos from '@components/Event/EventLayout/EventInfos';
import SimpleBar from 'simplebar-react';
import Hall from '@components/Event/SelectRoom/Hall/Hall';
import { UIActions } from '@actions/ui_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoomAddUserList from '@components/Event/Room/RoomListUsers/RoomAddUserList';
import RoomListVisitors from '@components/Event/Room/RoomListUsers/RoomListVisitors';
import ChatRoom from './Room/ChatRoom/ChatRoom';
import CandidatDrawer from './CandidatDrawer/CandidatDrawer';
import Drawer from '@components/_common/Drawer';

const EventLayout: React.FC = props => {
    const { children } = props;
    const showListUsers = useSelector(
        (state: IStore) => state.UI.showListUsers
    );
    const showRoom = useSelector((state: IStore) => state.UI.showRoom);
    const showEventInfos = useSelector(
        (state: IStore) => state.UI.showEventInfos
    );
    const showAddUser = useSelector((state: IStore) => state.UI.showAddUser);
    const showWaitingList = useSelector(
        (state: IStore) => state.UI.showWaitingList
    );
    const showChat = useSelector((state: IStore) => state.UI.showChat);

    const { isOpen } = useSelector((state: IStore) => state.UI.drawerCandidat);

    const dispatch = useDispatch();

    return (
        <EventWrapperLayout>
            <EventOverlay />
            <EventHeader />
            <EventWrapperContentLayout>
                {showEventInfos && (
                    <EventSideBar>
                        <EventInfos />
                    </EventSideBar>
                )}
                {children}
                {showRoom && (
                    <EventDarkOverlay>
                        <SimpleBar className='scrollable-area'>
                            <Hall />
                        </SimpleBar>
                        <EventDarkOverlayCloseBtn
                            onClick={() => dispatch(UIActions.toggleShowRoom())}
                        >
                            <FontAwesomeIcon icon={['far', 'times']} />
                        </EventDarkOverlayCloseBtn>
                    </EventDarkOverlay>
                )}
                {showListUsers && (
                    <EventSideBar>
                        <RoomListUsers />
                    </EventSideBar>
                )}
                {showAddUser && (
                    <EventSideBar>
                        <RoomAddUserList />
                    </EventSideBar>
                )}
                {showChat && (
                    <EventSideBar>
                        <ChatRoom />
                    </EventSideBar>
                )}
                {showWaitingList && (
                    <EventSideBar>
                        <RoomListVisitors />
                    </EventSideBar>
                )}
            </EventWrapperContentLayout>
            <Drawer visible={isOpen} width={690}>
                <CandidatDrawer />
            </Drawer>
        </EventWrapperLayout>
    );
};

export default EventLayout;
