import {
    MEETING_STATE_ERROR,
    MEETING_STATE_IDLE,
    MEETING_STATE_JOINED,
    MEETING_STATE_JOINING
} from '@constants';
import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { CallActions } from '@actions/call_actions';
import { UIActions } from '@actions/ui_actions';
import { ErrorsActions } from '@actions/errors_actions';
import Selectbox, {
    SelectboxItem
} from '@components/_common/_form/Selectbox/Selectbox';
import { Form, Formik } from 'formik';
import FormLabel from '@components/_common/_form/FormLabel';
import { Container } from '@components/_struct/_layouts/Grid';
import styled from 'styled-components';
import { colors, bp } from '@components/_struct/variables';
import Button from '@components/_common/Button';
import ConfigCam from '@components/Join/ConfigStep/ConfigCam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigActions } from '@actions/config_actions';
import { displayError } from '@components/_common/Toast';
import getConfig from 'next/config';
import jwt from 'jwt-simple';
import { APIMercure } from '@api/mercureApi';
import { useRouter } from 'next/router';
import { AuthActions } from '@actions/auth_actions';
import DropdownLangue from '@components/_common/DropdownLangue';
import { useMSTranslation } from '@utils/useMSTranslation';
const { publicRuntimeConfig } = getConfig();

const MainTitle = styled.h1`
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 50px;
    text-align: center;
    @media (${bp.sm}) {
        font-size: 24px;
        margin-bottom: 20px;
    }
`;

const ProgressBg = styled.div`
    overflow: hidden;
    background: #e3e4e8;
    border-radius: 12px;
    height: 6px;
`;
const ProgressBar = styled.div`
    background: ${colors.main};
    border-radius: 12px;
    height: 100%;
`;

const LanguePlacement = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    @media (${bp.md}) {
        position: relative;
        padding: 10px;
        display: flex;
        top: inherit;
        right: inherit;
        justify-content: flex-end;
    }
`;

const ConfigStep = () => {
    const { __ } = useMSTranslation(['join', 'struct']);

    const callObject = useSelector((state: IStore) => state.call.callObject);
    const meetingState = useSelector(
        (state: IStore) => state.call.meetingState
    );
    const participants = useSelector(
        (state: IStore) => state.call.participants
    );
    const noMic = useSelector((state: IStore) => state.UI.noMic);
    const usedShortToken = useSelector(
        (state: IStore) => state.UI.usedShortToken
    );

    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const infosEvent = useSelector((state: IStore) => state.Data.event);

    const [listMicro, setListMicro]: [
        SelectboxItem[],
        React.Dispatch<React.SetStateAction<any>>
    ] = useState([]);
    const [listAudio, setListAudio]: [
        SelectboxItem[],
        React.Dispatch<React.SetStateAction<any>>
    ] = useState([]);
    const [listCam, setListCam]: [
        SelectboxItem[],
        React.Dispatch<React.SetStateAction<any>>
    ] = useState([]);
    const [infosDevices, setInfosDevices] = useState({
        microphone: '',
        cam: '',
        audio: ''
    });

    const dispatch = useDispatch();

    const [audioProcessor, setAudioProcessor] = useState<any>([]);
    const [volumeMic, setVolumeMic] = useState(0);

    const stopStreamMicro = useCallback(() => {
        if (audioProcessor.length > 0) {
            audioProcessor[0].disconnect();
            audioProcessor[1].disconnect();
            audioProcessor[2].disconnect();
            audioProcessor[3]
                .getTracks()
                .forEach(function (track: MediaStreamTrack) {
                    track.stop();
                });
        }
    }, [audioProcessor]);

    const volumeMeter = useCallback(
        deviceId => {
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: {
                            deviceId:
                                deviceId != 'default'
                                    ? { exact: deviceId }
                                    : undefined
                        },
                        video: false
                    })
                    .then(function (stream) {
                        const AudioContext =
                            window.AudioContext || // Default
                            // @ts-ignore
                            window.webkitAudioContext; // Safari and old versions of Chrome
                        const audioContext = new AudioContext();
                        const analyser = audioContext.createAnalyser();
                        const microphone = audioContext.createMediaStreamSource(
                            stream
                        );
                        const javascriptNode = audioContext.createScriptProcessor(
                            2048,
                            1,
                            1
                        );

                        analyser.smoothingTimeConstant = 0.8;
                        analyser.fftSize = 1024;

                        microphone.connect(analyser);
                        analyser.connect(javascriptNode);
                        javascriptNode.connect(audioContext.destination);

                        javascriptNode.onaudioprocess = function () {
                            const array = new Uint8Array(
                                analyser.frequencyBinCount
                            );
                            analyser.getByteFrequencyData(array);
                            let values = 0;

                            const length = array.length;
                            for (let i = 0; i < length; i++) {
                                values += array[i];
                            }

                            const average = values / length;
                            setVolumeMic(average);
                        };

                        setAudioProcessor([
                            javascriptNode,
                            analyser,
                            microphone,
                            stream
                        ]);
                    });
            } else {
                console.log('getUserMedia not supported');
            }
        },
        [setVolumeMic]
    );

    const updateDevicesList = useCallback(() => {
        let listMicroTmp: SelectboxItem[] = [];
        let listAudioTmp: SelectboxItem[] = [];
        let listCamTmp: SelectboxItem[] = [];

        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true
            })
            .then(function () {
                navigator.mediaDevices
                    .enumerateDevices()
                    .then((value: MediaDeviceInfo[]) => {
                        value.forEach(item => {
                            if (item.kind == 'audiooutput')
                                listAudioTmp.push({
                                    label: item.label,
                                    value: item.deviceId
                                });
                            else if (item.kind == 'audioinput')
                                listMicroTmp.push({
                                    label: item.label,
                                    value: item.deviceId
                                });
                            else if (item.kind == 'videoinput')
                                listCamTmp.push({
                                    label: item.label,
                                    value: item.deviceId
                                });
                        });
                        // En cas de refus du micro, les listes possèdent un élément vide
                        listMicroTmp = listMicroTmp.filter(
                            item => item.label != ''
                        );
                        listAudioTmp = listAudioTmp.filter(
                            item => item.label != ''
                        );
                        listCamTmp = listCamTmp.filter(
                            item => item.label != ''
                        );

                        setListAudio(listAudioTmp);
                        setListMicro(listMicroTmp);
                        setListCam(listCamTmp);

                        // On dispatch noMic/noCam si une liste est vide
                        if (listMicroTmp.length == 0)
                            dispatch(UIActions.setNoMic(true));
                        else dispatch(UIActions.setNoMic(false));
                        if (listCamTmp.length == 0)
                            dispatch(UIActions.setNoCam(true));
                        else dispatch(UIActions.setNoCam(false));
                        // On met à jour le volume meter
                        if (listMicroTmp.length != 0) volumeMeter('default');
                    });
            })
            .catch(function () {
                displayError(
                    __(
                        "Votre navigateur ne dispose pas des autorisations nécessaires pour fonctionner avec Viseet. Vous pouvez tout de même rejoindre l'évènement, mais votre micro et votre caméra sont désactivés.",
                        'join'
                    )
                );
                dispatch(UIActions.setNoMic(true));
                dispatch(UIActions.setNoCam(true));
            });
    }, [__, dispatch, volumeMeter]);

    // Connexion à la room de configuration
    const [firstEffect, setFirstEffect] = useState(true);

    const router = useRouter();

    useEffect(() => {
        if (!callObject && meetingToken && firstEffect) {
            setFirstEffect(false);
            dispatch(
                CallActions.joinOrCreate(
                    meetingToken,
                    'tech_configStep',
                    callObject
                )
            );
        }
    }, [callObject, meetingToken, dispatch, firstEffect]);

    // On va chercher la liste des devices
    useEffect(() => {
        if (!callObject || meetingState != MEETING_STATE_JOINED) return;

        if (
            !navigator ||
            !navigator.mediaDevices ||
            !navigator.mediaDevices.enumerateDevices
        )
            return;

        updateDevicesList();
    }, [callObject, meetingState, dispatch, updateDevicesList]);

    // Mise à jour des péripheriques utilisés
    useEffect(() => {
        meetingState == MEETING_STATE_JOINED &&
            callObject.getInputDevices().then((r: any) => {
                setInfosDevices({
                    cam: r.camera?.deviceId,
                    audio: r.speaker?.deviceId,
                    microphone: r.mic?.deviceId
                });
            });
    }, [meetingState, callObject]);

    const handleChangeDevice = useCallback(
        (device: string, value: any) => {
            if (device === 'audio') {
                setInfosDevices({
                    cam: infosDevices.cam,
                    audio: value,
                    microphone: infosDevices.microphone
                });
                callObject.setOutputDevice({ outputDeviceId: value });
            } else if (device === 'mic') {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: { deviceId: value }
                    })
                    .then(function () {
                        callObject.setInputDevices({
                            audioDeviceId: value,
                            videoDeviceId: infosDevices.cam
                        });
                        setInfosDevices({
                            cam: infosDevices.cam,
                            audio: infosDevices.audio,
                            microphone: value
                        });

                        if (!noMic) stopStreamMicro();
                        // On met à jour le volume meter
                        if (!noMic) volumeMeter(value);
                    })
                    .catch(function () {
                        displayError(
                            __("Ce périphérique n'est pas accessible", 'join')
                        );
                    });
            } else if (device === 'cam') {
                navigator.mediaDevices
                    .getUserMedia({
                        video: { deviceId: value }
                    })
                    .then(function () {
                        setInfosDevices({
                            cam: value,
                            audio: infosDevices.audio,
                            microphone: infosDevices.microphone
                        });
                        callObject.setInputDevices({
                            audioDeviceId: infosDevices.microphone,
                            videoDeviceId: value
                        });
                    })
                    .catch(function () {
                        displayError(
                            __("Ce périphérique n'est pas accessible", 'join')
                        );
                    });
            }
        },
        [
            infosDevices.cam,
            infosDevices.microphone,
            infosDevices.audio,
            callObject,
            noMic,
            stopStreamMicro,
            volumeMeter,
            __
        ]
    );

    // Détection des changements sur les participants
    useEffect(() => {
        if (!callObject) return;

        const events = [
            'participant-joined',
            'participant-updated',
            'participant-left'
        ];

        function handleNewParticipantsState() {
            dispatch(CallActions.setParticipants(callObject.participants()));
        }

        handleNewParticipantsState();

        for (const event of events) {
            callObject.on(event, handleNewParticipantsState);
        }

        return function cleanup() {
            for (const event of events) {
                callObject.off(event, handleNewParticipantsState);
            }
        };
    }, [callObject, dispatch]);

    // Quand le statut du meeting change, on change notre state
    useEffect(() => {
        if (!callObject) return;

        const events = [
            'joining-meeting',
            'joined-meeting',
            'left-meeting',
            'error'
        ];

        function handleNewMeetingState() {
            switch (callObject.meetingState()) {
                case 'joining-meeting':
                    dispatch(
                        CallActions.setMeetingState(MEETING_STATE_JOINING)
                    );
                    break;
                case 'joined-meeting':
                    dispatch(CallActions.setMeetingState(MEETING_STATE_JOINED));
                    break;
                case 'left-meeting':
                    callObject.destroy().then(() => {
                        dispatch(
                            CallActions.setMeetingState(MEETING_STATE_IDLE)
                        );

                        // On reset le state redux
                        dispatch(UIActions.resetState());
                        dispatch(CallActions.resetState());
                        dispatch(ErrorsActions.reset());
                        dispatch(ErrorsActions.resetPage());

                        dispatch(ConfigActions.setConfigDevicesStep(true));
                    });
                    break;
                case 'error':
                    dispatch(CallActions.setMeetingState(MEETING_STATE_ERROR));
                    break;
                default:
                    break;
            }
        }

        handleNewMeetingState();

        for (const event of events) {
            callObject.on(event, handleNewMeetingState);
        }

        return function cleanup() {
            for (const event of events) {
                callObject.off(event, handleNewMeetingState);
            }
        };
    }, [callObject, dispatch]);

    // Détection des changements sur les périphériques
    useEffect(() => {
        if (navigator.mediaDevices)
            navigator.mediaDevices.ondevicechange = function () {
                updateDevicesList();
            };
    }, [updateDevicesList]);

    const soundTest = useCallback((e: SyntheticEvent) => {
        e.preventDefault();
        const sourceJoin1 = document.createElement('source');
        const sourceJoin2 = document.createElement('source');
        sourceJoin1.src = '/sounds/connect.wav';
        sourceJoin2.src = '/sounds/connect.ogg';
        const audioJoin = document.createElement('audio');
        audioJoin.appendChild(sourceJoin1);
        audioJoin.appendChild(sourceJoin2);
        audioJoin.play();
        audioJoin.remove();
    }, []);

    if (!callObject) return <></>;
    const participant = callObject.participants().local;

    const checkUsed = () => {
        let token = '';

        if (typeof router.query.token == 'string')
            token = router.query.token.toString();
        if (infosEvent && token && !usedShortToken) {
            const payloadMercure = {
                mercure: {
                    publish: ['*'],
                    subscribe: [
                        publicRuntimeConfig.LIEN_SITE + infosEvent.roomId,
                        '/.well-known/mercure/subscriptions/' +
                            encodeURIComponent(
                                publicRuntimeConfig.LIEN_SITE +
                                    infosEvent.roomId
                            ) +
                            '{/subscriber}',
                        publicRuntimeConfig.LIEN_SITE +
                            infosEvent.roomId +
                            '-reception',
                        '/.well-known/mercure/subscriptions/' +
                            encodeURIComponent(
                                publicRuntimeConfig.LIEN_SITE +
                                    infosEvent.roomId +
                                    '-reception'
                            ) +
                            '{/subscriber}'
                    ],
                    payload: null
                }
            };
            const JWTtokenMercure = jwt.encode(
                payloadMercure,
                publicRuntimeConfig.SECRET_JWT_MERCURE
            );
            // Vérification event
            APIMercure.getParticipantsEvent(
                JWTtokenMercure,
                publicRuntimeConfig.LIEN_SITE + infosEvent.roomId
            ).then(r => {
                const data = r.data;
                let isUsed =
                    data.subscriptions.filter(
                        (item: any) => item.payload.shortToken === token
                    ).length > 0;

                // Vérification room d'accueil
                APIMercure.getParticipantsEvent(
                    JWTtokenMercure,
                    publicRuntimeConfig.LIEN_SITE +
                        infosEvent.roomId +
                        '-reception'
                ).then(r => {
                    const data = r.data;
                    if (!isUsed)
                        isUsed =
                            data.subscriptions.filter(
                                (item: any) => item.payload.shortToken === token
                            ).length > 0;

                    if (isUsed) {
                        dispatch(UIActions.setUsedShortToken(true));
                        displayError(
                            __(
                                "Quelqu'un est déjà connecté avec cet accès.",
                                'join'
                            )
                        );
                        if (!noMic) stopStreamMicro();
                        callObject.leave();
                        return dispatch(AuthActions.setUser(null));
                    } else {
                        dispatch(
                            ConfigActions.setDevices(
                                JSON.stringify(infosDevices)
                            )
                        );
                        dispatch(CallActions.setRoomId(null));
                        if (!noMic) stopStreamMicro();
                        callObject.leave();
                    }
                });
            });
        } else if (infosEvent && !token) {
            dispatch(ConfigActions.setDevices(JSON.stringify(infosDevices)));
            dispatch(CallActions.setRoomId(null));
            if (!noMic) stopStreamMicro();
            callObject.leave();
        }
    };

    return (
        <div className='flex flex-col md:justify-center md:h-full relative'>
            <LanguePlacement>
                <DropdownLangue variant='dark' />
            </LanguePlacement>
            <Container width='1000'>
                <MainTitle className='mt-2 md:mt-0'>
                    {__('Êtes-vous prêt ?', 'join')}
                </MainTitle>
                <div className='flex flex-col md:flex-row'>
                    <div className='w-full md:w-2/3 px-2'>
                        {participants.length > 0 && participant && (
                            <ConfigCam
                                key={participant.user_id}
                                videoTrack={
                                    participant && participant.videoTrack
                                }
                                isLoading={
                                    meetingState === MEETING_STATE_JOINING ||
                                    participant.videoTrack === false ||
                                    participant.audioTrack === false
                                }
                                userName={participant.user_name}
                                stateMic={callObject.localAudio()}
                                stateCam={callObject.localVideo()}
                            />
                        )}
                        {noMic && (
                            <div className='flex mt-2 items-center'>
                                <FontAwesomeIcon
                                    icon={['far', 'exclamation-triangle']}
                                    className='text-error'
                                />
                                <div className='ml-1 text-main opacity-75'>
                                    {__(
                                        'Aucun micro détecté. Connectez un micro ou vérifiez les paramètres de votre système.',
                                        'join'
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='w-full mt-2 pl-2 pr-2 md:mt-0 md:w-1/3 md:pl-4 flex flex-col justify-center'>
                        <Formik
                            onSubmit={values => {
                                console.log(values);
                            }}
                            initialValues={infosDevices}
                            enableReinitialize={true}
                        >
                            {() => (
                                <Form>
                                    <div className=''>
                                        <div className='text-center md:text-left font-bold text-xl mb-2'>
                                            {__('Vos paramètres', 'join')}
                                        </div>
                                        {listAudio.length > 0 && (
                                            <div>
                                                <FormLabel
                                                    className='select-none'
                                                    link={
                                                        <a
                                                            onClick={soundTest}
                                                            href=''
                                                        >
                                                            {__(
                                                                'Tester le son',
                                                                'join'
                                                            )}
                                                        </a>
                                                    }
                                                >
                                                    {__('Sortie audio', 'join')}{' '}
                                                    :
                                                </FormLabel>
                                                <Selectbox
                                                    name='audio'
                                                    items={listAudio}
                                                    placeholder={__(
                                                        'Sélectionnez une sortie audio',
                                                        'join'
                                                    )}
                                                    onChange={(value: any) => {
                                                        handleChangeDevice(
                                                            'audio',
                                                            value
                                                        );
                                                    }}
                                                    noSearch
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={
                                                listAudio.length > 0
                                                    ? 'mt-2'
                                                    : undefined
                                            }
                                        >
                                            <FormLabel className='select-none'>
                                                {__('Microphone', 'join')} :
                                            </FormLabel>
                                            <Selectbox
                                                name='microphone'
                                                items={listMicro}
                                                placeholder={__(
                                                    'Sélectionnez un micro',
                                                    'join'
                                                )}
                                                onChange={(value: any) => {
                                                    handleChangeDevice(
                                                        'mic',
                                                        value
                                                    );
                                                }}
                                                noSearch
                                            />
                                            <div className='mt-1'>
                                                <ProgressBg>
                                                    <ProgressBar
                                                        style={{
                                                            width:
                                                                volumeMic + '%'
                                                        }}
                                                    />
                                                </ProgressBg>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <FormLabel>
                                                {__('Webcam', 'join')} :
                                            </FormLabel>
                                            <Selectbox
                                                name='cam'
                                                items={listCam}
                                                placeholder={__(
                                                    'Sélectionnez une webcam',
                                                    'join'
                                                )}
                                                onChange={(value: any) => {
                                                    handleChangeDevice(
                                                        'cam',
                                                        value
                                                    );
                                                }}
                                                noSearch
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className='mt-2 md:mt-5 mb-2 text-center'>
                    <Button
                        variant='action'
                        size='large'
                        loadingText={__('Préparation', 'join') + '...'}
                        isLoading={
                            meetingState === MEETING_STATE_IDLE ||
                            meetingState === MEETING_STATE_JOINING ||
                            (participant && participant.videoTrack === false) ||
                            (participant && participant.audioTrack === false)
                        }
                        onClick={() => {
                            checkUsed();
                        }}
                    >
                        {__('Je suis prêt', 'join')}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default ConfigStep;
