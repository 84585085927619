import styled from 'styled-components';
import { bp } from '@components/_struct/variables';
import { Tag } from '@components/_common/Tag';

export const RoomCardWrapper = styled.div`
    position: relative;
    cursor: pointer;
    display: flex;
    min-height: 138px;
    transition: all 0.15s ease;
    color: #fff;
    &:hover {
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.3);
        z-index: 77777;
    }
`;

export const RoomCardPic = styled.div`
    position: relative;
    z-index: 1;
    width: 180px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgba(0, 0, 0, 0.5);
    @media (${bp.md}) {
        width: 100px;
    }
    @media (${bp.sm}) {
        display: none;
    }
`;

export const RoomCardInfos = styled.div`
    position: relative;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M52 0V52H0L52 0Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 20px 40px 20px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${Tag} {
        font-size: 14px;
        text-transform: inherit;
    }
`;
