import React from 'react';
import { CSSProperties } from 'styled-components';
import { CompanyAvatarCont, CompanyAvatarInner } from './Avatar.css';

export interface ICompanyAvatar {
    name: string;
    src?: string;
    size?: string;
    className?: string;
}

interface ICompanyAvatarStyle extends CSSProperties {
    background?: string;
}

const CompanyAvatar: React.FC<ICompanyAvatar> = props => {
    const { src, size, name, className } = props;

    const stringToHslColor = (
        name: string,
        saturation: number,
        lightness: number
    ) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        const h = hash % 360;
        return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
    };

    const style: ICompanyAvatarStyle = {};

    if (!src) {
        style.background = stringToHslColor(name, 60, 60);
    }

    const inner = src ? (
        <img src={src} alt={name} />
    ) : (
        <svg
            aria-hidden='true'
            focusable='false'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 448 512'
        >
            <path
                fill='currentColor'
                d='M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z'
            ></path>
        </svg>
    );

    return (
        <CompanyAvatarCont size={size} className={className}>
            <CompanyAvatarInner size={size} style={style}>
                {inner}
            </CompanyAvatarInner>
        </CompanyAvatarCont>
    );
};

export default CompanyAvatar;
