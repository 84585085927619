import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import { useDispatch, useSelector } from 'react-redux';
import {
    ListUserItem,
    ListUserAvatarCont,
    ListUserItemName,
    ListUserItemState,
    ListUserItemNameWrapper
} from './RoomListUsers.css';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import Button from '@components/_common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CallActions } from '@actions/call_actions';
import UBCheckbox from '@components/_common/_form/Checkbox/UBCheckbox';
import { IStore } from '@interfaces/logic/redux';
import { UIActions } from '@actions/ui_actions';
import OptionsOnUser from '@components/Event/Room/RoomListUsers/RoomUserItem/OptionsOnUser';
import { useMSTranslation } from '@utils/useMSTranslation';

export interface IRoomUserItem {
    userName: string;
    webcam?: boolean;
    microphone?: boolean;
    pinned?: boolean;
    userId: string;
    topic: string;
    activeSpeaker?: boolean;
    onCheck?: (id: string) => void;
    checked?: boolean;
    checkable?: boolean;
    intervenant: boolean;
    noPin?: boolean;
    societeNom?: string;
    societeLogo?: string;
    fonction?: string;
}

const RoomUserItem: React.FC<IRoomUserItem> = props => {
    const {
        userName,
        webcam,
        microphone,
        pinned,
        userId,
        activeSpeaker,
        onCheck,
        checked,
        checkable,
        intervenant,
        noPin,
        societeNom,
        societeLogo,
        fonction
    } = props;
    const { __ } = useMSTranslation(['struct']);

    const userSite = useSelector((state: IStore) => state.Auth.user);
    const dispatch = useDispatch();
    const roomId = useSelector((state: IStore) => state.call.roomId);
    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );

    const participant = participantsConnectedEvent.filter(
        item => item.userId == userId
    )[0];
    if (!participant) return <></>;

    const icoWebcam: [IconPrefix, IconName] = webcam
        ? ['far', 'webcam']
        : ['far', 'webcam-slash'];
    const icoMicrophone: [IconPrefix, IconName] = microphone
        ? ['far', 'microphone']
        : ['far', 'microphone-slash'];

    const canSeeProfil =
        userId &&
        !intervenant &&
        userSite &&
        userSite.intervenant &&
        !participant.intervenant;
    const isClickableClassName = canSeeProfil ? 'is-clickable' : '';
    const isPinnedClassName = pinned ? 'is-pinned' : '';
    const variantButton = pinned ? 'normal-white' : 'outline-white';
    const isSpeakingClassName = activeSpeaker ? 'is-speaking' : '';

    return (
        <ListUserItem className={`${isPinnedClassName} ${isSpeakingClassName}`}>
            {roomId && checkable && (
                <UBCheckbox
                    variant='white'
                    onChange={() => onCheck && onCheck(userId)}
                    checked={checked}
                    name='user-item'
                />
            )}
            <ListUserAvatarCont noPin={noPin}>
                <UserAvatar
                    src={societeLogo === '' ? undefined : societeLogo}
                    name={`${userName}`}
                    size='30'
                />
                {!noPin && (
                    <Button
                        onClick={() =>
                            roomId &&
                            dispatch(CallActions.pinParticipant(userId))
                        }
                        variant={variantButton}
                        onlyIcon
                        leftIcon='thumbtack'
                        size='small'
                    />
                )}
            </ListUserAvatarCont>
            <ListUserItemNameWrapper>
                <ListUserItemName
                    className={`${isClickableClassName}`}
                    onClick={() =>
                        canSeeProfil &&
                        dispatch(UIActions.openDrawerCandidat(userId))
                    }
                >
                    <span className='truncate'>{userName}</span>
                </ListUserItemName>
                {societeNom && fonction && (
                    <div className='text-sm opacity-50 truncate'>
                        {`${fonction} ${__('chez', 'struct')} ${societeNom}`}
                    </div>
                )}
            </ListUserItemNameWrapper>
            {microphone !== undefined && (
                <ListUserItemState
                    style={!microphone ? { opacity: '0.6' } : {}}
                >
                    <FontAwesomeIcon icon={icoMicrophone} />
                </ListUserItemState>
            )}

            {webcam !== undefined && (
                <ListUserItemState style={!webcam ? { opacity: '0.6' } : {}}>
                    <FontAwesomeIcon icon={icoWebcam} />
                </ListUserItemState>
            )}

            {userSite && userId !== userSite.userId && (
                <div className='ml-1'>
                    <OptionsOnUser userId={userId} />
                </div>
            )}
        </ListUserItem>
    );
};

export default RoomUserItem;
