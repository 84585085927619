import React, { useState, SyntheticEvent, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Button from '@components/_common/Button';
import { useSelector, useDispatch } from 'react-redux';
import { APINote } from '@api/noteApi';
import { IStore } from '@interfaces/logic/redux';
import { DataActions } from '@logic/actions/data_actions';
import { useMSTranslation } from '@utils/useMSTranslation';

const NoteInputWrapper = styled.div`
    position: relative;
    border-top: 2px solid #e3e4e8;
    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
`;

const NoteTextarea = styled.textarea`
    background: none;
    border: none;
    resize: none;
    color: #000;
    overflow-x: hidden;
    appearance: none;
    padding: 20px 70px 20px 25px;
    width: 100%;
    outline: none;
`;

interface IProps {
    participant: {
        userId: string;
        name: string;
    };
}

const CandidatNotesInput = (props: IProps) => {
    const { participant } = props;
    const { __ } = useMSTranslation(['event']);

    const [value, setValue] = useState('');
    const TextareaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = useCallback((event: SyntheticEvent) => {
        const elm = event.target as HTMLTextAreaElement;
        setValue(elm.value);

        // algo auto-grow
        const textareaLineHeight = 17.25;
        elm.rows = 1;
        const currentRows = Math.round(
            (elm.scrollHeight - 40) / textareaLineHeight
        );

        if (currentRows >= 3) {
            elm.rows = 3;
        } else {
            elm.rows = currentRows;
        }
    }, []);
    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const dispatch = useDispatch();

    const [loadingSendNote, setLoadingSendNote] = useState(false);

    const sendValue = useCallback(() => {
        if (
            userSite &&
            userSite.userId &&
            participant &&
            participant.userId &&
            ctoken
        ) {
            const valueBackup = value;
            setValue('');
            setLoadingSendNote(true);
            APINote.sendNote(
                valueBackup,
                userSite.userId,
                participant.userId,
                ctoken
            ).then(() => {
                dispatch(DataActions.getNotes(participant.userId, ctoken));
                if (TextareaRef.current) {
                    TextareaRef.current.rows = 1;
                }
                setLoadingSendNote(false);
            });
        }
    }, [userSite, participant, ctoken, value, dispatch]);

    const onEnter = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key == 'Enter') {
                event.preventDefault();
                if (!loadingSendNote) sendValue();
            }
        },
        [sendValue, loadingSendNote]
    );

    return (
        <NoteInputWrapper>
            <NoteTextarea
                placeholder={__('Votre message', 'event') + '...'}
                rows={1}
                onChange={e => handleChange(e)}
                onKeyPress={e => onEnter(e)}
                value={value}
                ref={TextareaRef}
            />
            <Button
                onClick={sendValue}
                disabled={loadingSendNote}
                isLoading={loadingSendNote}
                loadingText={__('Ajout en cours', 'event') + '...'}
            >
                {__('Ajouter la note', 'event')}
            </Button>
        </NoteInputWrapper>
    );
};

export default CandidatNotesInput;
