import styled from 'styled-components';

export const ModalRoot = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
`;

export const ModalContainer = styled.div`
    position: absolute;
    z-index: 1000;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;
`;

export const ModalDialog = styled.div`
    pointer-events: all;
    user-select: text;
`;
