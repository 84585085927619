import styled from 'styled-components';
import { form } from '@components/_struct/variables';

// Last update 08/07/2020

export const Chk = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    &:focus ~ span {
        border-color: ${form.borderFocus};
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
    }
    &:focus:checked ~ span {
        border-color: ${form.borderFocus};
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
    }
    &:checked ~ span {
        border-color: ${form.borderDefault};
        color: #fff;
        &::before {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='%23000'/%3E%3C/svg%3E");
        }
    }
    .white &:focus ~ span {
        border-color: ${form.checkboxWhiteBorderHover};
        box-shadow: 0 0 0 2px ${form.checkboxWhiteboxShadowFocus};
    }
    .white &:checked ~ span {
        border-color: ${form.checkboxWhiteBorderDefault};
        color: #fff;
        &::before {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='%23FFF'/%3E%3C/svg%3E");
        }
    }
    .white &:focus:checked ~ span {
        border-color: ${form.checkboxWhiteBorderHover};
        box-shadow: 0 0 0 2px ${form.checkboxWhiteboxShadowFocus};
    }
`;

export const Label = styled.label`
    display: block;
    position: relative;
    cursor: pointer;
    text-transform: none;
    padding-left: 26px;
    font-size: 15px;
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .checkboxes-horizontal & {
        margin-top: 0;
        margin-right: 20px;
    }
`;

export const ChkIndicator = styled.span`
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 2px solid ${form.borderDefault};
    border-radius: 3px;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    color: #fff;
    background: #fff;
    margin-left: -26px;
    margin-right: 10px;
    margin-top: -3px;
    vertical-align: middle;
    user-select: none;
    &::before {
        display: block;
        width: 18px;
        height: 18px;
        content: '';
        margin: -2px 0 0 -2px;
    }
    ${Label}:hover & {
        border-color: rgba(0, 0, 0, 0.3);
    }
    ${Label}.disabled & {
        cursor: not-allowed;
    }
    ${Label}.disabled:hover & {
        border-color: ${form.borderDefault};
    }
    .white & {
        border: 2px solid ${form.checkboxWhiteBorderDefault};
        background: transparent;
    }
    ${Label}.white:hover & {
        border-color: ${form.checkboxWhiteBorderHover};
    }
`;
