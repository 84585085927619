import React, { useEffect, useCallback } from 'react';
import { ChatRoomWrapper } from './ChatRoom.css';
import { UIActions } from '@actions/ui_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { ListCloseButton } from '@components/Event/Room/RoomListUsers/RoomListUsers.css';
import { useMSTranslation } from '@utils/useMSTranslation';
import { IStore } from '@interfaces/logic/redux';
import SimpleBar from 'simplebar-react';
import ChatMessage from './ChatMessage/ChatMessage';
import ChatInput from './ChatInput/ChatInput';

const ChatRoom = () => {
    const dispatch = useDispatch();
    const { __s } = useMSTranslation([]);
    const scrollableNodeRef = React.createRef<HTMLDivElement>();

    const participants = useSelector(
        (state: IStore) => state.call.participants
    );
    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );
    const messagesRoom = useSelector(
        (state: IStore) => state.Data.messagesRoom
    );
    const roomId = useSelector((state: IStore) => state.call.roomId);

    let nbParticipants = participantsConnectedEvent.length;
    if (roomId) nbParticipants = participants.length;

    const scrollToBottom = useCallback(() => {
        if (scrollableNodeRef && scrollableNodeRef.current) {
            scrollableNodeRef.current.scrollTop =
                scrollableNodeRef.current.scrollHeight;
        }
    }, [scrollableNodeRef]);

    useEffect(() => {
        dispatch(UIActions.setUnreadedMsgCount(0));

        scrollToBottom();
    }, [scrollToBottom, dispatch]);

    return (
        <ChatRoomWrapper>
            <div className='p-2 pb-1 select-none'>
                <div>
                    {__s(
                        nbParticipants,
                        '1 Participant',
                        '{{count}} Participants',
                        'event'
                    )}
                </div>
            </div>
            <SimpleBar
                className='scrollable-area'
                scrollableNodeProps={{ ref: scrollableNodeRef }}
            >
                <div>
                    {messagesRoom && messagesRoom.length > 0
                        ? messagesRoom.map(item => {
                              return (
                                  <ChatMessage
                                      user={item.username}
                                      message={item.msg}
                                      date={item.date}
                                      msgId={item.msgId}
                                      userId={item.userId}
                                      key={item.msgId}
                                      hide={!!item.hide}
                                      sessionId={
                                          item.sessionId ? item.sessionId : ''
                                      }
                                  />
                              );
                          })
                        : ''}
                </div>
            </SimpleBar>
            <ChatInput />
            <ListCloseButton onClick={() => dispatch(UIActions.toggleChat())}>
                <FontAwesomeIcon icon={['far', 'times']} />
            </ListCloseButton>
        </ChatRoomWrapper>
    );
};

export default ChatRoom;
