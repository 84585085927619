import React from 'react';
import styled from 'styled-components';
import { Container } from '@components/_struct/_layouts/Grid';
import { useMSTranslation } from '@utils/useMSTranslation';
import { background, bp } from '@components/_struct/variables';

const SectionMasked = styled.div`
    overflow: hidden;
    position: relative;
    z-index: 1;
`;

const SectionBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${background.lightgray};
    background-image: url('/images/home_03_lines_bg.png');
    background-repeat: no-repeat;
    background-position: top left;
`;

const SectionContainer = styled.div`
    position: relative;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 250px;
    padding-bottom: 250px;
    @media (${bp.md}) {
        padding-top: 155px;
        padding-bottom: 165px;
    }
`;

const SectionBackgroundStart = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    svg {
        fill: #fff;
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 100px;
    }
`;

const SectionBackgroundEnd = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    svg {
        fill: #131a50;
        position: relative;
        display: block;
        width: calc(140% + 1.3px);
        height: 90px;
    }
`;

const TitleSection = styled.div`
    position: relative;
    font-size: 2.5rem;
    color: #1c287f;
    font-weight: bold;
    display: inline-flex;
    &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: -6px;
        width: 19px;
        height: 21px;
        z-index: -1;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='21' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 14.012C24 14.0887 24 14.1655 24 14.2353V14.4236C24 14.4236 24 14.4655 24 14.4864C23.9361 14.888 23.7879 15.2677 23.5672 15.5957C23.4958 15.6958 23.4193 15.7913 23.338 15.8818C18.3577 21.8896 11.8367 26.1067 4.63076 27.9797H4.55438C4.2026 28.0068 3.84953 28.0068 3.49776 27.9797L3.08402 27.8402L2.90579 27.7495C2.85508 27.7258 2.80614 27.6978 2.75939 27.6658C2.64026 27.5847 2.5255 27.4962 2.41568 27.4007C2.14406 27.0787 1.90503 26.7255 1.70277 26.3471C1.70186 26.3262 1.70186 26.3053 1.70277 26.2844V26.2844C1.70277 26.2495 1.70278 26.2216 1.67095 26.1936C1.63913 26.1657 1.60094 25.8936 1.67095 26.082C-0.556984 18.2182 -0.556984 9.79877 1.67095 1.93499V1.93499C1.67095 1.90011 1.67095 1.86522 1.67095 1.83732C1.8088 1.35318 2.07141 0.923048 2.42715 0.598732C2.78288 0.274416 3.21657 0.0697457 3.67598 0.0093701H3.746C3.98004 -0.0115222 4.21559 0.00256154 4.44617 0.0512324H4.49072L4.63076 0.0861163C11.7838 1.95794 18.2603 6.13808 23.2235 12.0864L23.2871 12.1631C23.3508 12.2329 23.4144 12.3096 23.4717 12.3864C23.7004 12.7029 23.8595 13.0729 23.9364 13.4678C23.9534 13.5601 23.9661 13.6532 23.9745 13.7469C23.9745 13.7469 23.9745 13.8097 23.9745 13.8445L24 14.012Z' fill='%2303CE9C'/%3E%3C/svg%3E%0A");
    }
    @media (${bp.md}) {
        font-size: 2rem;
    }
`;

const Block = styled.div`
    background: linear-gradient(
        180deg,
        #fdfdfd 15.81%,
        rgba(255, 255, 255, 0) 91.96%
    );
    border-radius: 12px;
    padding: 20px;
`;

const BgDots = styled.div`
    position: relative;
    z-index: 0;
    &::before {
        display: block;
        width: 110px;
        height: 135px;
        content: '';
        background-image: url('/images/home_03_dots.png');
        background-repeat: no-repeat;
        background-position: bottom left;
        position: absolute;
        top: 0;
        right: -30px;
        z-index: -1;
    }
`;

const MH130 = styled.div`
    height: 130px;
    max-width: 130px;
    margin: 0 auto;
    display: flex;
    align-items: center;
`;

const Col = styled.div`
    @media (${bp.md}) {
        min-width: 270px;
    }
`;

const ForWhoSection = () => {
    const { __ } = useMSTranslation(['home']);
    return (
        <SectionMasked>
            <SectionBackground>
                <SectionBackgroundStart>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 1200 120'
                        preserveAspectRatio='none'
                    >
                        <path d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'></path>
                    </svg>
                </SectionBackgroundStart>
                <SectionBackgroundEnd>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 1200 120'
                        preserveAspectRatio='none'
                    >
                        <path d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'></path>
                    </svg>
                </SectionBackgroundEnd>
            </SectionBackground>
            <SectionContainer>
                <Container>
                    <div className='text-center mb-2'>
                        <TitleSection>
                            {__('Pour qui est fait Viseet ?', 'home')}
                        </TitleSection>
                    </div>
                    <BgDots className='text-2xl lg:text-3xl leading-normal mb-4 text-center'>
                        {__('Conçu pour organiser des', 'home')}{' '}
                        <strong>{__('évènements interactifs', 'home')}</strong>
                    </BgDots>
                    <div className='flex -mx-1 z-0 relative overflow-auto md:overflow-visible'>
                        <Col className='px-1 w-1/4'>
                            <Block>
                                <MH130 className='mb-2'>
                                    <img
                                        src='/images/home_event_externe.svg'
                                        className='w-full'
                                    />
                                </MH130>
                                <div className='font-bold text-lg mb-2'>
                                    {__('Evènements Externes', 'home')}
                                </div>
                                <div className='leading-normal text-gray80'>
                                    {__(
                                        'Viseet apporte son modèle multi-rooms et de personnalisation pour impressionner vos invités. Faites à la fois un événement de communication et de recrutement grâce à notre système de gestion et suivi des participants.',
                                        'home'
                                    )}
                                </div>
                            </Block>
                        </Col>
                        <Col className='px-1 w-1/4'>
                            <Block>
                                <MH130 className='mb-2'>
                                    <img
                                        src='/images/home_event_interne.svg'
                                        className='w-full'
                                    />
                                </MH130>
                                <div className='font-bold text-lg mb-2'>
                                    {__('Evènements Internes', 'home')}
                                </div>
                                <div className='leading-normal text-gray80'>
                                    {__(
                                        'Viseet permet de créer plusieurs ateliers et de naviguer entre chacuns. Retournez dans une salle en groupe pour débriefer puis invitez vous en privée pour développer vos idées en individuel.',
                                        'home'
                                    )}
                                </div>
                            </Block>
                        </Col>
                        <Col className='px-1 w-1/4'>
                            <Block>
                                <MH130 className='mb-2'>
                                    <img
                                        src='/images/home_conference.svg'
                                        className='w-full'
                                    />
                                </MH130>
                                <div className='font-bold text-lg mb-2'>
                                    {__('Conférences et salons', 'home')}
                                </div>
                                <div className='leading-normal text-gray80'>
                                    {__(
                                        'Viseet met à disposition son outil 100% personnalisable pour créer une conférence en ligne à partir de zéro en quelques minutes. Avec des salons, des conférences et du networking mais aussi des salles de réunion en ligne.',
                                        'home'
                                    )}
                                </div>
                            </Block>
                        </Col>
                        <Col className='px-1 w-1/4'>
                            <Block>
                                <MH130 className='mb-2'>
                                    <img
                                        src='/images/home_webinaire.svg'
                                        className='w-full'
                                    />
                                </MH130>
                                <div className='font-bold text-lg mb-2'>
                                    {__('Webinaires', 'home')}
                                </div>
                                <div className='leading-normal text-gray80'>
                                    {__(
                                        "Viseet casse le modèle unilatéral du Webinaire. Faites intervenir vos interlocuteurs pour créer de l'interaction.",
                                        'home'
                                    )}
                                </div>
                            </Block>
                        </Col>
                    </div>
                </Container>
            </SectionContainer>
        </SectionMasked>
    );
};

export default ForWhoSection;
