import React, { useEffect, useState } from 'react';
import {
    CandDrawerWrapper,
    CandidatDrawerHead,
    CandidatDrawerCloseButton
} from './CandidatDrawer.css';
import UserAvatar from '@components/_common/Avatar/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs, { TabPanel } from '@components/_common/Tabs';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import CandidatNotes from './CandidatNotes/CandidatNotes';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import { UIActions } from '@logic/actions/ui_actions';
import { DataActions } from '@logic/actions/data_actions';
import { getNameByPattern } from '@logic/func';
import { useMSTranslation } from '@utils/useMSTranslation';
import Button from '@components/_common/Button';
import { displaySuccess } from '@components/_common/Toast';
import getConfig from 'next/config';
import Linkify from 'react-linkify';
const { publicRuntimeConfig } = getConfig();

const Table = styled.table`
    & tr:nth-child(odd) td {
        background: #f0f2f5;
    }
    td {
        min-height: 36px;
        padding: 10px;
    }
    td:first-child {
        vertical-align: top;
    }
`;

const BtnZone = styled.div`
    position: absolute;
    top: 7px;
    right: 10px;
    z-index: 2;
`;

const linkDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number
) => {
    return (
        <a href={decoratedHref} target='_blank' rel='noreferrer' key={key}>
            {decoratedText}
        </a>
    );
};

const CandidatDrawer = React.memo(() => {
    const dispatch = useDispatch();
    const { __ } = useMSTranslation(['struct']);

    const { userId } = useSelector((state: IStore) => state.UI.drawerCandidat);
    const infosEvent = useSelector((state: IStore) => state.Data.event);
    const notes = useSelector((state: IStore) => state.Data.notes);

    const ctoken = useSelector((state: IStore) => state.Auth.ctoken);

    const [participant, setParticipant] = useState<IParticipant | null>(null);

    const [fullName, setFullName] = useState('');
    const mercureTokenEvent = useSelector(
        (state: IStore) => state.Data.mercureTokenEvent
    );
    const userSite = useSelector((state: IStore) => state.Auth.user);
    const fiche = useSelector((state: IStore) => state.UI.fiche);
    const meetingToken = useSelector(
        (state: IStore) => state.Auth.meetingToken
    );
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const participantsConnectedEvent = useSelector(
        (state: IStore) => state.Data.participantsConnectedEvent
    );

    // On va chercher les infos du participant
    useEffect(() => {
        let participantTmp;
        if (infosEvent) {
            if (infosEvent.type === 'LIBRE') {
                if (userId && userSite) {
                    if (fiche === null)
                        dispatch(
                            DataActions.askForFiche(
                                userId,
                                mercureTokenEvent,
                                userSite.userId
                            )
                        );
                    else {
                        setFullName(fiche.username);
                        setParticipant({
                            userId: '',
                            email: '',
                            fields: fiche.fields,
                            roomId: '',
                            status: 'SELECTED',
                            date: 0
                        });
                    }
                }
            } else if (infosEvent.type === 'INSCRIPTION') {
                if (!ctoken || !userId) return;

                participantTmp = infosEvent.participants.filter(
                    (item: IParticipant) => item.userId == userId
                )[0];

                // On close directement si le participant n'est pas dans la liste des inscrits
                if (typeof participantTmp == 'undefined') return;

                setParticipant(participantTmp);

                const fullNameTmp = getNameByPattern(
                    infosEvent.paternName,
                    participantTmp.firstname,
                    participantTmp.lastname,
                    participantTmp.username
                );
                if (fullNameTmp != undefined) setFullName(fullNameTmp);

                if (ctoken && userId) {
                    dispatch(DataActions.getNotes(userId, ctoken));
                }
            }
        }
    }, [
        dispatch,
        userId,
        infosEvent,
        ctoken,
        fiche,
        mercureTokenEvent,
        userSite
    ]);
    const closeDrawer = () => {
        dispatch(UIActions.closeDrawerCandidat());
        if (infosEvent?.type === 'LIBRE')
            dispatch(UIActions.setDrawerCandidatData(null));
    };

    const inviteRoomTemporaire = () => {
        if (!infosEvent || !ctoken || !meetingToken) return;
        const participant = participantsConnectedEvent.filter(
            item => item.userId == userId
        )[0];

        if (!participant) return;

        dispatch(
            DataActions.addTmpRoom(
                ctoken,
                infosEvent.roomId,
                meetingToken,
                callObject,
                [participant.topic],
                mercureTokenEvent,
                {
                    type: 'invitation_room',
                    nomInvitation: userSite?.userName,
                    topicRetour:
                        publicRuntimeConfig.LIEN_MERCURE +
                        'users/' +
                        userSite.userId
                }
            )
        );
        displaySuccess(
            __(
                "Une proposition a été envoyée à {{username}}, il vous rejoindra en cas d'acceptation",
                'event',
                { username: participant.userName }
            )
        );
        closeDrawer();
    };

    CandidatDrawer.displayName = 'CandidatDrawer';

    return (
        <CandDrawerWrapper>
            <CandidatDrawerCloseButton onClick={closeDrawer}>
                <FontAwesomeIcon icon={['far', 'times']} />
            </CandidatDrawerCloseButton>
            <CandidatDrawerHead>
                <div>
                    <UserAvatar name={fullName} size='64' />
                </div>
                <div className='flex-1 ml-2 text-xl text-white font-bold'>
                    {fullName}
                </div>
            </CandidatDrawerHead>
            <div className='p-1 flex-1 min-h-0 relative z-0'>
                <BtnZone>
                    <Button variant='action' onClick={inviteRoomTemporaire}>
                        Inviter en privé
                    </Button>
                </BtnZone>

                <Tabs defaultActiveKey='1' variant='alt' fullHeight>
                    <TabPanel tab='Profil' key='1'>
                        <SimpleBar className='scrollable-area'>
                            <div className='p-2'>
                                <Table className='w-full border-collapse'>
                                    <colgroup>
                                        <col width='35%' />
                                        <col width='75%' />
                                    </colgroup>
                                    <tbody>
                                        {participant && participant.email && (
                                            <tr>
                                                <td>{__('Email', 'struct')}</td>
                                                <td className='font-bold'>
                                                    {participant.email}
                                                </td>
                                            </tr>
                                        )}
                                        {participant &&
                                            participant.fields &&
                                            participant.fields.map(
                                                (item: any, index: number) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.name.includes(
                                                                'file'
                                                            )
                                                                ? item.labelField
                                                                : item.name}
                                                        </td>
                                                        <td className='font-bold'>
                                                            {item.name.includes(
                                                                'file'
                                                            ) ? (
                                                                <a
                                                                    href={
                                                                        item.values
                                                                    }
                                                                    target='_blank'
                                                                    rel='noreferrer'
                                                                >
                                                                    {
                                                                        item.fileName
                                                                    }
                                                                </a>
                                                            ) : (
                                                                <Linkify
                                                                    componentDecorator={
                                                                        linkDecorator
                                                                    }
                                                                >
                                                                    {
                                                                        item.values
                                                                    }
                                                                </Linkify>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </Table>
                            </div>
                        </SimpleBar>
                    </TabPanel>
                    {infosEvent?.type !== 'LIBRE' && (
                        <TabPanel tab='Note' key='2' notif={notes?.length}>
                            {participant && (
                                <CandidatNotes
                                    notes={notes}
                                    participant={participant}
                                />
                            )}
                        </TabPanel>
                    )}
                </Tabs>
            </div>
        </CandDrawerWrapper>
    );
});

export default CandidatDrawer;
