import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@components/_common/Button';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '@interfaces/logic/redux';
import {
    MEETING_STATE_IDLE,
    MEETING_STATE_JOINED,
    MEETING_STATE_JOINING
} from '@constants';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import DropdownPopOver from '@components/_common/DropdownPopOver';
import { Formik, Form } from 'formik';
import FormLabel from '@components/_common/_form/FormLabel';
import Selectbox, {
    SelectboxItem
} from '@components/_common/_form/Selectbox/Selectbox';
import { UIActions } from '@actions/ui_actions';
import { bp } from '@components/_struct/variables';
import { isMobile } from 'react-device-detect';
import DropdownLangue from '@components/_common/DropdownLangue';
import { useMSTranslation } from '@utils/useMSTranslation';

const ControlsWrapper = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    border-radius: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    z-index: 25;
    @media (${bp.md}) {
        position: fixed;
        height: 50px;
        padding: 0 6px;
        bottom: 5px;
    }
    > * + * {
        margin-left: 20px;
        @media (${bp.md}) {
            margin-left: 10px;
        }
    }
`;

const LangueWrapper = styled.div`
    background: #e3e4e8;
    border-radius: 12px 12px 0 0;
`;

const Controls = () => {
    const callObject = useSelector((state: IStore) => state.call.callObject);
    const callStatus = useSelector((state: IStore) => state.call.meetingState);
    const noCam = useSelector((state: IStore) => state.UI.noCam);
    const noMic = useSelector((state: IStore) => state.UI.noMic);
    const devices = useSelector((state: IStore) => state.Config.devices);
    const needReception = useSelector(
        (state: IStore) => state.Config.needReception
    );
    const { __ } = useMSTranslation(['event']);

    const dispatch = useDispatch();

    const icoWebcam: IconName = callObject.localVideo()
        ? 'webcam'
        : 'webcam-slash';

    const icoMicro: IconName = callObject.localAudio()
        ? 'microphone'
        : 'microphone-slash';

    const [listMicro, setListMicro]: [
        SelectboxItem[],
        React.Dispatch<React.SetStateAction<any>>
    ] = useState([]);
    const [listAudio, setListAudio]: [
        SelectboxItem[],
        React.Dispatch<React.SetStateAction<any>>
    ] = useState([]);
    const [listCam, setListCam]: [
        SelectboxItem[],
        React.Dispatch<React.SetStateAction<any>>
    ] = useState([]);

    const leaveCall = () => {
        if (!callObject) return;
        callObject.leave();
    };

    // On peut en avoir besoin un jour ...
    /*const addFakeParticipant = () => {
        if (!callObject) return;
        callObject.addFakeParticipant();
    };*/

    const [infosDevices, setInfosDevices] = useState({
        microphone: '',
        cam: '',
        audio: ''
    });

    // On va chercher la liste des devices
    useEffect(() => {
        if (!callObject || callStatus != MEETING_STATE_JOINED) return;
        if (
            !navigator ||
            !navigator.mediaDevices ||
            !navigator.mediaDevices.enumerateDevices
        )
            return;

        const listMicroTmp: SelectboxItem[] = [];
        const listAudioTmp: SelectboxItem[] = [];
        const listCamTmp: SelectboxItem[] = [];

        navigator.mediaDevices
            .enumerateDevices()
            .then((value: MediaDeviceInfo[]) => {
                value.forEach(item => {
                    if (item.kind == 'audiooutput')
                        listAudioTmp.push({
                            label: item.label,
                            value: item.deviceId
                        });
                    else if (item.kind == 'audioinput')
                        listMicroTmp.push({
                            label: item.label,
                            value: item.deviceId
                        });
                    else if (item.kind == 'videoinput')
                        listCamTmp.push({
                            label: item.label,
                            value: item.deviceId
                        });
                });
                setListAudio(listAudioTmp);
                setListMicro(listMicroTmp);
                setListCam(listCamTmp);

                // On dispatch noMic/noCam si une liste est vide
                if (listMicroTmp.length == 0)
                    dispatch(UIActions.setNoMic(true));
                if (listCamTmp.length == 0) dispatch(UIActions.setNoCam(true));
            });
    }, [callObject, callStatus, dispatch]);

    //Recup des péripheriques utilisés depuis Config
    useEffect(() => {
        if (callStatus == MEETING_STATE_JOINED && devices) {
            callObject.setInputDevices({
                audioDeviceId: JSON.parse(devices).microphone,
                videoDeviceId: JSON.parse(devices).cam
            });
        }
    }, [callStatus, callObject, devices]);

    // Mise à jour des péripheriques utilisés
    useEffect(() => {
        if (callStatus == MEETING_STATE_JOINED && devices) {
            setTimeout(() => {
                // Hack daily on reforce le device de sortie apres 5s
                callObject.setOutputDevice({
                    outputDeviceId: JSON.parse(devices).audio
                });
                Promise.resolve(callObject.getInputDevices())
                    .then((r: any) => {
                        setInfosDevices({
                            cam: r.camera?.deviceId,
                            audio: r.speaker?.deviceId,
                            microphone: r.mic?.deviceId
                        });
                    })
                    .catch((err: any) => console.log(err));
            }, 5000);
        }
    }, [callStatus, callObject, devices]);

    const handleChangeDevice = useCallback(
        (device: string, value: any) => {
            if (device === 'audio') {
                callObject.setOutputDevice({ outputDeviceId: value });
                setInfosDevices({
                    cam: infosDevices.cam,
                    audio: value,
                    microphone: infosDevices.microphone
                });
            } else if (device === 'mic') {
                callObject.setInputDevices({
                    audioDeviceId: value,
                    videoDeviceId: infosDevices.cam
                });
                setInfosDevices({
                    cam: infosDevices.cam,
                    audio: infosDevices.audio,
                    microphone: value
                });
            } else if (device === 'cam') {
                callObject.setInputDevices({
                    audioDeviceId: infosDevices.microphone,
                    videoDeviceId: value
                });
                setInfosDevices({
                    cam: value,
                    audio: infosDevices.audio,
                    microphone: infosDevices.microphone
                });
            }
        },
        [infosDevices, callObject]
    );

    const handleScreenShare = useCallback(() => {
        if (!callObject) return;

        if (callObject.participants().local.screen)
            callObject.stopScreenShare();
        else callObject.startScreenShare();
    }, [callObject]);

    return (
        <ControlsWrapper>
            <DropdownPopOver placement='top-center'>
                <DropdownPopOver.Trigger>
                    <Button
                        variant='clear-white'
                        onlyIcon
                        leftIcon='cog'
                        disabled={
                            callStatus == MEETING_STATE_IDLE ||
                            callStatus == MEETING_STATE_JOINING
                        }
                    />
                </DropdownPopOver.Trigger>
                <DropdownPopOver.Popover>
                    <Formik
                        onSubmit={values => {
                            console.log(values);
                        }}
                        initialValues={infosDevices}
                        enableReinitialize={true}
                    >
                        {() => (
                            <Form>
                                <LangueWrapper className='py-1 px-2'>
                                    <DropdownLangue
                                        variant='dark'
                                        placement='bottom-start'
                                    />
                                </LangueWrapper>
                                <div
                                    className='p-2'
                                    style={{ minWidth: '350px' }}
                                >
                                    {listAudio.length > 0 && (
                                        <div>
                                            <FormLabel className='select-none'>
                                                {__('Sortie audio', 'event')} :
                                            </FormLabel>
                                            <Selectbox
                                                name='audio'
                                                items={listAudio}
                                                placeholder={__(
                                                    'Sélectionnez une sortie audio',
                                                    'event'
                                                )}
                                                onChange={(value: any) => {
                                                    handleChangeDevice(
                                                        'audio',
                                                        value
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div
                                        className={
                                            listAudio.length > 0
                                                ? 'mt-2'
                                                : undefined
                                        }
                                    >
                                        <FormLabel className='select-none'>
                                            {__('Microphone', 'event')} :
                                        </FormLabel>
                                        <Selectbox
                                            name='microphone'
                                            items={listMicro}
                                            placeholder={__(
                                                'Sélectionnez un micro',
                                                'event'
                                            )}
                                            onChange={(value: any) => {
                                                handleChangeDevice(
                                                    'mic',
                                                    value
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <FormLabel>
                                            {__('Webcam', 'event')} :
                                        </FormLabel>
                                        <Selectbox
                                            name='cam'
                                            items={listCam}
                                            placeholder={__(
                                                'Sélectionnez une webcam',
                                                'event'
                                            )}
                                            onChange={(value: any) => {
                                                handleChangeDevice(
                                                    'cam',
                                                    value
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DropdownPopOver.Popover>
            </DropdownPopOver>

            {noCam ? (
                <DropdownPopOver placement='top-center'>
                    <DropdownPopOver.Trigger>
                        <div className='opacity-75'>
                            <Button
                                variant='delete'
                                onlyIcon
                                leftIcon={'webcam-slash'}
                                disabled={
                                    callStatus == MEETING_STATE_IDLE ||
                                    callStatus == MEETING_STATE_JOINING
                                }
                            />
                        </div>
                    </DropdownPopOver.Trigger>
                    <DropdownPopOver.Popover>
                        <div
                            className='p-2 text-center'
                            style={{ minWidth: '280px' }}
                        >
                            <div className='font-bold select-none'>
                                {__('Aucune caméra détectée', 'event')}
                            </div>
                        </div>
                    </DropdownPopOver.Popover>
                </DropdownPopOver>
            ) : (
                <Button
                    variant='clear-white'
                    onlyIcon
                    onClick={() =>
                        callObject.setLocalVideo(!callObject.localVideo())
                    }
                    leftIcon={icoWebcam}
                    disabled={
                        callStatus == MEETING_STATE_IDLE ||
                        callStatus == MEETING_STATE_JOINING
                    }
                />
            )}

            {noMic ? (
                <DropdownPopOver placement='top-center'>
                    <DropdownPopOver.Trigger>
                        <div className='opacity-75'>
                            <Button
                                variant='delete'
                                onlyIcon
                                leftIcon={'microphone-slash'}
                                disabled={
                                    callStatus == MEETING_STATE_IDLE ||
                                    callStatus == MEETING_STATE_JOINING
                                }
                            />
                        </div>
                    </DropdownPopOver.Trigger>
                    <DropdownPopOver.Popover>
                        <div
                            className='p-2 text-center'
                            style={{ minWidth: '280px' }}
                        >
                            <div className='font-bold select-none'>
                                {__('Aucun microphone détecté', 'event')}
                            </div>
                        </div>
                    </DropdownPopOver.Popover>
                </DropdownPopOver>
            ) : (
                <Button
                    variant='clear-white'
                    onlyIcon
                    onClick={() =>
                        callObject.setLocalAudio(!callObject.localAudio())
                    }
                    leftIcon={icoMicro}
                    disabled={
                        callStatus == MEETING_STATE_IDLE ||
                        callStatus == MEETING_STATE_JOINING
                    }
                />
            )}

            {!isMobile && (
                <Button
                    variant={
                        callStatus == MEETING_STATE_JOINED &&
                        callObject.participants().local &&
                        callObject.participants().local.screen
                            ? 'clear-green'
                            : 'clear-white'
                    }
                    blink={
                        !!(
                            callStatus == MEETING_STATE_JOINED &&
                            callObject.participants().local &&
                            callObject.participants().local.screen
                        )
                    }
                    onlyIcon
                    onClick={() => handleScreenShare()}
                    leftIcon={'desktop'}
                    disabled={
                        callStatus == MEETING_STATE_IDLE ||
                        callStatus == MEETING_STATE_JOINING
                    }
                />
            )}
            {!needReception && (
                <DropdownPopOver placement='top-center'>
                    <DropdownPopOver.Trigger>
                        <Button
                            disabled={
                                callStatus == MEETING_STATE_IDLE ||
                                callStatus == MEETING_STATE_JOINING
                            }
                            variant='red'
                            onlyIcon
                            leftIcon='sign-out'
                        />
                    </DropdownPopOver.Trigger>
                    <DropdownPopOver.Popover>
                        <div
                            className='p-2 text-center'
                            style={{ minWidth: '280px' }}
                        >
                            <div className='font-bold select-none'>
                                {__(
                                    'Etes-vous certain de vouloir quitter cette conférence ?',
                                    'event'
                                )}
                            </div>
                            <div className='mt-2'>
                                <Button
                                    variant='red'
                                    onClick={() => leaveCall()}
                                >
                                    {__('Quitter la room', 'event')}
                                </Button>
                            </div>
                        </div>
                    </DropdownPopOver.Popover>
                </DropdownPopOver>
            )}
        </ControlsWrapper>
    );
};

export default Controls;
