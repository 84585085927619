import React from 'react';
import { Chk, Label, ChkIndicator } from './Checkbox.css';

// Last update 08/07/2020

interface UBCheckbox {
    label?: string;
    value?: string;
    id?: string;
    checked?: boolean;
    onChange?: (checked?: boolean) => void;
    name?: string;
    disabled?: boolean;
    variant?: 'white';
    widthChildrens?: string;
}

const UBCheckbox = (props: UBCheckbox) => {
    const {
        id,
        label,
        checked,
        onChange,
        widthChildrens,
        disabled,
        variant,
        ...rest
    } = props;

    const disabledClassName = disabled ? 'disabled' : '';

    const variantClassName = variant && variant == 'white' ? 'white' : '';

    return (
        <>
            <Label
                style={{ width: widthChildrens }}
                className={`${disabledClassName} ${variantClassName}`}
            >
                <Chk
                    {...rest}
                    disabled={disabled}
                    checked={checked}
                    onChange={() => onChange && onChange(checked)}
                    type='checkbox'
                    id={id}
                />
                <ChkIndicator />
                {label && label}
            </Label>
        </>
    );
};

export default React.memo(UBCheckbox);
