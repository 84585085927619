import React from 'react';
import Modal from '.';
import ConfirmLayout from '@components/_struct/_layouts/ModalLayout/ConfirmLayout';

interface IModalConfirm {
    visible: boolean;
    onClose: () => void;
    onSubmit: () => void;
    onCancel?: () => void;
    title: string;
    description: string | JSX.Element;
}

const Confirm: React.FC<IModalConfirm> = React.memo(
    ({ visible, onClose, title, description, onSubmit, onCancel }) => {
        Confirm.displayName = 'Confirm';
        return (
            <Modal visible={visible} onClose={onClose} noCloseOverlay={true}>
                <ConfirmLayout
                    title={title}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                >
                    {description}
                </ConfirmLayout>
            </Modal>
        );
    }
);

export default Confirm;
